export const colorArray = [
    '#dd001c',
    "#d05134",
    "#9f4e44",
    "#ffa500",
    '#370375',
    "#FF6633",
    "#e3e31b",
    "#FFB399",
    "#FF33FF",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
    '#370375'
];
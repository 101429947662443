import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../config/constant';
import { toast } from 'react-toastify';
import axiosInstance from '../../Dashboard/httpInterceptor';
import Loader from '../../../React-Loader/loader';
import {Link,useHistory} from  'react-router-dom'
import moment from 'moment';
import Footer from '../../../Components/Footer/footer';
import Header from '../../../Components/Header/header';
import { SubHeader } from '../Home/SubHeader';

function MyP2pOrders() {
  const routeHistory = useHistory()
   const [loading, setloading] = useState(false);
   const [Orders, setOrders] = useState([]);
   const [statusEnum, setStatusEnum] = useState();
const [user, setUser] = useState(null)

useEffect(() => {
    const usrstr = localStorage.getItem('ECannaUser')
    const user = usrstr ? JSON.parse(usrstr): null;
    setUser(user)
    if(!user){
      routeHistory.push('/login')
      localStorage.clear()
    }
}, [])


   useEffect(() => {
        if (user) {
            getStatusEnum()
            getMyOrder(user._id);
        }
   }, [user])
   
   const getMyOrder = (userId)=>{
    setloading(true)
    axiosInstance.get(`${BASE_URL}get/my-p2p-orders?userId=${userId}`).then((res)=>{
        setOrders(res.data.data)
    }).finally(()=>{ 
        setloading(false)
    })
   }
   const getStatusEnum = ()=>{
    setloading(true)
    axiosInstance.get(BASE_URL+'get/status-enum').then((res)=>{
        setStatusEnum(res.data.data)
    }).finally(()=>{ 
    })
   }

  return (
    <>
     <Header loadFees={false} />
      <SubHeader  />
   {loading && <Loader />}
   <div className="content">
    <table className="table" style={{ minWidth: 1000 }}>
      <thead>
        <tr>
          <th>COIN</th>
          <th>DATE</th>
          <th>ORDER TYPE </th>
          <th>PRICE</th>
          <th>CRYPTO AMOUNT</th>
          <th>TOTAL FIAT AMOUNT</th>
          <th>STATUS</th>
          <th>ID</th>
        </tr>
      </thead>
      <tbody>
        {Orders
          // .filter((e) => e.type === "Buy")
          .map((data,ind) => (
            <tr key={'order'+ind} style={{fontSize: 12}}>
                <td>
                    <div className='d-flex'>
                    <img src={"/coinicon/" + data.assetCoin + ".png"} width="16px"  height="16px" alt=""  />
                    <div className='ml-2'> {data.assetCoin}</div>
                    </div>
                   </td>
                <td>{moment(data.createdOn).format('DD/MM/YYYY HH:mm ')}</td>
                <td>{data.type}</td>
                <td>{data.price}</td>
                <td>{data.amount} <small>{data.assetCoin}</small></td>
                <td>{data.amount * data.price}<small> {data.fiatCoin}</small></td>
                <td>{statusEnum && <div>{statusEnum[data.status]}</div>} </td>
                <td className='txt-yellow-5'>
                    <Link to={`/p2p-orders/${data._id}`}>{data._id}</Link>
                </td>
            </tr>
          ))}
      </tbody>
    </table>
   </div>
    <Footer />
    </>
  );
}

export default MyP2pOrders
import React,{useEffect, useState} from 'react';
// import Axios from 'axios';
import Axios from '../../Pages/Dashboard/httpInterceptor';
import { BASE_URL, IMAGE_START_URL } from '../../config/constant';
import { Link, Redirect } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Loader from '../../React-Loader/loader';

function HomeBannerSection() {
const [loading, setLoading] = useState(false)
  const [banner, setBanner] = useState('')
  const [topPairDetails, setTopPairDetails] = useState([
    {
      pairWithSeparator:'BTC/USDT',
      pair: 'BTCUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'ETH/USDT',
      pair: 'ETHUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'XRP/USDT',
      pair: 'XRPUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'XLM/USDT',
      pair: 'XLMUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'TRX/USDT',
      pair: 'TRXUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
  ]);
const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('ECannaUser'));
  const updatePairPrice = (data) =>{
    setTopPairDetails(existing => existing.map(pr => pr.pair == data.s ? 
      {
        ...pr,
        price: (+data.c).toFixed(4),
        percentageChange:data.P,
        priceChange: (+data.p).toFixed(4)
      } : pr))
  }
  useEffect(() => {
    const conn = new WebSocket("wss://stream.binance.com:9443/ws/!ticker@arr");
    conn.onopen = function(evt) {
      // console.log(evt);
        // conn.send(JSON.stringify({ method: "subscribe", topic: "allMiniTickers", symbols: ["$all"] }));
    }
    conn.onmessage = function(evt) {
      try {
        let msgs = JSON.parse(evt.data);
        if (Array.isArray(msgs)) {
          for (let msg of msgs) {
            updatePairPrice(msg);
          }
        } else {
          // updatePairPrice(msgs)
        }
      } catch (e) {
        console.log('Unknown message: ' + evt.data, e);
      }
    }
    return () => {
      conn.close()
    };
  }, []);
  
  useEffect(() => {
    setLoading(true)
    Axios.get(`${BASE_URL}banner/get`)
    .then((response) => {
      setLoading(false)
        if(response.data.data && response.data.data.length){
            setBanner(response.data.data[0].fileName)
        }
    }).catch((e)=>{
      setLoading(false)
    })
  }, [])
    return(
//       <section id="top_banner"> 
//       <img src="images/graph_bg.png" className="graph_bg" alt="img" />
//       <img src="images/banner/image002.jpg" className="graph_bg" alt="img" />
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-7 banner_txt">
//               <h1>  A trusted and secure <span>cryptocurrency exchange</span></h1>
//           <p>Here at E-Canna, we are committed to user protection with strict protocols and industry-leading technical measures.
// </p>
//           <a href="#" className="orange_btn">
//           <Link to="/register" >  Get Started Now</Link>
//          </a>
//               </div> 
//            <div className="col-lg-5 banner_img text-center">
//          <img src="images/banner_coin.png" alt="img" />
//           </div>
//           </div>
//         </div>
//       </section>
<section > 
  {loading && <Loader />}
{/* <img src="images/graph_bg.png" className="graph_bg" alt="img" /> */}
{/* <div className='row'>
      <div className='col-md-12 banner_fixed'>
<img src="images/banner/image002.jpg" alt="img" />

      </div>
</div> */}





<div className='row'>
      <div className='col-md-12 banner_fixed'>
<img src={IMAGE_START_URL+"/"+banner} alt="img" />

      </div>
</div>
{isLoggedIn && <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 24}}>
<Link to="/buyecna" className="btn-banner"> <span><img src={`/images/mini-logo.png`} alt='' style={{height:30, width:30}}/></span> Click Here To Book Your E-Canna Coin</Link>
</div>}
  
</section>
    )
}

export default HomeBannerSection;

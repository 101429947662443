import React, { useEffect, useState } from 'react'
import { BASE_URL, IMAGE_START_URL } from '../../../config/constant';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../../Dashboard/httpInterceptor';
import Loader from '../../../React-Loader/loader';
import {Link, useParams, useHistory} from  'react-router-dom'
import moment from 'moment';
import Footer from '../../../Components/Footer/footer';
import Header from '../../../Components/Header/header';
import { SubHeader } from '../Home/SubHeader';
import Countdown from 'react-countdown';
import P2PChat from '../chat/P2PChat';

function P2pOrderDetails() {
  const routeHistory = useHistory()
   const [loading, setloading] = useState(false);
   const [Orders, setOrders] = useState();
   const [SellerOrder, setSellerOrder] = useState();
   const [statusEnum, setStatusEnum] = useState();
    const [user, setUser] = useState(null)
    const [orderId, setOrderId] = useState(null);
    const [uploadedFile, setUploadedFile] = useState()
    const [showChat, setShowChat] = useState(false)
    const [customChatId, setCustomChatId] = useState('')
    const [userDetails, setUserDetails] = useState();
    const [secondUserDetails, setSecondUserDetails]= useState('')
    const { id } = useParams();

    const tmr = Date.now() + 5000;
    
    useEffect(() => {
        const usrstr = localStorage.getItem('ECannaUser')
        const user = usrstr ? JSON.parse(usrstr): null;
        setUser(user)
        setOrderId(id);
        if(!user){
          routeHistory.push('/login');
          localStorage.clear()
        }
}, [])


   useEffect(() => {
        if (orderId) {
            getStatusEnum()
            getMyOrder(orderId);
           
        }
   }, [orderId])
   const getUserDetails =(id, secondUserId)=>{
    axiosInstance.post(`${BASE_URL}userInformation/get`,{id}).then((res)=>{
      setUserDetails(res.data.data)
    })
  if(secondUserId){
    axiosInstance.post(`${BASE_URL}userInformation/get`,{id:secondUserId}).then((res)=>{
      setSecondUserDetails(res.data.data)
    })
  }
   }
   const getMyOrder = (orderId)=>{
    setloading(true)
    axiosInstance.get(`${BASE_URL}get/order-details?id=${orderId}`).then((res)=>{
      setOrders(res.data.data)
      setloading(false)
      if(res.data?.data?.matchedWith){
        if(res.data?.data?.type === "Buy"){
          getMatchingOrder(res.data?.data?.matchedWith)
          setCustomChatId(res.data?.data?._id )
        }else{
          setSellerOrder(res.data?.data)
          setCustomChatId(res.data?.data?._id)
          // setCustomChatId(res.data.data.matchedWith+res.data.data._id)

        }
      }
      getUserDetails(res.data?.data?.userId, res.data?.data?.matchingOrderUserId)
    }).finally(()=>{ 
        setloading(false)
    })
   }
   const getMatchingOrder = (orderId)=>{
    setloading(true)
    axiosInstance.get(`${BASE_URL}get/matching-order-details?matchedOrderId=${orderId}`).then((res)=>{
      setSellerOrder(res.data.data)
    }).finally(()=>{ 
        setloading(false)
    })
   }
   const getStatusEnum = ()=>{
    setloading(true)
    axiosInstance.get(BASE_URL+'get/status-enum').then((res)=>{
        setStatusEnum(res.data.data)
    }).finally(()=>{ 
    })
   }
   const notifyTransfer =(order)=>{
    if (Orders?.receipt && Orders?.receipt?.length) {
      setloading(true)
      axiosInstance.post(BASE_URL+'fund-transfer/notify-seller',{sellOrderId: order.matchedWith}).then((res)=>{
        setloading(false)
        getMyOrder(orderId)
      }).finally(()=>{ 
        setloading(false)
      })
    }else{
      toast.error('Upload payment receipt!')
    }
   }
   const updateOrderStatus =(id, status)=>{
    setloading(true)
    axiosInstance.post(BASE_URL+'p2p/buy-order/cancel',{status: status, orderId: id}).then((res)=>{
      setloading(false)
      getMyOrder(orderId)
    }).finally(()=>{ 
      setloading(false)
    })
   }
   const cancelPendingOrder =(id)=>{
    setloading(true)
    axiosInstance.post(BASE_URL+'p2p/cancel-pendingOrder',{orderId: id}).then((res)=>{
      setloading(false)
      getMyOrder(orderId)
    }).finally(()=>{ 
      setloading(false)
    })
   }
   const verifyPaymentBySeller =(order)=>{
    setloading(true)
    axiosInstance.post(BASE_URL+'fund-received/notify-buyer',{sellOrderId: order._id}).then((res)=>{
      setloading(false)
      getMyOrder(orderId)
    }).finally(()=>{ 
      setloading(false)
    })
   }
   const raiseDispute =(id)=>{
    setloading(true)
    axiosInstance.post(BASE_URL+'p2p/raise/dispute',{sellOrderId: id}).then((res)=>{
      setloading(false)
      getMyOrder(orderId)
    }).finally(()=>{ 
      setloading(false)
    })
   }
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      if (orderId && Orders && Orders?.status === 2 ) { //if status is "Processing"
        // alert(seconds)
        updateOrderStatus(orderId, 5)
      }
      return<></>
    } else {
      return <span> <span style={{fontSize: '10px'}} ></span> <strong className='txt-yellow-5'>{minutes} : {seconds}</strong> </span>;
    }
  };
  useEffect(() => {
   if ( Orders && Orders?.status === 2 && Orders?.orderMatchedAt+ 1000*15*60 < Date.now()) {
    updateOrderStatus(orderId, 5)
   }
  }, [Orders])
  
  const uploadReceipt = (e)=>{
    setloading(true)
    const formData = new FormData();
    formData.set('file', e.target.files[0])
    formData.set('orderId', orderId)
    axiosInstance.post(BASE_URL+'p2p/uploadReceipt',formData).then((res)=>{
      setUploadedFile(res.data?.data)
      getMyOrder(orderId)
      setloading(false)
    }).finally(()=>{ 
      setloading(false)
    })
  }
  const handleChatClose =()=>{
    setShowChat(false)
  }
  return (
    <>
      <Header loadFees={false} />
      <SubHeader />
      <ToastContainer />
      {loading && <Loader />}
     {userDetails &&  secondUserDetails && <P2PChat show={showChat} handleClose={handleChatClose} user={userDetails} orderId={orderId} refetchUser={getUserDetails} secondUserDetails={secondUserDetails} />}
      <div className="content">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 form-input">
          
            {Orders?.type === "Sell" ? (
              <div className="badges badges-red">
                Warning !! Prior to click on confirm button, please ensure that
                your respective assets received to your account.
              </div>
            ) : Orders?.type === "Buy" ? (
              <div className="badges badges-red">
                Warning ! Prior to click on transferred button ,please ensure
                that you have transferred the respective assets successfully and
                uploaded the screenshot.
              </div>
            ) : null}

            <hr />
            <div className='d-flex flex-end'>
           {userDetails && secondUserDetails && <button className='btn btn-info' onClick={()=> setShowChat(true)}>Chat</button>}
            </div>
            <div className="d-flex flex-between">
              <div className="" style={{ marginLeft: 0, marginBottom: 10 }}>
                <span
                  className={
                    Orders?.type === "Buy"
                      ? "badges badges-green"
                      : "badges badges-red"
                  }
                >
                  {Orders?.type}
                </span>
                <span className="font-12"> {Orders?.assetCoin}</span>
                {statusEnum && (
                  <div
                    className={
                      Orders?.status === 2
                        ? "txt-yellow-w-5"
                        : Orders?.status === 5
                        ? "txt-red"
                        : "txt-green"
                    }
                  >
                    {" "}
                    Order{" "}
                    <i className="font-w-500">
                      {" "}
                      {statusEnum[Orders?.status]}
                    </i>{" "}
                  </div>
                )}
              </div>
              <div>
                {Orders?.orderMatchedAt + 1000 * 15 * 60 > Date.now() &&
                  Orders?.status === 2 && (
                    <Countdown
                      date={Orders?.orderMatchedAt + 1000 * 15 * 60}
                      renderer={renderer}
                    />
                  )}
              </div>
            </div>
            <div
              className="coin-name "
              style={{ marginLeft: 0, marginBottom: 10 }}
            >
              Confirm order info
            </div>
            <hr />
            <div className="d-flex flex-between ">
              <div>
                <label className="text-md">Date</label>
                <div className="font-12">
                  {Orders
                    ? moment(Orders?.createdOn).format("DD/MM/YYYY, HH:mm ")
                    : ""}
                </div>
              </div>
              <div>
                <label className="text-md">Amount</label>
                <div className=" all">{Orders?.amount}</div>
              </div>
              <div>
                <label className="text-md">Price</label>
                <div className="font-12">{Orders?.price}</div>
              </div>
              <div>
                <label className="text-md">Total</label>
                <div className="font-12">
                  {Orders?.amount * Orders?.price || ""}
                </div>
              </div>
              <div>
                <label className="text-md">Order Id</label>
                <div className="font-12">{Orders?._id}</div>
              </div>
            </div>
            <div className="coin-name mt-4" style={{ marginLeft: 0 }}>
              Payment Method
            </div>
            {SellerOrder?.status >= 2 ? (
              <>
                <div>
                  {SellerOrder?.paymentMethods?.UPI && (
                    <div className="pm-bg-1">
                      <div className="d-flex flex-between">
                        <strong className="txt-black">UPI</strong>
                      </div>
                      <label className="mt-3">
                        <span className="txt-black font-w-600">Name: </span>
                        {SellerOrder?.paymentMethods?.UPI.userName}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">UPI: </span>
                        {SellerOrder?.paymentMethods?.UPI.upiAddress}
                      </label>
                      {SellerOrder?.paymentMethods?.UPI.qr && (
                        <div>
                          <img
                            src={SellerOrder?.paymentMethods?.UPI.qr}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {SellerOrder?.paymentMethods?.IMPS && (
                    <div className="pm-bg-1">
                      <div className="d-flex flex-between">
                        <strong className="txt-black">IMPS</strong>
                      </div>
                      <label className="mt-3">
                        <span className="txt-black font-w-600">Name: </span>
                        {SellerOrder?.paymentMethods?.IMPS.userName}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">
                          Account No:{" "}
                        </span>
                        {SellerOrder?.paymentMethods?.IMPS.accountNo}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">
                          IFSC Code:{" "}
                        </span>
                        {SellerOrder?.paymentMethods?.IMPS.ifsc}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">
                          Bank Name:{" "}
                        </span>
                        {SellerOrder?.paymentMethods?.IMPS.bankName}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">
                          Account Type:{" "}
                        </span>
                        {SellerOrder?.paymentMethods?.IMPS.accountType}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">Branch: </span>
                        {SellerOrder?.paymentMethods?.IMPS.branchName}
                      </label>
                    </div>
                  )}

                  {SellerOrder?.paymentMethods?.BankTransfer && (
                    <div className="pm-bg-1">
                      <div className="d-flex flex-between">
                        <strong className="txt-black">
                          Bank Transfer (India)
                        </strong>
                      </div>
                      <label className="mt-3">
                        <span className="txt-black font-w-600">Name: </span>
                        {SellerOrder?.paymentMethods?.BankTransfer.userName}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">
                          Account No:{" "}
                        </span>
                        {SellerOrder?.paymentMethods?.BankTransfer.accountNo}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">
                          IFSC Code:{" "}
                        </span>
                        {SellerOrder?.paymentMethods?.BankTransfer.ifsc}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">
                          Bank Name:{" "}
                        </span>
                        {SellerOrder?.paymentMethods?.BankTransfer.bankName}
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">
                          Account Type:{" "}
                        </span>
                        {
                          SellerOrder?.paymentMethods?.BankTransfer
                            .accountType
                        }
                      </label>
                      <br />
                      <label>
                        <span className="txt-black font-w-600">Branch: </span>
                        {SellerOrder?.paymentMethods?.BankTransfer.branchName}
                      </label>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div>Payment method can't be displayed for this order.</div>
            )}

            <div className="d-flex flex-between mt-4">
              <div>
                {Orders?.type === "Buy" &&
                  (Orders?.status === 2 || Orders?.status === 4) && (
                    <div>
                      <label htmlFor="receipt">
                        <strong>Upload Transaction Receipt</strong>
                      </label>
                      <input
                        type="file"
                        name="receipt"
                        id="receipt"
                        onChange={(e) => uploadReceipt(e)}
                      />
                    </div>
                  )}
              </div>
              {Orders?.receipt &&
                Orders?.receipt?.length &&
                Orders?.receipt?.map((receipt) => (
                  <a
                    className="btn ml-2"
                    href={IMAGE_START_URL + "/" + receipt.file}
                    target="_blank"
                  >
                    <img
                      src="/images/Receipt-Template.jpg"
                      alt=""
                      height={100}
                      width={150}
                    />
                  </a>
                ))}
            </div>
            <div className="d-flex flex-between">
              {Orders?.type === "Buy" && (
                <div>
                  {Orders?.status === 2 ? (
                    <button
                      className="btn bg-yellow-5 txt-white font-w-600"
                      onClick={() => notifyTransfer(Orders)}
                    >
                      Transfered, notify seller
                    </button>
                  ) : null}
                  {Orders?.status === 2 ? (
                    <button
                      className="btn ml-2"
                      onClick={() => updateOrderStatus(Orders._id, 5)}
                    >
                      Cancel Order
                    </button>
                  ) : null}
                 
                </div>
              )}
               
              {Orders?.type === "Sell" && (
                <div>
                  {Orders?.status >= 2 && Orders?.status < 5 && (
                    <button
                      className="btn bg-yellow-5 txt-white font-w-600"
                      onClick={() => verifyPaymentBySeller(Orders)}
                    >
                      Payment Received, notify buyer
                    </button>
                  )}
                  {Orders?.status > 2 && Orders?.status < 5 ? (
                    <button
                      className="btn ml-2"
                      onClick={() => raiseDispute(Orders._id)}
                    >
                      Dispute
                    </button>
                  ) : null}
                </div>
              )}
               {Orders?.status < 2 ? (
                    <button
                      className="btn ml-2"
                      onClick={() => cancelPendingOrder(Orders._id)}
                    >
                      Cancel Order
                    </button>
                  ) : null}
            </div>
          </div>
          {/* <div className="col-md-4">
           
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default P2pOrderDetails
import Axios from 'axios';
import BigNumber from 'bignumber.js';
import Binance from 'binance-api-react-native';
import $ from "jquery";
import moment from 'moment';
import React, { Component } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import { Link } from "react-router-dom";
import io from 'socket.io-client';
import { isArray } from 'util';
import '../../App.css';
import Header from "../../Components/Header/header";
import { BASE_URL, WEB_SOCKET_URL } from "../../config/constant";
import Loader from '../../React-Loader/loader';
import axios from '../Dashboard/httpInterceptor';
import { decimalData } from './decimalDataForAllCoins';
import { MarginOrderDetailsModal } from './MarginOrderDetailsModal';
import OrderBookAsk from './OrderBookAsk';
import OrderBookBid from './OrderBookBid';
import { TVChartContainer } from './tradeChart/trading-chart';
import TradeHistory from './TradeHistory';
import { YourMarginDetailsModal } from './YourMarginDetalis';
const client = Binance()
const wazirxTestApiKey = 'wN8DVr0wJSWxJlovZB3iVhV8zwLNCLIwnhAyUiRAxSTwN3nKwowe9UFS4ufiwwUV'

// const socket = io.connect("wss://bithind.com:2087");
const socket = io.connect("");


const validNumber = RegExp(/^[0-9.]*$/);
var priceValid = new RegExp(/^[0-9.]{1,11}(\.\d{1,8})?$/);
// var priceValid = new RegExp(/^[0-9.]+(\.[0-9]{1,2})?$/);
// const validNumber = RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
// /(\d{0,2})[^.]*((?:\.\d{0,4})?)/g

class MarginTrade extends Component {
marginLeverage= 3;
marginFee= 0.1;
marginTDS= 1;
maintenanceMarginRate=20/100; //20% 
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      LodingOrder: false,
      exchangeType: "Binance",
      // ticker: 0,
      // allticker: [],
      trades: [],
      tradehistory: [],
      depth: [],
      userData: [],
      tokenData: [],
      // tickerPrice: [],
      // coinCode: null,
      // pairCode: null,
      coinCode: 'BTC',
      pairCode: 'USDT',
      isSellModalOpen: false,
      loadFeeSubs: false,
      isBuyModalOpen: false,
      userBalanceCoin: 0,
      userBalancePair: 0,
      userBalanceFESS: 0,
      FESSstakeamt: 0,
      tradingPercent: 0.1,
      userId: 0,
      errors: "",
      themeMode: true,
      theme : "dark_mode",
      openOrderrows: [],
      cancelOrderrows: [],
      historyOrderrows: [],
      buyType: "MARKET",
      sellType: "MARKET",
      socketConnect: false,
      minAmt: 0,
      minTotal: 0,
      priceDecimal: 0,
      amtDecimal: 0,
      myfavcoin: [],
      myorderPrice: [],
      doctitle: " E-Canna",
      feesPacks: [],
      // buyOrderMode: 'LIMIT',
      // sellOrderMode: 'LIMIT',
      manageFee: false,
      type: "",
      borrowMode_buy : "NORMAL",
      borrowMode_sell : "NORMAL",
      type_SPOT_CROSS : this.props.match.params.type,

      buyDisable : false,
      sellDisable : false,
      cancelDisable :false,

      loading : false,
      activePositions :[],
      marginPortfolio: [],
      activeTableTab: 'portfolio'
    };
    let rows = [];
    this.tradesCount = 100;
    this.streams = ['@depth20', '@trade'];
    // this.streams = ['@ticker','@depth20','@trade'];
    this.inrStream = ''
  }

  // _getTickerBySymbol(data) {
  //     let ticker = {}
  //     data.forEach(item => {
  //         let symbol = item.symbol || item.s;
  //         ticker[symbol] = {
  //             symbol: symbol,
  //             lastPrice: item.lastPrice || item.c,
  //             priceChange: item.priceChange || item.p,
  //             priceChangePercent: item.priceChangePercent || item.P,
  //             highPrice: item.highPrice || item.h,
  //             lowPrice: item.lowPrice || item.l,
  //             quoteVolume: item.quoteVolume || item.q,
  //         }
  //     }) 
  //     return ticker;
  // }
inrStreamConnection = ()=>{
  if (!this.props.match.params.coinCode) {
    return;
  }
  const currentPair = (this.props.match.params.coinCode + this.props.match.params.pairCode).toLowerCase()
  const streams={"event":"subscribe","streams":["!ticker@arr", currentPair+"@depth",currentPair+"@trades"]}
  const conn = new WebSocket(`wss://stream.wazirx.com/stream`);
  conn.onopen = function(evt) {
  conn.send(JSON.stringify(streams))
  }
  conn.onmessage = eventData => {
    // console.log('first');
    if (eventData.data) {
      
      const temp = JSON.parse(eventData.data);
      // console.log(temp.stream);
      if ( temp && temp.stream && temp.stream.endsWith('ticker@arr')) {   
        temp && temp.data && Array.isArray(temp.data) && temp.data.forEach(dt => {
          $("." + dt.s.toUpperCase() + "_price").html(new BigNumber( dt.c).toFormat(null, 1))
        });
        const dArray = temp && temp.data && Array.isArray(temp.data) ? temp.data.filter(e=> e.s == currentPair ): [];
        const dt = dArray && dArray.length >0? dArray[0]:{}
        if (currentPair == dt.s) {
    
          // document.title = dt.s + ' | Bithind'
          this.setState({ doctitle: new BigNumber(dt.c).toFormat(null, 1) + ' | ' + dt.s + ' | E-Canna' })
          $("." + dt.s.toUpperCase() + "_priceChange").html(new BigNumber(dt.p).toFormat(null, 1))
          $("." + dt.s.toUpperCase() + "_priceHigh").html(new BigNumber(dt.h).toFormat(null, 1))
          $("." + dt.s.toUpperCase() + "_priceLow").html(new BigNumber(dt.l).toFormat(null, 1))
          $("." + dt.s.toUpperCase() + "_priceVolume").html(new BigNumber(dt.q).toFormat(2, 1))
  
         
          // this.setState({ [dt.s.toUpperCase()+'_priceChangePercent']:  dt.P })
          // $("." + dt.s.toUpperCase() + "_priceChangePercent").html(new BigNumber( dt.P).toFormat(2, 1))
  
        }
      }
      if (temp && temp.stream && temp.stream.endsWith('@depth') && this.props.match.params.pairCode === 'INR') {
        const orderBookData = {
          "asks": temp.data.a,
          "bids": temp.data.b,
        }
        this.setState({ depth: orderBookData });
      }
      if (temp && temp.stream && temp.stream.endsWith('@trades') &&  this.props.match.params.pairCode === 'INR') {
        this.setState({ tradehistory: "" });

        var trades = this.state.trades.slice();
        trades.push(temp.data.trades[0]);
        this.setState({ trades: trades });

        // trades.push(eventData.data);

        trades = trades.slice(-1 * this.tradesCount);
        this.setState({ trades: trades });
      }
    }
  }
}
testWS(userid){
  const conn = new WebSocket(`${WEB_SOCKET_URL}?userId=${userid}`);
  conn.onmessage =(e)=>{
    alert(e)
  }
}
  _connectTickerStreams(streams) {
    streams = streams.join('/');
    let connection = btoa(streams);
    // let connectionnew = btoa(streams);  
    if(streams == '//'){
      streams =  (this.props.match.params.coinCode + this.props.match.params.pairCode).toLowerCase();
    }
    this[connection] = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${streams}`);
    // this[connectionnew] = io.connect('wss://stream.binance.com:9443/stream?streams='+streams); 

    // client.ws.depth('ETHBTC', depth => { 
    //   console.log(depth)
    // }) 

    // console.log("stream",streams[0])
    var lastsockettimeNew = 0
    var currenttimestampNew
  
    this[connection].onmessage = evt => {
      currenttimestampNew = Math.floor(Date.now() / 1000)

      // lastsockettimeNew = currenttimestampNew + 0;

      let eventData = JSON.parse(evt.data);

      if (eventData.stream.endsWith('@ticker')) {
        // let decimals
        // if(eventData.data.s.endsWith('BTC')) {
        //   decimals = 8
        // }
        // if(eventData.data.s.endsWith('ETH')) {
        //   decimals = 6
        // }
        // if(eventData.data.s.endsWith('USDT')) {
        //   decimals = 4
        // } 
        // console.log("tokendata",eventData.data)
        var lastPrice = $("." + eventData.data.s + "_price").html();
        if(lastPrice!=undefined){
          lastPrice =  lastPrice.replace(/,/g, "");
        }
        
        // console.log('I think main data eventData',eventData,lastPrice);
        // $(document).prop('title', eventData.data.s + ' | Bithind'); 

        if (Number(lastPrice) > eventData.data.c) {
          $("." + eventData.data.s + '_priceColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceColor').removeClass("green_clr")
          $("." + eventData.data.s + '_priceColor').addClass("red_clr")

          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-up")
          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-down")
          $("#" + eventData.data.s + "_priceArrow").addClass("fa-arrow-down")
          // this.setState({ [eventData.data.s+'_priceColor']: "red_clr"})
        } else if (Number(lastPrice) < eventData.data.c) {
          $("." + eventData.data.s + '_priceColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceColor').removeClass("green_clr")
          $("." + eventData.data.s + '_priceColor').addClass("green_clr")

          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-up")
          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-down")
          $("#" + eventData.data.s + "_priceArrow").addClass("fa-arrow-up")
          // this.setState({ [eventData.data.s+'_priceColor']: "green_clr" }) 
        } else {

          $("." + eventData.data.s + '_priceColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceColor').removeClass("green_clr")
          // this.setState({ [eventData.data.s+'_priceColor']: "" }) 
        }

        if (eventData.data.P > 0) {

          $("." + eventData.data.s + '_priceChangePercentColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceChangePercentColor').removeClass("green_clr")
          $("." + eventData.data.s + '_priceChangePercentColor').addClass("green_clr")
        } else {

          $("." + eventData.data.s + '_priceChangePercentColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceChangePercentColor').removeClass("green_clr")
          $("." + eventData.data.s + '_priceChangePercentColor').addClass("red_clr")
        }

        // this.setState({ [eventData.data.s+'_price']: eventData.data.c })
        let decimalForCoin = 0;
        if(eventData.data.s.substr(eventData.data.s.length - 4)  == "USDT"){
          decimalForCoin = 3;
        }else if(eventData.data.s.substr(eventData.data.s.length - 3)  == "BTC"){
          decimalForCoin = 6;
        }else if(eventData.data.s.substr(eventData.data.s.length - 3)  == "ETH"){
          decimalForCoin = 4;
        }

        $("." + eventData.data.s + "_price").html(new BigNumber(eventData.data.c).toFormat(decimalForCoin, 1))
        // this.setState({ [eventData.data.s+'_priceChangePercent']: eventData.data.P })
        $("." + eventData.data.s + "_priceChangePercent").html(new BigNumber(eventData.data.P).toFormat(2, 1))

        if ((this.props.match.params.coinCode + this.props.match.params.pairCode) == eventData.data.s) {


          // document.title = eventData.data.s + ' | Bithind'
          this.setState({ doctitle: new BigNumber(eventData.data.c).toFormat(null, 1) + ' | ' + eventData.data.s + ' | E-Canna' })
          $("." + eventData.data.s + "_priceChange").html(new BigNumber(eventData.data.p).toFormat(null, 1))
          $("." + eventData.data.s + "_priceHigh").html(new BigNumber(eventData.data.h).toFormat(null, 1))
          $("." + eventData.data.s + "_priceLow").html(new BigNumber(eventData.data.l).toFormat(null, 1))
          $("." + eventData.data.s + "_priceVolume").html(new BigNumber(eventData.data.q).toFormat(2, 1))
        }


        //       eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0
        //       this.setState({ticker: eventData.data});


      }


      // let ticker = this._getTickerBySymbol(JSON.parse(evt.data).data)
      // this.props.dispatch({
      //     type: 'UPDATE_MARKET_PAIRS',
      //     data: ticker
      // })

      // this.setState({allticker: ticker});
      // console.log(ticker)

      // if(this.state.tokenData) {
      //   this.state.tokenData.forEach(item => {





      //     data = '<tr style={{borderBottom:"1px solid rgba(255,255,255,0.1)"}}>
      //     <td style={{padding:"10px 10px"}}><i className="fa fa-star"></i></td>
      //     <td style={{padding:"10px 0px"}}><a href="#">{d.tokenSymbol}/USDT</a></td>
      //     <td style={{padding:"10px 0px"}}>

      //       {/* {this.state.allticker.ETHBTC.lastPrice} */}

      //     </td> 
      //     <td style={{padding:"10px 0px"}} className="red_clr">-0.21%</td> 
      // </tr>'
      // if(this.state.allticker[''+item.tokenSymbol+'USDT']) {

      //   if(this.state[item.tokenSymbol+'USDT_price'] > this.state.allticker[''+item.tokenSymbol+'USDT'].lastPrice) {
      //     this.setState({ [item.tokenSymbol+'USDT_priceColor']: "red_clr"})
      //   } else if(this.state[item.tokenSymbol+'USDT_price'] < this.state.allticker[''+item.tokenSymbol+'USDT'].lastPrice) {
      //     this.setState({ [item.tokenSymbol+'USDT_priceColor']: "green_clr" })
      //   } else {
      //     this.setState({ [item.tokenSymbol+'USDT_priceColor']: "" })
      //   }

      //   this.setState({ [item.tokenSymbol+'USDT_price']: this.state.allticker[''+item.tokenSymbol+'USDT'].lastPrice })
      //   this.setState({ [item.tokenSymbol+'USDT_priceChangePercent']: this.state.allticker[''+item.tokenSymbol+'USDT'].priceChangePercent })


      // }


      // if(item.tokenSymbol == 'BTC' || item.tokenSymbol == 'ETH') {

      // } else {
      //   if(this.state.allticker[''+item.tokenSymbol+'ETH']) {

      //     if(this.state[item.tokenSymbol+'ETH_price'] > this.state.allticker[''+item.tokenSymbol+'ETH'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'ETH_priceColor']: "red_clr"})
      //     } else if(this.state[item.tokenSymbol+'ETH_price'] < this.state.allticker[''+item.tokenSymbol+'ETH'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'ETH_priceColor']: "green_clr" })
      //     } else {
      //       this.setState({ [item.tokenSymbol+'ETH_priceColor']: "" })
      //     }

      //   this.setState({ [item.tokenSymbol+'ETH_price']: this.state.allticker[''+item.tokenSymbol+'ETH'].lastPrice })
      //   this.setState({ [item.tokenSymbol+'ETH_priceChangePercent']: this.state.allticker[''+item.tokenSymbol+'ETH'].priceChangePercent })

      //   }
      // }

      // if(item.tokenSymbol == 'BTC') {

      // } else {
      //   if(this.state.allticker[''+item.tokenSymbol+'BTC']) {

      //     if(this.state[item.tokenSymbol+'BTC_price'] > this.state.allticker[''+item.tokenSymbol+'BTC'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'BTC_priceColor']: "red_clr"})
      //     } else if(this.state[item.tokenSymbol+'BTC_price'] < this.state.allticker[''+item.tokenSymbol+'BTC'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'BTC_priceColor']: "green_clr" })
      //     } else {
      //       this.setState({ [item.tokenSymbol+'BTC_priceColor']: "" })
      //     }

      //   this.setState({ [item.tokenSymbol+'BTC_price']: this.state.allticker[''+item.tokenSymbol+'BTC'].lastPrice })
      //   this.setState({ [item.tokenSymbol+'BTC_priceChangePercent']: this.state.allticker[''+item.tokenSymbol+'BTC'].priceChangePercent })

      //   }
      // }

      // console.log(this.state.ETHUSDT_price)
      // console.log(item.tokenSymbol)
      //   })
      // }




      // !this.props.active_market.market && this._handleTabClick('BTC')
      // this.setState({
      //   isLoaded: true
      // })

    
    }
 

    this[connection].onerror = evt => {
      console.error(evt);
    }
  }


  _connectSocketStreams(streams) {

    streams = streams.join('/');
    let connection = btoa(streams);
    
    this[connection] = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${streams}`);

    var lastsockettime = 0;
    var currenttimestamp
   
    this[connection].onmessage = evt => {
      currenttimestamp = Math.floor(Date.now() / 1000)

    if(currenttimestamp > lastsockettime) {
      lastsockettime = currenttimestamp + 1;

      // console.log("lastsockettime",Date.now())
      let eventData = JSON.parse(evt.data);

      // if(eventData.stream.endsWith('@ticker')){
      //     eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0
      //     this.setState({ticker: eventData.data});
      //     // console.log("ticket",this.state.ticker.s.length)
      //     // this.props.dispatch({
      //     //     type: 'SET_TICKER',
      //     //     data: eventData.data
      //     // })     
      //     this.setState({
      //         loadedTicker: true
      //     })   
      // }


      if (eventData.stream.endsWith('@trade') && this.props.match.params.pairCode !== 'INR') {

        // if(this.state.trades && Object.keys(this.state.trades).length > 0){


        // $('#tradehistorytable').prepend('<tr className="coinhover tradehistoryclick" onclick="this.tradehistoryclick"style="cursor:pointer"><td>'+new BigNumber(eventData.data.p).toFormat(null,1)+'</td><td>'+new BigNumber(eventData.data.q).toFormat(null,1)+'</td><td>'+new Date(eventData.data.T).toLocaleTimeString()+'</td></tr>'); 

        // $('#tradehistorytable').html(<><tr className="coinhover tradehistoryclick" ><td>sadaadsa</td></tr></>);  

        // $('#tradehistorytable tr:last').remove();   

        // console.log(eventData.data) 
        // E: 1590227905352 
        // M: true
        // T: 1590227905350
        // a: 2277982279
        // b: 2277982282
        // e: "trade"
        // m: false
        // p: "9130.48000000"
        // q: "0.29765100"
        // s: "BTCUSDT"
        // t: 326511267
        // this.setState({trades: eventData.data});
        // let trades = this.state.trades;

        
          // alert("hi")
        this.setState({ tradehistory: "" });

        var trades = this.state.trades.slice();
        trades.push(eventData.data);
        this.setState({ trades: trades });

        // trades.push(eventData.data);

        trades = trades.slice(-1 * this.tradesCount);
        this.setState({ trades: trades });
        
        // trades = trades.slice(-1*this.tradesCount);
        // console.log("trades",this.state.trades[2].q)


        // this.props.dispatch({ 
        //     type: 'SET_TRADES',
        //     data: trades
        // }) 
        // this.setState({
        //     loadedTrades: true
        // }) 
        // }              
      }
      if (eventData.stream.endsWith('@depth20') && this.props.match.params.pairCode !== 'INR') {
        // this.props.dispatch({
        //     type: 'SET_DEPTH',
        //     data: eventData.data
        // }) 

        this.setState({ depth: eventData.data });

        // console.log(this.state.depth.bids.length)
        // this.setState({
        //     loadedDepth: true
        // })  
      }

    }

      // this.setState({
      //     isLoaded: true
      // })  
    };
 
    this[connection].onerror = evt => {
      console.error(evt);
    }
  }


  handleBuyModal() {
    // $("#buy_modal").modal("hide");
    $('#buy_trade_click_active').removeClass('active');
    $('#sell_trade_click_active').removeClass('active');
    $('#buy_trade_click_active').addClass('active');
    $('#buy_trade_click').trigger('click');
    // $("#sell_modal").modal("hide");
    // $("#buy_modal").modal("show");
  }

  handleSellModal() {
    // $("#sell_modal").modal("hide");
    $('#buy_trade_click_active').removeClass('active');
    $('#sell_trade_click_active').removeClass('active');
    $('#sell_trade_click_active').addClass('active');
    $('#sell_trade_click').trigger('click');
    // $("#buy_modal").modal("hide");
    // $("#sell_modal").modal("show");
  }

  handleCloseModal() {
    $("#buy_modal").modal("hide");
    $("#sell_modal").modal("hide");
  }

  getmyfavcoin() {
    axios.post(BASE_URL + `favcoin/get`, {
      "userId": this.state.userId
    })
      .then(res => {
        // console.log("favcoin",res);
        if (res.data.message == "success") {
          this.setState({
            myfavcoin: res.data.data,
          })
          res.data.data.map(row => {
            this.setState({ [row.coin + row.pair + '_favcoin']: true })
          })

        }


      })
  }

  async getBithindDetails() {
    if (this.state.exchangeType == "E-Canna") {
      await axios.post(BASE_URL + `trade/history/get`, {
        "coinName": this.props.match.params.coinCode,
        "pair": this.props.match.params.pairCode,
      })
        .then(res => {
          // console.log("tradehistory",res.data.data) 
          if (res !== null) {
            var tradesStates = res.data.data.map(row => {
              var dataRow = {
                "p": Number(row.price).toFixed(6),
                "q": Number(row.amount).toFixed(6),
                "T": Number(row.timestamp + "001"),
                "E" : Number(row.timestamp + "001"),
                "Mode" : row.orderType
              }
              return dataRow
            }).reverse()
            // console.log("tradehistoryrow",tradesStates) 
            this.setState({ trades: tradesStates });
          }
        })


      await axios.post(BASE_URL + `order/open`, {
        "coinName": this.props.match.params.coinCode,
        "pair": this.props.match.params.pairCode,
      })
        .then(res => {
          // console.log("tradehistoryCCCC",res)
          if (res.data.message !== "noRecordsFound") {

            var sellOrders = res.data.data.sell.map(row => {
              var dataRow = [
                Number(row.price).toFixed(6),
                Number(row.amount).toFixed(6)
              ]
              // var dataRow = [Number(row.price).toFixed(6),Number(row.amount).toFixed(6)]

              return dataRow
            }).reverse()

            var buyOrders = res.data.data.buy.map(row => {
              var dataRow = [
                Number(row.price).toFixed(6),
                Number(row.amount).toFixed(6)
              ]
              // var dataRow = [Number(row.price).toFixed(6),Number(row.amount).toFixed(6)]

              return dataRow
            }).reverse()

            var openOrderBookData = {
              "asks": sellOrders,
              "bids": buyOrders,
            }

            // asks: [["0.00081132", "3691.00000000"], ["0.00081133", "7659.00000000"], ["0.00081137", "3297.00000000"],…]
            // bids: [["0.00081032", "9851.00000000"], ["0.00081031", "7659.00000000"], ["0.00081030", "9853.00000000"],…]

            this.setState({ depth: openOrderBookData });
            // depth.asks 
            // console.log("tradehistory",res.data.data) 
            // console.log("tradehistorystate",this.state.depth) 
            // console.log("tradehistorystate",openOrderBookData) 
          }
        })


    }
  }

  async getBithindPrice() {
    // axios({
    //   method: 'get',
    //   // url: `https://cors-anywhere.herokuapp.com/https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
    //   url: `https://bithind.com/dataApi/getlastprice`
    // })
    //   .then(response => {

    //     // console.log("tradedata",response.data)

    //     {
    //       this.state.tokenData.map((d, idx) => {
    //         if (d.tActive == true) {
    //           if (d.BTC == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "BTC"])) {

    //               if (response.data["exch" + d.tokenSymbol + "BTC"].length > 0) {
    //                 $("." + d.tokenSymbol + "BTC_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].close).toFormat(2, 1))

    //                 if (Number(response.data["exch" + d.tokenSymbol + "BTC"][0].close) < Number(response.data["exch" + d.tokenSymbol + "BTC"][0].open)) {
    //                   $("." + d.tokenSymbol + 'BTC_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "BTC_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "BTC"][0].close) > Number(response.data["exch" + d.tokenSymbol + "BTC"][0].open)) {
    //                   $("." + d.tokenSymbol + 'BTC_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "BTC_priceArrow").addClass("fa-arrow-up")
    //                 }


    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "BTC"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "BTC"][0].close - response.data["exch" + d.tokenSymbol + "BTC"][0].open) / response.data["exch" + d.tokenSymbol + "BTC"][0].open) * 100).toFormat(2, 1)
    //                 }
    //                 $("." + d.tokenSymbol + "BTC_priceChangePercent").html(percentage)

    //                 $("." + d.tokenSymbol + "BTC_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "BTC"][0].close) - Number(response.data["exch" + d.tokenSymbol + "BTC"][0].open)).toFormat(2, 1))

    //                 $("." + d.tokenSymbol + "BTC_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "BTC_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "BTC_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].high).toFormat(2, 1))

    //               }

    //             }
    //           }
    //           if (d.ETH == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "ETH"])) {

    //               if (response.data["exch" + d.tokenSymbol + "ETH"].length > 0) {
    //                 $("." + d.tokenSymbol + "ETH_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].close).toFormat(2, 1))

    //                 if (Number(response.data["exch" + d.tokenSymbol + "ETH"][0].close) < Number(response.data["exch" + d.tokenSymbol + "ETH"][0].open)) {
    //                   $("." + d.tokenSymbol + 'ETH_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "ETH_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "ETH"][0].close) > Number(response.data["exch" + d.tokenSymbol + "ETH"][0].open)) {
    //                   $("." + d.tokenSymbol + 'ETH_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "ETH_priceArrow").addClass("fa-arrow-up")
    //                 }

    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "ETH"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "ETH"][0].close - response.data["exch" + d.tokenSymbol + "ETH"][0].open) / response.data["exch" + d.tokenSymbol + "ETH"][0].open) * 100).toFormat(2, 1)
    //                 }

    //                 $("." + d.tokenSymbol + "ETH_priceChangePercent").html(percentage)

    //                 $("." + d.tokenSymbol + "ETH_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "ETH"][0].close) - Number(response.data["exch" + d.tokenSymbol + "ETH"][0].open)).toFormat(2, 1))

    //                 $("." + d.tokenSymbol + "ETH_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "ETH_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "ETH_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].high).toFormat(2, 1))

    //               }

    //             }
    //           }
    //           if (d.USDT == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "USDT"])) {

    //               if (response.data["exch" + d.tokenSymbol + "USDT"].length > 0) {
    //                 $("." + d.tokenSymbol + "USDT_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "USDT"][0].close).toFormat(2, 1))

    //                 if (Number(response.data["exch" + d.tokenSymbol + "USDT"][0].close) < Number(response.data["exch" + d.tokenSymbol + "USDT"][0].open)) {
    //                   $("." + d.tokenSymbol + 'USDT_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "USDT_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "USDT"][0].close) > Number(response.data["exch" + d.tokenSymbol + "USDT"][0].open)) {
    //                   $("." + d.tokenSymbol + 'USDT_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "USDT_priceArrow").addClass("fa-arrow-up")
    //                 }

    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "USDT"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "USDT"][0].close - response.data["exch" + d.tokenSymbol + "USDT"][0].open) / response.data["exch" + d.tokenSymbol + "USDT"][0].open) * 100).toFormat(2, 1)
    //                 }

    //                 $("." + d.tokenSymbol + "USDT_priceChangePercent").html(percentage)

    //                 $("." + d.tokenSymbol + "USDT_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "USDT"][0].close) - Number(response.data["exch" + d.tokenSymbol + "USDT"][0].open)).toFormat(2, 1))

    //                 $("." + d.tokenSymbol + "USDT_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "USDT"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "USDT_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "USDT"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "USDT_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].high).toFormat(2, 1))

    //               }

    //             }
    //           }
    //           if (d.INR == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "INR"])) {
    //               if (response.data["exch" + d.tokenSymbol + "INR"].length > 0) {
    //                 $("." + d.tokenSymbol + "INR_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].close).toFormat(2, 1))

    //                 if (Number(response.data["exch" + d.tokenSymbol + "INR"][0].close) < Number(response.data["exch" + d.tokenSymbol + "INR"][0].open)) {
    //                   $("." + d.tokenSymbol + 'INR_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "INR_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "INR"][0].close) > Number(response.data["exch" + d.tokenSymbol + "INR"][0].open)) {
    //                   $("." + d.tokenSymbol + 'INR_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "INR_priceArrow").addClass("fa-arrow-up")
    //                 }

    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "INR"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "INR"][0].close - response.data["exch" + d.tokenSymbol + "INR"][0].open) / response.data["exch" + d.tokenSymbol + "INR"][0].open) * 100).toFormat(2, 1)
    //                 }

    //                 $("." + d.tokenSymbol + "INR_priceChangePercent").html(percentage)

    //                 $("." + d.tokenSymbol + "INR_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "INR"][0].close) - Number(response.data["exch" + d.tokenSymbol + "INR"][0].open)).toFormat(2, 1))

    //                 $("." + d.tokenSymbol + "INR_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "INR_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "INR_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].high).toFormat(2, 1))

    //               }

    //             }
    //           }
    //         }
    //       })
    //     }

    //     // this.setState({
    //     //     trades:response.data,
    //     //     isLoaded: true,
    //     //     loadedTrades: true
    //     // }) 
    //   })
  }


  async getmyBalance() {
    let balance_api_url = "";
    this.getCrossBalance()

          await axios.get(BASE_URL + `feespack/get/all`)
            .then(async res => {
                // console.log(res);
                // console.log(res.data.data);
                if (res.data.message === "success") {

                    this.setState({
                        feesPacks: res.data.data
                    }) 

                    this.state.feesPacks.map(row => {
                      if(row.title == "General FESS") {
                        this.setState({ tradingPercent: row.feePercent })
                      } 
                    })

                    if (this.state.userBalanceFESS >= this.state.FESSstakeamt) {
                      this.setState({ manageFee: true })
                    }

                    if (Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                        await axios.post(BASE_URL + `feespack/get/historyrev`, {
                          "userId": this.state.userId 
                        })
                        .then(res => {
                            // console.log(res);
                            // console.log(res.data.data);
                            if (res.data.message === "success") { 
            
                              {res.data.data.map((rowMain,xid) => {
                                //  console.log("myhistory",xid)
                                if(xid == 0) {
                                  this.setState({ tradingPercent: rowMain.feePercent })
                                }
                                //  console.log("myhistory",xid)
                                //  console.log("myhistory",rowMain.title)
                              }).reverse()}
                                
                            }
                        })
                      this.setState({ loadFeeSubs: true })
                    } 
                    
                }
          })
 
  }




getCrossBalance= ()=>{
  this.setState({loading : true})
  axios.post(BASE_URL + `user/get/marginBalance`, {
   "userId": localStorage.getItem('userDetails')
 }).then(async res => {
  this.setState({loading : false})
     if (res.data.message == "success") {
       this.setState({
         userBalanceCoin: res.data.data[this.props.match.params.coinCode],
         userBalancePair: res.data.data[this.props.match.params.pairCode],
         userBalanceFESS: res.data.data['FESS']
       })
       this.getmyMarginOrders()
     }}).catch((err)=>{
      this.setState({loading : false})
     })

}




  async getmyMarginOrders() {
      await axios.post(BASE_URL + `marginOrder/get/history`, {
        "pair": this.props.match.params.pairCode,
        "coinName": this.props.match.params.coinCode,
        "userId": localStorage.getItem('userDetails'),
      })
        .then(res => {
          if (res.data.message == "success") {
            this.setState({ historyOrderrows: res.data.data })
          }
        })
  }



  async getOrderByTab(value) {
    this.setState({activeTableTab : value})
    switch (value) {
      case 'PORTFOLIO':
        await this.getPortfolio()
        break;
      case 'POSITION':
        await this.getActivePositions()
        break;
      case 'ORDER_HISTORY':
        await this.getmyMarginOrders()
        break;

      default:
        break;
    }
  }
  getPortfolio = async () => {
    try {
      const userId = localStorage.getItem("userDetails")
      const res = await axios.get(BASE_URL + `margin/get-portfolio/${userId}`);
      this.setState({ marginPortfolio: res.data.data })

    } catch (error) {
      this.setState({ marginPortfolio: [] })
    }
  }
 getActivePositions = async()=>{
 const coinName = this.props.match.params.coinCode
  const userId = localStorage.getItem('userDetails')
  try{
   const positions = await axios.post(BASE_URL + `getPoistion/${userId}/${coinName}`)
   this.setState({activePositions: positions.data.data})
  }catch(error){

  }
}

  async componentDidMount() {
    this.testWS(localStorage.getItem('userDetails') || '')
    console.log(this.props)
    console.log(this.props.match.params.type)
    await this.getmyBalance()
    await this.getActivePositions()
    await this.getPortfolio()
    if(this.props.match.params.coinCode == 'ECNA'){
      setInterval(()=>{
          this.getmySpotOrders("OPEN")
          this.getBithindDetails()
      },10000)
    }

   this.inrStreamConnection()
    if (localStorage.getItem('tradeMode')) {
      if(localStorage.getItem('tradeMode') == "light_mode") {
        this.setState({
          alignment : 'left',
          theme: 'light_mode',
          backgroundcolor1: '#FDB813',
          backgroundcolor2: '#fff',
        })
      } else {
        this.setState({
          alignment : 'right',
          theme: 'dark_mode',
          backgroundcolor1: '#fff',
          backgroundcolor2: '#FDB813',
        })
      }
  
    }
    console.log(this.state)

    socket.on('connect', msg => {
      this.setState({ socketConnect: true })
    })
    socket.on('disconnect', msg => {
      this.setState({ socketConnect: false })
    })

    socket.on('orderUpdate', msg => {
      this.getBithindDetails()
      // this.getBithindPrice()
      this.getmyBalance()
    })

    socket.on('orderDetails', msg => {

      if (msg.executionType == "TRADE") {


        // console.log("mssg",msg)
        // console.log("mssg",this.state.userBalanceCoin)

        this.state.openOrderrows.map((row, xid) => {

          if (row.binanceorderId == msg.orderId) {
            // console.log("mssg","ORDER FOUND")

            this.setState({ myorderPrice: [] })
            const item = {
              coinName: row.coinName,
              pair: row.pair,
              orderType: row.orderType,
              orderMode: row.orderMode,
              price: row.price,
              amount: row.amount,
              total: row.total,
              dateTime: row.dateTime,
              tradingFee: row.tradingFee,
              binanceorderId: row.binanceorderId,
              _id: row._id
            };

            this.setState({
              historyOrderrows: [...this.state.historyOrderrows, item]
            });

            if (row.orderType == "Sell") {
              this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) + Number(row.amount * row.price)).toFixed(10).slice(0, -4) })
            } else {
              this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) + Number(row.amount)).toFixed(10).slice(0, -4) })
            }

            const openOrderrows = [...this.state.openOrderrows]
            openOrderrows.splice(xid, 1)
            this.setState({ openOrderrows })



            this.state.openOrderrows.map(row => {
              // console.log("myorderPrice",row)
              this.setState({
                myorderPrice: this.state.myorderPrice.concat(row.price)
              })
            })



          }

        })

      }

    });

    // const clean = await client.ws.user(msg => {
    //   console.log(msg)
    // })

   
    if (this.props.match.params.coinCode && this.props.match.params.pairCode) {
      this.setState({
        coinCode: this.props.match.params.coinCode,
        pairCode: this.props.match.params.pairCode
      })

      const usrID  = localStorage.getItem('userDetails');
      // if (usrID && this.props.match.params.coinCode != 'ECNA') {
      //   let sym = this.props.match.params.coinCode + this.props.match.params.pairCode;
      //    axios.get(BASE_URL + `order/updateOrderStatus?symbol=${sym?.toUpperCase()}&userId=${usrID}`)
      // }


      let symbol = (this.props.match.params.coinCode + this.props.match.params.pairCode).toLowerCase();
      // let symbol = 'BTCUSDT'.toLowerCase(); 
      // console.log(this.state.coinCode);
      // console.log(symbol);

      // PRICE SOCKET TO START
      if (this.state.exchangeType == "Binance") { 
        this._connectSocketStreams(this.streams.map(i => `${symbol}${i}`));
      }


      this.setState({loading : true})
      await axios.get(BASE_URL + `ercToken/get/all`)
        .then(res => {
          this.setState({loading : false})
          // console.log(res);
          if (res.data.message == "success") {
            this.setState({ tokenData: res.data.data })
            // console.log("tokendata",this.state.tokenData)

            {
              this.state.tokenData.map((d, idx) => {
                if (d.tokenSymbol == "FESS") {
                  this.setState({ FESSstakeamt: d.minstake })
                }
                if (d.tokenSymbol == this.props.match.params.coinCode) {
                  if (this.props.match.params.pairCode == "BTC") {
                    this.setState({ minAmt: d.minAmtBTC })
                    this.setState({ minTotal: d.minTotalBTC })
                    this.setState({ priceDecimal: d.priceDecimalBTC })
                    this.setState({ amtDecimal: d.amtDecimalBTC })
                    this.setState({ tradingPercent: d.marginfees || 0 })
                  }
                  if (this.props.match.params.pairCode == "ETH") {
                    this.setState({ minAmt: d.minAmtETH })
                    this.setState({ minTotal: d.minTotalETH })
                    this.setState({ priceDecimal: d.priceDecimalETH })
                    this.setState({ amtDecimal: d.amtDecimalETH })
                    this.setState({ tradingPercent: d.marginfees || 0 })
                  }
                  if (this.props.match.params.pairCode == "USDT") {
                    this.setState({ minAmt: d.minAmtUSDT })
                    this.setState({ minTotal: d.minTotalUSDT })
                    this.setState({ priceDecimal: d.priceDecimalUSDT })
                    this.setState({ amtDecimal: d.amtDecimalUSDT })
                    this.setState({ tradingPercent: d.marginfees || 0 })
                  }
                  if (this.props.match.params.pairCode == "INR") {
                    this.setState({ minAmt: d.minAmtINR })
                    this.setState({ minTotal: d.minTotalINR })
                    this.setState({ priceDecimal: d.priceDecimalINR })
                    this.setState({ amtDecimal: d.amtDecimalINR })
                  }
                }

                if (this.props.match.params.coinCode == d.tokenSymbol) {
                  // if (d.exchange == "E-Canna") {
                  //   this.setState({ exchangeType: "E-Canna" });
                  // }
                }

                if (d.tActive == true) {
                  if (d.BTC == "1") {
                    this.setState({ [d.tokenSymbol + 'BTC_favcoin']: false })
                  }
                  if (d.ETH == "1") {
                    this.setState({ [d.tokenSymbol + 'ETH_favcoin']: false })
                  }
                  if (d.USDT == "1") {
                    this.setState({ [d.tokenSymbol + 'USDT_favcoin']: false })
                  }
                  if (d.INR == "1") {
                    this.setState({ [d.tokenSymbol + 'INR_favcoin']: false })
                  }
                }
              })
            }
            // userBalanceCoin: res.data.data[this.props.match.params.coinCode], 
            // userBalancePair: res.data.data[this.props.match.params.pairCode],
            if (this.props.match.params.pairCode == "INR" || this.props.match.params.pairCode == "ECNA" || this.props.match.params.coinCode == "ECNA"  ) {
              console.log('first')
              this.setState({ exchangeType: "E-Canna" });
              this.getBithindDetails()

            }

            let usdtpair = this.state.tokenData.map(i => `${i.tokenSymbol.toLowerCase() + "usdt@ticker"}`)
            let btcpair = this.state.tokenData.map(i => `${i.tokenSymbol.toLowerCase() + "btc@ticker"}`)
            let ethpair = this.state.tokenData.map(i => `${i.tokenSymbol.toLowerCase() + "eth@ticker"}`)


            // PRICE SOCKET TO START  
            // this._connectTickerStreams([btcpair.join('/')]) 
            this._connectTickerStreams([usdtpair.join('/') + "/" + btcpair.join('/') + "/" + ethpair.join('/')]) 
            // this._connectTickerStreams([usdtpair]) 

            // NEED TO START 

            // axios({
            //   method: 'get',
            //   // url: `https://cors-anywhere.herokuapp.com/https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
            //   url: `https://www.binance.com/api/v3/ticker/24hr`
            // })
            // .then(response => {
            //   response.data.map(row => {
            //     // console.log(row)
            //     this.state.tokenData.map(i => {
            //         if(i.tokenSymbol+"USDT" == row.symbol || i.tokenSymbol+"BTC" == row.symbol || i.tokenSymbol+"ETH" == row.symbol) {

            //             this.setState({ [row.symbol+'_price']: row.lastPrice })
            //             this.setState({ [row.symbol+'_priceChangePercent']: row.priceChangePercent })
            //             this.setState({ [row.symbol+'_priceHigh']: row.highPrice })
            //             this.setState({ [row.symbol+'_priceLow']: row.lowPrice })
            //             this.setState({ [row.symbol+'_priceVolume']: row.volume })

            //         }
            //     })
            //   })
            // })

          }
        }).catch((err)=>{
          this.setState({loading : false})
        })


      // await this.getE-CannaDetails()
      // await this.getE-CannaPrice()
      // this._connectTickerStreams(['!ticker@arr'])            
      // this._connectTickerStreams(['btcusdt@ticker','ethusdt@ticker'])            

      
      symbol = (this.props.match.params.coinCode + this.props.match.params.pairCode).toLowerCase();
      Axios({
        method: 'get',
        // url: `https://cors-anywhere.herokuapp.com/https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
        // url: `https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
        url: `https://api.wazirx.com/sapi/v1/trades?symbol=${symbol}&limit=${this.tradesCount}`
      })
        .then(response => {
          // console.log("tradedata",response.data)
          this.setState({
            trades: response.data,
            isLoaded: true,
            loadedTrades: true
          })
          const tradeData = response.data && isArray(response.data) && response.data.map((item)=> {return{
            p:item.price,
            q:item.qty,
            T:item.time
          }})
          let a = tradeData.at(-1)//this.state.trades[this.state.trades.length - 1];
          var decimalPrice =""
          if (this.state.coinCode == "BTC") {
            decimalPrice = Number(a.p).toFixed(6)
          } else if (this.state.coinCode == "ETH") {
            decimalPrice = Number(a.p).toFixed(4)
          } else if(this.state.coinCode == "BNB"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "TRX"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "XRP"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "XLM"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "DOGE"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "BTT"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "VET"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "RSR"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "VTHO"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "ENJ"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "LINK"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "MATIC"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "SHIB"){
            decimalPrice = Number(a.p).toFixed(3)
          }else if(this.state.coinCode == "USDT"){
            decimalPrice = Number(a.p).toFixed(3)
          }else {
            decimalPrice = Number(a.p)
          }
          
          this.setState({
            buyPrice: decimalPrice,
            sellPrice : decimalPrice
          })
          console.log('>>>>>>>>>>>>>>>>>>>>>>   ',decimalPrice)
        })






      if (localStorage.getItem('userDetails')) {

        await this.setState({ userId: localStorage.getItem('userDetails') })

        await this.getmyfavcoin()

        await axios.post(BASE_URL + `userInformation/get`, {
          "id": this.state.userId
        })
          .then(res => {
            // console.log(res);
            if (res.data.message == "success") {
              this.setState({
                userData: res.data.data
              })

            }


          })


       


      }



    } else {
      window.location.href = "/margin-trade/BTC/USDT/CROSS";
      const usrID  = localStorage.getItem('userDetails');
      if (usrID && this.props.match.params.coinCode != 'ECNA') {
        let sym = this.props.match.params.coinCode + this.props.match.params.pairCode;
        let a = await axios.get(BASE_URL + `order/updateOrderStatus?symbol=${sym?.toUpperCase()}&userId=${usrID}`)
      }
      
    }
    // if(this.props.match.params.coinCode === 'ECNA' || this.props.match.params.pairCode === 'ECNA'){
      axios.get(`${BASE_URL}coinpair/getall`).then((res)=>{
        if (res.data && res.data.statusCode === 200) {
          if(this.props.match.params.coinCode === 'ECNA' || this.props.match.params.pairCode === 'ECNA'){
            const currentPair = res.data.data.find(e=> e.primaryCoin.toUpperCase() === this.props.match.params.coinCode && e.secondaryCoin.toUpperCase() === this.props.match.params.pairCode)
            
          this.setState({ tokenPairData: res.data.data })
          this.setState({
            buyPrice: (currentPair?.price || 0).toFixed(3),
            sellPrice : (currentPair?.price || 0).toFixed(3),
            currentPair: currentPair,
            change24H : currentPair?.change24Hr,
            changeHigh : currentPair?.high24Hr,
            changeLow : currentPair?.low24Hr,
            volume : currentPair?.volume
          })
        }
        
        const currentPair1 = res.data.data.find(e=> e.primaryCoin.toUpperCase() === "ECNA" && e.secondaryCoin.toUpperCase() === this.props.match.params.pairCode)
        
        try {
          $(".ECNA" + this.props.match.params.pairCode + '_priceColor').removeClass("red_clr")
        $(".ECNA" + this.props.match.params.pairCode + '_priceColor').removeClass("green_clr")
        $(".ECNA" + this.props.match.params.pairCode + '_priceColor').addClass("green_clr")
        
        $(".ECNA" + this.props.match.params.pairCode + "_price").html(new BigNumber(currentPair1?.price).toFixed(currentPair1.primaryCoin == 'ECNA' ? decimalData[currentPair1?.primaryCoin+currentPair1?.secondaryCoin]['D'] : decimalData[currentPair1?.primaryCoin]['D']))
        $(".ECNA" + this.props.match.params.pairCode + "_priceChangePercent").html(new BigNumber(currentPair1?.change24Hr).toFormat(2, 1))
        
        } catch (error) {
          console.error(error)
        }
        (res.data?.data || []).forEach((cp)=>{
          this.setEcannaINRPrice(cp)
        })
      }
      
        
      })
    // }
  }

  setEcannaINRPrice( pairData){
        const tokenSymbol = pairData.primaryCoin
          $("." +tokenSymbol + "INR_price").html(new BigNumber(pairData.price).toFormat(2, 1))

          if (Number(pairData.change24Hr) < 0) {
            $("." +tokenSymbol + 'INR_priceColor').addClass("red_clr")
            $("#" +tokenSymbol + "INR_priceArrow").addClass("fa-arrow-down")
          } else  {
            $("." +tokenSymbol + 'INR_priceColor').addClass("green_clr")
            $("#" +tokenSymbol + "INR_priceArrow").addClass("fa-arrow-up")
          }

          $("." +tokenSymbol + "INR_priceChangePercent").html(new BigNumber(pairData.change24Hr).toFormat(2, 1))

          $("." +tokenSymbol + "INR_priceChange").html(new BigNumber(Number(pairData.change24Hr)).toFormat(2, 1))

          $("." +tokenSymbol + "INR_priceHigh").html(new BigNumber(pairData.high24Hr).toFormat(2, 1))
          $("." +tokenSymbol + "INR_priceLow").html(new BigNumber(pairData.low24Hr).toFormat(2, 1))
          $("." +tokenSymbol + "INR_priceVolume").html(new BigNumber(pairData.volume).toFormat(2, 1))
     
  }


  getCoinPairAll(value){
    axios.get(`${BASE_URL}coinpair/getall`).then((res)=>{
      if (res.data && res.data.statusCode === 200) {
      //   if(this.props.match.params.coinCode === value || this.props.match.params.pairCode === value){
      //     const currentPair = res.data.data.find(e=> e.primaryCoin.toUpperCase() === this.props.match.params.coinCode && e.secondaryCoin.toUpperCase() === this.props.match.params.pairCode)
          
      //   // this.setState({ tokenPairData: res.data.data })
      //   // this.setState({
      //   //   buyPrice: currentPair?.price || 0,
      //   //   sellPrice : currentPair?.price || 0,
      //   //   currentPair: currentPair,
      //   //   change24H : currentPair?.change24Hr,
      //   //   changeHigh : currentPair?.high24Hr,
      //   //   changeLow : currentPair?.low24Hr,
      //   //   volume : currentPair?.volume
      //   // })
      // }

          const currentPair1 = res.data.data.find(e=> e.primaryCoin.toUpperCase() === "ECNA" && e.secondaryCoin.toUpperCase() === value)

        $(".ECNA" + value + '_priceColor').removeClass("red_clr")
        $(".ECNA" + value + '_priceColor').removeClass("green_clr")
        $(".ECNA" + value + '_priceColor').addClass("green_clr")

        $(".ECNA" + value + "_price").html(new BigNumber(currentPair1?.price).toFixed(currentPair1.primaryCoin == 'ECNA' ? decimalData[currentPair1?.primaryCoin+currentPair1?.secondaryCoin]['D'] : decimalData[currentPair1?.primaryCoin]['D']))
        $(".ECNA" + value + "_priceChangePercent").html(new BigNumber(currentPair1?.change24Hr).toFormat(2, 1))

      }
      
    })
  }




  sellBalancePer(percent) {
    let calculatedBalance = (this.state.userBalanceCoin / 100) * percent
    this.setState({ sellVolume: (calculatedBalance).toFixed(10).slice(0, -4) })
    if (this.state.sellType != "MARKET") {
      this.setState({ sellTotal: (calculatedBalance * this.state.sellPrice).toFixed(10).slice(0, -4) })
    } else {
      var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
      // console.log(lastPrice) 
      if(lastPrice!=undefined){
        lastPrice =  lastPrice.replace(/,/g, "");
      }
      
      this.setState({ sellTotal: (Number(calculatedBalance) * Number(lastPrice)).toFixed(10).slice(0, -4) })
    }
    // console.log(calculatedBalance)
  }

  buyBalancePer(percent) {

    var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
    if(lastPrice!=undefined){
      lastPrice =  lastPrice.replace(/,/g, "");
    }
    

    let calculatedBalance = (this.state.userBalancePair / 100) * percent
    if (this.state.buyType != "MARKET") {
      this.setState({ buyVolume: (calculatedBalance / this.state.buyPrice).toFixed(10).slice(0, -4) })
      this.setState({ buyTotal: (calculatedBalance).toFixed(10).slice(0, -4) })
    } else {
      this.setState({ buyVolume: (calculatedBalance / lastPrice).toFixed(10).slice(0, -4) })
      this.setState({ buyTotal: (calculatedBalance).toFixed(10).slice(0, -4) })
    }
    // console.log(calculatedBalance)
  }

  handelTradeHistory = (price, amount, mode) => {
    // console.log("coin","coinTradePage",price,amount,mode)
    if (mode == "Buy") {
      this.setState({
        buyType: "LIMIT",
        buyPrice: Number(price),
        buyVolume: Number(amount),
        buyTotal: Number(price * amount).toFixed(4),
      })
      this.handleBuyModal()
    } else {
      this.setState({
        sellType: "LIMIT",
        sellPrice: Number(price),
        sellVolume: Number(amount),
        sellTotal: Number(price * amount).toFixed(4),
      })
      this.handleSellModal()
    }
  }

  changeOrderMode = (type,mode)=>{
    if (type === 'sell') {
      this.setState({sellType: mode})
    }
    if (type === 'buy') {
      this.setState({buyType: mode})
    }
  }

  changeBorrowMode = (type,mode)=>{
    if (type === 'borrowMode_buy') {
      this.setState({borrowMode_buy: mode})
    }
    if (type === 'borrowMode_sell') {
        this.setState({borrowMode_sell: mode})
      }
  }

  changeTypeMode = (mode)=>{
    this.getmyBalance()
    // let a = this.props.match.params.coinCode + "/" + this.props.match.params.pairCode + "/" + mode
    // this.props.history.push(a);
    // this.getmyBalance();
    // // this.props.history.replace({ pathname: `/${this.props.match.params.coinCode}` `/${this.props.match.params.pairCode}` `/${mode}` })
    // if (typeofMode === 'type') {
    //   this.setState({type: mode})
    // }
  }

  handleSubmitBuy = (event) => {

    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      buyDisable : true
    })
      
    let errors = this.state.errors;
    let orderProcess = true;
    let tradingFess = Number(this.state.tradingPercent);
    let fs
    this.setState({ LodingOrder: true })
    if (this.state.buyType == "MARKET") {
      var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
      if(lastPrice!=undefined){
        lastPrice =  lastPrice.replace(/,/g, "");
      }
      let buyTotal = Number(Number(lastPrice) * Number(this.state.buyVolume))

      if (Number(buyTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show("Order total should be more than " + this.state.minTotal + " " + this.props.match.params.pairCode, "error");
      }
      if (Number(buyTotal) > Number(this.state.userBalancePair)) {
        orderProcess = false;

        notify.show("Not enough balance to execute this order", "error");
      }
    }
    if (this.state.buyPrice == null || this.state.buyPrice == "") {
      orderProcess = false;

      notify.show("Please enter a valid price", "error");
    } else
      if (this.state.buyVolume == null || this.state.buyVolume == "") {
        orderProcess = false;

        notify.show("Please enter a valid amount", "error");
      } else
        if (this.state.buyTotal == null || this.state.buyTotal == "") {
          orderProcess = false;

          notify.show("Please enter a valid total", "error");
        } else
          if (Number(this.state.buyTotal) > Number(this.state.userBalancePair)) {
            orderProcess = false;

            notify.show("Not enough balance to execute this order", "error");
          }
    if (this.state.buyType != "MARKET") {
      if (Number(this.state.buyTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show("Order total should be more than " + this.state.minTotal + " " + this.props.match.params.pairCode, "error");
      }
    }
    if (Number(this.state.buyVolume) <= Number(this.state.minAmt)) {
      orderProcess = false;

      notify.show("Order amount should be more than " + this.state.minAmt + " " + this.props.match.params.coinCode, "error");
    }


    if (orderProcess == true) {

      let tradingFessAmt = (Number(this.state.buyVolume) / 100) * tradingFess

      let pricesubmit = 0
      let totalsubmit = 0
      let statusubmit = 0
      if (this.state.buyType == "MARKET") {
        pricesubmit = 0
        totalsubmit = 0
        statusubmit = 1
      } else {
        pricesubmit = Number(this.state.buyPrice).toFixed(12).slice(0, -4)
        totalsubmit = Number(this.state.buyTotal).toFixed(12).slice(0, -4)
        statusubmit = 0
      }

      let buyUrl = 'marginOrder/add';
      var newDecimalprice;
      var newDecimalAmount;
      var decimalVal ;
      if (this.props.match.params.coinCode == "BTC") {
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.BTC.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.BTC.amountDecimal)
        decimalVal = decimalData.BTC.amountDecimal;
      } else if (this.props.match.params.coinCode == "ETH") {
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.ETH.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.ETH.amountDecimal);
        decimalVal = decimalData.ETH.amountDecimal;
      } else if(this.props.match.params.coinCode == "BNB"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.BNB.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.BNB.amountDecimal);
        decimalVal = decimalData.BNB.amountDecimal;
      }else if(this.props.match.params.coinCode == "TRX"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.TRX.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.TRX.amountDecimal);
        decimalVal = decimalData.TRX.amountDecimal;
      }else if(this.props.match.params.coinCode == "XRP"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.XRP.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.XRP.amountDecimal);
        decimalVal = decimalData.XRP.amountDecimal
      }else if(this.props.match.params.coinCode == "XLM"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.XLM.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.XLM.amountDecimal);
        decimalVal = decimalData.XLM.amountDecimal;
      }else if(this.props.match.params.coinCode == "DOGE"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.DOGE.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.DOGE.amountDecimal);
        decimalVal = decimalData.DOGE.amountDecimal;
      }else if(this.props.match.params.coinCode == "BTT"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.BTT.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.BTT.amountDecimal);
        decimalVal = decimalData.BTT.amountDecimal;
      }else if(this.props.match.params.coinCode == "VET"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.VET.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.VET.amountDecimal);
        decimalVal = decimalData.VET.amountDecimal;
      }else if(this.props.match.params.coinCode == "RSR"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.RSR.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.RSR.amountDecimal);
        decimalVal = decimalData.RSR.amountDecimal;
      }else if(this.props.match.params.coinCode == "VTHO"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.VTHO.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.VTHO.amountDecimal)
        decimalVal = decimalData.VTHO.amountDecimal;
      }else if(this.props.match.params.coinCode == "ENJ"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.ENJ.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.ENJ.amountDecimal);
        decimalVal = decimalData.ENJ.amountDecimal;
      }else if(this.props.match.params.coinCode == "LINK"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.LINK.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.LINK.amountDecimal);
        decimalVal = decimalData.LINK.amountDecimal;
      }else if(this.props.match.params.coinCode == "MATIC"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.MATIC.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.MATIC.amountDecimal);
        decimalVal = decimalData.MATIC.amountDecimal;
      }else if(this.props.match.params.coinCode == "SHIB"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.SHIB.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalData.SHIB.amountDecimal);
        decimalVal = decimalData.SHIB.amountDecimal;
      }
      else {
        newDecimalprice = Number(pricesubmit)
        newDecimalAmount = Number(this.state.buyVolume)
        decimalVal = 0;
      }
      const liquidationPrc = this.getHardLiquidationPrice('Long')
      let sendKey = {
        "orderType": "Long",
        "userId": this.state.userId,
        "price": newDecimalprice,
        "amount" : newDecimalAmount,
        "orderMode": this.state.buyType,
        "tradingFeePercent": this.marginFee,//this.state.tradingPercent,
        "total": totalsubmit,
        "priceDecimal": this.state.priceDecimal,
        "decimal" : decimalVal,
        "date": 0,
        "time": 0,
        "coinName": this.props.match.params.coinCode,
        "pair": this.props.match.params.pairCode,
        "status": statusubmit,
        "stopLossPrice":"",
        "marginLeverage": this.marginLeverage,
        "liquidationPrice" : liquidationPrc,
        "yourMargin": this.getMyMargin("Long")

      }
      console.log("sendKey  buy " , sendKey)
      if(this.props.match.params.type == "CROSS"){
        sendKey["stopLossPrice"] =""
      }
      this.setState({loading : true})
      axios.post(BASE_URL + buyUrl, sendKey)
        .then(res => {
          this.setState({loading : false,buyDisable: false})
          if (res.data.message == "addedSuccessfully") {
            let resdata = res.data.data[0]


            if (resdata.orderMode == "LIMIT") {
              if (resdata.status == 0) {
                this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) - Number(resdata.total)).toFixed(10).slice(0, -4) })
              } else {
                this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) + Number(resdata.amount)).toFixed(10).slice(0, -4) })
                this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) - Number(resdata.total)).toFixed(10).slice(0, -4) })
              }
            } else {
              this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) + Number(resdata.amount)).toFixed(10).slice(0, -4) })
              this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) - Number(resdata.total)).toFixed(10).slice(0, -4) })
            }


            if (resdata.orderMode == "LIMIT") {

              this.setState({ myorderPrice: [] })
              const item = {
                coinName: resdata.coinName,
                pair: resdata.pair,
                orderType: resdata.orderType,
                orderMode: resdata.orderMode,
                price: resdata.price,
                amount: resdata.amount,
                total: resdata.total,
                dateTime: resdata.dateTime,
                tradingFee: resdata.tradingFee,
                binanceorderId: resdata.binanceorderId,
                _id: resdata._id
              };

              if (resdata.status == 0) {

                this.setState({
                  openOrderrows: [...this.state.openOrderrows, item]
                });

                this.state.openOrderrows.map(row => {
                  this.setState({
                    myorderPrice: this.state.myorderPrice.concat(row.price)
                  })
                })
              } else {
                this.setState({
                  historyOrderrows: [...this.state.historyOrderrows, item]
                });
              }

            } else {
              const item = {
                coinName: resdata.coinName,
                pair: resdata.pair,
                orderType: resdata.orderType,
                orderMode: resdata.orderMode,
                price: resdata.price,
                amount: resdata.amount,
                total: resdata.total,
                dateTime: resdata.dateTime,
                tradingFee: resdata.tradingFee,
                binanceorderId: resdata.binanceorderId,
                _id: resdata._id
              };
              this.setState({
                historyOrderrows: [...this.state.historyOrderrows, item]
              });
            }

            if (resdata.status == 0) {

              notify.show("Buy order submitted successfully", "warning");
            } else {

              notify.show("Long order opened successfully", "success");
            }
            this.setState({
              buyVolume: "",
              buyTotal: "",
              buyDisable : false
            })
           
            this.setState({ LodingOrder: false })
            this.getBithindDetails()
            this.getBithindPrice()
            this.getmyBalance()
            this.getActivePositions().then(()=>{})
          } else {

            notify.show("Something went wrong...", "error");
            this.setState({ LodingOrder: false ,buyDisable : false  })
          }


        }).catch((err)=>{
        }).finally(()=>{
          // this.setState({loading : false})
          this.setState({
            loading:false,
            buyDisable : false
          })
        })
    } else {

      this.setState({ LodingOrder: false ,buyDisable : false})
    }

  }


  handleSubmitSell = (event) => {
    if(this.state.sellDisable ){
      return
    }
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      sellDisable : true
    })


    let errors = this.state.errors;
    let orderProcess = true;
    let tradingFess = Number(this.state.tradingPercent);
    this.setState({ LodingOrder: true })
 
    if (this.state.sellType == "MARKET") {
      var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
      if(lastPrice!=undefined){
        lastPrice =  lastPrice.replace(/,/g, "");
      }
      let sellTotal = Number(Number(lastPrice) * Number(this.state.sellVolume))
      if (Number(sellTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show("Order total should be more than " + this.state.minTotal + " " + this.props.match.params.pairCode, "error");
      }
    }



    if (this.state.sellPrice == null || this.state.sellPrice == "") {
      orderProcess = false;

      notify.show("Please enter a valid price", "error");
    } else
      if (this.state.sellVolume == null || this.state.sellVolume == "") {
        orderProcess = false;

        notify.show("Please enter a valid amount", "error");
      } else
        if (this.state.sellTotal == null || this.state.sellTotal == "") {
          orderProcess = false;

          notify.show("Please enter a valid total", "error");
        } else
          if (Number(this.state.sellVolume) > Number(this.state.userBalanceCoin)) {
            orderProcess = false;

            notify.show("Not enough balance to execute this order", "error");
          }
    if (this.state.sellType != "MARKET") {
      if (Number(this.state.sellTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show("Order total should be more than " + this.state.minTotal + " " + this.props.match.params.pairCode, "error");
      }
    }
    if (Number(this.state.sellVolume) <= Number(this.state.minAmt)) {
      orderProcess = false;

      notify.show("Order amount should be more than " + this.state.minAmt + " " + this.props.match.params.coinCode, "error");
    }

    if (orderProcess == true) {

      
      let pricesubmit = 0
      let totalsubmit = 0
      let statusubmit = 0
      if (this.state.sellType == "MARKET") {
        pricesubmit = 0
        totalsubmit = 0
        statusubmit = 1
      } else {
        pricesubmit = Number(this.state.sellPrice).toFixed(12).slice(0, -4)
        totalsubmit = Number(this.state.sellTotal).toFixed(12).slice(0, -4)
        statusubmit = 0
      }
      let sellUrl = 'marginOrder/add';

      var newDecimalprice;
      var newDecimalAmount;
      var decimalVal ;
      if (this.props.match.params.coinCode == "BTC") {
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.BTC.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.BTC.amountDecimal)
        decimalVal = decimalData.BTC.amountDecimal;
      } else if (this.props.match.params.coinCode == "ETH") {
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.ETH.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.ETH.amountDecimal);
        decimalVal = decimalData.ETH.amountDecimal;
      } else if(this.props.match.params.coinCode == "BNB"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.BNB.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.BNB.amountDecimal);
        decimalVal = decimalData.BNB.amountDecimal;
      }else if(this.props.match.params.coinCode == "TRX"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.TRX.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.TRX.amountDecimal);
        decimalVal = decimalData.TRX.amountDecimal;
      }else if(this.props.match.params.coinCode == "XRP"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.XRP.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.XRP.amountDecimal);
        decimalVal = decimalData.XRP.amountDecimal
      }else if(this.props.match.params.coinCode == "XLM"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.XLM.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.XLM.amountDecimal);
        decimalVal = decimalData.XLM.amountDecimal;
      }else if(this.props.match.params.coinCode == "DOGE"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.DOGE.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.DOGE.amountDecimal);
        decimalVal = decimalData.DOGE.amountDecimal;
      }else if(this.props.match.params.coinCode == "BTT"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.BTT.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.BTT.amountDecimal);
        decimalVal = decimalData.BTT.amountDecimal;
      }else if(this.props.match.params.coinCode == "VET"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.VET.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.VET.amountDecimal);
        decimalVal = decimalData.VET.amountDecimal;
      }else if(this.props.match.params.coinCode == "RSR"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.RSR.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.RSR.amountDecimal);
        decimalVal = decimalData.RSR.amountDecimal;
      }else if(this.props.match.params.coinCode == "VTHO"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.VTHO.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.VTHO.amountDecimal)
        decimalVal = decimalData.VTHO.amountDecimal;
      }else if(this.props.match.params.coinCode == "ENJ"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.ENJ.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.ENJ.amountDecimal);
        decimalVal = decimalData.ENJ.amountDecimal;
      }else if(this.props.match.params.coinCode == "LINK"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.LINK.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.LINK.amountDecimal);
        decimalVal = decimalData.LINK.amountDecimal;
      }else if(this.props.match.params.coinCode == "MATIC"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.MATIC.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.MATIC.amountDecimal);
        decimalVal = decimalData.MATIC.amountDecimal;
      }else if(this.props.match.params.coinCode == "SHIB"){
        newDecimalprice = Number(pricesubmit).toFixed(decimalData.SHIB.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalData.SHIB.amountDecimal);
        decimalVal = decimalData.SHIB.amountDecimal;
      }
      else {
        newDecimalprice = Number(pricesubmit)
        newDecimalAmount = Number(this.state.sellVolume)
        decimalVal = 0;
      }
      
      let tradingFessAmt
      if(this.state.sellType == "MARKET"){
        tradingFessAmt = (Number(this.state.sellVolume*lastPrice) / 100) * tradingFess
      }else{
       tradingFessAmt = (Number(totalsubmit) / 100) * tradingFess
      }
      
      this.setState({loading : true})
      const liquidationPrc = this.getHardLiquidationPrice( 'Short')
      const payload={
        "orderType":'Short',
        "userId": this.state.userId,
        "price": newDecimalprice,
        "amount": newDecimalAmount,
        "orderMode": this.state.sellType,
        "tradingFeePercent": this.marginFee,//this.state.tradingPercent,
        "total": totalsubmit,
        "priceDecimal": this.state.priceDecimal,
        "decimal": decimalVal,
        "date": 0,
        "time": 0,
        "coinName": this.props.match.params.coinCode,
        "pair": this.props.match.params.pairCode,
        "status": statusubmit,
        "stopLossPrice":"",
        "marginLeverage": this.marginLeverage,
        "liquidationPrice" : liquidationPrc,
        "yourMargin": this.getMyMargin("Short")
      }
      if(this.props.match.params.type == "CROSS"){
        payload["stopLossPrice"] =""
      }
      axios.post(BASE_URL + sellUrl, payload)
        .then(res => {
          this.setState({loading : false})

          if (res.data.message == "addedSuccessfully") {
            let resdata = res.data.data[0]
            let amountwithoutfees
           

            if (resdata.orderMode == "LIMIT") {
              if (resdata.status == 0) {
                this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) - Number(this.state.sellVolume)).toFixed(10).slice(0, -4) })
              } else {
                this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) - Number(this.state.sellVolume)).toFixed(10).slice(0, -4) })
                this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) + Number(resdata.amount * resdata.price)).toFixed(10).slice(0, -4) })
              }
            } else {
              this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) - Number(this.state.sellVolume)).toFixed(10).slice(0, -4) })
              this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) + Number(resdata.amount * resdata.price)).toFixed(10).slice(0, -4) })
            }


            if (resdata.orderMode == "LIMIT") {

              this.setState({ myorderPrice: [] })
              const item = {
                coinName: resdata.coinName,
                pair: resdata.pair,
                orderType: resdata.orderType,
                orderMode: resdata.orderMode,
                price: resdata.price,
                amount: resdata.amount,
                total: resdata.total,
                dateTime: resdata.dateTime,
                tradingFee: resdata.tradingFee,
                binanceorderId: resdata.binanceorderId,
                _id: resdata._id
              };

              if (resdata.status == 0) {
                this.setState({
                  openOrderrows: [...this.state.openOrderrows, item]
                });

                this.state.openOrderrows.map(row => {
                  this.setState({
                    myorderPrice: this.state.myorderPrice.concat(row.price)
                  })
                })
              } else {
                this.setState({
                  historyOrderrows: [...this.state.historyOrderrows, item]
                });
              }

            } else {
              const item = {
                coinName: resdata.coinName,
                pair: resdata.pair,
                orderType: resdata.orderType,
                orderMode: resdata.orderMode,
                price: resdata.price,
                amount: resdata.amount,
                total: resdata.total,
                dateTime: resdata.dateTime,
                tradingFee: resdata.tradingFee,
                binanceorderId: resdata.binanceorderId,
                _id: resdata._id
              };
              this.setState({
                historyOrderrows: [...this.state.historyOrderrows, item]
              });
            }

          
            if (resdata.status == 0) {

              notify.show("Sell order submitted successfully", "warning");
            } else {

              notify.show("Short order opened successfully", "success");
            }
            this.setState({
              sellVolume: "",
              sellTotal: "",
              sellDisable : false
            })
            this.getBithindDetails()
            this.getBithindPrice()
            this.getmyBalance()
            this.setState({ LodingOrder: false       })
          } else {

            notify.show("Something went wrong...", "error");
            this.setState({ LodingOrder: false  ,               sellDisable : false
            })
          }


        }).catch((err)=>{
          this.setState({loading : false})
        })
    } else {

      this.setState({ LodingOrder: false , sellDisable : false })
    }


  }

  addFavCoin = (coin, pair) => () => {
    axios.post(BASE_URL + `favcoin/add`, {
      "coin": coin,
      "pair": pair,
      "userId": this.state.userId,
    })
      .then(res => {
        if (res.data.message == "added") {
          this.getmyfavcoin()
          this.setState({ [coin + pair + '_favcoin']: true })
        }
      })
  }

  removeFavCoin = (coin, pair) => () => {
    axios.post(BASE_URL + `favcoin/remove`, {
      "coin": coin,
      "pair": pair,
      "userId": this.state.userId,
    })
      .then(res => {
        if (res.data.message == "removed") {
          this.getmyfavcoin()
          this.setState({ [coin + pair + '_favcoin']: false })
        }
      })
  }

  cancelMarginOrder = (idx) => {
    if (this.state.cancelDisable == false) {
      this.setState({ cancelDisable: true })
      let cancelUrl = 'cancelMarginOrder';

      this.setState({ loading: true })
      axios.post(BASE_URL + cancelUrl, {
        "pair": this.state.openOrderrows[idx].pair,
        "coinName": this.state.openOrderrows[idx].coinName,
        "orderType": this.state.openOrderrows[idx].orderType,
        "status": 5,
        "id": this.state.openOrderrows[idx]._id,
        "type": this.state.openOrderrows[idx].orderMode,
        "price": this.state.openOrderrows[idx].price,
        "amount": this.state.openOrderrows[idx].amount,
        "timestamp": this.state.openOrderrows[idx].timestamp
      })
        .then(res => {
          this.setState({ loading: false })
          if (res.data.statusCode == 200) {

            if (this.state.openOrderrows[idx].orderType == "Sell") {
              this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) + Number(Number(this.state.openOrderrows[idx].amount) + Number(this.state.openOrderrows[idx].tradingFee))).toFixed(10).slice(0, -4) })
            } else {
              this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) + Number(this.state.openOrderrows[idx].total)).toFixed(10).slice(0, -4) })
            }

            this.setState({ myorderPrice: [] })
            const item = {

              coinName: this.state.openOrderrows[idx].coinName,
              pair: this.state.openOrderrows[idx].pair,
              orderType: this.state.openOrderrows[idx].orderType,
              orderMode: this.state.openOrderrows[idx].orderMode,
              price: this.state.openOrderrows[idx].price,
              amount: this.state.openOrderrows[idx].amount,
              total: this.state.openOrderrows[idx].total,
              dateTime: this.state.openOrderrows[idx].dateTime,
              tradingFee: this.state.openOrderrows[idx].tradingFee,
              binanceorderId: this.state.openOrderrows[idx].binanceorderId,
              _id: this.state.openOrderrows[idx]._id
            };
            this.setState({
              cancelOrderrows: [...this.state.cancelOrderrows, item]
            });


            const openOrderrows = [...this.state.openOrderrows]
            openOrderrows.splice(idx, 1)
            this.setState({ openOrderrows, cancelDisable: false })

            notify.show("Order cancelled successfully", "success");
            this.getBithindDetails()
            this.getBithindPrice()
            this.getmyBalance()



            this.state.openOrderrows.map(row => {
              // console.log("myorderPrice",row)
              this.setState({
                myorderPrice: this.state.myorderPrice.concat(row.price)
              })
            })

          } else {
            this.setState({ cancelDisable: false })

            notify.show("Something went wrong...", "error");
          }

        }).catch((err) => {
          this.setState({ loading: false })

        })
    }
  }
  handleRemoveRowOpenOrder = (idx) => () => {
    if(this.props.match.params.type == 'CROSS'){
      this.cancelMarginOrder(idx)
    }else{
    // console.log("cancelorder", this.state.openOrderrows[idx])
    if(this.state.cancelDisable == false){
      this.setState({cancelDisable : true})
    let cancelUrl = 'order/update/status';
    if (this.state.exchangeType == "Binance") {
      cancelUrl = 'order/update/status';
    }
    if (this.state.exchangeType == "E-Canna") {
      cancelUrl = 'trade/order/cancel';
    }
    this.setState({loading : true})
    axios.post(BASE_URL + cancelUrl, {
      "pair": this.state.openOrderrows[idx].pair,
      "coinName": this.state.openOrderrows[idx].coinName,
      "orderType": this.state.openOrderrows[idx].orderType,
      "status": 2,
      "binanceorderId": this.state.openOrderrows[idx].binanceorderId,
      "binanceclientOrderId": this.state.openOrderrows[idx].binanceclientOrderId,
      // "id": this.state.openOrderrows[idx].userId
      "id": this.state.openOrderrows[idx]._id     ,
      "type" : this.state.openOrderrows[idx].orderMode,
      "price" : this.state.openOrderrows[idx].price,
      "amount" : this.state.openOrderrows[idx].amount,
      "timestamp" : this.state.openOrderrows[idx].timestamp
    })
      .then(res => {
        this.setState({loading : false})

        // console.log("cancelorder", res.data.message)
        if (res.data.statusCode == 200) {

          if (this.state.openOrderrows[idx].orderType == "Sell") {
            this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) + Number(Number(this.state.openOrderrows[idx].amount) + Number(this.state.openOrderrows[idx].tradingFee))).toFixed(10).slice(0, -4) })
          } else {
            this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) + Number(this.state.openOrderrows[idx].total)).toFixed(10).slice(0, -4) })
          }

          this.setState({ myorderPrice: [] })
          const item = {

            coinName: this.state.openOrderrows[idx].coinName,
            pair: this.state.openOrderrows[idx].pair,
            orderType: this.state.openOrderrows[idx].orderType,
            orderMode: this.state.openOrderrows[idx].orderMode,
            price: this.state.openOrderrows[idx].price,
            amount: this.state.openOrderrows[idx].amount,
            total: this.state.openOrderrows[idx].total,
            dateTime: this.state.openOrderrows[idx].dateTime,
            tradingFee: this.state.openOrderrows[idx].tradingFee,
            binanceorderId: this.state.openOrderrows[idx].binanceorderId,
            _id: this.state.openOrderrows[idx]._id
          };
          this.setState({
            cancelOrderrows: [...this.state.cancelOrderrows, item]
          });


          const openOrderrows = [...this.state.openOrderrows]
          openOrderrows.splice(idx, 1)
          this.setState({ openOrderrows , cancelDisable : false})

          notify.show("Order cancelled successfully", "success");
          this.getBithindDetails()
          this.getBithindPrice()
          this.getmyBalance()
          


          this.state.openOrderrows.map(row => {
            // console.log("myorderPrice",row)
            this.setState({
              myorderPrice: this.state.myorderPrice.concat(row.price)
            })
          })

        } else {
          this.setState({ cancelDisable : false})

          notify.show("Something went wrong...", "error");
        }

      }).catch((err)=>{
        this.setState({loading : false})

      })
    }else{

    }
  }

  };


  handleChange = (event) => {


    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;



    switch (name) {
      case 'sellType':

        if (value === "MARKET") {
          // this.setState({sellTotal: "0" })
          this.setState({ sellPrice: value })
        } else {
          this.setState({ sellPrice: '' })
        }
        this.setState({ sellType: value })

        break;
      case 'buyType':

        if (value === "MARKET") {
          // this.setState({buyTotal: "0" })
          this.setState({ buyPrice: value })
        } else {
          this.setState({ buyPrice: '' })
        }
        this.setState({ buyType: value })

        break;

      case 'sellPrice':
        if (value.length == 0) {
          this.setState({ sellPrice: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            if(priceValid.test(value)) {
              this.setState({ sellPrice:  value})
              }
            // this.setState({ sellPrice: value })
            this.setState({ sellTotal: (value * this.state.sellVolume).toFixed(4) })
          }

        }
        break;

      case 'buyPrice':
        if (value.length == 0) {
          this.setState({ buyPrice: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            // console.log("priceValid",priceValid.test(value))
            if(priceValid.test(value)) {

            this.setState({ buyPrice:  value})
            }
            this.setState({ buyTotal: (value * this.state.buyVolume).toFixed(4) })
          }

        }
        break;

      case 'sellVolume':
        if (value.length == 0) {
          this.setState({ sellVolume: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ sellVolume: value })

            if (this.state.sellType == "MARKET") {
              var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
              if(lastPrice!=undefined){
                lastPrice =  lastPrice.replace(/,/g, "");
              }
              this.setState({ sellTotal: (value * lastPrice).toFixed(4) })
            } else {
              this.setState({ sellTotal: (value * this.state.sellPrice).toFixed(4) })
            }

          }

        }
        break;
      case 'buyVolume':
        if (value.length == 0) {
          this.setState({ buyVolume: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ buyVolume: value })

            if (this.state.buyType == "MARKET") {
              var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
              if(lastPrice!=undefined){
                lastPrice =  lastPrice.replace(/,/g, "");
              }
              this.setState({ buyTotal: (value * lastPrice).toFixed(4) })
            } else {
              this.setState({ buyTotal: (value * this.state.buyPrice).toFixed(4) })
            }

          }

        }
        break;

      case 'sellTotal':
        if (value.length == 0) {
          this.setState({ sellTotal: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ sellTotal: value })

            if (this.state.sellType == "MARKET") {
              var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
              if(lastPrice!=undefined){
                lastPrice =  lastPrice.replace(/,/g, "");
              }
              this.setState({ sellVolume: (value / lastPrice).toFixed(4) })
            } else {
              this.setState({ sellVolume: (value / this.state.sellPrice).toFixed(4) })
            }

          }

        }
        break;
      case 'buyTotal':
        if (value.length == 0) {
          this.setState({ buyTotal: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ buyTotal: value })

            if (this.state.buyType == "MARKET") {
              var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
              if(lastPrice!=undefined){
                lastPrice =  lastPrice.replace(/,/g, "");
              }
              this.setState({ buyVolume: (value / lastPrice).toFixed(4) })
            } else {
              this.setState({ buyVolume: (value / this.state.buyPrice).toFixed(4) })
            }

          }

        }
        break;

      default:
        break;
    }

    // this.setState({[name]: value});
  }


  // ******* DAY NIGHT MODE ***********
  handleAlignment = (event, newAlignment) => {
    console.log(newAlignment)
    if(newAlignment === 'left'){
      this.setState({
        alignment : newAlignment,
        theme: 'light_mode',
        backgroundcolor1: '#FDB813',
        backgroundcolor2: '#fff'
      })
      localStorage.setItem('tradeMode','light_mode')
    } else if(newAlignment === 'right'){
      this.setState({
        alignment : newAlignment,
        theme: 'dark_mode',
        backgroundcolor1: '#fff',
        backgroundcolor2: '#FDB813' 
      })
      localStorage.setItem('tradeMode','dark_mode')

    }
  }
  getProfitLoss =(data)=>{
    let profitLoss = 0;
    const currValue = data?.position * data?.currentPrice;
    const fee = (currValue * (data?.tradingFee || 0)/100);
    const tds =  data.status === "close" ? data?.exitTDS : data?.entryTDS
    profitLoss = ( data?.position * (( data?.currentPrice - data?.entryPrice))) - (fee+tds+(data.totalInterest))

    return profitLoss
  }
  getMyMargin = (side) => {
    if(side === 'Long'){
      const total = this.state.buyTotal || 0
      return (total / this.marginLeverage) + (total * this.marginFee / 100) + (total* this.marginTDS / 100)
    }else{
      return 0
    }
  }
  getHardLiquidationPrice =(type)=>{
    let hlPrice = 0;   
    let entryPrice=0; 
    if (type === "Long") {
        entryPrice= this.state.buyPrice;        
        if (this.state.buyVolume) {
        const idealLiqPrice = entryPrice- (entryPrice/this.marginLeverage);
        const saftyFactor  = entryPrice * 0.075;
        const actualLiqPrice = idealLiqPrice + saftyFactor
        hlPrice = actualLiqPrice
        }
      }else{
        entryPrice=this.state.sellPrice     
        if (this.state.sellVolume) {
          const idealLiqPrice = entryPrice +(entryPrice/this.marginLeverage);
        const saftyFactor  = entryPrice * 0.075;
          const actualLiqPrice = idealLiqPrice - saftyFactor
          hlPrice = actualLiqPrice
        }
    }
    return hlPrice;
  }
  exitMargingOrder = (order)=>{

    try{
      let text = "Are you sure you want to exit.";
      if (window.confirm(text) == true) {
        this.setState({loading: true})
        const endPoint = order.status === "init" ? "marginOrder/cancel" : "marginOrder/exit" 
        axios.post(`${BASE_URL+ endPoint}`, {orderId: order.orderId}).then(async(res)=>{
          if (res.data.statusCode === 200) {
            notify.show(res.data.message, "success");
            this.getCrossBalance()
            await this.getOrderByTab('POSITION')
          }
          this.setState({loading: false})
        }).catch((error)=>{
          this.setState({loading: false})
          notify.show(error?.message, "error");
        })
      } 
     
    }catch(err){
      this.setState({loading: false})
      notify.show(err?.message, "error");
      console.log(err);
    }
  }
  render() {
    let openFavTab = false
    let openBtcTab = false
    let openEthTab = false
    let openUsdtTab = false
    let openInrTab = false
    this.state.myfavcoin.map((d, idx) => {
      if (d.coin + d.pair == this.state.coinCode + this.state.pairCode) {
        openFavTab = true
      }
    })
    if (openFavTab == true) {

    } else if (this.state.pairCode == "BTC") {
      openBtcTab = true
    } else if (this.state.pairCode == "ETH") {
      openEthTab = true
    } else if (this.state.pairCode == "USDT") {
      openUsdtTab = true
    } else if (this.state.pairCode == "INR") {
      openInrTab = true
    }



    document.title = this.state.doctitle;
    // document.body.classList.add('faded_bg');
    if(this.state.theme == 'dark_mode') {
      document.body.classList.remove('lightbackground'); 
      document.body.classList.add('darkbackground'); 
    } else {
      document.body.classList.remove('darkbackground');  
      document.body.classList.add('lightbackground'); 
    }
    // console.log('All the props in trade.js', this.props);
    // console.log('All the state in trade.js', this.state);
    return (
      <div
       className={this.state.theme}
      >
        <Notifications />
        <Header />
        {this.state.loading ? <Loader /> : <></>}
        <section id="basic_trade">
          <div className="container-fluid">
            <div className="row ico_detail">
            <div className="col-md-12 no_padding">
                  <div className="trade_info clearfix">
                  <div className="basic_top_bg clearfix">
                    <div className="row">
                      <div className="col-xs-4 full">
                        <div className="trade_left">
                          <h6>{this.state.coinCode}/{this.state.pairCode} </h6>
                          <span>
                          
                            <small style={{marginLeft:'0px', fontSize:'18px'}} className={this.state.coinCode + this.state.pairCode + '_price'}>{this.state.buyPrice ? (+this.state.buyPrice).toFixed(4) : '0.000000'}</small>
                           
                          </span>
                        </div>
                      </div>
                      <div className="col-xs-8 full">
                        <div className="trade_right text-right">
                          <ul>
                            <li>
                              <span>24H Change</span>
                              {/* <div className="red_clr">Change -0.3%</div> */}
                              <span className={this.state.coinCode + this.state.pairCode + '_priceChangePercentColor'}> <span style={{display: 'inline'}} className={`${this.state.coinCode + this.state.pairCode + '_priceChangePercent'} ${this.state.coinCode + this.state.pairCode + '_priceChangePercentColor'}`}>{this.state.change24H || 0.00}</span>% </span>
                            </li>
                            <li>
                              <span>High</span>
                               {/* 0.00001580{" "} */}
                               <div className={this.state.coinCode + this.state.pairCode + '_priceHigh'}>{this.state.changeHigh || 0}</div>
                            </li>
                            <li>
                              <span>Low</span>
                               {/* 0.00001580{" "} */}
                               <div className={this.state.coinCode + this.state.pairCode + '_priceLow'}>{this.state.changeLow || 0}</div>
                            </li>
                            <li>
                              <span>Vol</span>
                              {/* 28,636.4 ETH */}
                              <div><span style={{display: 'inline'}} className={this.state.coinCode + this.state.pairCode + '_priceVolume'}>{this.state.volume ? (+this.state.volume).toFixed(5) : '0.00'}</span> {this.state.pairCode}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                    </div>
                    </div>
                    <div className="row ico_detail">
              <div className="col-lg-5 col-md-6 col-sm-5">
                <div className="row">              
              <div className="col-lg-7 col-md-7 no_padding clearfix">
              <div className="trade_side_br">
                    <div role="tabpanel" className="tab-pane active" id="token">
                      <ul className="nav nav-tabs" role="tablist">
                          <li className={openUsdtTab == true ? 'active':''} style={{ width: "22%", textAlign: "center" }}><a href="#USDT" onClick={()=>this.getCoinPairAll("USDT")} aria-controls="ent" role="tab" data-toggle="tab" aria-expanded="false">USDT</a></li>
                          <li className={openBtcTab == true ? 'active':''} style={{ width: "22%", textAlign: "center" }}><a href="#BTC" onClick={()=>this.getCoinPairAll("BTC")} aria-controls="new" role="tab" data-toggle="tab" aria-expanded="true">BTC</a></li>
                          <li className={openEthTab == true ? 'active':''} style={{ width: "22%", textAlign: "center" }}><a href="#ETH" onClick={()=>this.getCoinPairAll("ETH")} aria-controls="new" role="tab" data-toggle="tab" aria-expanded="true">ETH</a></li>
                          <li className={openInrTab == true ? 'active':''} style={{ width: "22%", textAlign: "center" }}><a href="#INR"  onClick={()=>this.getCoinPairAll("INR")} aria-controls="new" role="tab" data-toggle="tab" aria-expanded="true">INR</a></li>
                      </ul>
                      {/* <div className="trade_search clearfix">
                        <input type="text" placeholder="Search" />
                      </div> */}
                      <div className="tab-content scroller">
                        {/* <!-- tab1 start --> */}
                        <div role="tabpanel" className={openUsdtTab == true ? 'tab-pane active' : 'tab-pane'} id="USDT">


              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      
                      <th style={{ textAlign: "left" }}>Pair</th>
                      <th style={{ textAlign: "right" }}>Price</th>
                      <th style={{ textAlign: "right" }}>Chg</th>
                    </tr>
                  </thead>
                  <tbody>

                    
                    {this.state.tokenData.map((d, idx) => (
                      d.active == true && d.tActive == true && d.USDT == 1 &&
                      <tr key={idx} style={{ cursor: "pointer" }} className="coinhover">
                        {/* <td><img src="images/currency_img1.png" alt="" width="15px"/></td> */}
                        <td style={{ padding: "10px 5px", fontSize: "11px" }} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/USDT/'+this.props.match.params.type }}>{d.tokenSymbol}/USDT</td>
                        <td style={{ padding: "10px 5px", fontSize: "11px", textAlign: "right", fontWeight: "500" }} className={d.tokenSymbol + 'USDT_priceColor'} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/USDT/'+this.props.match.params.type }}>
                          <span className={d.tokenSymbol + "USDT_price"}>0.000 </span>   
                                                   

                        </td>
                        <td style={{ padding: "10px 5px", fontSize: "11px" }} className={d.tokenSymbol + 'USDT_priceChangePercentColor'} onClick={(e)=> { window.location.href = '/margin-trade/'+this.props.match.params.type + d.tokenSymbol + '/USDT/'+this.props.match.params.type }}>
                          <span className={d.tokenSymbol + "USDT_priceChangePercent"}>0.00</span>%
                  
                        </td>

                      </tr>


                    ))}
  
                  </tbody>
                </table>
              </div>

            </div>
                        {/* <!-- tab1 end -->  */}

                        {/* <!-- tab2 start --> */}
                        
            <div role="tabpanel" className={openBtcTab == true ? 'tab-pane active' : 'tab-pane'} id="BTC">


<div className="table-responsive">
  <table className="table">
    <thead>
      <tr>
        
        <th style={{ textAlign: "left" }}>Pair</th>
        <th style={{ textAlign: "right" }}>Price</th>
        <th style={{ textAlign: "right" }}>Chg</th>
      </tr>
    </thead>
    <tbody>

      
      {this.state.tokenData.map((d, idx) => (

        d.active == true && d.tActive == true && d.BTC == 1 &&
        <tr key={idx} style={{cursor: "pointer" }} className="coinhover">

          {/* <td><img src="images/currency_img1.png" alt="" width="15px"/></td> */}
          <td style={{ padding: "10px 5px", fontSize: "11px" }} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/BTC/'+this.props.match.params.type }}>{d.tokenSymbol}/BTC</td>
          <td style={{ padding: "10px 5px", fontSize: "11px", textAlign: "right", fontWeight: "500" }} className={d.tokenSymbol + 'BTC_priceColor'} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/BTC/'+this.props.match.params.type }}>
            <span className={d.tokenSymbol + "BTC_price"}>0.000 </span>
            
            

          </td>
          <td style={{ padding: "10px 5px", fontSize: "11px" }} className={d.tokenSymbol + 'BTC_priceChangePercentColor'} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/BTC/'+this.props.match.params.type }}>
            <span className={d.tokenSymbol + "BTC_priceChangePercent"}>0.00</span>%

          </td>

        </tr>



      ))}

    </tbody>
  </table>
</div>

</div>
                        {/* <!-- tab2 end -->  */}

                        {/* <!-- tab3 start --> */}
                          
            <div role="tabpanel" className={openEthTab == true ? 'tab-pane active' : 'tab-pane'} id="ETH">


<div className="table-responsive">
  <table className="table">
    <thead>
      <tr>
        
        <th style={{ textAlign: "left" }}>Pair</th>
        <th style={{ textAlign: "right" }}>Price</th>
        <th style={{ textAlign: "right" }}>Chg</th>
      </tr>
    </thead>
    <tbody>

      
      {this.state.tokenData.map((d, idx) => (
        d.active == true && d.tActive == true && d.ETH == 1 &&
        <tr key={idx} style={{cursor: "pointer" }} className="coinhover">

          {/* <td><img src="images/currency_img1.png" alt="" width="15px"/></td> */}
          <td style={{ padding: "10px 5px", fontSize: "11px" }} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/ETH/'+this.props.match.params.type }}>{d.tokenSymbol}/ETH</td>
          <td style={{ padding: "10px 5px", fontSize: "11px", textAlign: "right", fontWeight: "500" }} className={d.tokenSymbol + 'ETH_priceColor'} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/ETH/'+this.props.match.params.type }}>
            <span className={d.tokenSymbol + "ETH_price"}>0.000 </span>
            
            

          </td>
          <td style={{ padding: "10px 5px", fontSize: "11px" }} className={d.tokenSymbol + 'ETH_priceChangePercentColor'} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/ETH/'+this.props.match.params.type }}>
            <span className={d.tokenSymbol + "ETH_priceChangePercent"}>0.00</span>%
        
          </td>

        </tr>

      ))}

    </tbody>
  </table>
</div>

</div>

                        {/* <!-- tab4 start --> */}
                            
            <div role="tabpanel" className={openInrTab == true ? 'tab-pane active' : 'tab-pane'} id="INR">


<div className="table-responsive">
  <table className="table">
    <thead>
      <tr>
        
        <th style={{ textAlign: "left" }}>Pair</th>
        <th style={{ textAlign: "right" }}>Price</th>
        <th style={{ textAlign: "right" }}>Chg</th>
      </tr>
    </thead>
    <tbody>

      
      {this.state.tokenData.map((d, idx) => (
        d.active == true && d.tActive == true && d.INR == 1 && d.tokenSymbol !== 'INR' &&
        <tr key={idx} style={{cursor: "pointer" }} className="coinhover">
          {/* <td><img src="images/currency_img1.png" alt="" width="15px"/></td> */}
          <td style={{ padding: "10px 5px", fontSize: "11px" }} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/INR/'+this.props.match.params.type }}>{d.tokenSymbol}/INR</td>
          <td style={{ padding: "10px 5px", fontSize: "11px", textAlign: "right", fontWeight: "500" }} className={d.tokenSymbol + 'INR_priceColor'} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/INR/'+this.props.match.params.type }}>
            <span className={d.tokenSymbol + "INR_price"}>0.000 </span>
            
            

          </td>
          <td style={{ padding: "10px 5px", fontSize: "11px" }} className={d.tokenSymbol + 'INR_priceChangePercentColor'} onClick={(e)=> { window.location.href = '/margin-trade/' + d.tokenSymbol + '/INR/'+this.props.match.params.type }}>
            <span className={d.tokenSymbol + "INR_priceChangePercent"}>0.00</span>%
           
          </td>

        </tr>


      ))}

    </tbody>
  </table>
</div>

</div>

                      </div>
                    
                    </div>
                  
                 
                </div>
                <div className="trade_his">
                    <div className="bg_clr">
                      <h6>TRADE HISTORY</h6>
                      <div className="table-responsive" style={{height: 425}}>
                        <table className="table table-hover">
                        <thead>
                    <tr>
                      <th>Price</th>                    
                      <th>Volume</th>
				          	  <th>Time</th>
                    </tr>
                  </thead>

                        <tbody id="tradehistorytable">   
                              <TradeHistory trades={this.state.trades} coinName={this.state.coinCode=='ECNA'?this.state.coinCode+this.state.pairCode :this.state.coinCode } handelTradeHistory={this.handelTradeHistory} />
                            </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="col-lg-5 col-md-5 no_padding">               
              <div className="order_list clearfix">
                      <ul className="nav nav-tabs" role="tablist">
                        <h6>Order Book</h6>
                        {/* <li>
                          <a
                            href="#market_dep"
                            aria-controls="odr_his"
                            role="tab"
                            data-toggle="tab"
                          >
                            Market Depth
                          </a>
                        </li>
                        <li>
                          <a
                            href="#order_vol"
                            aria-controls="cncl_odr"
                            role="tab"
                            data-toggle="tab"
                          >
                            Order Volume
                          </a>
                        </li> */}
                      </ul>
                      <div className="tab-content" style={{height: 894}}>
                        {/* <!-- tab 1 start--> */}
                        <div
                          role="tabpanel"
                          className="tab-pane active"
                          id="market_dep"
                        >
                          <div className="table-responsive inner_table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Volume</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  
                              <td colSpan="2">
                                <table style={{width:"100%"}}><OrderBookBid bids={this.state.depth.bids}  coinName={this.state.coinCode=='ECNA'?this.state.coinCode+this.state.pairCode :this.state.coinCode }  myorders={this.state.myorderPrice} handelTradeHistory={this.handelTradeHistory} /></table>
                                </td>
                              
                                </tr>
                                <tr>
                                <td colSpan="2">
                                <table style={{width:"100%"}}><OrderBookAsk asks={this.state.depth.asks}  coinName={this.state.coinCode=='ECNA'?this.state.coinCode+this.state.pairCode :this.state.coinCode } myorders={this.state.myorderPrice} handelTradeHistory={this.handelTradeHistory} /></table>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 1 ends --> */}

                      </div>
                    </div>
              </div>
              </div>
              </div> 
              <div className="col-lg-7 col-md-6 col-sm-7 no_padding">                
                {/* <div className="dark_map"> */}
                  {/* <!-- TradingView Chart BEGIN -->  */}
                  {/* <Helmet> */}
                    {/* <script
                      type="text/javascript"
                      src="https://s3.tradingview.com/tv.js"
                    ></script>
                    <script type="text/javascript">
                      var tradingview_embed_options = {};
                      tradingview_embed_options.width = '100%';
                      tradingview_embed_options.height = '340';
                      tradingview_embed_options.chart = 'WVU6IPbf'; new
                      TradingView.chart(tradingview_embed_options);
                    </script> */}
                    <div className="dark_map map_part col-lg-12 full" >

                    {/* </div> */}
                    {this.state.exchangeType == "Binance" &&

                      <>
                      {/* {console.log('this.state.exchangeType1',this.state.exchangeType)} */}
                      {this.state.theme === "dark_mode" ?
                      <>
                      {/* <TradingViewWidget
                        symbol={"BINANCE:" + this.state.coinCode + "" + this.state.pairCode}
                        theme={Themes.LIGHT}
                        // theme={Themes.LIGHT}
                        style="1"
                        locale="en"
                        enable_publishing="false"
                        allow_symbol_change=""
                        hide_legend="true"
                        toolbar_bg="#262D33"
                        autosize
                      /> */}
                      <TVChartContainer symbol={"Binance:" + this.props.match.params.coinCode + "/" + this.props.match.params.pairCode} />
                    
                      </>
                      :
                      <>
                      {/* <TradingViewWidget
                        symbol={"BINANCE:" + this.state.coinCode + "" + this.state.pairCode}
                        theme={Themes.LIGHT}
                        style="1"
                        locale="en"
                        enable_publishing="false"
                        allow_symbol_change=""
                        hide_legend="true"
                        toolbar_bg="#262D33"
                        autosize
                      />  */}
                      <TVChartContainer symbol={"Binance:" + this.props.match.params.coinCode + "/" + this.props.match.params.pairCode} />
                      </>
                      }
                      </>
                    }
                          {this.state.exchangeType == "E-Canna" &&
                            <>
                              {this.state.theme === "dark_mode" ?
                                <>
                                  <TVChartContainer symbol={"Ecanna:" + this.props.match.params.coinCode + "/" + this.props.match.params.pairCode} />
                                </>
                                :
                                <>
                                  <TVChartContainer symbol={"Ecanna:" + this.props.match.params.coinCode + "/" + this.props.match.params.pairCode} />
                                </>}
                            </>

                          }
                  {/* </Helmet> */}
                  {/* <!-- TradingView Chart END -->  */}
                </div>
                <div className="clearfix">                
                <div className="trade_form col-md-12 no_padding">                  
                  <div className="trade_form_bg clearfix">                  
                      <div className="trade_buy_form">
                        
                        <div className='form_option' style={{marginBottom:10}}>

                        <div className='option_link col-sm-6 text-centerr' onClick={this.getCrossBalance}>
                            <Link to={"/margin-trade/" + this.props.match.params.coinCode + "/" + this.props.match.params.pairCode + "/CROSS"}>
                            <span className={this.props?.match.params.type === 'CROSS'?'btn btn-primary':'btn btn-link'} > CROSS 3X  </span>
                            </Link>
                        </div>
                             </div>

                        {/* CROSS ORDER UI    START  */}
             
                          <div  id="buy_trade" className="col-sm-6">
                            <div className="type_option" style={{display:'flex', paddingBottom:'5px'}}>
                            <span>Order Type</span> 
                              <button onClick={()=>{this.changeOrderMode('buy', 'LIMIT')}} className={this.state.buyType === 'LIMIT'? 'btn btn-warning':'btn btn-secondry'}>LIMIT</button>
                              <button onClick={()=>{this.changeOrderMode('buy', 'MARKET')}} className={this.state.buyType === 'MARKET'? 'btn btn-warning mx-2':'btn btn-secondry mx-2'}>MARKET</button>
                              {/* <button onClick={()=>{this.changeOrderMode('buy', 'STOP-LIMIT')}} className={this.state.buyType === 'STOP-LIMIT'? 'btn btn-warning':'btn btn-secondry'}>STOP-LIMIT</button> */}
                            </div>

                            {/* <div style={{display:'flex',justifyContent:'space-between', paddingBottom:'5px'}}>
                               <span>Borrow Mode</span> 
                              <button onClick={()=>{this.changeBorrowMode('borrowMode_buy', 'NORMAL')}} className={this.state.borrowMode_buy === 'NORMAL'? 'btn btn-warning':'btn btn-secondry'}>NORMAL</button>
                              <button onClick={()=>{this.changeBorrowMode('borrowMode_buy', 'BORROW')}} className={this.state.borrowMode_buy === 'BORROW'? 'btn btn-warning mx-2':'btn btn-secondry mx-2'}>BORROW</button>
                              <button onClick={()=>{this.changeBorrowMode('borrowMode_buy', 'REPAY')}} className={this.state.borrowMode_buy === 'REPAY'? 'btn btn-warning mx-2':'btn btn-secondry mx-2'}>REPAY</button>
                            </div> */}

                          <form>

                      <div className="form-group clearfix">
                        <label>Avbl<br></br>{this.state.pairCode}</label>
                        <input type="number" placeholder="0"className="form-control" readOnly value={(Number(this.state.userBalancePair).toFixed(10).slice(0, -4))} />
                      </div>


                            <div className="form-group clearfix">
                              <label> Price </label>
                              <input type="text" className="form-control" placeholder="0" 
                              name="buyPrice" autoComplete="off" value={this.state.buyPrice} 
                              onChange={this.handleChange}
                               disabled={this.state.buyType === 'MARKET'}
                              // {...this.state.buyPrice === "MARKET" ? { 'disabled': 'disabled' } : {}}
                              />
                            </div>
                            <div className="form-group clearfix">
                              <label>Amount</label>
                              <input
                                type="text" className="form-control"
                                placeholder="0"
                                name="buyVolume" value={this.state.buyVolume}  onChange={this.handleChange}
                              />
                              <div className="amount_opt">
                                <ul>
                                  <li onClick={() => this.buyBalancePer(25)}>25%</li>
                                  <li onClick={() => this.buyBalancePer(50)}>50%</li>
                                  <li onClick={() => this.buyBalancePer(75)}>75%</li>
                                  <li onClick={() => this.buyBalancePer(100)}>100%</li>
                                </ul>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Total {this.state.pairCode}</label>
                              <input type="text" className="form-control" placeholder="0" name="buyTotal" value={isNaN(this.state.buyTotal)?0:this.state.buyTotal}/>
                            </div>
                            <div style={{background: "#00ff0014", color: "#02a102", borderRadius: 8}}>
                              <div style={{ display: "flex", justifyContent: "space-between", fontSize: 11, marginTop: 24, padding :8 }}>
                                <div>
                                  <div style={{fontWeight: 600}}>Order Value</div>
                                  <div>{this.state.buyTotal ?? 0} USDT</div>
                                </div>
                                <div>
                                  <div style={{fontWeight: 600}}>Your Margin</div>
                                  <div>{this.getMyMargin("Long").toFixed(4)} USDT</div>
                                </div>
                                <div>
                                  <div style={{fontWeight: 600}}>Borrowed Margin</div>
                                  <div>{((this.state.buyTotal || 0) / this.marginLeverage * (this.marginLeverage - 1)).toFixed(4)} USDT</div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', color: '#02a102' ,padding : 8,fontSize: 11,}}>
                                <div style={{ paddingBottom: '1px dotted #02a102',fontWeight: 600 }}>Hard Liquidation Price</div>
                                <div style={{ marginLeft: 10 }}>{this.state.buyVolume ? this.getHardLiquidationPrice("Long"):0.00} USDT/{this.state.coinCode}</div>
                              </div>
                            </div>
                            <div className="" onClick={ this.state.buyDisable == false ?  this.handleSubmitBuy : "return false"}>
                              {" "}
                              <a href="#"  className={this.state.buyDisable == false ?  'green_btn'  : 'green_btn op0_6'}>
                                Long
                              </a>{" "}
                            </div>
                              <YourMarginDetailsModal data={{orderValue: this.state.buyTotal ?? 0}} getMyMargin={this.getMyMargin} text={'Your margin includes 0.1% fees and 1% TDS'}/>
                          </form>
                          </div>

                            <div id="sell_trade" className="col-sm-6">
                                <div className="type_option" style={{ display: 'flex', paddingBottom: '5px' }}>
                                    <span>Order Type</span>
                                    <button onClick={() => { this.changeOrderMode('sell', 'LIMIT') }} className={this.state.sellType === 'LIMIT' ? 'btn btn-warning' : 'btn btn-secondry'}>LIMIT</button>
                                    <button onClick={() => { this.changeOrderMode('sell', 'MARKET') }} className={this.state.sellType === 'MARKET' ? 'btn btn-warning mx-2' : 'btn btn-secondry mx-2'}>MARKET</button>
                                    {/* <button onClick={()=>{this.changeOrderMode('sell', 'STOP-LIMIT')}} className={this.state.sellType === 'STOP-LIMIT'? 'btn btn-warning':'btn btn-secondry'}>STOP-LIMIT</button> */}
                                </div>
                                {/* <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '5px' }}>
                                    <span>Borrow Mode</span>
                                    <button onClick={() => { this.changeBorrowMode('borrowMode_sell', 'NORMAL') }} className={this.state.borrowMode_sell === 'NORMAL' ? 'btn btn-warning' : 'btn btn-secondry'}>NORMAL</button>
                                    <button onClick={() => { this.changeBorrowMode('borrowMode_sell', 'BORROW') }} className={this.state.borrowMode_sell === 'BORROW' ? 'btn btn-warning mx-2' : 'btn btn-secondry mx-2'}>BORROW</button>
                                    <button onClick={() => { this.changeBorrowMode('borrowMode_sell', 'REPAY') }} className={this.state.borrowMode_sell === 'REPAY' ? 'btn btn-warning mx-2' : 'btn btn-secondry mx-2'}>REPAY</button>
                                </div> */}
                                <form>


                                    <div className="form-group clearfix">
                                        <label>Avbl<br></br>{this.state.coinCode}</label>
                                        <input type="number" placeholder="0" className="form-control" readOnly value={(Number(this.state.userBalanceCoin).toFixed(10).slice(0, -4))} />
                                    </div>

                                    <div className="form-group clearfix">
                                        <label> Price </label>
                                        <input type="text" className="form-control" placeholder="0"
                                            name="sellPrice" autoComplete="off" value={this.state.sellPrice}
                                            onChange={this.handleChange}
                                            disabled={this.state.sellType === 'MARKET'}
                                            // {...this.state.sellPrice === "MARKET" ? { 'disabled': 'disabled' } : {}}
                                        />
                                    </div>
                                    <div className="form-group clearfix">
                                        <label>Amount</label>
                                        <input
                                            type="text" className="form-control"
                                            placeholder="0"
                                            name="sellVolume" value={this.state.sellVolume} onChange={this.handleChange}
                                        />
                                        <div className="amount_opt">
                                            <ul>
                                                <li onClick={() => this.sellBalancePer(25)}>25%</li>
                                                <li onClick={() => this.sellBalancePer(50)}>50%</li>
                                                <li onClick={() => this.sellBalancePer(75)}>75%</li>
                                                <li onClick={() => this.sellBalancePer(100)}>100%</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Total  {this.state.pairCode}</label>
                                        <input type="text" className="form-control" placeholder="0" name="sellTotal" value={isNaN(this.state.sellTotal)?0:this.state.sellTotal } />
                                    </div>
                                    <div style={{background: "#b1060614", color: "#e00000", borderRadius: 8}}>
                              <div style={{ display: "flex", justifyContent: "space-between", fontSize: 11, marginTop: 24, padding :8 }}>
                                <div>
                                  <div style={{fontWeight: 600}}>Order Value</div>
                                  <div>{this.state.sellTotal ?? 0} USDT</div>
                                </div>
                                <div>
                                  <div style={{fontWeight: 600}}>Your Margin</div>
                                  <div>{this.getMyMargin('Short').toFixed(4)} USDT</div>
                                </div>
                                <div>
                                  <div style={{fontWeight: 600}}>Borrowed Margin</div>
                                  <div>{((this.state.sellTotal || 0) / this.marginLeverage * (this.marginLeverage - 1)).toFixed(4)} USDT</div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', color: '#e00000' ,padding : 8,fontSize: 11,}}>
                                <div style={{ paddingBottom: '1px dotted #e00000',fontWeight: 600 }}>Hard Liquidation Price</div>
                                <div style={{ marginLeft: 10 }}>{this.state.sellVolume ?this.getHardLiquidationPrice("Short") : 0.00} USDT/{this.state.coinCode}</div>
                              </div>
                            </div>
                                    <div className="" >
                                        <button type="button" onClick={(e)=> this.handleSubmitSell(e)} className={!this.state.sellDisable ?  'blue_btn'  : 'blue_btn op0_6'} style={{border:"none", padding:12}}>
                                            Short 
                                        </button>{" "}
                                        <small>
                                            Fee: Maker fee: 0.2% | Taker fee: 0.2%
                                        </small>{" "}
                                    </div>
                                </form>
                            </div>
                        

                        {/* CROSS ORDER UI    END  */}

                        </div>
                      </div>                     
                    </div>
                  </div>
                </div>
                </div>                           
                <div className="row ico_detail">
              <div className="col-lg-12 col-md-12 order_his no_padding">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="active">
                    <a href="#portfolio" role="tab" data-toggle="tab" onClick={()=> this.getOrderByTab("PORTFOLIO")}>
                      Portfolio
                    </a>
                  </li>
                <li >
                    <a href="#active_pos" role="tab" data-toggle="tab" onClick={()=> this.getOrderByTab("POSITION")}>
                      Active positions ({this.state.activePositions?.length || 0})
                    </a>
                  </li>
                  <li>
                    <a href="#margin_his" role="tab" data-toggle="tab"  onClick={()=> this.getOrderByTab("ORDER_HISTORY")}>
                     Margin History
                    </a>
                  </li>
                </ul>
                <div className="tab-content order_table">
                   {/* <!-- tab 1 start--> */}
                   <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="portfolio"
                  >
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                          <th>Coin</th>                            
                            <th>Total Qty.</th>
                            <th>Avalable Qty.</th>
                            <th>Inorder Qty.</th>
                            <th>Total Asset Value</th>
                          </tr>
                        </thead>
                        <tbody>

                        {this.state.marginPortfolio.map((row, xid) => (
                                <tr key={xid}>
                                  <td className= "green_clr">
                                    {row.pair}
                                    </td>
                                  <td>{(row.availableQty + row.inOrderQty).toFixed(2)}</td>
                                  <td>{(row.availableQty || 0).toFixed(2)}</td>
                                  <td>{(row.inOrderQty || 0).toFixed(2)}</td>
                                  <td>{(row.availableQty + row.inOrderQty).toFixed(2)} USDT</td>
                                  
                                </tr>
                              )).reverse()}


                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <!-- tab 1 ends--> */}
                <div
                    role="tabpanel"
                    className="tab-pane"
                    id="active_pos"
                  >
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                          <th>Pair</th>                            
                            <th>Type</th>
                            <th>Pos</th>
                            <th>Stop loss</th>
                            <th>Entry</th>
                            {/* <th>Target</th> */}
                            <th>Current price</th>
                            <th>Current P/L</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                        {this.state.activePositions.map((row, xid) => (
                                <tr key={xid}>
                                  <td className={row.orderType == "Long" ? "green_clr" : "red_clr"}>
                                    {row.pair}
                                    </td>
                                  <td className={row.orderType == "Long" ? "green_clr" : "red_clr"}>{row.orderType.toUpperCase()}(3x)</td>
                                  <td>{Number(row.position).toFixed(4)}</td>
                                  <td>{row.stopLoss ? row.stopLoss.toFixed(2):'-'}</td>
                                  <td>{row.entryPrice}</td>
                                  {/* <td>
                                    <MarginOrderDetailsModal data={row}/>
                                  </td> */}
                                  <td>{row.currentPrice}</td>
                                  <td className={(this.getProfitLoss(row)> 0) ? "green_clr" : "red_clr"}> {(this.getProfitLoss(row)).toFixed(4)} USDT</td>
                                  <td>
                                   {this.state.activeTableTab === 'POSITION' && <MarginOrderDetailsModal data={row}/>}
                                    <a className="btn-danger btn-sm stroke_btn_main "style={{marginLeft:5}} onClick={ ()=>{this.exitMargingOrder(row)}}>Exit</a>
                                  </td>
                                </tr>
                              )).reverse()}


                        </tbody>
                      </table>
                    </div>
                  </div>
                 

                  {/* <!-- tab 2 start--> */}
                  <div role="tabpanel" className="tab-pane" id="margin_his">
                    <div className="table-responsive inner_table">
                      <table className="table">
                        <thead>
                          <tr>
                          <th>Coin</th>                            
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Fees</th>
                            <th>Total</th>
                            <th>Side</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.historyOrderrows.map((row, xid) => (
                                <tr key={xid}>
                                  <td className={row.orderType == "Long" ? "green_clr" : "red_clr"}>
                                    {row.coinName + row.pair}
                                    </td>
                                  <td className={row.orderType == "Long" ? "green_clr" : "red_clr"}>{Number(row.entryPrice).toFixed(2)} {row.pair}</td>
                                  <td>{(+row.amount).toFixed(4)} {row.coinName}</td>
                                  <td>{(row.tradingFee * row.exitPrice * row.amount /100).toFixed(2)} USDT</td>
                                  <td>{(+row.total).toFixed(4)} {row.pair}</td>
                                  <td className={row.orderType == "Long" ? "green_clr" : "red_clr"}>{row.orderType}</td>
                                  <td>
                                  {moment(row.createdAt).format('DD MMM yyyy HH:MM:SS A')}
                                  </td>  
                                  <td>
                                  {this.state.activeTableTab === 'ORDER_HISTORY' && <MarginOrderDetailsModal data={row}/>}
                                    </td>                                
                                </tr>
                              )).reverse()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <!-- tab 2 ends-->  */}

                  <div role="tabpanel" className="tab-pane" id="cancel_odr">
                    <div className="table-responsive inner_table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Coin</th>
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.cancelOrderrows.map((row, xid) => (
                                <tr key={xid}>
                                  <td className={row.orderType == "Buy" ? "green_clr" : "red_clr"}>
                                    {row.coinName + row.pair}<br></br>{row.orderType} ({row.orderMode})
                                    <br></br>
                                    <small> {moment(row.timestamp*1000).format('DD MMM yyyy HH:MM:SS A')}</small>
                                    </td>
                                  <td className={row.orderType == "Buy" ? "green_clr" : "red_clr"}>{Number(row.price).toFixed(row.coinName == 'ECNA'?decimalData[row.coinName+row.pair]['D'] :decimalData[row.coinName]['D'])} {row.pair}</td>
                                  <td>{(+row.amount).toFixed(4)} {row.coinName}<br></br><small>Fee: {(+row.tradingFee).toFixed(4)} {row.orderType == 'Buy' ? row.coinName  :  row.pair}</small></td>
                                  <td>{(+row.total).toFixed(4)} {row.pair}</td>
                                </tr>
                              )).reverse()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </section>
        
      </div>
    );
  }
}

export default MarginTrade;



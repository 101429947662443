import React from "react";
import Webcam from "react-webcam";
import "./index.css"
import { useCallback, useRef, useState } from "react"; // import useCallback

const CustomWebcam = ({show, handleClose, setImageFile}) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  // create a capture function
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
   
  }, [webcamRef]);
  const upload=()=>{
    var file = dataURLtoFile(imgSrc,'kycSelfie'+Date.now());
    setImageFile(file);
     handleClose();
  }
const retake =()=>{
    setImgSrc("")
}
const videoConstraints = {
//   width: 300,
  height: 400,
  facingMode: "selfie"
};
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

  return (
    <div
      className={`modal fade in popup_form ${show && "show"}`}
      id="transfer_modal"
      data-backdrop="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content rounded-md">
             <div className="d-flex flex-between px-2 py-2">
              <h5 className="modal-title" id="exampleModalLabel">
                Capture
              </h5>
              <button
                type="button"
                className="close font-16"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                <span aria-hidden="true">&times;</span>{" "}
              </button>
            </div>
          <div className="modal-body">
            <div className="container">
              {imgSrc ? (
                <img src={imgSrc} alt="webcam" />
              ) : (
             show && <Webcam
                audio={false}
                // height={500}
                // width={250}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                
                  ref={webcamRef}
                  screenshotQuality={0.8}
                />
              )}
              <div className="btn-container">
                {imgSrc ? (
                  <div style={{display: 'flex'}}>
                  <button className="btn" onClick={retake}>Retake photo</button>
                  <button className="btn ml-4" onClick={upload}>Upload photo</button>
                  </div>
                ) : (
                  <button className="btn" onClick={capture}>Capture photo</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomWebcam;
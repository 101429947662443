import React, { Component } from "react";
import { Button } from "reactstrap";

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../config/constant";
import QRCode from "qrcode.react";
import moment from "moment";
import TextEllipsis from "react-text-ellipsis";
import speakeasy from "@levminer/speakeasy";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  render() {
    document.title = "FAQ";
    document.body.classList.add("faded_bg");

    return (
      <>
        <Header loadFees={this.state.loadFeeSubs} />
        <section id="inner_top_part" class="fee_head">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 text-center">
                <h2> FAQ</h2>
              </div>
            </div>
          </div>
        </section>
        <section id="middle_content">
    <div class="container">
	<div class="row">
	<div class="col-md-12">
	<div class="white_bx clearfix">
                <h3>FAQ</h3>
                <hr></hr>

                <div style={{ textAlign: "justify" }}>
                {/* <p>&nbsp;</p> */}

                  <p>
              What is E-Canna coin & E-Canna Digital Exchange?
                </p> 
                <p>&nbsp;</p> 
                <p>
                E-Canna coin is a revolutionary digital coin platform that offers a simple way to gain huge profits, conduct trading and multiply your savings digitally. E-Canna Digital Exchange is the company’s most ambitious project ever. It offers customers a secure environment where customers can buy, sell and trade their coins and get the highest value.
                </p>    
                <p>&nbsp;</p> 
                <p>
                What’s the coin’s current value?
                </p>
                <p>&nbsp;</p> 
                <p>
                Due to the success of E-Canna’s multiple projects, the value keeps rising at a steady rate. As of September 25 2022, the price of one E-Canna coin is valued at $6
                </p>
                <p>&nbsp;</p> 
                <p>
                How to Buy Cryptocoin on E-Canna Digital Exchange?
                     </p>
                     <p>&nbsp;</p> 
                     <p>
                     Buying E-Canna coins on our digital exchange is a lot easier than it looks. All you have to do is follow the steps and you are good to go.
                        </p>  
                        <p>&nbsp;</p> 
                        <p>
                        1. Log in to your E-Canna account and click Buy Now.
                        </p>
                        <p>
                        2. You can choose to buy crypto coins using different fiat currencies. By entering the amount you wish to spend, the system automatically displays the total amount of crypto you can get. Once done, click continue.
                        </p>
                        <p>
                        3. Choose the preferred payment method to make the payment. You can buy them using a credit/debit card. Choose the card type and click continue.
                        </p>
                        <p>
                        4. On the next page, follow the instructions and enter your card information. If everything goes well, your card will be added.
                        </p>
                        <p>
                        5. Once the card is added, check the payment details, and the amount and confirm your order within 1 minute. As the value of coins keeps rising, the more you wait, the total amount gets recalculated based on the current market value.
                        </p>
                        <p>
                        7. After order confirmation, you will be redirected to your bank's OTP Transaction Page. Just follow the instructions and verify the payment.
                        </p>
                        <p>
                        8. Once you have made the payment successfully, you can view the purchased coins in your wallet.
                        </p>
                        <p>&nbsp;</p> 
                        <p >
                        Are my funds safe on E-Canna Digital Exchange?
                        </p>  <p>&nbsp;</p> 
                        <p>
                        Yes, they are always safe. E-Canna Digital Exchange is India’s first self-built crypto coin exchange system designed and developed by E-Canna Buy. This robust system is built using state-of-the-art technologies and cannot be compromised by any means through cyber attacks.
                        </p><p>&nbsp;</p> 
                        <p>
                        How many coins will be listed in the exchange?
                        </p>  <p>&nbsp;</p> 
                        <p>
                        More than 10 companies’ crypto coins and E-Canna’s own coins will be listed in the exchange.
                        </p>  <p>&nbsp;</p> 
                        <p>
                        How does the value of the coin keep increasing?
                        </p>    <p>&nbsp;</p> 
                        <p>
                        The rising value of E-Canna coins is due to the immense success of our several projects. Thanks to this, the coin’s value never goes down however, there will be fluctuations.
                        </p>   <p>&nbsp;</p> 
                        <p>
                        What benefits does E-Canna Digital Exchange offer?
                        </p>  <p>&nbsp;</p> 
                        <p>
                        Customers get the exclusive opportunity to gain higher profits from their coins. Customers get the option to do instantaneous and secure trading without the worry of losing their coins.
                        </p>  <p>&nbsp;</p> 
                        <p>
                        Can I make deposits/withdrawals on the exchange without completing my KYC?
                        </p>   <p>&nbsp;</p> 
                        <p>
                        All users who wish to do trading on our digital exchange are required to complete their KYC to conduct trading, sell goods, etc. The same applies to avail deposit/withdrawal facilities.
                        </p>  <p>&nbsp;</p> 
                        <p>
                        What is the fee structure on E-Canna Digital Exchange?
                        </p>  <p>&nbsp;</p> 
                        <p>
                        In E-Canna Digital Exchange, the fees are categorized into Deposit Fees, Trading Fees & Withdrawal Fees. There are currently no fees for crypto deposits. Each trade carries a standard fee of 0.1%. Our withdrawal fees are automatically adjusted based on the status of the market.
                        </p>   <p>&nbsp;</p> 
                        <p>
                        How many accounts can I have on E-Canna Digital Exchange?
                        </p>  <p>&nbsp;</p> 
                        <p>
                        A single individual may only have one account with E-Canna Digital Exchange.
                        </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default Faq;

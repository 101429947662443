import React, { useEffect, useState } from 'react'
import { colorArray } from '../p2pContants'
import TransferModal from '../transfer/transfer.modal';
import { BASE_URL } from '../../../config/constant';
import { toast } from 'react-toastify';
import axiosInstance from '../../Dashboard/httpInterceptor';
import AddPaymentMethods from '../AddPaymentMethods/AddPaymentMethods';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

function Sell({advertiesment, baseAsset,quoteAsset, balances, reloadList, setLoading}) {
  const routeHistory = useHistory()

    const [selected, setSelected] = useState('')
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [amounts, setAmounts] = useState({amount0: 0, amount1:0})
    const [inavlidAmount, setInavlidAmount] = useState(false)
    const [paymentModes, setPaymentModes] = useState([])
    const [selectedPaymentModes, setSelectedPaymentModes] = useState({UPI:null, IMPS:null, BankTransfer:null})
    const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
    const [usersPaymentsMethods, setusersPaymentsMethods] = useState();
    const onPaymentModeAdd =(mode)=>{
      if (usersPaymentsMethods && usersPaymentsMethods[mode]) {
        setSelectedPaymentModes({...selectedPaymentModes, [mode]: usersPaymentsMethods[mode]})
      }else{
        setShowPaymentMethodModal(true)
      }
    }
    const getPaymentModes=()=>{
      axiosInstance
          .get(BASE_URL + `get/paymentMethods`)
          .then((res) => {
              setPaymentModes(res.data?.data || []);
            // setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            // setLoading(false);
          });
    }
    const closePaymentMethodModal = ()=>{
      const userId = localStorage.getItem("userDetails");
      setShowPaymentMethodModal(false)
      getUserPaymentMethods(userId)
      }
    useEffect(() => {
      const userId = localStorage.getItem("userDetails");
      if(userId)
        getUserPaymentMethods(userId);
        getPaymentModes()
    }, [])
    
    const getUserPaymentMethods = (userId) => {
      // setLoading(true);
      // 
      axiosInstance
        .get(BASE_URL + `get/userPaymentMethods?userId=${userId || ''}`)
        .then((res) => {
          const data = (res.data?.data);
          setusersPaymentsMethods(data)
          // setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          // setLoading(false);
        });
    };
    const removePaymentMode =(mode)=>{
      const modes = {...selectedPaymentModes, [mode]: null};
          setSelectedPaymentModes(modes)
    }
    const closeTransferModal = ()=>{
        setShowTransferModal(false)
      }
      const openTransferModal = ()=>{
        setShowTransferModal(true)
      }
    const amountChangeHandler=(item,amount, side)=>{
      if (side === 'from') {
        setAmounts((amt)=> ({amount0: amount, amount1: item.price * amount}))
      }else{
        setAmounts((amt)=> ({amount0: amount / item.price , amount1: amount}))
      }
    }
    useEffect(() => {
     if (amounts.amount0) {
      if (amounts.amount0 > Number(balances?.p2pBalances[baseAsset])) {
        setInavlidAmount(true)
       }else{
        setInavlidAmount(false)
       }
     }
    }, [amounts.amount0])
    
    const submit =(item)=>{
      const usrstr = localStorage.getItem('ECannaUser')
      const user = usrstr ? JSON.parse(usrstr): null
      if (user) {
        if (amounts.amount0>0 && amounts.amount1>0 && amounts.amount0<= Number(balances?.p2pBalances[baseAsset])) {
        setLoading(true)
        setInavlidAmount(false)
        const payload={
          type:'Sell',
          amount: amounts.amount0,
          price: item.price,
          total: item.price * amounts.amount0,
          assetCoin: item.assetCoin,
          fiatCoin: item.fiatCoin,
          paymentMethods: selectedPaymentModes,
          adPostedBy: user.userName,
          userId: user._id,
          emailId: user.emailId,
          matchedWith: item._id
        }
        axiosInstance.post(`${BASE_URL}p2p/matchOrder`,payload).then((res)=>{

          reloadList()
          setLoading(false)
          routeHistory.push('p2p-orders/'+res.data.data)

        }).catch((err)=>{
          setLoading(false)
        })
      }else{
        setInavlidAmount(true)
        toast.error('Insufficient funds!')
      }
    }else{
      toast.error('User session has been expire, login again')
    }
  }
  return (
    <>
          <TransferModal
        show={showTransferModal}
        handleClose={closeTransferModal}
        coin={baseAsset}
      />
       <AddPaymentMethods  show={showPaymentMethodModal}
        handleClose={closePaymentMethodModal}/>
    <table className="table" style={{ minWidth: 1000 }}>
      <thead>
        <tr>
          <th>ADVERTISER</th>
          <th>
            PRICE <span className="badges badges-yellow">HIGH TO LOW</span>
          </th>
          <th>AMOUNT</th>
          <th>TOTAL</th>
          <th>PAYMENT</th>
          <th>DATE</th>
          <th>TRADE</th>
        </tr>
      </thead>
      <tbody>
        {advertiesment
          .filter((e) => e.type === "Buy" && e.fiatCoin === quoteAsset && e.assetCoin === baseAsset)
          .map((data,ind) => (
            <tr key={'sell'+ind}>
              
              <td>
                <div className="d-flex flex-start">
                  <div
                    className="avatar mr-2"
                    style={{
                      background:
                        colorArray[
                          Math.floor(Math.random() * colorArray.length)
                        ],
                    }}
                  >
                     {data.adPostedBy.split(' ')[0].charAt(0)+""+(data.adPostedBy.split(' ').length >1 ?data.adPostedBy.split(' ')[1].charAt(0):'')}
                  </div>
                  <div className="txt-black font-w-500">
                    <div>{data.adPostedBy}</div>
                    (<small>{data.userId}</small>)
                  </div>
                </div>
              </td>
              <td>
                <div className="font-16 txt-black font-w-600">
                  {(+data.price).toFixed(5)}{" "}
                  <small className="font-w-500">{data.assetCoin}</small>
                </div>
              </td>
              <td>
                <div className="font-16 txt-black font-w-600">
                  {(+data.amount).toFixed(2)}{" "}
                  <small className="font-w-500"></small>
                </div>
              </td>
               <td>
                <div className="font-16 txt-black font-w-600">
                  {(data.price * data.amount).toFixed(2)}{" "}
                  <small className="font-w-500"></small>
                </div>
              </td>
              <td>
                {/* {selected === data._id ?  */}
                {false ? <div>
                 {data.paymentMethods.UPI &&<div className="pm-bg-1">
                   <div className="d-flex flex-between">
                       <strong className="txt-black">UPI</strong>
                      
                       </div>
                           <label className="mt-3"><span className="txt-black font-w-600">Name: </span><small>{data.paymentMethods.UPI.userName}</small></label><br />
                           <label><span className="txt-black font-w-600">UPI: </span><small>{data.paymentMethods.UPI.upiAddress}</small></label>
                           {data.paymentMethods.UPI.qr && <div><img src={data.paymentMethods.UPI.qr} alt="" /></div>}
                 </div>}
                 {data.paymentMethods.IMPS &&<div className="pm-bg-1">
                   <div className="d-flex flex-between">
                       <strong className="txt-black">IMPS</strong>
                       
                       </div>
                           <label className="mt-3"><span className="txt-black font-w-600">Name: </span><small>{data.paymentMethods.IMPS.userName}</small></label><br />
                           <label><span className="txt-black font-w-600">Account No: </span><small>{data.paymentMethods.IMPS.accountNo}</small></label><br />
                           <label><span className="txt-black font-w-600">IFSC Code: </span><small>{data.paymentMethods.IMPS.ifsc}</small></label><br />
                           <label><span className="txt-black font-w-600">Bank Name: </span><small>{data.paymentMethods.IMPS.bankName}</small></label><br />
                           <label><span className="txt-black font-w-600">Account Type: </span><small>{data.paymentMethods.IMPS.accountType}</small></label><br />
                           <label><span className="txt-black font-w-600">Branch: </span><small>{data.paymentMethods.IMPS.branchName}</small></label>
                 </div>}
 
                 {data.paymentMethods.BankTransfer &&<div className="pm-bg-1">
                   <div className="d-flex flex-between">
                       <strong className="txt-black">Bank Transfer (India)</strong>
                       
                       </div>
                       <label className="mt-3"><span className="txt-black font-w-600">Name: </span><small>{data.paymentMethods.BankTransfer.userName}</small></label><br />
                           <label><span className="txt-black font-w-600">Account No: </span><small>{data.paymentMethods.BankTransfer.accountNo}</small></label><br />
                           <label><span className="txt-black font-w-600">IFSC Code: </span><small>{data.paymentMethods.BankTransfer.ifsc}</small></label><br />
                           <label><span className="txt-black font-w-600">Bank Name: </span><small>{data.paymentMethods.BankTransfer.bankName}</small></label><br />
                           <label><span className="txt-black font-w-600">Account Type: </span><small>{data.paymentMethods.BankTransfer.accountType}</small></label><br />
                           <label><span className="txt-black font-w-600">Branch: </span><small>{data.paymentMethods.BankTransfer.branchName}</small></label>
                 </div>}
                 </div>
                 :
                 <div className="d-flex">
                 {Object.keys(data.paymentMethods || {}).map((key, i) => (
                    <>
                    {!!data.paymentMethods[key] && <div
                      className={`ml-2 badges ${
                        i === 0
                          ? "badges-yellow"
                          : i === 1
                          ? "badges-green"
                          : "badges-blue"
                      }`}
                    >
                      {" "}
                      { key}
                    </div>}
                </>
                 
                 ))}
               </div>
                }
              </td>
              <td className='font-16 txt-black font-w-600'>{data ? moment(data?.createdOn).format('DD/MM/YYYY, hh:mm a'):''}</td>
              <td>
                {selected === data._id ? (
                  <div>
                    <div className="form-input" style={{borderColor: inavlidAmount && 'red'}}>
                      <div className="label">I want to sell</div>
                      <div className="d-flex flex-between mt-3">
                        <input type="number" placeholder="0.00000" value={amounts.amount0} onChange={(e)=> amountChangeHandler(data, e.target.value, 'from')} />
                        <div className="d-flex flex-end ml-2">
                          <div className="all">All</div>
                          <div className="d-flex flex-start ml-2">
                            <img
                              src={"/coinicon/" + baseAsset + ".png"}
                              width="14px"
                              height="14px"
                              alt=""
                              className="coin-icon"
                            />
                            <div className="ml-2 coin-name">{baseAsset}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-start py-2">
                      <div className="font-12 txt-dark">Balance: {(Number(balances?.p2pBalances[baseAsset])).toFixed(6)} {baseAsset}</div>
                      <div className="font-12 txt-yellow-5 ml-4 cursor-pointer" onClick={()=> openTransferModal()}>Transfer</div>
                    </div>

                    <div className="form-input mt-4"  style={{borderColor: inavlidAmount && 'red'}}>
                      <div className="label">I will receive</div>
                      <div className="d-flex flex-between mt-3">
                        <input type="number" placeholder="0.00" value={amounts.amount1} onChange={(e)=> amountChangeHandler(data, e.target.value, 'to')} />
                        <div className="d-flex flex-end ml-2">
                          <div className="d-flex flex-start ml-2">
                            <img
                              src={"/coinicon/" + quoteAsset + ".png"}
                              width="14px"
                              height="14px"
                              alt=""
                              className="coin-icon"
                            />
                            <div className="ml-2 coin-name">{quoteAsset}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
      <label> Payment Method</label>
      <div></div>
      <div className="">
        <a
          href="#"
          className="dropdown-toggle btn bg-yellow-5 txt-white font-w-600"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-plus"></i>
          {" Add "}
          <span className="caret"> </span>
        </a>
        <ul className="dropdown-menu">
          {paymentModes.map((modes,i) => (
            <li key={'buy-'+i}>
              <a href="javascript:void" onClick={() => onPaymentModeAdd(modes.paymentMethod)}>
                {modes.displayName}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {selectedPaymentModes.UPI &&<div className="pm-bg-1">
        <div className="d-flex flex-between">
            <strong className="txt-black">UPI</strong>
            <div onClick={()=> removePaymentMode('UPI')}><i className="fa fa-close cursor-pointer"></i></div>
            </div>
                <label className="mt-3"><span className="txt-black font-w-600">Name: </span>{selectedPaymentModes.UPI.userName}</label><br />
                <label><span className="txt-black font-w-600">UPI: </span>{selectedPaymentModes.UPI.upiAddress}</label>
                {selectedPaymentModes.UPI.qr && <div><img src={selectedPaymentModes.UPI.qr} alt="" /></div>}
      </div>}
      {selectedPaymentModes.IMPS &&<div className="pm-bg-1">
        <div className="d-flex flex-between">
            <strong className="txt-black">IMPS</strong>
            <div onClick={()=> removePaymentMode('IMPS')}><i className="fa fa-close cursor-pointer"></i></div>
            </div>
                <label className="mt-3"><span className="txt-black font-w-600">Name: </span>{selectedPaymentModes.IMPS.userName}</label><br />
                <label><span className="txt-black font-w-600">Account No: </span>{selectedPaymentModes.IMPS.accountNo}</label><br />
                <label><span className="txt-black font-w-600">IFSC Code: </span>{selectedPaymentModes.IMPS.ifsc}</label><br />
                <label><span className="txt-black font-w-600">Bank Name: </span>{selectedPaymentModes.IMPS.bankName}</label><br />
                <label><span className="txt-black font-w-600">Account Type: </span>{selectedPaymentModes.IMPS.accountType}</label><br />
                <label><span className="txt-black font-w-600">Branch: </span>{selectedPaymentModes.IMPS.branchName}</label>
      </div>}

      {selectedPaymentModes.BankTransfer &&<div className="pm-bg-1">
        <div className="d-flex flex-between">
            <strong className="txt-black">Bank Transfer (India)</strong>
            <div onClick={()=> removePaymentMode('BankTransfer')}><i className="fa fa-close cursor-pointer"></i></div>
            </div>
                <label className="mt-3"><span className="txt-black font-w-600">Name: </span>{selectedPaymentModes.BankTransfer.userName}</label><br />
                <label><span className="txt-black font-w-600">Account No: </span>{selectedPaymentModes.BankTransfer.accountNo}</label><br />
                <label><span className="txt-black font-w-600">IFSC Code: </span>{selectedPaymentModes.BankTransfer.ifsc}</label><br />
                <label><span className="txt-black font-w-600">Bank Name: </span>{selectedPaymentModes.BankTransfer.bankName}</label><br />
                <label><span className="txt-black font-w-600">Account Type: </span>{selectedPaymentModes.BankTransfer.accountType}</label><br />
                <label><span className="txt-black font-w-600">Branch: </span>{selectedPaymentModes.BankTransfer.branchName}</label>
      </div>}
    </div>


                    <div className="d-flex flex-center mt-4">
                      <button className="btn " onClick={() => setSelected("")}>
                        Cancel
                      </button>
                      <button className="bg-red btn txt-white w-100" onClick={()=> submit(data)}>
                        Sell {baseAsset}
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    className="bg-red btn txt-white"
                    onClick={() => {setSelected(data._id); setAmounts((amt)=>({amount0: data.amount, amount1: data.amount * data.price}))} }
                  >
                    Sell {baseAsset}
                  </button>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
    </>
  );
}

export default Sell
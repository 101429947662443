import React, { Component } from "react";
import { Button } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import { BASE_URL, GOOGLE_CAPTCHA_KEY , FRONTEND_URL, hostName} from "../../config/constant";

import { Link, Redirect } from "react-router-dom";


import { detect } from "detect-browser";
import Header from "../../Components/Header/header";
import speakeasy from "@levminer/speakeasy";
import { toast , ToastContainer} from "react-toastify";
import Loader from "../../React-Loader/loader";
import {Client} from "@heroiclabs/nakama-js"

const browser = detect();
const publicIp = require("public-ip");
const TEST_SITE_KEY = GOOGLE_CAPTCHA_KEY;
const DELAY = 1500;

// ReCAPTCHA
// site key : 6LeDCJgaAAAAAH_Rgyi0bg_rzJiwzf6qJzhnr3Tz
// secret : 6LeDCJgaAAAAACvUL9EFlBmwTowIs2FfBNLGaKc_

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validPhonenumberRegex = RegExp(
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};
const client = new Client("defaultkey", hostName, "7350");
const socket = client.createSocket();
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      otp:null,
      password: null,
      captcha: null,
      reserror: null,
      ressuccess: null,
      userId: null,
      showTwoFA: false,
      buttonDisable: false,
      userDetails: "",
      userName: "",
      token : '',
      ECannaUser: '',
      twoFASecret: "",
      twofa: "",
      loader: 0,
      loading : false,
      errors: {
        email: "",
        password: "",
        captcha: "",
        twofa: "",
      },
      screenUI : "LOGIN"
    };
  }
  componentDidMount() {
    if (localStorage.getItem("userDetails")) {
      let userData = localStorage.getItem("userDetails");
      this.setState({ userId: userData });
      this.props.history.push("/dashboard");
    }

    // console.log("Response2",this.state.userId)
  }
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
        case "otp":
          errors.otp = "" ? 'Enter OTP' : '';
          break;
      case "password":
        errors.password =
          value.length < 8 ? "Password must be 8 characters long!" : "";
        break;
      case "twofa":
        errors.twofa = value.length < 6 ? "Please enter valid 2FA code" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleChange2 = (value) => {
    let errors = this.state.errors;
    // console.log("Captcha value:", value);
    // this.state.captcha = value;
    this.setState({ captcha: value });
    // console.log(this.state.captcha);
    if (this.state.captcha != null) {
      errors.captcha = "";
      this.setState({ errors });
    }
  };

  if(browser) {
    // console.log(browser.name);
    // console.log(browser.version);
    // console.log(browser.os);
  }

  addLoginHistory = async (userid) => {
    var ipAddress = await publicIp.v4();
    console.log(ipAddress);
    console.log(browser.os);
    // await axios.get('https://ipapi.co/json/')
    // .then(res => {
    //   console.log("Response: ", res)
    // })
    axios
      .post(BASE_URL + `loginhistory`, {
        browser: browser.os + " (" + browser.name + " " + browser.version + ")",
        userId: userid,
        ipAddress: ipAddress,
      })
      .then((res) => {});
  };

  resendMail = () => {
    const data = {
      emailId: this.state.email,
      formUrl: FRONTEND_URL,
    };

    this.setState({ reserror: null  , loading : true});

    axios.post(BASE_URL + `registration/resendmail`, data).then((res) => {
      this.setState({
        loading : false
      })
      if (res.data.statusCode == 200) {
        this.setState({ ressuccess: "Verification mail resent successfully" });
              this.setState({screenUI : "OTP"})
      } else {
        toast.error('Something went wrong')
        this.setState({ reserror: "Something went wrong" });
              this.setState({screenUI : "LOGIN"})
      }
    }).catch((err)=>{
      this.setState({
        loading : false
      })
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      loader: 1,
    });
    let errors = this.state.errors;

    if (this.state.email == null) {
      errors.email = "Email is not valid!";
    }
    if (this.state.password == null) {
      errors.password = "Password must be 8 characters long!";
    }
    if (this.state.captcha == null) {
      errors.captcha = "Please verify Recaptcha";    //uncomment this
      // errors.captcha = "";    //comment this
    } else {
      errors.captcha = "";
    }
    this.setState({ errors });

    if (validateForm(this.state.errors)) {
      this.setState({ buttonDisable: true });
      // console.info('Valid Form')
      //   window.location.assign(`/dashboard`);

      const data = {
        emailId: this.state.email,
        password: this.state.password,
      };
      this.setState({ reserror: null, ressuccess: null , loading : true });


      axios.post(BASE_URL + `login`, data).then((res) => {
        // console.log(res);
        // console.log(res.data.userData._id);
        this.setState({
          loading : false
        })

        if (res.data.message == "emailidDoesNotExists") {
          this.setState({ reserror: "User Not Found!" });
          toast.error('User Not Found!')
        }
        if (res.data.message == "pleaseVerifyYourEmail") {
          this.setState({
            reserror: (
              <div>
                Please verify your email address!<br></br>Didn't received mail?{" "}
                <a href="#" onClick={this.resendMail}>
                  <b>Resend</b>
                </a>
              </div>
            ),
          });
          toast.error('Please verify your email address!')
        }
        if (res.data.message == "loginSuccessful") {
          this.nakamaAuthenticate(res.data.userData).then((e)=>{})
          if (res.data.userData.two_factor == 0) {
            this.addLoginHistory(res.data.userData._id);
            localStorage.setItem("ECannaUser", JSON.stringify(res.data.userData));
            localStorage.setItem("userDetails", res.data.userData._id);
            localStorage.setItem("userName", res.data.userData.userName);
            localStorage.setItem("token", res.data.token);
            this.props.history.push("/dashboard");
            this.setState({ reserror: "Please wait..." });
            toast.success('Login Successfully')
          } else {
            this.setState({
              screenUI : "2FA",
              showTwoFA: true,
              twoFASecret: res.data.userData.secret,
              userDetails: res.data.userData._id,
              userName: res.data.userData.userName,
              ECannaUser : res.data.userData,
              token : res.data.token
            });
          }
        }
        if (res.data.message == "emailidAndPasswordDoesNotMatch") {
          this.setState({ reserror: "Email Id and password does not match!" });
          toast.error('Email Id and password does not match!')
        }
        if (res.data.message == "accountBlocked") {
          this.setState({ reserror: "Your account has been blocked, please contact to admin at support@ecannabuy.com" });
          // toast.error('this account is blocked, please contact with Ecanna support team!')
        }
        this.setState({ buttonDisable: false, loader: 0 });
      }).catch((err)=>{
        this.setState({
          loading : false
        })
      });
    } else {
      // console.error('Invalid Form')
    }
  };

  verifyOTP = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      loader: 1,
    });

    if(this.state.otp){
      const data = {
        emailId : this.state.email,
          otp : this.state.otp,
        };
        
        console.log(data)
        
        this.setState({
          loading : true
        })
        axios.post(BASE_URL + `registration/verifyOTP`, data).then((res) => {
          this.setState({
            loading : false
          })
          if (res.data.statusCode == 200) {
            this.setState({screenUI : "LOGIN"})
            this.setState({ buttonDisable: false });
            toast.success(res.data.message)
        } else{
          this.setState({screenUI : "OTP"})
          toast.error(res.data.message);
        }


      }).catch((err)=>{
        this.setState({
          loading : false
        })
      });
    }else{
      toast.error('Enter OTP')
    }
  };



  verifyTwoFA = async (event) => {
    event.preventDefault();
    let errors = this.state.errors;

    if (this.state.twofa == null) {
      errors.twofa = "Please enter valid 2FA code";
    }
    if (this.state.twofa.length == 0) {
      errors.twofa = "Please enter valid 2FA code";
    }

    this.setState({ errors });

    if (validateForm(this.state.errors)) {
      var verified = speakeasy.totp.verify({
        secret: this.state.twoFASecret,
        encoding: "base32",
        token: this.state.twofa,
      });

      if (verified == true) {
        this.addLoginHistory(this.state.userDetails);
        localStorage.setItem("ECannaUser", JSON.stringify(this.state.ECannaUser));
        localStorage.setItem("userDetails", this.state.userDetails);
        localStorage.setItem("userName", this.state.userName);
        localStorage.setItem("token", this.state.token);

        this.props.history.push("/dashboard");
        this.setState({ reserror: "Please wait..." });
      } else {
        this.setState({ twofa: "" });
        errors.twofa = "Please enter valid 2FA code";
        this.setState({ errors });
      }
    }
    // console.log("verify",verified)
  };

  nakamaAuthenticate = async (user) => {
    try {
      // const create = true;
      const session = await client.authenticateEmail(
        user.emailId,
        user.emailId,
        user.chatId ? false : true,
        user.emailId
      );
      console.info("Successfully authenticated:", session);
  
      await axios.post(`${BASE_URL}updateChatId`, {emailId: user.emailId, chatId: session?.user_id})
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { errors } = this.state;
    document.title = "Login";
    document.body.classList.add("faded_bg");

    return (
      <>
        <Header blueBg={true} />
        {this.state.loading ? <Loader /> : <></>}

        <ToastContainer />
        <section id="form_page">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-sm-10 col-lg-offset-3 col-sm-offset-1">  
                { this.state.screenUI == "LOGIN" &&<div>
              <div className="text-center">
                  <h5> E-Canna Account Login</h5>
                  <p>Welcome back! Log In with your Email</p>
                  </div>    
                
                  {/* {this.state.showTwoFA == false ? ( */}
                    <form onSubmit={this.handleSubmit} noValidate>
                      {this.state.reserror != null && (
                        <div className="alert alert-danger text-center">
                          {this.state.reserror}
                        </div>
                      )}

                      {/* {this.state.ressuccess != null && (
                        <div className="alert alert-success text-center">
                          {this.state.ressuccess}
                        </div>
                      )} */}
                      
                      <div class="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" name="email" placeholder="Email" onChange={this.handleChange} noValidate/>
                        {errors.email.length > 0 && 
                        <span className='formerror'>{errors.email}</span>}
                      </div>
                      <div class="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" name="password" placeholder="Password" onChange={this.handleChange} noValidate/>
                        {errors.password.length > 0 && 
                        <span className='formerror'>{errors.password}</span>}
                      </div>
                      <ReCAPTCHA
                            style={{ display: "inline-block" }}
                            theme="light"
                            ref={this._reCaptchaRef}
                            sitekey={TEST_SITE_KEY}
                            onChange={this.handleChange2}
                            asyncScriptOnLoad={this.asyncScriptOnLoad}
                        />
                        {errors.captcha.length > 0 && 
                            <div className='formerror' style={{textAlign:"center"}}>{errors.captcha}</div>}

                      <div class="form-group btn_part">
                        {this.state.buttonDisable == true ? 
                        <button type="" className="orange_btn" disabled style={{backgroundColor: '#5a9ce8'}}>Log In </button>
                        :
                          <button type="submit" class="orange_btn">Log In</button>
                          }
                      </div>
                      <div class="form_link">
                      <a href="/forgotpassword">Forgot Password?</a>                       
                      <a href="/register">Sign Up Now</a>
                      </div>
                    </form>
                    </div>}



                    { this.state.screenUI == "OTP" &&
                    <div> 
                         <div className="text-center">
                  <h2>Verification OTP sent on email.</h2>
                  </div>  
                        <form>
                      <div class="form-group">
                        <label>One Time Password</label>
                        <input type="text" className="form-control" name="otp" placeholder="Otp" onChange={this.handleChange} />
                        {errors.otp == '' && 
                        <span className='formerror'>{errors.otp}</span>}
                      </div>
                      <div class="form-group btn_part">
                      <button
                            type=""
                            className="orange_btn"
                            onClick={this.verifyOTP}
                          >
                            Verify OTP{" "} 
                          </button>
                          </div>
                          </form>
                    </div>}


                  { this.state.screenUI == "2FA" &&
                    <div className="text-center">
                      <hr></hr>
                      <h4 style={{ fontWeight: "300" }}>
                        Two-Factor Authentication (2FA)
                      </h4>

                      <div className="">
                        <input
                          type="number"
                          style={{
                            margin: "20px 0px 0px 0px",
                            textAlign: "center",
                          }}
                          className="form-control"
                          name="twofa"
                          onChange={this.handleChange}
                          value={this.state.twofa}
                          placeholder="Enter Authentication Code"
                          noValidate
                        />
                        {errors.twofa.length > 0 && (
                          <span className="formerror">{errors.twofa}</span>
                        )}
                        <div className="clearfix"></div>
                        <button
                          className="btn btn-success"
                          style={{ margin: "20px 0px", width: "100%" }}
                          onClick={this.verifyTwoFA}
                        >
                          Verify Code
                        </button>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  }


                  
                  {/* <div class="center_part text-center">
                    <span>OR</span>
                  </div>

                  <div class="form_social text-center">
                    <a href="#">
                      <div class="binance_img">
                        {" "}
                        <img src="images/binance_icon.png" alt="img" />
                      </div>{" "}
                      LOGIN VIA BINANCE
                    </a>
                  </div> */}                
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Login;

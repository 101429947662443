import React from 'react'
import BigNumber from 'bignumber.js'
import { decimalData } from './decimalDataForAllCoins';
const TradeHistory = (props) => {
    
    if(props.trades) {
    let currentp = new BigNumber(0);
    let rows = [];
    let numRows = props.trades.length;
    for (var i = 0; i < numRows; i++) {
        let newp = new BigNumber(props.trades[i].p);
        let newq = new BigNumber(props.trades[i].q);
        let mode
        if(newp >= currentp) {
            mode = "Buy"
        } else {
            mode = "Sell"    
        }
        // debugger
        rows.unshift(
                <tr key={`${i}:${props.trades[i].p}:${props.trades[i].q}:${props.trades[i].T}`} onClick={() => props.handelTradeHistory(Number(newp),Number(newq),mode)} className="coinhover" style={{cursor:"pointer"}}>
                    { props.trades[i].Mode ?
                        <td ><span className={props.trades[i].Mode == "Buy" ? 'green_clr' : 'red_clr'}>{new BigNumber(props.trades[i].p).toFormat(3,1)}</span></td>      
                      :
                        <td ><span className={newp.gte(currentp) ? 'green_clr' : 'red_clr'}>{newp.toFormat(decimalData[props.coinName]['D'],1)}</span></td>     
                    } 
                    <td>{new BigNumber(props.trades[i].q).toFormat(4,1)}</td>
                    {props.trades[i].E && <td>{new Date(props.trades[i].E).toLocaleTimeString()}</td>}
                    {/* {(props.trades[i].T) && <td>{new Date(props.trades[i].T).toLocaleTimeString()}</td>} */}
                </tr>
               
   
        )
        currentp = new BigNumber(props.trades[i].p);
    }
    return (
        <>
        {rows}
        </>
    )


} else {
    return (
        <div></div>
    )
}

}

export default TradeHistory;
import { useState } from "react";
import { HYPERVERGE_indianWorkflow, HYPERVERGE_nonIndianWorkflow } from "../../config/constant";


export const useHyperverge =()=>{
    const [response, setResponse] = useState({responseType: "",responseText:"", result:{}})
    const initHyperVergeWidget = (accessToken, userId, isIndian) => {
        const HYPERVERGE_WORKFLOW_ID = isIndian ? HYPERVERGE_indianWorkflow : HYPERVERGE_nonIndianWorkflow;
        const hyperKycConfig = new window.HyperKycConfig(accessToken, HYPERVERGE_WORKFLOW_ID, userId);
    
        const handler = (HyperKycResult) => {
            switch (HyperKycResult.status) {
    
                // ----Incomplete workflow-----
    
                case "user_cancelled":
                    setResponse({responseType: "error",responseText:"Cancelled by user", result:HyperKycResult})
                    console.log("user-cancelled", HyperKycResult);
                    break;
                case "error":
                    setResponse({responseType: "error",responseText:"Session expired! Please refresh the page.", result:HyperKycResult})
                    console.log("some error", HyperKycResult);
                    break;
    
                // ----Complete workflow-----
    
                case "auto_approved":
                    setResponse({responseType: "sucess",responseText:"Kyc approved", result:HyperKycResult})
                    console.log("auto_approved", HyperKycResult);
                    break;
                case "auto_declined":
                    setResponse({responseType: "error",responseText:"Kyc auto declined", result:HyperKycResult})
                    console.log("auto_declined", HyperKycResult);
                    break;
                case "needs_review":
                    setResponse({responseType: "error",responseText:"Kyc submitted for review", result:HyperKycResult})
                    console.log("needs_review", HyperKycResult);
                    break;
            }
        }
        window.HyperKYCModule.launch(hyperKycConfig, handler);
    }

    return {initHyperVergeWidget, response}
}
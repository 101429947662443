import React, { useEffect, useState } from 'react'
import Sidebars from '../../Components/Dashboard/sidebar'
import Header from '../../Components/Header/header'
import Notifications, { notify } from 'react-notify-toast';
import { BASE_URL, ECANNA_TOKEN_SYMBOL } from '../../config/constant';
import axios from './httpInterceptor';
import BigNumber from 'bignumber.js';

const BuyECNA = () => {
    const [tokenList, setTokenList] = useState([]);
    const [selectDropdown, setSelectDropdown] = useState('Select Token');
    const [userBalance, setUserBalance] = useState();
    const [availableBalance, setAvailableBalance] = useState(0);
    const [selectedToken, setSelectedToken] = useState({});
    const [amount, setAmount] = useState('');
    const [tokenUsdPrice, setTokenUsdPrice] = useState(0);
    const [enannaPrice, setEnannaPrice] = useState(0);
    const [userTransaction, setUserTransaction] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getTokenlist();
        getEcannaPrice();
        getUserTransaction()
    }, []);
    useEffect(() => {
        if (selectedToken && selectedToken?.tokenSymbol ) {
            getUserBalance()
            if (selectedToken?.tokenSymbol?.toUpperCase() === 'USDT') {
                setTokenUsdPrice(1)
            }else if(selectedToken?.tokenSymbol?.toUpperCase() === 'INR'){
                const url = `https://api.wazirx.com/sapi/v1/ticker/24hr?symbol=usdt${selectedToken?.tokenSymbol?.toLowerCase()}`
                fetch(url).then((response) => response.json()).then((res)=>{
                                setTokenUsdPrice(1/Number(res?.lastPrice))
                            })
            }
            else{
                const header ={
                    "Access-Control-Allow-Origin": '*',
                    "Content-Type":'application/json',
                    "authorization": 'Apikey 93f91878dfb5e5c640b34711ba1bd6293e4e2f628bac1c086ea4725dd30f7e9a'
                }
                const pair = `${selectedToken?.tokenSymbol?.toUpperCase()}USDT`;
                const url = 'https://api.binance.com/api/v3/ticker/price?symbol='+pair
                // axios.post(BASE_URL+"get/tokenPairPrice",{url}).then((res)=>{
                //                 setTokenUsdPrice(+res.data?.data?.price)
                //             })
                // axios.get(url).then((res)=>{
                //     setTokenUsdPrice(+res.data?.data?.price)
                // })

        //  fetch(`https://min-api.cryptocompare.com/data/price?fsym=${selectedToken?.tokenSymbol?.toUpperCase()}&tsyms=USDT&api_key=93f91878dfb5e5c640b34711ba1bd6293e4e2f628bac1c086ea4725dd30f7e9a`,{headers:header}).then((res)=>{
        //             setTokenUsdPrice(res.data?.USDT)
        //         })
                            
          fetch(`https://api.wazirx.com/sapi/v1/ticker/24hr?symbol=${selectedToken?.tokenSymbol?.toLowerCase()}usdt`).then((response) => response.json())
          .then((res)=>{
                        setTokenUsdPrice(+res?.lastPrice)
                    })
            }
        }
    }, [selectedToken]);
useEffect(() => {
    if (tokenUsdPrice) {
    console.log(tokenUsdPrice)
    }
}, [tokenUsdPrice]);
    const getTokenlist = () => {
        axios.get(BASE_URL + `ercToken/get/all`)
            .then(async res => {
                if (res.data.message === "success") {
                    setTokenList(res.data.data)
                }
            })
    }
    const getUserBalance = async () => {
        const userId = localStorage.getItem('userDetails')
        await axios.post(BASE_URL + `userBalance/get`, {
            "userId": userId
        })
            .then(res => {
                if (res.data.message === "success") {
                    setUserBalance(res.data.data)
                    const available = isNaN(res.data.data[selectedToken?.tokenSymbol]) ? 0 : Number((res.data.data[selectedToken?.tokenSymbol]).toFixed(4))
                    setAvailableBalance(available)
                }
            })
    }
    const getUserTransaction = async () => {
        const userId = localStorage.getItem('userDetails')
        await axios.post(BASE_URL + `ecanna/getAllEcannaTransaction`, {
            "userId": userId
        })
            .then(res => {
                if (res.data.statusCode === 200) {
                    setUserTransaction(res.data.data)
                    setLoading(false) 
                }
            }).catch((e)=>{ setLoading(false) })
    }

    const getEcannaPrice = ()=>{
        axios.post(BASE_URL + `ecannarate/getPrice`,{date:new Date().toISOString()}).then((res)=>{
            if (res.data.statusCode === 200) {
                setEnannaPrice(res.data?.data?.price || 0)
            }
        })
    }
    const handleSelectCoin = (coin) => {
        setSelectedToken(coin)
        setSelectDropdown(<><img src={"/coinicon/" + coin.tokenSymbol + ".png"} alt='' width="20px" height="20px" style={{ marginLeft: "10px", marginRight: "20px", marginTop: "-4px" }} /> {coin.tokenSymbol} <span style={{ fontSize: "12px", color: "#888" }}>({coin.tokenName})</span></>)
      
    }
    const handleChange = (e, target) =>{
        if (target === 'amount') {
            setAmount(e.target.value)
        }
    }
    const buyEcanna = ()=>{
        const userId = localStorage.getItem('userDetails')
        if (selectedToken?.tokenSymbol && Number(amount) > 0 && availableBalance >= amount && Number(enannaPrice) >0 ) {
        const data = {
            userId: userId,
                fromTokenSymbol: selectedToken?.tokenSymbol,
                toTokenSymbol: ECANNA_TOKEN_SYMBOL,
                tokenAmount: amount,
                ecnaPrice: enannaPrice,
                ECNAReceived: tokenUsdPrice * amount / enannaPrice ,
        }
        axios.post(BASE_URL + `ecanna/buy`, data).then((res)=>{
            if (res.data.statusCode === 200) {
                notify.show("Transaction successful", "success")
                clearForm()
                getUserTransaction();
                getUserBalance()
            }
        })
    }
    }
    const clearForm = ()=>{
        setAmount('')
    }
    return (
        <div>
            <Notifications />
            <Header />
            <section id="middel_area" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 col-sm-3 area_left">
                            <Sidebars />
                        </div>

                        <div className="col-md-10 col-sm-9 area_right">
                            <div className="white_bx clearfix deposit_part">
                                <div className="side_txt">
                                    <h6>Buy ECNA <small>1 ECNA ≈ {enannaPrice} ($)</small></h6>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-md-offset-3">
                                        <div className="dropdown">
                                            <button id="dLabel" className="form-control text-left" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {selectDropdown} <span className="caret"></span>
                                            </button>

                                            <ul className="dropdown-menu" aria-labelledby="dLabel">
                                                {tokenList.map((d, xid) => (
                                                    (d.active === true && d.dActive === true) || (d.tokenSymbol ==='INR') ?

                                                    <li key={xid} onClick={() => { handleSelectCoin(d) }}>
                                                        <img src={"/coinicon/" + d.tokenSymbol + ".png"} width="20px" height="20px" style={{ marginLeft: "10px", marginRight: "20px" }} />
                                                        {d.tokenSymbol} <span style={{ fontSize: "12px", color: "#888" }}>({d.tokenName})</span>
                                                    </li>:''


                                                ))}
                                            </ul>
                                            <div className="deposit_detail_bx">
                                                <div className="address_field">
                                                    <div className="form-group mt-4" style={{ position: "relative" }}>
                                                        <label>Amount <span>Available {selectedToken?.tokenSymbol}: <strong>{availableBalance}{tokenUsdPrice ? ` ≈ ${(Number(availableBalance * tokenUsdPrice)).toFixed(4)}$`:'' }</strong></span></label>
                                                        <input type="number" className="form-control" step="any" name="amount" value={amount} onChange={(e)=>handleChange(e,'amount')} noValidate />
                                                       <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',fontSize:'11px'}}>
                                                       {amount && <span>{amount} {selectedToken?.tokenSymbol} ≈ {(Number(tokenUsdPrice * amount / enannaPrice)).toFixed(4) } ECNA</span>}
                                                        {availableBalance < amount &&
                                                                                <span className='formerror'>Insufficient Amount of {selectedToken?.tokenSymbol}</span>}
                                                       </div>

                                                        <span style={{ fontSize: "12px", color: "#efbb18", fontWeight: "700", cursor: "pointer",background:'#fff', position: "absolute", top: "40px", right: "10px" }} onClick={(e)=>setAmount(availableBalance)}>Max</span>
                                                        {/* {this.state.error !== null ?
                                                            <span style={{ color: 'red', fontSize: 10 }}>{this.state.error}</span> : <span></span>} */}
                                                    </div>
                                                    <div className='text-center'>
                                                        <button className='btn btn-success ' onClick={()=>{buyEcanna()}}>Swap {selectedToken?.tokenSymbol}</button>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="row  mt-4">
                                    <hr />
                                <div className="col-sm-12">
                                    <h3>Transaction List</h3>
                                </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-12">
                                      
                                    <div className="table-responsive">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Token Symbol</th>
                                            <th>Token Amount</th>
                                            <th>Rate ($)</th>
                                            <th>Received Amount (ECNA)</th>
                                            <th>Date</th>

                                        </tr>
                                    </thead>

                                    {loading ?
                                        <tbody>
                                            <tr>
                                                <td colSpan="6" height="350px">
                                                    <div className="loader">Loading...</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        :
                userTransaction && userTransaction.length ?  <tbody>
                       {userTransaction.map((e,i)=>{
                        return(
                        <tr>
                            <td>{i+1}</td>
                            <td>{e.fromTokenSymbol}</td>
                            <td>{e.tokenAmount}</td>
                            <td>{e.tokenECNArate}</td>
                            <td>{(Number(e.ECNAReceived)).toFixed(4)}</td>
                            <td>{new Date(e.timestamp).toLocaleDateString()}</td>
                        </tr>
                        )
                       }) }
                    </tbody>
                    :
                    <tbody>
                                            <tr>
                                                <td colSpan="6" style={{textAlign:'center',minHeight:200}}>
                                                    No Transaction Found!
                                                </td>
                                            </tr>
                                        </tbody>
                                    }

                                </table>
                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></section></div>
    )
}

export default BuyECNA
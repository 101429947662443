import React from 'react'

const BlogDetailsMain=()=>{
    return(
<section id="blog-post">
    <div class="container">
       <div class="col-md-12">
                <div class="post-area">
                    <div class="row blog_wrapper">
                                <div class="col-lg-8 blog_details_left order-lg-1 order-md-2 order-2">
                                    <img src="images/post.jpg" class="img-fluid" alt="" />
                                    <div class="blog_details">
                                        
                                        
                                        
                                        <div class="blog-hadding-wrapper">
                                            <div class="blog-title">
                                                <h3>Communication Underminesthe Bitcoin Ecosystems</h3>   
                                                <div class="author_info">
                                                    <span>Posted On - 25 Aug 2018</span>
                                                    <span>By - John Doe</span>  
                                                </div>                            
                                            </div>
                                        </div>

                                        
                                        <div class="blog-details-pera">
                                           <p>Conversion first mover advantage ramen deployment business model canvas beta creative android burness model canvas beta creative android burn rate graphical user. Conversion first mover advantage ramen deployment Conversion first mover advantage ramen deployment business model canvas beta creative android burness model canvas beta creative android burn rate graphical user. Conversion first mover advantag deployment </p>
										   <br />
                                           <p>Conversion first mover advantage ramen deployment business model canvas beta creative android burness model canvass model canvas beta creative android burness model canvas beta creative android burn rate graphical. Conversion first mover advantage ramen deployment </p>
                                           <p>Conversion first mover advantage ramen deployment brst mover advantage ramen deployment business model canvas beta creative android burness model canvas beta creative android burn rate graphical user. Conversion first mover advantage ramen deployment </p>
										   <br />
                                           <p>Conversion first mover advantage ramen deployment business canvas beta creative android bu onversion first mover advantage ramen deployment business canvas beta creative androon first mover advantage ramen deployment business canvas beta creative android burness model canvas beta creative android burn rate graphical user. Conversion first mover advantage ramen deployment </p>
                                       </div> 

                                    </div>
									<div class="row" id="blog_page">
										<div class="col-xs-12">
	<h5>Related Post </h5>
	<hr/>
	</div>
				<div class="col-md-6">
                <div class="blog_singel">
                    <div class="blog-img">
                        <a href="#"><img src="images/blog1.jpg" class="img-fluid" alt="" /></a>
                    </div>
                    <div class="blog-title">
                        <span>25 January 2021</span>     
                        <h3><a href="#">NASDAQ CEO Open Crypto NASDAQ Collaborating..</a></h3>    
                        <div class="blog-meta">  
                            <span class="ago">1 Hour Ago</span>                              
                        </div>                 
                    </div>   
                </div>                
            </div>
			
			<div class="col-md-6">
                <div class="blog_singel">
                    <div class="blog-img">
                        <a href="#"><img src="images/blog2.jpg" class="img-fluid" alt="" /></a>
                    </div>
                    <div class="blog-title">
                        <span>25 January 2021</span>     
                        <h3><a href="#">NASDAQ CEO Open Crypto NASDAQ Collaborating..</a></h3>    
                        <div class="blog-meta">  
                            <span class="ago">1 Hour Ago</span>                              
                        </div>                 
                    </div>   
                </div>                
            </div>
									</div>
                                </div>   


                                
                                <div class="col-lg-4 blog_wrapper_right order-lg-2 order-md-1 order-1">
                                    <div class="blog-right-items">
                                        
                                        <div class="widget">
                                            <form role="search" method="get" class="search-form">
                                                <input type="search" placeholder="Search" class="form-control"/>
                                            </form>
                                        </div>
                                      
                                        <div class="widget">
                                            <h3 class="title">Categories</h3>
											<hr/>
                                            <ul class="list-unstyled tags">
                                                <li><a href="#" title="">Announcements</a></li>
                                                <li><a href="#" title="">Bitcoin</a></li>
                                                <li><a href="#" title="">Featured</a></li>
                                                <li><a href="#" title="">Knowledgebase</a></li>
                                                <li><a href="#" title="">Cryptocurrency</a></li>
                                                <li><a href="#" title="">Trading</a></li>
                                                <li><a href="#" title="">Uncategorized</a></li>
                                            </ul>
                                        </div>  
										
										<div class="widget">
                                            <h3 class="title">Recent Posts</h3>
											<hr/>
                                            <ul class="list-styled">
                                                <li><a href="#" title="">Consectetur adipiscing elit, sed do eiusmod tempor incididunt.</a></li>
                                                <li><a href="#" title="">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></li>
                                                <li><a href="#" title="">Consectetur adipiscing elit, sed do eiusmod tempor incididunt.</a></li>
                                                <li><a href="#" title="">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></li>
												<li><a href="#" title="">Consectetur adipiscing elit, sed do eiusmod tempor incididunt.</a></li>
                                                <li><a href="#" title="">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></li>
												<li><a href="#" title="">Consectetur adipiscing elit, sed do eiusmod tempor incididunt.</a></li>
                                                <li><a href="#" title="">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></li>
                                            </ul>
                                        </div>  

                                    </div>
                                </div>
                            </div>
                        </div>


            </div>
    </div> 
     
</section>)
}

export default BlogDetailsMain
import axios from 'axios';
import { toast , ToastContainer} from "react-toastify";



console.log("hiiii http interceptorrrrrrrrr   ")
// axios instance for making requests 
const axiosInstance = axios.create();

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  // add token to request headers
    if(localStorage.getItem('token')){
        config.headers['Authorization'] = localStorage.getItem('token');
        config.headers['x-access-token'] =localStorage.getItem('token');
    }
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  if(response.data.statusCode == 401){
    toast.error('Invalid Token Please Login')
    setTimeout(() => {
      window.location.href='/login';
      localStorage.clear()
    }, 1000);
  }else if(response.data.statusCode === 403){
    // setTimeout(() => {
      window.location.href='/login';
      localStorage.clear()
    // }, 100);
  }
  return response;
});



export default axiosInstance;
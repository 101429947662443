import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import '../chat.scss';
import '../p2p.scss';
import {Client} from "@heroiclabs/nakama-js"
import { BASE_URL, hostName } from "../../../config/constant";
import axiosInstance from "../../Dashboard/httpInterceptor";
import ReactTimeAgo from 'react-time-ago'

function P2PChat({ show, handleClose, user,orderId,refetchUser, secondUserDetails }) {
  const client = new Client("defaultkey", hostName, "443", true);
  const socket = client.createSocket(true,);

  const [channelId, setChannelId] = useState("");
  const [session, setSession] = useState();
  const [msg, setMsg] = useState("");
  const [messageList, setMessageList] = useState([])
  const [chatId, setChatId] = useState('')
  const [isChalleSaved, setIsChalleSaved] = useState(false)

  const divRef = useRef();
  useEffect(() => {
    const f = async ()=>{
      await getChatMeta()
    }
    f()
  }, [])
  
  useEffect(() => {
    if (user && orderId) {
      nakamaAuthenticate();
    }
  }, [user, orderId]);

  useEffect(() => {
  
    if (channelId && show ) {
      fetchHistory();
    }
  }, [channelId,show]);
useEffect(() => {
 if (session && chatId) {
  initiateChat().then(()=>{})
 }
}, [session, chatId])
useLayoutEffect(() => {
  divRef.current.scrollIntoView({ behavior: "smooth" });;
}, [messageList])
async function fetchHistory() {
  const forward = true;
  try {
    // await getChatMeta(channelId)
    var result = await client.listChannelMessages(
      session,
      channelId,
      100,
      forward
    );

    setMessageList(result.messages);
    console.log("result.messages", result.messages)
    if (result.next_cursor) {
      result = await client.listChannelMessages(
        session,
        channelId,
        100,
        forward,
        result.next_cursor
      );
      setMessageList(result.messages);
    }
     divRef.current.scrollIntoView({ behavior: "smooth" });
  } catch (error) {
    // console.log("error", error);
  }
}
  const getChatMeta = async (channelid)=>{
    axiosInstance.get(`${BASE_URL}p2p/get-chat-info/${orderId}`).then((res)=>{
      if(!(res.data.data && res.data.data.channelId) && channelId){
        axiosInstance.post(`${BASE_URL}p2p/save/chat-info`,{channelid,orderId }).then((res1)=>{
          
        })
      }else{
        setChannelId(res.data?.data?.channelId)
        setIsChalleSaved(res.data?.data?.channelId ? true :false)
      }
    })
  }
  useEffect(() => {
   if (socket) {
    const oldChat = messageList
    socket.onchannelmessage = (msg) => {
      let message = msg.content;
      if (message !== undefined) {
        // setMessageList([...oldChat, msg]);
        fetchHistory()
      }
    };
   }
  }, [socket])
  

  // socket.onclose = () => {
  // console.error("Please try again later.");
  // };

  const handleMessageSent = async () => {
    // const temp = await isSessionClosed();
    try {
      await socket.connect(session, true);
    const userId = secondUserDetails?.chatId;
    const persistence = true;
    const hidden = false;
    const channel = await socket.joinChat(userId, 2, persistence, hidden);
    const messageAck = await socket.writeChatMessage(channel.id, {message:msg});
    // console.log("messageAck", messageAck)
    setMsg("")
    divRef.current.scrollIntoView({ behavior: "smooth" });
    if (!isChalleSaved) {
      await getChatMeta(channel.id)
    }
    } catch (error) {
      console.log(error)
    }
  };
  const nakamaAuthenticate = async () => {
    try {
      const create = false;
      const sessions = await client.authenticateEmail(
        user.emailId,
        user._id,
         user?.chatId ? false: true,
        user.emailId
      );
      console.info("Successfully authenticated:", sessions);
      setSession(sessions);
      setChatId(sessions.user_id)
     
      if(!user?.chatId){
        await axiosInstance.post(`${BASE_URL}updateChatId`, {emailId: user.emailId, chatId: sessions?.user_id})
        refetchUser()
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initiateChat = async () => {
    var appearOnline = true;
    await socket.connect(session, appearOnline);
    const channel = await socket.joinChat(secondUserDetails.chatId, 2, true, false);
    setChannelId(channel.id);
  };
  const sendMsg = async () => {
    handleMessageSent()
  };
  return (
    <div
      className={`modal fade in popup_form ${show && "show"}`}
      id="transfer_modal"
      data-backdrop="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content rounded-md">
          <div className="px-2 py-2">
            <h5 className="modal-title" id="exampleModalLabel">
              Chat
            </h5>
            <div className="d-flex flex-end">
              {" "}
              <button
                type="button"
                className="close font-16"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                <span aria-hidden="true">&times;</span>{" "}
              </button>
            </div>
            <div className="modal-body">
              <div style={{ overflow: "auto" }}>
                <div style={{ maxHeight: "65vh" }}>
                 {messageList.map((msgs)=>(
                    <>
                    {msgs.sender_id === secondUserDetails.chatId && <div
                    className="d-flex"
                    style={{ justifyContent: "start", flexDirection: "row" }}
                  >
                    <img
                      src="/images/user.png"
                      alt="avatar 1"
                      style={{
                        width: "45px",
                        height: "100%",
                        borderRadius: "50%",
                        marginRight: 10,
                      }}
                    />
                    <div>
                      <p
                        className="small p-2 ms-3 mb-1 rounded-3"
                        style={{ backgroundColor: "#f5f6f7" }}
                      >
                        {msgs.content.message}
                      </p>
                      
                      <p className="small ms-3 mb-3 rounded-3 text-muted">
                      {/* {new Date(msgs.create_time).toLocaleString()} */}
                      <ReactTimeAgo date={msgs.create_time} locale="en-US"/>
                      </p>
                    </div>
                  </div>}
                      <div style={{height: 10, width: "100%"}}></div>
                  {/* <div
                    className="dividers d-flex mb-4"
                    style={{ alignItems: "center" }}
                  >
                    <p
                      className="text-center mx-3 mb-0"
                      style={{ color: "#a2aab7" }}
                    >
                      Today
                    </p>
                  </div> */}

                  {msgs.sender_id === chatId && <div
                    className="d-flex mb-4 pt-1"
                    style={{ justifyContent: "end" }}
                  >
                    <div>
                      <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                      {msgs.content.message}
                      </p>
                      <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
                      {/* {new Date(msgs.create_time).toLocaleString()} */}
                      <ReactTimeAgo date={msgs.create_time} locale="en-US"/>
                      </p>
                    </div>
                    <img
                      src="/images/user.png"
                      alt="avatar 1"
                      style={{
                        width: "45px",
                        height: "100%",
                        borderRadius: "50%",
                        marginLeft: 10,
                      }}
                    />
                  </div>}
                    </>
                 )) }
                  <div ref={divRef}></div>
                </div>
              </div>
            </div>
            <div className="modal-footer text-muted d-flex flex-start p-3">
              <img
                src="/images/user.png"
                alt="avatar 3"
                style={{
                  width: "45px",
                  height: "100%",
                  borderRadius: "50%",
                  marginRight: 10,
                }}
              />
              <input
                type="text"
                className="form-control form-control-lg"
                id="exampleFormControlInput1"
                placeholder="Type message"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
              />
              {/* <a className="ms-1 text-muted" href="#!">
                <i className="fa fa-paperclip"></i>
              </a>
              <a className="ms-3 text-muted" href="#!">
                 <i className="fa fa-smile"></i>
              </a> */}
              <a className="ms-3" href="#!" onClick={() => sendMsg()}>
                <i className="fa fa-paper-plane"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default P2PChat;

import React from 'react';

const ourQuoteSection=()=>{
    return(
      <section id="crypto_exchange">
      <div class="container">
        <div class="row flex_box">
      <div class="col-md-7 exchange_points">
       <div class="heading">
            <h2>Fastest <span>Cryptocurrency Exchanges</span></h2>
        <p>Here at E-Canna Digital Exchange, we are committed to user protection with strict protocols and industry-leading technical measures. </p>
          </div>
      <ul>
      <li>
      <span>01</span> 
      <div class="point_info">
      <h4>Secure Assets Fund</h4>
      <p> E-Canna stores 10% of all trading fees in a secure asset fund to protect a share of user funds. </p>
      </div>
      </li>
      <li>
      <span>02</span> 
      <div class="point_info">
      <h4>Personalised Access Control</h4>
      <p>Advanced access control allows you to restrict devices and addresses that can access your account, for greater ease of mind. </p>
      </div>
      </li>
      <li>
      <span>03</span> 
      <div class="point_info">
      <h4>Advanced Data Encryption</h4>
      <p>  

Your transaction data is secured via end-to-end encryption with E-Canna Digital Exchange, ensuring that only you have access to your personal information.

  </p>
      </div>
      </li>
      </ul>
      </div>
      <div class="col-md-5 img_part text-center">
      <img src="images/exchange.png" alt="img" />
      </div>
      </div>
      </div>
    </section>
    )
}

export default ourQuoteSection
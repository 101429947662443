import React, { Component } from 'react';

import Axios from 'axios';
import Notifications from 'react-notify-toast';
import Sidebar from "../../Components/Dashboard/sidebar";
import Footer from "../../Components/Footer/footer";
import Header from "../../Components/Header/header";
import { BASE_URL } from "../../config/constant";
import axios from './httpInterceptor';

import BigNumber from 'bignumber.js';
import { TransferModal } from '../../Components/margin-dashboard/TransferBalance';
import { PieChart } from '../../Components/pieChart/pieChart';
import Loader from '../../React-Loader/loader';
var id;
var tableFields = [];
var fessAmt = 0;
var fessUsdValue = 0;
class DashboardMargin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            tokenData: [],
            userData: [],
            inorderdata: [],
            withdrawPending: [],
            loadFeeSubs: false,
            coinBalance: {},
            FESSstakeamt: 0,
            btcusdtPrice: "0",
            totalBalanceValueInBTC : 0,
            totalWithdrawValueInBTC : 0,
            totalBalanceValueInUSDT: 0,
            totalWithdrawArray : [],
            balanceLoading: true,
            fessData: [],
            usdFessAmt: 0,
            loading : false

        };
    }

    async componentDidMount() {
        if(!localStorage.getItem('userDetails')) {
          this.props.history.push('/login')
        } else {
            this.setState({ userId: localStorage.getItem('userDetails') })
            // await axios.get(BASE_URL + `/FESS/price`)
            // .then(res => {
            //     let dataByDigi = JSON.parse(res.data.data)
            //     // console.log("Data: ", dataByDigi.data.ticker.usdt_fess.last)
            //     let val = Number(dataByDigi.data.ticker.usdt_fess.last.toFixed(6))
            //         this.setState({ fessData: dataByDigi.data.ticker.usdt_fess.last })
            // })
        }
               
    }

    async componentWillMount() {
        id = localStorage.getItem('userDetails');
        console.log("User id: ", id);
        // below api Just for demo testing 
        // await axios.get(BASE_URL + `order/coin/borrow2`)
        // .then(res => {
        //     console.log( res.data);
        // })
        this.tableData()
        
    }

    tableData = async () => {
        this.setState({loading : true})
        await axios.get(BASE_URL + `ercToken/get/all`)
        .then(res => {
            console.log( res.data);
            if (res.data.data.length && res.data.message === "success") {
                let popElement = res.data.data[10]
                // console.log("In render: ", popElement);
                res.data.data.unshift(popElement)
                res.data.data.splice(11,1) 
                this.setState({ tokenData: res.data.data , loading : false })

                {res.data.data.map((d,idx) => {
                    if(d.tokenSymbol === "FESS") {
                        this.setState({FESSstakeamt: d.minstake})
                    }
                })}
                this.getInOrderValues();
            }
        }).catch((err)=>{
            this.setState({loading : false})
            console.log(err);
        })



        await axios.post(BASE_URL + `userInformation/get`,{
            "id": this.state.userId
        })
        .then(res => {
            // console.log(res);
            if (res.data.message === "success") {
                this.setState({ 
                    userData: res.data.data
                })

            }
        })


        await axios.post(BASE_URL + `withdrawal/gettotal`, {
            "userId": this.state.userId
        })
        .then(res => {
            // console.log("user id",this.state.userId)
            // console.log(res);
            if (res.data.message === "success") {
                this.setState({ totalWithdrawArray: res.data.data })
                // console.log("withdraw",res.data)
                
            }
        })
        this.setState({loading : true})
        await axios.post(BASE_URL + `user/get/marginBalance`, {
            "userId": this.state.userId
        })
        .then(res => {
            // console.log("user id",this.state.userId)
            // console.log(res);
            this.setState({loading : false})
            if (res.data.message === "success") {
                this.setState({ coinBalance: res.data.data }, function(){
                    this.state.tokenData.map((d) => {
                        if(!(this.state.coinBalance.hasOwnProperty([d.tokenSymbol + "_lock"]))){
                            var name = d.tokenSymbol + "_lock"
                          res.data.data[name] = 0
                        }
                    })
                })
                
                fessAmt = new BigNumber(this.state.coinBalance['FESS']  * this.state.fessData)
                if(Number(this.state.coinBalance['FESS']) >= Number(this.state.FESSstakeamt)) {
                 
                    this.setState({loadFeeSubs:true, usdFessAmt: fessAmt})
                }
                if(Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                    this.setState({loadFeeSubs:true})
                }
                
            }
        }).catch((err)=>{
            this.setState({loading : false})
            console.log(err);
        })

        await Axios({
            method: 'get',
            url: `${BASE_URL}coinpair/getall`
          })
          .then(response => { 
            let totalBtc=0;
            let totalUsdt = 0;
              this.state.tokenData.forEach(tokenDetails => {
                  const usdtRow = response.data.data.find(cp => (tokenDetails.tokenSymbol === cp.primaryCoin.toUpperCase()) && (cp.secondaryCoin.toUpperCase() === "USDT"))
                  const usdt = Number(this.state.coinBalance[tokenDetails.tokenSymbol]) + (this.state.coinBalance[tokenDetails.tokenSymbol + "_lock"]);
                  const usdtAmount = usdt && usdtRow?.price ? usdt * usdtRow?.price : 0

                  totalUsdt += usdtAmount
                  if (usdtRow?.primaryCoin === "BTC") {
                      this.setState({ btcusdtPrice: Number(usdtRow.price) })
                  }
                  if (tokenDetails.tokenSymbol === "USDT") {
                      totalUsdt += Number(this.state.coinBalance["USDT"])
                  }

                })
              let totalWithdrawalInUSDT = 0;
                  this.state.totalWithdrawArray.map(rowW => {
                    const usdtRow = rowW.data.data.find(cp => (rowW._id === cp.primaryCoin.toUpperCase()) && (cp.secondaryCoin.toUpperCase() === "USDT"))
                    totalWithdrawalInUSDT+= rowW.amountSum * usdtRow?.price 
                })

            this.setState({
                totalBalanceValueInBTC: totalBtc, 
                totalBalanceValueInUSDT: totalUsdt,
                totalWithdrawValueInBTC: totalWithdrawalInUSDT/this.state.btcusdtPrice,
                balanceLoading : false
            })
          })

          await axios.post(BASE_URL + `deposite/get/id`,{
            tokenSymbol: "TRX",
            userId: this.state.userId,
            userIDNo: 0
        })
        .then(async res => {
            // console.log(res);
            if (res.data.message === "success") {
                
                console.log("trx",res.data.data.walletAddress)

                await axios.post('https://api.ecannacoin.com:2053/trxadd',{
                    walletAddress: res.data.data.walletAddress
                })
                .then()


            }
        })

       

        // await axios.post('https://coincdn.ECanna.com:2083/xlmadd',{})
        // .then()

            // console.log("coinbalance",this.state.coinBalance["BTC"])

        // await this.state.tokenData.map((d) => {
        //     for (var key of Object.keys(this.state.coinBalance)) {
        //         if (d.tokenSymbol === key) {
        //             d["availableBalance"] = this.state.coinBalance[key]
        //         }
        //     }
        // })
        
        // console.log("Data: ", this.state.tokenData);
        
    }

    getInOrderValues = async() => {
        var withdrawData  = []
        await axios.post(BASE_URL + `inorder/withdraw`, {
            "userId": this.state.userId
        })
        .then(async (res) => {
            // if (res.data.message === "success") {
                withdrawData = res.data.data
                // console.log("Data: ", withdrawData)
                // this.setState({
                //     inorderdata : res.data.data
                // })
                // this.state.tokenData.push(res.data.data)
                // console.log(this.state.tokenData)
            // }
   

        await axios.post(BASE_URL + `inorder/open/order`, {
            "userId": this.state.userId
        })
        .then(res => {
            // if (res.data.message === "success") {
                console.log("Data2: ", res.data.data)
                let tableData = res.data.data

                Promise.all(this.state.tokenData.map((d) => {
                    // console.log("Data2: ", d)
                    if(tableData.hasOwnProperty([d.tokenSymbol+"BTC"])){
                        // console.log("Data2: ", d.tokenSymbol)
                        if(tableData[d.tokenSymbol+"BTC"].length > 0) {
                            tableData[d.tokenSymbol+"BTC"].map((e) => {
                                if(e._id === "Buy"){
                                    withdrawData["BTC"] += e.totalSum + withdrawData[d.tokenSymbol]
                                }
                                if(e._id === "Sell"){
                                    withdrawData[d.tokenSymbol] += e.amountSum + withdrawData[d.tokenSymbol]
                                }
                            })
                        }
                    } 

                    if(tableData.hasOwnProperty([d.tokenSymbol+"ETH"])){
                        // console.log("Data2: ", d.tokenSymbol)
                        if(tableData[d.tokenSymbol+"ETH"].length > 0) {
                            tableData[d.tokenSymbol+"ETH"].map((e) => {
                                if(e._id === "Buy"){
                                    withdrawData["ETH"] += e.totalSum + withdrawData[d.tokenSymbol]
                                }
                                if(e._id === "Sell"){
                                    withdrawData[d.tokenSymbol] += e.amountSum + withdrawData[d.tokenSymbol]
                                }
                            })
                        }
                    }

                    if(tableData.hasOwnProperty([d.tokenSymbol+"INR"])){
                        // console.log("Data2: ", d.tokenSymbol)
                        if(tableData[d.tokenSymbol+"INR"].length > 0) {
                            tableData[d.tokenSymbol+"INR"].map((e) => {
                                if(e._id === "Buy"){
                                    withdrawData["INR"] += e.totalSum + withdrawData[d.tokenSymbol]
                                }
                                if(e._id === "Sell"){
                                    withdrawData[d.tokenSymbol] += e.amountSum + withdrawData[d.tokenSymbol]
                                }
                            })
                        }
                    }


                    if(tableData.hasOwnProperty([d.tokenSymbol+"USDT"])){
                        // console.log("Data2: ", d.tokenSymbol)
                        if(tableData[d.tokenSymbol+"USDT"].length > 0) {
                            tableData[d.tokenSymbol+"USDT"].map((e) => {
                        console.log("Data2: ", e)

                                if(e._id === "Buy"){
                                    withdrawData["USDT"] += e.totalSum + withdrawData[d.tokenSymbol]
                                }
                                if(e._id === "Sell"){
                                    withdrawData[d.tokenSymbol] += e.amountSum + withdrawData[d.tokenSymbol]
                                }
                            })
                        }
                    }

                        // console.log("Data2: ", this.state.inorderdata)


                }))


                this.setState({
                    inorderdata : withdrawData
                })
                // this.state.tokenData.map((d) => {
                //     if(tableData.hasOwnProperty([d.tokenSymbol+"BTC"])){
                //     if(tableData[d.tokenSymbol+"BTC"].length > 0) {
                //     tableData[d.tokenSymbol+"BTC"].map((e) => {
                //         if(d.tokenSymbol === e._id){
                //             withdrawData[d.tokenSymbol] = e.amountSum + withdrawData[d.tokenSymbol]
                //         }
                //     })
                //      }
                // }

                // if(tableData.hasOwnProperty([d.tokenSymbol+"ETH"])){
                //     if(tableData[d.tokenSymbol+"ETH"].length > 0) {
                //     tableData[d.tokenSymbol+"ETH"].map((e) => {
                //         if(d.tokenSymbol === e._id){
                //             withdrawData[d.tokenSymbol] = e.amountSum + withdrawData[d.tokenSymbol]
                //         }
                //     })
                //      }
                // }

                // if(tableData.hasOwnProperty([d.tokenSymbol+"USDT"])){
                //     if(tableData[d.tokenSymbol+"USDT"].length > 0) {
                //     tableData[d.tokenSymbol+"USDT"].map((e) => {
                //         if(d.tokenSymbol === e._id){
                //             withdrawData[d.tokenSymbol] = e.amountSum + withdrawData[d.tokenSymbol]
                //         }
                //     })
                //      }
                // }

                // if(tableData.hasOwnProperty([d.tokenSymbol+"INR"])){
                //     if(tableData[d.tokenSymbol+"INR"].length > 0) {
                //     tableData[d.tokenSymbol+"INR"].map((e) => {
                //         if(d.tokenSymbol === e._id){
                //             withdrawData[d.tokenSymbol] = e.amountSum + withdrawData[d.tokenSymbol]
                //         }
                //     })
                //      }
                // }
                // })
                
        })
    })
    }

    render() {
        // console.log("In order render: ", this.state.inorderdata)
    //  var fessUsdValue = new BigNumber(Number(Number(this.state.totalBalanceValueInBTC) * this.state.btcusdtPrice) + Number(fessAmt))
        document.title = "Margin Dashboard";
        document.body.classList.add('faded_bg');
        // fessUsdValue = Number(Number(fessAmt / this.state.btcusdtPrice).toFixed(6))
        // fessUsdValue = Number(Number(fessUsdValue).toFixed(6)) + Number(this.state.totalBalanceValueInBTC)
        // console.log("In render: ", Number((fessUsdValue).toFixed(6)) + Number(this.state.totalBalanceValueInBTC));

        return (
            <div>
                <Notifications />
                <Header  loadFees={this.state.loadFeeSubs}/>
                {this.state.loading?<Loader />   : <></>  }
                <section id="middel_area" className='mDashboard'>
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-md-2 col-sm-3 area_left">

                                <Sidebar  loadFees={this.state.loadFeeSubs}/>
                            </div>

                            <div className="col-md-10 col-sm-9 area_right">
                                <div className="clearfix assets_part">
                                    <div className="dashboard_top_info row">
                                        <div className="col-md-6">
                                        <div className="white_bx clearfix">
                                                <div className="dash_icon"><i className="fa fa-bar-chart"></i></div>
                                                <div className="asset_info" style={{display: "inline-block",marginLeft: "20px"}}>
                                                    <h5 style={{fontWeight:"300",fontSize:"13px",lineHeight:"18px"}}>Estimated Value</h5>
                                                    <p>
                                                    <span>{this.state.totalBalanceValueInUSDT && this.state.btcusdtPrice ? new BigNumber((this.state.totalBalanceValueInUSDT ) / (this.state.btcusdtPrice)).toFormat(6,1): "0.000"} BTC</span> ≈ {new BigNumber(this.state.totalBalanceValueInUSDT).toFormat(2,1) } USDT
                                                        </p>
                                                </div>
                                            </div>
                                            <div className="white_bx clearfix">
                                                <div className="dash_icon"><i className="fa fa-credit-card-alt"></i></div>
                                                <div className="asset_info" style={{display: "inline-block",marginLeft: "20px"}}>
                                                    <h5 style={{fontWeight:"300",fontSize:"13px",lineHeight:"18px"}}>24h Withdrawal Limit</h5>
                                                    <p><span>{new BigNumber(this.state.totalWithdrawValueInBTC).toFormat(6,1)}</span> / {this.state.userData.kyc === 1 ? '100' : "1"} BTC</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="white_bx clearfix">
                                                {/* <div className="dash_icon"><i className="fa fa-bar-chart"></i></div>
                                                <div className="asset_info" style={{display: "inline-block",marginLeft: "20px"}}>
                                                    <h5 style={{fontWeight:"300",fontSize:"13px",lineHeight:"18px"}}>Estimated Value</h5>
                                                    <p><span>{this.state.totalBalanceValueInBTC} BTC</span> ≈ {new BigNumber(Number(this.state.totalBalanceValueInBTC * this.state.btcusdtPrice)).toFormat(2,1) } USD</p>
                                                </div> */}
                                                <div className='row'>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-6 offset-3 col-md-6 offset-md-3'>
                                                        <PieChart />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="clearfix assets_part">
                                    <div className="dashboard_top_info row">
                                        <div className="col-md-6">
                                            <div className="light_bg clearfix">
                                                <div className="dash_icon"><i className="fa fa-user"></i></div>
                                                <div className="asset_info" style={{width:"90%"}}>
                                                    <h5 style={{fontWeight:"300",fontSize:"13px",lineHeight:"18px"}}>Your Referral Link</h5>
                                                    <p>
                                                    <input type="text" className="form-control" style={{background:"#fff"}} name="extratag"  value={"https://ECanna.com/invite/"+this.state.userData.refCode} readOnly noValidate/>    
                                                    <CopyToClipboard
                                                        style={{marginTop:"10px"}}
                                                        className="btn btn-success"
                                                        text={"https://ECanna.com/invite/"+this.state.userData.refCode}
                                                        onCopy={() => { notify.hide(); notify.show("Referral Link Copied!", "success")}}>
                                                        <a href="#" className="btn btn-success"><i className="fa fa-files-o"></i> Copy Invite Link</a>
                                                    </CopyToClipboard>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="light_bg clearfix">
                                                <div className="dash_icon"><i className="fa fa-user"></i></div>
                                                <div className="asset_info" style={{width:"90%"}}>
                                                    <h5 style={{fontWeight:"300",fontSize:"13px",lineHeight:"18px"}}>Your Referral Code</h5>
                                                    <p>
                                                    <input type="text" className="form-control" style={{background:"#fff"}} name="extratag"  value={this.state.userData.refCode} readOnly noValidate/>    
                                                    <CopyToClipboard
                                                        style={{marginTop:"10px"}}
                                                        className="btn btn-success"
                                                        text={this.state.userData.refCode}
                                                        onCopy={() => { notify.hide(); notify.show("Referral Code Copied!", "success")}}>
                                                        <a href="#" className="btn btn-success"><i className="fa fa-files-o"></i> Copy Invite Code</a>
                                                    </CopyToClipboard>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

                                <div className="white_bx clearfix">
                                    <div className="side_txt">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                            <h6>MARGIN Dashboard 
                                           {/* <Link to={'/borrowHistory'} className='btn btn-primary btn-sm mt_mb_0'>
                                                   Borrow History
                                                   </Link>  */}
                                           </h6>
                                            </div>
                                        </div>
                                            
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Coin</th>
                                                    <th>Name</th>
                                                    <th>Available</th>
                                                    {/* <th>Locked Balance</th> */}
                                                    <th>In Order</th>
                                                    <th>Total</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody> 

                                                {this.state.balanceLoading === true ?
                                                <tr>
                                                    <td colSpan="7" height="350px">
                                                        <div className="loader">Loading...</div>
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {this.state.tokenData.map((d,xid) => (

                                                        d.active === true &&
                                                        <tr key={xid}>
                                                            <td><img src={"/coinicon/"+d.tokenSymbol+".png" } width="20px" height="20px"/></td>
                                                            <td><a href={"/margin-trade/"+d.tokenSymbol+"/USDT" }>{d.tokenSymbol}<span className="name"> ({d.tokenName})</span></a></td>

                                                            <td>{Number(this.state.coinBalance[d.tokenSymbol]).toFixed(6) >= 0 ? Number(this.state.coinBalance[d.tokenSymbol]).toFixed(10).slice(0,-4) : 0.000000}</td>

                                                            {/* <td>{Number(this.state.coinBalance[d.tokenSymbol+"_lock"]).toFixed(6) >= 0 ? Number(this.state.coinBalance[d.tokenSymbol+"_lock"]).toFixed(10).slice(0,-4) : 0.000000}</td> */}

                                                            <td>{Number(this.state.inorderdata[d.tokenSymbol]).toFixed(6) >= 0 ? Number(this.state.inorderdata[d.tokenSymbol]).toFixed(10).slice(0,-4) : 0.000000}</td>

                                                            {/* <td>{Number(this.state.coinBalance[d.tokenSymbol]).toFixed(6) >= 0 ? Number(this.state.coinBalance[d.tokenSymbol]).toFixed(10).slice(0,-4) : 0.000000}</td> */}

                                                            {/* <td>{
                                                            Number(Number(this.state.coinBalance[d.tokenSymbol])+Number(this.state.coinBalance[d.tokenSymbol+"_lock"])+Number(this.state.inorderdata[d.tokenSymbol])).toFixed(10).slice(0,-4)
                                                            }</td> */}
                                                            <td>{Number(this.state.coinBalance[d.tokenSymbol]).toFixed(6) >= 0 ? Number(this.state.coinBalance[d.tokenSymbol]).toFixed(10).slice(0,-4) : 0.000000}</td>
                                                           <td>
                                                            

                                                                <TransferModal data={d} bal={Number(this.state.coinBalance[d.tokenSymbol]).toFixed(6)}/>

                                                                {/* <BorrowModal data={d}  bal={Number(this.state.coinBalance[d.tokenSymbol]).toFixed(6)} intPerHour={d.interestPerDay/24}  />
                                                                <RepayModal id={d._id}  tokenSymbol={d.tokenSymbol} tokenName={d.tokenName} intPerHour={d.interestPerDay/24} bal={Number(this.state.coinBalance[d.tokenSymbol]).toFixed(6)}   /> */}

                                                                {d.tActive === true && d.tokenSymbol != 'INR' &&
                                                                <a href={"/margin-trade/"+d.tokenSymbol+"/USDT"+"/CROSS" } className="btn orange_btn btn-sm" style={{ marginRight: 5 }}>Trade</a>
                                                                }
                                                                {d.tActive === true && d.tokenSymbol == 'INR' &&
                                                                <a href={"/margin-trade/USDT/"+d.tokenSymbol+"/CROSS" } className="btn orange_btn btn-sm" style={{ marginRight: 5 }}>Trade</a>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}

                                                        {/* <tr>
                                                            <td><img src="/coinicon/USDT.png" width="20px" height="20px"/></td>
                                                            <td><a href="#">USDT<span className="name"> (TetherUS)</span></a></td>
                                                            <td>{Number(this.state.coinBalance["USDT"]).toFixed(6) >= 0 ? Number(this.state.coinBalance["USDT"]).toFixed(6) : 0.000000}</td>
                                                            <td>0.000000</td>
                                                            <td>{Number(this.state.coinBalance["USDT"]).toFixed(6) >= 0 ? Number(this.state.coinBalance["USDT"]).toFixed(6) : 0.000000}</td>
                                                            <td>
                                                                <a href='/deposit/USDT' className="outline-btn" style={{ marginRight: 5 }}>Deposit</a>
                                                                <a href="/withdraw/USDT" className="outline-btn" style={{ marginRight: 5 }}>Withdraw</a>
                                                               
                                                            </td>
                                                        </tr> */}
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                        {/* <div className="clearfix text-right pagination_part">
                                            <ul className="pagination">
                                                <li><a href="#">Prev</a></li>
                                                <li className="active"><a href="#">1</a></li>
                                                <li><a href="#">2</a></li>
                                                <li><a href="#">3</a></li>
                                                <li><a href="#">...</a></li>
                                                <li><a href="#">Next</a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default DashboardMargin;
import React, { Component } from 'react';
import './index.css'
// import axios from 'axios';
import axios from '../../Pages/Dashboard/httpInterceptor';
import { BASE_URL } from "../../config/constant";

var userId = '';
class CurrencyConverter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectDropdown: 'Select Coin',
            selectfromDropdown: 'Select Coin',
            tokenNameSelected: '-',
            tokenSymbolSelected: '-',
           fromtokenNameSelected: '-',
           fromtokenSymbolSelected: '-',
            tokenData: [],
            fromTokenData:[],
            mainError: null,
            toAmountValue:0,
            fromAmountValue:0,
            errors : {
                walletaddress:"",
                amount:"",
                twofa:"",
                extratag: "",
                bankName: "",
                accountNumber: "",
                ifsc: "",
                remark: ""
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        let concatCoin=this.state.tokenSymbolSelected+this.state.fromtokenSymbolSelected;
        let inputpriceVal=event.target.value;
        this.setState({fromAmountValue:inputpriceVal})
        switch (name) {
          case 'fromAmount': 
          this.getUserAmountInfo(inputpriceVal,concatCoin);
       
            break;

          default:
            break;
        }
    
        this.setState({errors, [name]: value});
    }
    async componentDidMount() {
        if(!localStorage.getItem('userDetails')) {
          this.props.history.push('/login')
        } else {
            this.setState({ userId: localStorage.getItem('userDetails') })
        

            await axios.get(BASE_URL + `ercToken/get/all`)
            .then(async res => {
                if (res.data.message === "success") { 
                this.setState({ tokenData: res.data.data })
                this.setState({fromTokenData:res.data.data})  
                this.setState({selectDropdown: <><img src={"/coinicon/"+res.data.data[0].tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px",marginTop:"-4px"}}/> {res.data.data[0].tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({res.data.data[0].tokenName})</span></>})
                this.setState({selectfromDropdown: <><img src={"/coinicon/"+res.data.data[10].tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px",marginTop:"-4px"}}/> {res.data.data[10].tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({res.data.data[10].tokenName})</span></>})
               this.setState({tokenSymbolSelected: res.data.data[0].tokenSymbol})
                this.setState({fromtokenSymbolSelected: res.data.data[10].tokenSymbol})
               // this.setState({selectDropdown:res.data.data[0]})              
                }
            })

        if(this.props.match.params.coinCode) {
            this.setState({
              coinCode:this.props.match.params.coinCode,
              tokenSymbolSelected:this.props.match.params.coinCode
            }) 

            userId = localStorage.getItem('userDetails')


                        this.state.tokenData.map(row => {
                            if(this.state.coinCode === row.tokenSymbol) {
                                this.setState({selectDropdown :
                                <>
                                {/* {row.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>{row.tokenName}</span> */}
                                <img src={"/coinicon/"+row.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px",marginTop:"-4px"}}/>
                                {row.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({row.tokenName})</span>
                                </>,
                                tokenNameSelected: row.tokenName,
                                tokenSymbolSelected: row.tokenSymbol

                                })
                            }
                        })
                        this.state.fromTokenData.map(row => {
                            if(this.state.coinCode === row.tokenSymbol) {

                                this.setState({selectfromDropdown :
                                <>
                                {/* {row.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>{row.tokenName}</span> */}
                                <img src={"/coinicon/"+row.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px",marginTop:"-4px"}}/>
                                {row.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({row.tokenName})</span>
                                </>,
                                fromtokenNameSelected: row.tokenName,
                                fromtokenSymbolSelected: row.tokenSymbol
                                })
                            }
                        })
        } else {
            // window.location.href = "/withdraw/BTC";
        }


    }
    }

    getUserAmountInfo = async (priceVal,CoinName) => {
     
        await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=`+CoinName)
            .then(res => {
                 console.log(res);
                if (res.status == 200) { 
                console.log(res.data.price*priceVal);
                this.setState({
                    toAmountValue:res.data.price*priceVal
                   });
                this.setState({historyLoading:false})
                }
                else{
                    this.setState({
                        toAmountValue:0
                       });
                }
                
            }).catch(res=>{
                this.setState({
                    toAmountValue:0
                   });
            })
            
    }
    selectCoin = async (e) => {
        this.setState({
            tokenNameSelected: e.tokenName,
            tokenSymbolSelected: e.tokenSymbol,
            selectDropdown: <><img src={"/coinicon/"+e.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px",marginTop:"-4px"}}/> {e.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({e.tokenName})</span></>
                 
        }, function () {
            this.getPairSymbols(e)
        })
    }
    getPairSymbols = async (e)=>{
        axios.post(`${BASE_URL}ercToken/symbol/getpair`,{coin:e.tokenSymbol}).then((res)=>{
            if (res.data.statusCode === 200) {
                this.setState({fromTokenData:res.data.data})
            }
        })
    }
    selectfromCoin = async (e) => {
        this.setState({
            fromtokenNameSelected: e.tokenName,
            fromtokenSymbolSelected: e.tokenSymbol,
            selectfromDropdown: <><img src={"/coinicon/"+e.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px",marginTop:"-4px"}}/> {e.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({e.tokenName})</span></>
                 
        }, function () {
           
        })
    }
    addOrder =() =>{
        const postData={
            pair: this.state.tokenSymbolSelected,
            coinName: this.state.fromtokenSymbolSelected,
            price: this.state.fromAmountValue/this.state.fromAmountValue,
            amount: this.state.fromAmountValue,
            tredingFee: 0,
            total: this.state.toAmountValue,
            orderType: 'Buy',
            orderMode: 'LIMIT',
            userId: this.state.userId,
            status: 0,
        }
        axios.post(`${BASE_URL}directorder/add`,postData).then((res)=>{
            console.log(res)
        })
    }
render(){
    return (
        <div>
            <div style={{
                backgroundImage:
                    "url(" + process.env.PUBLIC_URL + "/images/bg1.png)",
                height: "300px",
                backgroundSize: "cover",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div className='text-center'>
                    <h1 style={{ color: '#FFF' }}>Convert & OTC Portal</h1>
                </div>

            </div>
            <div className='mt-5 converter'>
                <div className='input-grp'>
                    <input type="text" className='form-control my-form-control convert-input'name="fromAmount" onChange={this.handleChange}/>
                    <div className='select-coin' >
                    <div className="dropdown">
                    <span id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {this.state.selectDropdown} <span className="caret"></span> </span>
                                                <ul className="dropdown-menu" aria-labelledby="dLabel">
                                                    {this.state.tokenData.map((d,xid) => (
                                                        d.active === true && d.dActive === true &&
                                                        <li key={xid} onClick={() => { this.selectCoin(d) }}>
                                                            <img src={"/coinicon/"+d.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px"}}/>
                                                            {d.tokenSymbol}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                    </div>
                </div>
                <div className='swap-icon'>
                    <img src='/images/swap.svg' height={'25'} className='coin-icon' />
                </div>
                <div className='input-grp'>
                    <input type="text" className='form-control my-form-control convert-input' name="toAmount" value={this.state.toAmountValue} disabled />
                    <div className='select-fromcoin' >
                    <div className="dropdown">
                    <span id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {/* <i className="fa fa-search"></i>  */}
                                                    {this.state.selectfromDropdown} <span className="caret"></span> </span>
                                                <ul className="dropdown-menu" aria-labelledby="dLabel1">
                                                    {/* <li><img src="images/currency_img2.png" width="25px" alt="img" /> ETH <span>(Ethereum</span>)</li> */}
                                                    {this.state.fromTokenData.map((d,xid) => (
                                                        d.active === true && d.dActive === true &&
                                                        <li key={xid} onClick={() => { this.selectfromCoin(d) }}>
                                                            <img src={"/coinicon/"+d.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px"}}/>
                                                            {d.tokenSymbol}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                    </div>
                </div>
                <div className='text-center btns'>
                    {/* <button className='btn btn-info me-2' >Convert</button> */}
                    <button className='btn btn-info' onClick={this.addOrder}>Buy</button>
                </div>
            </div>
        </div>
    );
}
}
export default CurrencyConverter;
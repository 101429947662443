import React from 'react'
const SupportBanner= ()=>{
return(
    <section id="support_banner">
<div class="container">
<div class="row">
<div class="col-xs-12 text-center">
<h2>Hi! How can we help?</h2>
<fieldset>
<input type="text" class="form-control" placeholder="Search.." />
<button type="button" class="btn btn-warning search_btn"><i class="fa fa-search"></i></button>
</fieldset>
</div>
</div>
</div>
</section>
)
}
export default SupportBanner
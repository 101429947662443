import React, { useEffect, useState } from "react";
import { SubHeader } from "./SubHeader";
import axiosInstance from "../../Dashboard/httpInterceptor";
import axios from 'axios'
import { BASE_URL } from "../../../config/constant";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import Loader from "../../../React-Loader/loader";
import BigNumber from "bignumber.js";
import TransferModal from "../transfer/transfer.modal";
import {useHistory} from 'react-router-dom';

const P2PWallet = () => {
 const routeHistory = useHistory()
  const [tokenList, setTokenList] = useState([]);
  const [balances, setBalances] = useState({});
  const [loading, setLoading] = useState(false);
  const [wazirxTickers, setWazirxTickers] = useState([])
  const [totalUsdt, setTotalUsdt] = useState(0)
const [ecnaUsdtrate, setEcnaUsdtrate] = useState(0);
const [showTransferModal, setShowTransferModal] = useState(false);

const closeTransferModal = ()=>{
  setShowTransferModal(false)
  const userId = localStorage.getItem("userDetails");
  getBalances(userId)
}
const openTransferModal = ()=>{
  setShowTransferModal(true)
}
  useEffect(() => {
    const userId = localStorage.getItem("userDetails");
    if (userId) {
      setLoading(true);
      axiosInstance
        .get(BASE_URL + `ercToken/get/all`)
        .then((res) => {
          setTokenList(res.data?.data || []);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
        getEcannaPrice()
        getWazirXTickers()
      getBalances(userId);
    }else{
      routeHistory.push('/login');
      localStorage.clear()
    }
  }, []);
const getWazirXTickers =()=>{
  axios.get('https://api.wazirx.com/sapi/v1/tickers/24hr').then((tickers)=>{
    if(tickers.status === 200){
      setWazirxTickers(tickers.data)
    }
  })
}
  const getEcannaPrice = ()=>{
    axiosInstance.get(`${BASE_URL}coinpair/get/token?token=ECNA`).then((ecnaRate)=>{
      setEcnaUsdtrate(ecnaRate?.data?.data?.price || 0)
    })
  }

  useEffect(() => {
    if (wazirxTickers && wazirxTickers.length && balances?.p2pBalances) {
      let total_usdt = 0
      Object.keys(balances.p2pBalances).forEach((balKay)=>{
       const ticker = wazirxTickers.find(e=> e.baseAsset === balKay.toLowerCase() && e.quoteAsset ==='usdt');
       if (ticker) {
        total_usdt += ticker.lastPrice * balances.p2pBalances[balKay]
       }
      })
      setTotalUsdt(total_usdt);
    }
  }, [wazirxTickers, balances])
  
  const getBalances = (userId) => {
    setLoading(true);
    axiosInstance
      .get(BASE_URL + `get/p2pAndSpotBalances?userId=${userId}`)
      .then((res) => {
        setBalances(res.data?.data || []);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const getAvailbleBalance = (token) => {
    return balances?.p2pBalances ? Number(balances?.p2pBalances[token]) : 0;
  };

  const getEstimatedUSDT =()=>{
   return totalUsdt +(balances?.p2pBalances ? Number(balances?.p2pBalances['ECNA'] * ecnaUsdtrate || 0) :0)
  }
  const onBuySell=(type, symbol)=>{
    routeHistory.push('/adPost/'+ type+"/"+symbol)
  }
  return (
    <div>
      {loading ? <Loader /> : <></>}
      <Header />
      <SubHeader />
      <TransferModal
        show={showTransferModal}
        handleClose={closeTransferModal}
        coin={'USDT'}
      />
      {/* <section id="middel_area"> */}
      <div className="content">
        <div className="coin_list">
          <br />
          <h4>List of coins</h4>
          <hr />
         <div className="d-flex flex-between">
         <div
            className="est-val"
            style={{ display: "inline-block", marginLeft: "20px" }}
          >
            <h5
              style={{
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: "18px",
              }}
            >
              Estimated Value
            </h5>
            <p>
              <span>{new BigNumber(getEstimatedUSDT()).toFormat(4, 1)} <small>USDT</small></span> ≈{" "}
              {new BigNumber(
                Number(
                  getEstimatedUSDT() / ecnaUsdtrate
                )
              ).toFormat(4, 1)}{" "}
              <small>ECNA</small>
            </p>
          </div>
          <div>
          <div className="font-16 txt-white cursor-pointer btn-solid btn-md bg-yellow-5 rounded-sm border-none" onClick={()=> openTransferModal()}>Transfer Token </div>
      
          </div>
         </div>
          <div className="row">
            {tokenList.map(
              (d, xid) =>
                d.active === true && (
                  <div
                    key={xid}
                    className="col-md-3 col-sm-6 col-xs-6 full "
                    style={{ padding: 1 }}
                  >
                    <div className="outer_bx">
                      <div className="coin_bx">
                        <img
                          src={"/coinicon/" + d.tokenSymbol + ".png"}
                          alt=""
                        />
                        <div className="coin_info">
                          <h6>
                            {d.tokenSymbol} <small>({d.tokenName})</small>
                          </h6>
                          <p>
                            {/* <strong>Avail.</strong>{" "} */}
                            <strong>
                              {Number(
                                getAvailbleBalance(d.tokenSymbol)
                              ).toFixed(4) >= 0
                                ? Number(getAvailbleBalance(d.tokenSymbol))
                                    .toFixed(10)
                                    .slice(0, -6)
                                : 0.0}
                            </strong>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="action_btn text-center">
                        <button className="btn bg-green-5 txt-white m-1" onClick={()=>onBuySell('Buy',d.tokenSymbol)}>
                          Buy
                        </button>
                        <button className="btn bg-red txt-white m-1" onClick={()=>onBuySell('Sell',d.tokenSymbol)}>
                          Sell
                        </button>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footer />
    </div>
  );
};

export default P2PWallet;

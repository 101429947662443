import React from "react";
import {Link} from  'react-router-dom'

export const SubHeader =()=>{
    return (
      <div className="bg-dark p-30 text-center">
        <div className="text-lrg txt-white">
          P2P - Trade ECNA Easily - Buy and Sell Using Your Favorite Payment
          Methods
        </div>
        <div className="xs-text txt-light-gray">
          Make P2P TetherUS trades today with Zero Fees and your Preferred
          Payment Method!
        </div>
        <div className="d-flex flex-end">
          <button className="btn-solid btn-md bg-black txt-yellow-5 d-flex flex-center rounded-sm border-none">
           
            <Link to="/p2p"> <i className="fa fa-home mr-2"></i>P2P Home</Link>
          </button>
          <button className="btn-solid btn-md bg-black txt-yellow-5 d-flex flex-center rounded-sm border-none  ml-2">
            <i className="fa fa-credit-card mr-2"></i>
            {/* <Icon>AccountBalanceWalletIcon</Icon> */}
            <Link to="/p2pWallets">My P2P Wallet</Link>
          </button>
          <button className="btn-solid btn-md bg-black txt-yellow-5 d-flex flex-center rounded-sm border-none ml-2">
            <i className="fa fa-list-alt mr-2"></i>
            <Link to="/p2p-orders">Orders</Link>
          </button>
          <button className="btn-solid btn-md bg-black txt-yellow-5 d-flex flex-center rounded-sm border-none ml-2">
            <i className="fa fa-plus mr-2"></i>
            <Link to="/adPost/Buy/USDT">Post New Ad</Link>
          </button>
        </div>
      </div>
    );
}
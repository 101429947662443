import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom'
import { SubHeader } from "../Home/SubHeader";
import Loader from "../../../React-Loader/loader";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import BuyAdPost from "./BuyAdPost";
import SellAdPost from "./SellAdPost";
import axiosInstance from "../../Dashboard/httpInterceptor";
import { BASE_URL } from "../../../config/constant";
import axios from 'axios'
import '../p2p.scss';

function AdPost() {
  const { type, tokenSymbol } = useParams();
  const [loading, setLoading] = useState(false);
  const [usersPaymentsMethods, setusersPaymentsMethods] = useState()
  const [wazirxTickers, setWazirxTickers] = useState([])
  const [ecnaUsdtrate, setEcnaUsdtrate] = useState(0);
  const [balances, setBalances] = useState({});
useEffect(() => {
  const userId = localStorage.getItem("userDetails");
      if (userId) {
  getUserPaymentMethods()

  getWazirXTickers()
  getEcannaPrice()
  getBalances()
      }
}, [])

 const getWazirXTickers =()=>{
      axios.get('https://api.wazirx.com/sapi/v1/tickers/24hr').then((tickers)=>{
        if(tickers.status === 200){
          setWazirxTickers(tickers.data)
        }
      })
    }
      const getEcannaPrice = ()=>{
        axiosInstance.get(`${BASE_URL}coinpair/get/token?token=ECNA`).then((ecnaRate)=>{
          setEcnaUsdtrate(ecnaRate?.data?.data?.price || 0)
        })
      }
      const getBalances = () => {
        const userId = localStorage.getItem("userDetails");
        setLoading(true);
        axiosInstance
          .get(BASE_URL + `get/p2pAndSpotBalances?userId=${userId}`)
          .then((res) => {
            setBalances(res.data?.data || []);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };
  const getUserPaymentMethods = () => {
    setLoading(true);
    const userId = localStorage.getItem("userDetails");
    axiosInstance
      .get(BASE_URL + `get/userPaymentMethods?userId=${userId || ''}`)
      .then((res) => {
        const data = (res.data?.data);
        setusersPaymentsMethods(data)
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  return (
    <div>
      {loading ? <Loader /> : <></>}
      <Header />
      <SubHeader />
      <div className="content container-fluid">
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <div className="balance_tab_list" style={{ justifyContent: "center" }}>
              <ul className="nav nav-tabs w-100" role="tablist">
                <li className={type === "Buy"? "active w-50" : 'w-50'} style={{ textAlign: "center" }}>
                  <a
                    href="#buy_tab"
                    aria-controls="buy_tab"
                    role="tab"
                    data-toggle="tab"
                  >
                    I want to Buy
                  </a>
                </li>
                <li className={type === "Sell"? "active w-50" : 'w-50'} style={{ textAlign: "center" }}>
                  <a
                    href="#sell_tab"
                    aria-controls="sell_tab"
                    role="tab"
                    data-toggle="tab"
                  >
                    I want to Sell
                  </a>
                </li>
              </ul>
            </div>
            <div role="tabpanel" className="tab-pane">
              <div className="tab-content">
                <div role="tabpanel" className={type === "Buy"? "tab-pane active" : 'tab-pane'} id="buy_tab">
                <BuyAdPost loading={loading} setLoading={setLoading} usersPaymentsMethods={usersPaymentsMethods} 
                wazirxTickers={wazirxTickers} ecnaUsdtrate={ecnaUsdtrate} balances={balances} 
                getUserPaymentMethods={getUserPaymentMethods} tokenSymbol={tokenSymbol} getBalances={getBalances}/>
                </div>
                <div role="tabpanel" className={type === "Sell"? "tab-pane active" : 'tab-pane'} id="sell_tab">
                  <SellAdPost loading={loading} setLoading={setLoading} usersPaymentsMethods={usersPaymentsMethods} 
                  wazirxTickers={wazirxTickers} ecnaUsdtrate={ecnaUsdtrate} balances={balances} 
                  getUserPaymentMethods={getUserPaymentMethods} tokenSymbol={tokenSymbol} getBalances={getBalances}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AdPost;

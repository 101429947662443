import React, { Component } from 'react';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import HomeBannerSection from './../../Components/Homepage/HomeBannerSection'
import IntroductionSection from './../../Components/Homepage/IntroductionSection'
import DownloadAppSection from './../../Components/Homepage/DownloadAppSection'
import CustomerServiceSection from './../../Components/Homepage/CustomerServiceSection'
import CryptoMarketSection from '../../Components/Homepage/CryptoMarketSection'
import TeamSection from './../../Components/Homepage/TeamSection'
import OurQuoteSection from './../../Components/Homepage/OurQuoteSection'
import { Link, Redirect } from "react-router-dom";
import './styleHome.scss';
import TextEllipsis from 'react-text-ellipsis';
// import axios from 'axios';
import axios from '../../Pages/Dashboard/httpInterceptor';
import { BASE_URL } from "../../config/constant";
import BigNumber from 'bignumber.js'
import $ from "jquery";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BsBoxArrowInLeft, BsBoxArrowInRight } from 'react-icons/bs'
import "./homeSlider.scss";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Popup from './popup';
import { Helmet , HelmetProvider } from "react-helmet"
import ActiveCampaign from './activeCapign'; 

class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            timedPopup : false,
            tokenData: [],
            announcementdata: [],
            fessData: [],
            loader: true,
            sliderData: [],
            noVolume: 0,
            noUsers: 0,
            noTrade: 0,
            sliderWidth: false,
            dropdownOpen: false,
            dropdownOpen1: false,
            responsive: {
                0: {
                    items: 1,
                },
                450: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                1000: {
                    items: 3,
                },
            },
            responsive1: {
                0: {
                    items: 1,
                },
                450: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                1000: {
                    items: 1,
                },
            },
        }
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }
    toggle1 = () => {
        this.setState({
            dropdownOpen1: !this.state.dropdownOpen1
        })
    }

    _connectTickerStreams(streams) {
        streams = streams.join('/');
        let connection = btoa(streams);
        this[connection] = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${streams}`);
        this[connection].onmessage = evt => {

            let eventData = JSON.parse(evt.data);

            if (eventData.stream.endsWith('@ticker')) {
                //   console.log("tokendata",eventData.data) 
                //   console.log("tokendata",eventData.data.s) 


                var lastPrice = $("." + eventData.data.s + "_price").html();
                // lastPrice = lastPrice.replace(/,/g, "");

                if (lastPrice > eventData.data.c) {
                    $("." + eventData.data.s + "_priceColor").removeClass("red_clr")
                    $("." + eventData.data.s + "_priceColor").removeClass("green_clr")
                    $("." + eventData.data.s + "_priceColor").addClass("red_clr")
                    // this.setState({ [eventData.data.s+'_priceColor']: "red_clr"})
                } else if (lastPrice < eventData.data.c) {
                    $("." + eventData.data.s + "_priceColor").removeClass("red_clr")
                    $("." + eventData.data.s + "_priceColor").removeClass("green_clr")
                    $("." + eventData.data.s + "_priceColor").addClass("green_clr")
                    // this.setState({ [eventData.data.s+'_priceColor']: "green_clr" })
                } else {
                    $("." + eventData.data.s + "_priceColor").removeClass("red_clr")
                    $("." + eventData.data.s + "_priceColor").removeClass("green_clr")
                    // this.setState({ [eventData.data.s+'_priceColor']: "" }) 
                }

                if (eventData.data.P > 0) {
                    $("." + eventData.data.s + "_priceChangePercentColor").removeClass("red_clr")
                    $("." + eventData.data.s + "_priceChangePercentColor").removeClass("green_clr")
                    $("." + eventData.data.s + "_priceChangePercentColor").addClass("green_clr")


                    $("." + eventData.data.s + "_priceChangePercentColorBg").removeClass("red_bg")
                    $("." + eventData.data.s + "_priceChangePercentColorBg").removeClass("green_bg")
                    $("." + eventData.data.s + "_priceChangePercentColorBg").addClass("green_bg")
                } else {
                    $("." + eventData.data.s + "_priceChangePercentColor").removeClass("red_clr")
                    $("." + eventData.data.s + "_priceChangePercentColor").removeClass("green_clr")
                    $("." + eventData.data.s + "_priceChangePercentColor").addClass("red_clr")


                    $("." + eventData.data.s + "_priceChangePercentColorBg").removeClass("red_bg")
                    $("." + eventData.data.s + "_priceChangePercentColorBg").removeClass("green_bg")
                    $("." + eventData.data.s + "_priceChangePercentColorBg").addClass("red_bg")
                }
                //   this.setState({ [eventData.data.s+'_price']: eventData.data.c })
                //   this.setState({ [eventData.data.s+'_priceChangePercent']: eventData.data.P })
                //   this.setState({ [eventData.data.s+'_priceHigh']: eventData.data.h })
                //   this.setState({ [eventData.data.s+'_priceLow']: eventData.data.l })
                //   this.setState({ [eventData.data.s+'_priceVolume']: eventData.data.q })

                $("." + eventData.data.s + "_price").html(new BigNumber(eventData.data.c).toFormat(null, 1))
                $("." + eventData.data.s + "_priceChangePercent").html(new BigNumber(eventData.data.P).toFormat(null, 1))
                $("." + eventData.data.s + "_priceHigh").html(new BigNumber(eventData.data.h).toFormat(null, 1))
                $("." + eventData.data.s + "_priceLow").html(new BigNumber(eventData.data.l).toFormat(null, 1))
                $("." + eventData.data.s + "_priceVolume").html(new BigNumber(eventData.data.q).toFormat(2, 1))


                //       eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0
                //       this.setState({ticker: eventData.data});


            }


            // !this.props.active_market.market && this._handleTabClick('BTC')
        }

        this[connection].onerror = evt => {
            console.error(evt);
        }
    }

    async componentDidMount() {
    //     const script = document.createElement('script');

    // script.src = 'https://mktngecanna.activehosted.com/f/embed.php?id=1';
    // script.async = true;

    // document.body.appendChild(script);


        setTimeout(()=>{
            this.setState({timedPopup : true})
            console.log("hi")

        } , 5000)
        await axios.get(BASE_URL + `slider/get`).then(res => {
            if (res?.data.message == "success") {
                console.log(res.data.data)
                this.setState({ sliderData: res.data.data })
            }
        })

        await axios.get(BASE_URL + `count/users`).then(res => {
            if (res?.data.message == "success") {
                this.setState({ noUsers: res.data.data })
            }
        })


        await axios.get(BASE_URL + `count/trades`).then(res => {
            if (res?.data.message == "success") {
                // console.log("tg",res.data.data)
                this.setState({ noTrade: res.data.data })
            }
        })

        await axios.get(BASE_URL + `count/volume`).then(res => {
            if (res?.data.message == "success") {
                // console.log("tg",res.data.data)
                this.setState({ noVolume: res.data.data })
            }
        })

        await axios.get(BASE_URL + `FESS/price`)
            .then(res => {
                if(res){
                    let dataByDigi = JSON.parse(res.data?.data)
                    console.log("Data: ", dataByDigi.data.ticker.usdt_fess)
                    this.setState({ fessData: dataByDigi.data.ticker.usdt_fess })
                }
            })

        await axios.post(BASE_URL + `announcement/getalllimit`, {
            // "userId": this.state.userId
        })
            .then(res => {
                // console.log("user id",this.state.userId)
                // console.log(res.data);
                if (res?.data.message == "success") {

                    this.setState({
                        announcementdata: res.data.data,
                    })

                }
            })


        await axios.get(BASE_URL + `ercToken/get/all`)
            .then(res => {
                // console.log(res);
                if (res?.data.message == "success") {
                    this.setState({ tokenData: res.data.data })
                    console.log("tokendata", this.state.tokenData)

                    let usdtpair = this.state.tokenData.map(i => `${i.tokenSymbol.toLowerCase() + "usdt@ticker"}`)
                    let btcpair = this.state.tokenData.map(i => `${i.tokenSymbol.toLowerCase() + "btc@ticker"}`)
                    let ethpair = this.state.tokenData.map(i => `${i.tokenSymbol.toLowerCase() + "eth@ticker"}`)

                    axios({
                        method: 'get',
                        url: `${BASE_URL}coinpair/getall`
                    })
                        .then(response => {
                            // console.log("tradedata",response.data)
                            response.data.data.map(row => {
                                // console.log(row)  
                                this.state.tokenData.map(i => {
                                    if (i.tokenSymbol + "USDT" == (row.primaryCoin.toUpperCase()+ row.secondaryCoin.toUpperCase()) || i.tokenSymbol + "BTC" == (row.primaryCoin.toUpperCase()+ row.secondaryCoin.toUpperCase()) || i.tokenSymbol + "ETH" == (row.primaryCoin.toUpperCase()+ row.secondaryCoin.toUpperCase())) {
                                        $("." + (row.primaryCoin.toUpperCase()+ row.secondaryCoin.toUpperCase()) + "_price").html(new BigNumber(row.price).toFormat(null, 1))
                                        $("." + (row.primaryCoin.toUpperCase()+ row.secondaryCoin.toUpperCase()) + "_priceChangePercent").html(new BigNumber(row.change24Hr).toFormat(null, 1))
                                        $("." + (row.primaryCoin.toUpperCase()+ row.secondaryCoin.toUpperCase()) + "_priceHigh").html(new BigNumber(row.high24Hr).toFormat(null, 1))
                                        $("." + (row.primaryCoin.toUpperCase()+ row.secondaryCoin.toUpperCase()) + "_priceLow").html(new BigNumber(row.low24Hr).toFormat(null, 1))
                                        $("." + (row.primaryCoin.toUpperCase()+ row.secondaryCoin.toUpperCase()) + "_priceVolume").html(new BigNumber(row.volume).toFormat(2, 1))
                                    }
                                })
                            })



                            // PRICE SOCKET TO START
                            this._connectTickerStreams([usdtpair.join('/') + "/" + btcpair.join('/') + "/" + ethpair.join('/')])


                            this.setState({
                                loader: false
                            })
                        })



                }
            })

       
    }

    openNav = () => {
        this.setState({
            sliderWidth: !this.state.sliderWidth
        })
    }

    // _renderMenu = (menu, idx) => {
    //     return (
    //         <Menu key={idx} className={this.state.animationDirection} order={idx}>
    //             content      
    //        </Menu>
    //     )
    // }


    setTimedPopup = ()=>{
        this.setState({timedPopup : false})
    }




    render() {
        setInterval(() => {
            var $tbody = $('#tg tbody');
            $tbody.find('tr').sort(function (a, b) {
                var tda = $(a).find('td:eq(3)').text();
                tda = Number(tda.replace(/%/g, ""));
                var tdb = $(b).find('td:eq(3)').text();
                tdb = Number(tdb.replace(/%/g, ""));
                // if a < b return 1
                return tda < tdb ? 1
                    // else if a > b return -1
                    : tda > tdb ? -1
                        // else they are equal - return 0    
                        : 0;
            }).appendTo($tbody);
        }, 1000)
        setInterval(() => {
            var $tbody = $('#tl tbody');
            $tbody.find('tr').sort(function (a, b) {
                var tda = $(a).find('td:eq(3)').text();
                tda = Number(tda.replace(/%/g, ""));
                var tdb = $(b).find('td:eq(3)').text();
                tdb = Number(tdb.replace(/%/g, ""));
                // if a < b return 1
                return tda > tdb ? 1
                    // else if a > b return -1
                    : tda < tdb ? -1
                        // else they are equal - return 0    
                        : 0;
            }).appendTo($tbody);
        }, 1000)
        setInterval(() => {
            var $tbody = $('#tv tbody');
            $tbody.find('tr').sort(function (a, b) {
                var tda = $(a).find('td:eq(6)').text();
                tda = Number(tda.replace(/,/g, ""));
                var tdb = $(b).find('td:eq(6)').text();
                tdb = Number(tdb.replace(/,/g, ""));
                // if a < b return 1
                return tda < tdb ? 1
                    // else if a > b return -1
                    : tda > tdb ? -1
                        // else they are equal - return 0    
                        : 0;
            }).appendTo($tbody);
        }, 1000)

       

        document.title = " E-Canna Exchange";
        return (
            <div>
                <div>
                    <Header />
                    <HomeBannerSection />
                    <CryptoMarketSection /> 
                    <CustomerServiceSection />
                    {/* <TeamSection /> */}
                    <OurQuoteSection />
                    <IntroductionSection />
                    <DownloadAppSection />
                    <Footer />
                    {/* <Popup trigger={this.state.timedPopup}  setTrigger={this.setTimedPopup}> */}
            {/* <ActiveCampaign fid="1" /> */}
                {/* </Popup> */}
                </div>
            </div>
        );
    }
}

export default Homepage;
import React, { Component } from 'react';
import Footer from '../../Components/Footer/footer';
import Sidebar from '../../Components/Dashboard/sidebar';
import Header from '../../Components/Header/header';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import axios from 'axios';
import axios from './httpInterceptor';
import { BASE_URL } from "../../config/constant";
import Notifications, {notify} from 'react-notify-toast';
import Loader from '../../React-Loader/loader';
var id;
var responce= [];

export default class Referral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            refHistory: [],
            userData: [],
            kycBonus: '',
            loading : false
        };
    }

    async componentWillMount() {
        id = localStorage.getItem('userDetails');
        // console.log("User id: ", id);
        this.tableData()
    }

    tableData = async() => {
        
        var bonus= '';
        this.setState({loading : true})
        await axios.post(BASE_URL + `userInformation/get`,{
            "id": id
        })
        .then(res => {
            // console.log(res.data.data);
            this.setState({loading : false})
            if (res.data.message === "success") {
                responce = res.data.data
                this.setState({ 
                    userData: res.data.data,
                })
            }
        }).catch((err)=>{
            this.setState({loading : false})
        })

        // await axios.post(BASE_URL + `ercToken/symbol/get`,{
        //     "tokenSymbol": "FESS"
        // })
        // .then(res => {
        //     // console.log(res.data.data);
        //     if (res.data.message === "success") {
        //         bonus = res.data.data[0]
        //         this.setState({ 
        //             kycBonus:   Number(bonus.refBonus) 
        //         }, function() {
        //             this.getRefData()
        //         })
        //     }
        // })
    }

    getRefData = async() => {
        this.setState({loading : true})
        await axios.post(BASE_URL + `referral/history/get`,{
            "refCode": responce.refCode
        })
        .then(res => {
            this.setState({loading : false})
            if (res.data.message === "success") {
                let refer = res.data.data
                refer.map((d) => {
                    if(d.activationStatus === 1 || d.activationStatus === "1"){
                        d["status"] = "Active"
                        d["amount"] = this.state.kycBonus
                    } else {
                        d["status"] = "Inactive"
                        d["amount"] = ''
                    }
                })
                this.setState({ 
                    refHistory: res.data.data
                })
            }
        }).catch((err)=>{
            this.setState({loading : false})
        })
    }
    render() {
        document.title = "Referral";
        document.body.classList.add('faded_bg');
        return (
            <div>
                 <Notifications />
                <Header />
                {this.state.loading ? <Loader /> : <></>}

                {/* end header part */}
                
                {/* middel content area start */}
                <section id="middel_area" className="ref_mid">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 col-sm-3 area_left">
                                <Sidebar />
                            </div>
                            <div className="col-md-10 col-sm-9 area_right">
                                {/* <div className="row"> */}
                                    <div className="clearfix assets_part">
                                        <div className="dashboard_top_info row">
                                            <div className="col-md-6">
                                                <div className="white_bx clearfix">
                                                    <div className="dash_icon"><i className="fa fa-user"></i></div>
                                                    <div className="asset_info" style={{display: "inline-block",marginLeft: "20px",width: "80%"}}>
                                                        <h5 style={{ fontWeight: "300", fontSize: "13px", lineHeight: "18px" }}>Your Referral Link</h5>
                                                        <p>
                                                            <input type="text" className="form-control" style={{ background: "#fff",width:"100%" }} name="extratag" value={"https://ecannacoin.com/invite/" + this.state.userData.refCode} readOnly noValidate />
                                                            <CopyToClipboard
                                                                style={{ marginTop: "10px" }}
                                                                className="btn btn-success"
                                                                text={"https://ecannacoin.com/invite/" + this.state.userData.refCode}
                                                                onCopy={() => { notify.hide(); notify.show("Referral Link Copied!", "success") }}>
                                                                <a href="#" className="btn btn-success"><i className="fa fa-files-o"></i> Copy Invite Link</a>
                                                            </CopyToClipboard>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="white_bx clearfix">
                                                    <div className="dash_icon"><i className="fa fa-user"></i></div>
                                                    <div className="asset_info" style={{display: "inline-block",marginLeft: "20px",width: "80%"}}>
                                                        <h5 style={{ fontWeight: "300", fontSize: "13px", lineHeight: "18px" }}>Your Referral Code</h5>
                                                        <p>
                                                            <input type="text" className="form-control" style={{ background: "#fff" }} name="extratag" value={this.state.userData.refCode} readOnly noValidate />
                                                            <CopyToClipboard
                                                                style={{ marginTop: "10px" }}
                                                                className="btn btn-success"
                                                                text={this.state.userData.refCode}
                                                                onCopy={() => { notify.hide(); notify.show("Referral Code Copied!", "success") }}>
                                                                <a href="#" className="btn btn-success"><i className="fa fa-files-o"></i> Copy Invite Code</a>
                                                            </CopyToClipboard>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                {/* </div> */}
                                
                                <div className="white_bx clearfix">
                                    <div className="side_txt">
                                        <h6>Referral History </h6>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    {/* <th>Wallet Address</th> */}
                                                    <th>Date &amp; Time</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.refHistory.map((d) => (
                                                <tr>
                                                    <td>{d.userName}</td>
                                                    <td>{d.amount}</td>
                                                    <td>{d.createdOn}</td>
                                                    <td>{d.status}</td>
                                                     {/* <td colSpan={5} align="center" className="no_data"><i className="fa fa-file-o" /> No record found</td> */}
                                                 </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* middel content area end */}
                {/* start footer */}
                <Footer />
                {/* end footer */}
                {/* qr code popup start */}
                <div className="modal fade kyc_modal" id="authenticator" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header modal-header-success">
                                <h5 style={{ display: 'inline-block' }}>Scan this QR Code</h5>  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body text-center">
                                <img src="images/qr.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* qr code popup end */}
                {/* all scripts */}
            </div>

        );
    }
}

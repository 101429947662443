import React, { Component } from 'react';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import BlogMain from './BlogMain'
import axios from 'axios';



class ViewBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogData : '',
            recentNews : []
          }
    }


    componentDidMount(){
        axios.get('https://adminapi.ecannacoin.com/getAll/blog_news').then((res)=>{
            console.log(res)
            if(res.data.statusCode == 200){
                this.setState({recentNews :res.data.data })
            }else{
                this.setState({recentNews :[] })
            }
        }).catch((err)=>{
            console.log(err)
        })


        let localStorageData = localStorage.getItem('blogNews')
        let viewBlogData = JSON.parse(localStorageData)

            this.setState({ blogData: viewBlogData });

         
            console.log('viewBlogData   ',this.state)
        



    }


    render() { 
        return ( 
         <>
         <Header />
                <div>
                <section id="blog_banner">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h2> News & Blog</h2>
                            </div>
                        </div>
                    </div>
                </section>

            

                <section id="blog-post">
                    <div class="container">
                        <div class="col-md-12">
                            <div class="post-area">
                                <div class="row blog_wrapper">
                                    <div class="col-lg-8 col-md-7 blog_details_left">
                                        <img src="images/post.jpg" class="img-fluid" alt=""/>
                                            <div class="blog_details">
                                                <br/>
                                                    <div class="blog-hadding-wrapper">
                                                        <div class="blog-title">
                                                            <h3>{this.state.blogData.title}</h3>
                                                            <div class="author_info">
                                                                <span><i class="fa fa-calendar"></i> &nbsp; Posted On -  {this.state.blogData.date}</span>
                                                                {/* &nbsp; &nbsp;<span><i class="fa fa-user"></i> &nbsp; By - John Doe</span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr/>

                                                        <div class="blog-details-pera">
                                                         
                                                                
                                                                <p dangerouslySetInnerHTML={{ __html: this.state.blogData.story }}  /> </div>

                                                        </div>


                                                    </div>


                                                    <div class="col-lg-4 col-md-5">

                                                        <div class="widget-sidebar">
                                                            <h2 class="title-widget-sidebar">RECENT POST</h2>
                                                            <div class="content-widget-sidebar">
                                                                <ul>
                                                                {this.state.recentNews.map((row , i) => (
                                                                    
                                                                    <li class="recent-post" key={i} >
                                                                        <div class="post-img">
                                                                            <img src="images/blog_mg.jpg" class="img-responsive"/>
                                                                        </div>
                                                                        <a ><h5>{row.title}</h5></a>
                                                                        <p  ><small><i class="fa fa-calendar" data-original-title="" title=""></i> {row.date}</small></p>
                                                                    </li>
                                                                    ))
                                                                }
                                                                    <hr/>
                                                                </ul>
                                                            </div>
                                                        </div>


                                                    </div>
                                            </div>
                                    </div>


                                </div>
                            </div>
                        </section>
                </div>




         <Footer />
         </>
         );
    }
}
 
export default ViewBlog;
import React, { Component } from 'react';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import SupportBanner from "./SupportBanner"
import HelpSection from "./HelpSection"
import HowToUse from "./HowToUse"


class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        return ( 
         <>
         <Header />
         <SupportBanner />
         <HelpSection />
         <HowToUse />
         <Footer />
         </>
         );
    }
}
 
export default Support;
// import Axios from 'axios';
import React, { useState,useEffect } from 'react';
import { Row,Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './modal.css'
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css'; // import first


export const MarginOrderDetailsModal =({data })=> {
  const [show, setShow] = useState(false);
const handleShow = ()=>{
    setShow(true)
}
const handleClose = ()=>{
    setShow(false)
}
const getFees = (data) => {
  if (data) {
    const price = data.status === "close" ? data.exitPrice : data.entryPrice;
    return data.tradingFee * data?.position * price/100;
  } else {
    return 0;
  }
};
const getTDS = (data) => {
    if (data) {
    //   const price = data.status === "close" ? data.exitPrice : data.entryPrice;
      const tax = data.status === "close" ? data.exitTDS : data.entryTDS;
      return (tax || 0);
    } else {
      return 0;
    }
  };
const getGrossPL = (data) => {
  if (data){
      const pl= data.orderType === "Long" ? data?.position * (data.exitPrice - data.entryPrice) : data?.position * (data.entryPrice - data.exitPrice)
      return data.status === "close"
        ? pl
        : 0;
  }
  else return 0;
};
const getNetPL =(data)=>{
   if(data){
    const pl= data.orderType === "Long" ? data?.position * (data.exitPrice - data.entryPrice) : data?.position * (data.entryPrice - data.exitPrice)
    const gpl = data.status === 'close' ? pl: 0;
    // const price = data.status === 'close' ? data.exitPrice : data.entryPrice;
    const tds = getTDS(data)
    const fee = getFees(data);
    const npl = gpl - fee - tds - data.totalInterest;
    return npl;
   }else{
    return 0;
   }
}
  return (
    <>
      {/* <ToastContainer /> */}
      <a className="btn-info btn-sm stroke_btn_main" onClick={handleShow}>
        Details
      </a>
      <Modal show={show} onHide={handleClose} animation={false} size="md" contentClassName="marginModal">
        <Modal.Header closeButton>
          <Modal.Title>
          Order details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{fontSize: 12}}>

            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Market </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{data.status === 'open' || data.status === 'init' ? data.pair: data.coinName +"-"+ data.pair}</strong></div>
                </div>
            </Row>
       
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Side </div>
                    <div className={data?.orderType == "Long" ? "green_clr" : "red_clr"} style={{width: '50%',textAlign: 'right'}}><strong>{data?.orderType}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Leverage </div>
                    <div  style={{width: '50%',textAlign: 'right'}}><strong>{data?.leverage}X</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Status </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{data?.status?.toUpperCase()}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Order Entry Type </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{data?.orderMode}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Quantity </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{(data?.position || 0).toFixed(4)}/{(data?.position || 0).toFixed(4)}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Price </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{data?.entryPrice}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Target Price </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{data?.currentPrice}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> SL Price </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{(data?.stopLoss || 0).toFixed(2)}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Entry TDS(1%) </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{(data?.entryTDS || 0).toFixed(2)} USDT</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Exit TDS(1%) </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{data?.status === 'close' ? (+data?.exitTDS).toFixed(2) + " USDT" :'-'}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Interest </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong>{(+data.totalInterest).toFixed(2)}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}> Order Value </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong> { data?.position * data?.entryPrice}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}>Created At </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong> {new Date(data?.createdAt).toLocaleString()}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}>Updated At </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong> {new Date(data?.updatedAt).toLocaleString()}</strong></div>
                </div>
            </Row>
            <hr/>

            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}>Gross P&L(Active) </div>
                    <div style={{textAlign: 'right', width: '50%'}} className={getGrossPL(data) > 0 ? "green_clr" : "red_clr"}><strong> {getGrossPL(data).toFixed(4)} USDT</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}>-Fee(0.1%) </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong> -{getFees(data).toFixed(4)} USDT</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}>-TDS(1%) </div>
                    <div style={{width: '50%',textAlign: 'right'}}><strong> -{getTDS(data).toFixed(4)} USDT</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: '4px 32px'}}>
                    <div style={{width: '50%'}}>= Net P&L(Active) </div>
                    <div style={{textAlign: 'right',width: '50%'}} className={getNetPL(data)>0 ? "green_clr" : "red_clr"}><strong> {getNetPL(data).toFixed(4)} USDT</strong></div>
                </div>
            </Row>
            </div>
        </Modal.Body>
        <div style={{textAlign:'right', padding: 16}}>
        <hr />
          <Button variant="secondary" onClick={()=>handleClose()}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

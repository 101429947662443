import React, { useState,useEffect } from 'react';
import { Row,Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './modal.css'

export const YourMarginDetailsModal =({data,getMyMargin, text})=> {
  const [show, setShow] = useState(false);
const handleShow = ()=>{
    setShow(true)
}
const handleClose = ()=>{
    setShow(false)
}
  return (
    <>
      <small style={{cursor:"pointer", borderBottom: '1px dotted #777'}} onClick={handleShow} >
        {text}
      </small>
      <Modal show={show} onHide={handleClose} animation={false} size="md" contentClassName="marginModal">
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Your Margin details</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{textAlign: "center"}}>
                <div>Your Margin</div>
                <div style={{fontSize: 20, fontWeight: 600}}>{getMyMargin(data?.orderType)}</div>
                <span style={{fontSize: 14, fontWeight: 400}}></span>
            </div>
            <hr />
            <Row>
                <div style={{display: 'flex',padding: "8px 16px"}}>
                    <div style={{width: '50%'}}> Order Value </div>
                    <div style={{ textAlign: 'right',flex: "50%"}}><strong>{data?.orderValue}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: "8px 16px"}}>
                    <div style={{ width: '50%'}}> Entry Fees (0.1%) </div>
                    <div style={{textAlign: 'right',flex: "50%"}}><strong>{((data?.orderValue || 0) * data.tradingFee/100).toFixed(4)}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: "8px 16px"}}>
                    <div style={{ width: '50%'}}> Entry TDS (1%) </div>
                    <div style={{textAlign: 'right', flex: "50%"}}><strong>{(+data.entryTDS).toFixed(4)}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex',padding: "8px 16px"}}>
                    <div style={{ width: '50%'}}>Estimated exit Fees (0.1%) </div>
                    <div style={{textAlign: 'right',flex: "50%"}}><strong>{((data?.orderValue || 0) * data.tradingFee/100).toFixed(4)}</strong></div>
                </div>
            </Row>
            <Row>
                <div style={{display: 'flex', padding: "8px 16px"}}>
                    <div style={{ width: '50%'}}> Estimated exit TDS (1%) </div>
                    <div style={{textAlign: 'right',flex: "50%"}}><strong>{((data?.orderValue || 0) * data.tdsPercentage/100).toFixed(4)}</strong></div>
                </div>
            </Row>
            <hr />
            <div>
                <p>
                    <i>As per Income Tax regulation, your order value will be subject to 1% TDS from 1 July 2022. this TDS is levied by the 
                        Income Tax Department of India and may be claimed from the government during Income Tax filings.
                    </i>
                </p>
            </div>
        </Modal.Body>
        <div style={{textAlign:'right', padding: "8px 16px"}}>
        <hr />
          <Button variant="secondary" onClick={()=>handleClose()}>
            Okay
          </Button>
        </div>
      </Modal>
    </>
  );
}

import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
// import { BrowserRouter, Link, Redirect } from "react-router-dom";
// import axios from 'axios';
import axios from '../../Pages/Dashboard/httpInterceptor';
import { BASE_URL } from "../../config/constant";
// import QRCode from "qrcode.react";
import moment from "moment";
import Pagination from "react-js-pagination";
import speakeasy from "@levminer/speakeasy";
import Loader from '../../React-Loader/loader';

var page = 1;
var id;
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}
class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            loginHistory: [],
            loadFeeSubs: false,
            FESSstakeamt: 0,
            oldPassword: '',
            newPassword: '',
            mainError: null,
            userInfo: [],
            warningBlock: true,
            loading: true,
            count: 0,
            userDetails: true,
            enableTwoFA: false,
            disableTwoFA: false,
            activePage: 1,
            newTwoFACode: "",
            twofa: "",
            loading : false,
            errors: {
                oldpassword: '',
                newpassword: '',
                cnfpassword: '',
                twofa: '',
            }
        };
    }



    getUserInfo = async () => {
        let userId = localStorage.getItem('userDetails')
        this.setState({loading : true})
        await axios.post(BASE_URL + `userInformation/get`, {
            "id": userId
        })
            .then(res => {
                // console.log(res);
                this.setState({loading : false})

                if (res.data.message === "success") {
                    this.setState({
                        userData: res.data.data,
                        newTwoFACode: res.data.data.secret
                    })

                }

                this.setState({ loading: false })

            }).catch((err)=>{
                this.setState({loading : false})

            })
    }

    showTwoFACode = () => {

        this.setState({
            loading: true,
            warningBlock: false
        })

        var secret = speakeasy.generateSecret({ length: 10 });
        this.setState({ newTwoFACode: secret.base32 })


        this.setState({ loading: false })
    }

    verifyTwoFA = async (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({ mainError: null })

        if (this.state.twofa === null) {
            errors.twofa = 'Please enter valid 2FA code';
        }
        if (this.state.twofa.length === 0) {
            errors.twofa = 'Please enter valid 2FA code';
        }

        this.setState({ errors });

        if (validateForm(this.state.errors)) {
            var verified = speakeasy.totp.verify({
                secret: this.state.newTwoFACode,
                encoding: 'base32',
                token: this.state.twofa
            });

            if (verified === true) {

                let data = {
                    "id": this.state.userId,
                    "secret": this.state.newTwoFACode,
                    "two_factor": 1,
                }
                axios.post(BASE_URL + `userInformation/key/update`, data)
                    .then(res => {

                        this.getUserInfo()

                        this.setState({ twofa: "" })
                        this.setState({ mainError: "twofa" });
                    })



            } else {
                this.setState({ twofa: "" })
                errors.twofa = 'Please enter valid 2FA code';
                this.setState({ errors });
            }

        }
        // console.log("verify",verified)
    }



    // verifyTwoFA = async (event) => {
    //     event.preventDefault();
    //     const { name, value } = event.target;
    //     let errors = this.state.errors;
    //     this.setState({ mainError: null })

    //     if (this.state.twofa === null) {
    //         errors.twofa = 'Please enter valid 2FA code';
    //     }
    //     if (this.state.twofa.length === 0) {
    //         errors.twofa = 'Please enter valid 2FA code';
    //     }

    //     this.setState({ errors });

    //     if (validateForm(this.state.errors)) {

    //         var verified = speakeasy.totp.verify({
    //             secret: this.state.newTwoFACode,
    //             encoding: 'base32',
    //             token: this.state.twofa
    //         });

    //         if (verified === true) {

    //             let data
    //             if (this.state.userData.two_factor === 0) {
    //                 data = {
    //                     "id": this.state.userId,
    //                     "secret": this.state.newTwoFACode,
    //                     "two_factor": 1,
    //                 }
    //             } else {
    //                 data = {
    //                     "id": this.state.userId,
    //                     "secret": "",
    //                     "two_factor": 0,
    //                 }
    //             }


    //             axios.post(BASE_URL + `userInformation/key/update`, data)
    //                 .then(res => {

    //                     this.getUserInfo()

    //                     this.setState({ twofa: "" })

    //                     if (this.state.userData.two_factor === 0) {
    //                         this.setState({ mainError: "success" });
    //                     } else {
    //                         this.setState({ mainError: "error" });
    //                     }

    //                     this.setState({
    //                         enableTwoFA: false,
    //                         disableTwoFA: false,
    //                         userDetails: true
    //                     })

    //                 })



    //         } else {
    //             this.setState({ twofa: "" })
    //             errors.twofa = 'Please enter valid 2FA code';
    //             this.setState({ errors });
    //         }

    //     }
    //     // console.log("verify",verified)
    // }


    enableTwoFA = async () => {
        this.setState({ loading: true })
        var secret = speakeasy.generateSecret({ length: 10 });
        this.setState({ newTwoFACode: secret.base32 })
        this.setState({
            enableTwoFA: true,
            userDetails: false
        })
        this.setState({ loading: false })
    }

    disableTwoFA = async () => {
        this.setState({
            disableTwoFA: true,
            userDetails: false
        })
    }

    async componentDidMount() {
        if (!localStorage.getItem('userDetails')) {
            this.props.history.push('/login')
        } else {
            this.setState({ userId: localStorage.getItem('userDetails') })

            this.getUserInfo()

            await axios.get(BASE_URL + `ercToken/get/all`)
                .then(res => {
                    // console.log(res);
                    // console.log(res.data.data);
                    if (res.data.message === "success") {

                        res.data.data.map(row => {
                            if (row.tokenSymbol === "FESS") {
                                this.setState({ FESSstakeamt: row.minstake })
                            }
                        })

                    }
                })

            this.setState({loading : true})
            await axios.post(BASE_URL + `loginhistory/getall`, {
                "userId": this.state.userId
            })
                .then(res => {
                    this.setState({loading : false})
                    // console.log("user id",this.state.userId)
                    // console.log(res);
                    if (res.data.message === "success") {
                        this.setState({ loginHistory: res.data.data })

                        // console.log(res.data.data)

                    }
                }).catch((err)=>{
                    this.setState({loading : false})
                })

            await axios.post(BASE_URL + `userBalance/get`, {
                "userId": this.state.userId
            })
                .then(res => {
                    // console.log("user id",this.state.userId)
                    // console.log(res);
                    if (res.data.message === "success") {
                        if (Number(res.data.data['FESS']) >= Number(this.state.FESSstakeamt)) {
                            this.setState({ loadFeeSubs: true })
                        }

                        if (Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                            this.setState({ loadFeeSubs: true })
                        }

                    }
                })

            await axios.post(BASE_URL + `loginhistory/count`, {
                "userId": this.state.userId
            })
                .then(res => {
                    this.setState({ count: res.data.data })
                })

        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({ error: null })
        this.setState({ errorSuccess: null })
        switch (name) {
            case 'oldpassword':
                errors.oldpassword =
                    value.length < 8
                        ? 'Old Password must be 8 characters long!'
                        : '';
                break;
            case 'newpassword':
                errors.newpassword =
                    value.length < 8
                        ? 'New Password must be 8 characters long!'
                        : '';
                break;
            case 'cnfpassword':
                errors.cnfpassword =
                    value.length < 8
                        ? 'Confirm Password must be 8 characters long!'
                        :
                        this.state.newpassword !== value
                            ? 'New Password & Confirm Password should match!'
                            : '';
                break;
            case 'twofa':
                errors.twofa =
                    value.length < 6
                        ? 'Please enter valid 2FA code'
                        : '';
                break;


            default:
                break;
        }

        this.setState({ errors, [name]: value });
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({ error: null })
        this.setState({ errorSuccess: null })

        if (this.state.oldpassword === null) {
            errors.oldpassword = 'Old Password must be 8 characters long!';
        }
        if (this.state.newpassword === null) {
            errors.newpassword = 'New Password must be 8 characters long!';
        }
        if (this.state.cnfpassword === null) {
            errors.cnfpassword = 'Confirm Password must be 8 characters long!';
        }
        if (this.state.newpassword !== this.state.cnfpassword) {
            errors.cnfpassword = 'New Password & Confirm Password should match!';
        }
        if (this.state.twofa === null) {
            errors.twofa = 'Please enter valid 2FA code';
        }
        if (this.state.twofa.length === 0) {
            errors.twofa = 'Please enter valid 2FA code';
        }

        var verified = speakeasy.totp.verify({
            secret: this.state.userData.secret,
            encoding: 'base32',
            token: this.state.twofa
        });

        if (verified === true) {

        } else {
            this.setState({ twofa: "" })
            errors.twofa = 'Please enter valid 2FA code';
            this.setState({ errors });
        }


        this.setState({ errors });

        let data = {
            "id": this.state.userId,
            "oldPassword": this.state.oldpassword,
            "newPassword": this.state.newpassword,
        }


        if (validateForm(this.state.errors)) {
            await axios.post(BASE_URL + `changePassword`, data)
                .then(res => {
                    // console.log(res.data);
                    if (res.data.message === "updatedSuccessfully") {
                        this.setState({ error: null })
                        this.setState({ errorSuccess: 'Password updated successfully' })
                        //   alert("Updated!")
                    }
                    if (res.data.message === "passwordDoesNotMatch") {
                        // console.log("Id does not exist!");
                        this.setState({ error: 'Old Password does not match' })
                        //   alert("Password does not match")
                    }
                })
        }

    }


    async handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        page = pageNumber
        await axios.post(BASE_URL + `loginhistory/getall`, {
            "userId": this.state.userId,
            "pageNumber": 1
        })
            .then(res => {
                // console.log("user id",this.state.userId)
                // console.log(res);
                if (res.data.message === "success") {
                    this.setState({ loginHistory: res.data.data })

                    // console.log(res.data.data)

                }
            })
    }

    render() {
        const { errors } = this.state;
        document.title = "Activity Log";
        document.body.classList.add('faded_bg');
        console.log("User two factor: ", this.state.loginHistory)

        return (
            <div>
                <Header loadFees={this.state.loadFeeSubs} />
                {this.state.loading ? <Loader/>: <></>}

                <section id="middel_area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 col-sm-3 area_left">
                                <Sidebar loadFees={this.state.loadFeeSubs} />
                            </div>

                            <div className="col-md-10 col-sm-9 area_right">

                                <div className="white_bx clearfix">
                                    <div class="side_txt">
                                        <h6>Activity Logs</h6>
                                    </div>
                                    <div className="table-responsive recent_login">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Time</th>
                                                    <th>Login Device</th>
                                                    <th>IP Address</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.loginHistory.map((row, xid) => (
                                                    <tr key={xid}>
                                                        <td>{moment(new Date(row.timestamp * 1000)).format('MM/DD/YYYY hh:MM A')}</td>
                                                        <td>{row.browser}</td>
                                                        <td>{row.ipaddress}</td>

                                                    </tr>
                                                )).reverse()}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ marginTop: '20px', float: 'right' }}>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={this.state.count}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default Setting;
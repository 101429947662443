import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";

import {Helmet} from "react-helmet";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        return ( 
            <footer>
  <div className="container">
 
     <div className="row">     
      <div className="col-md-3 col-sm-3 col-xs-6 links">
        <h6>About</h6>
        <ul>
          {/* <li><Link to="/about">About us</Link></li> */}
          <li><Link to="/announcement">Announcement</Link></li>
          
          {/* <li><a href="#">Disclaimer Statement</a></li> */}
          <li><Link to="/news&blog">News & Blog</Link></li>
          <li><a href="/fees">Fees</a></li>
        </ul>
      </div>
      <div className="col-md-3 col-sm-3 col-xs-6 links">
        <h6>FAQ</h6>
        <ul>
          {/* <li><Link to="/support">Support</Link></li> */}
          <li><Link to="/faq">FAQ</Link></li>

          {/* <li><a href="javascript:void">Fees</a></li> */}
        </ul>
      </div>
	    <div className="col-md-3 col-sm-3 col-xs-6 links">
        <h6>Legal </h6>
        <ul>
        <li><Link to="/contact">Contact Us</Link></li>
        <li><Link to="/privacy">Privacy Policy</Link></li>
        <li><Link to="/terms">Terms of use</Link></li>
        </ul>
      </div>
      <div className="col-md-3 col-sm-3 col-xs-6 connect_info">
	  <h6>Community </h6>
        <ul>
          {/* <li><a href="#"><i className="fa fa-paper-plane-o"></i></a></li> */}
          <li><a href="https://twitter.com/" target={'_blank'}><i className="fa fa-twitter"></i></a> </li>
          <li><a href="https://www.facebook.com/" target={'_blank'}><i className="fa fa-facebook"></i></a></li>
		      <li><a href="https://www.instagram.com/" target={'_blank'}><i className="fa fa-instagram"></i></a></li>
          {/* <li><a href="#"><i className="fa fa-twitter"></i></a> </li> */}
        </ul>
       
        
      </div>
    </div> 
  </div>
  <div className="copyright clearfix">
    <div className="container">
      <div className="row">
	    <hr />
        <div className="col-xs-12 text-center"> <small>@Copyright All right reserved 2022</small> </div>
      </div>
    </div>
  </div>
</footer>
         );
    }
}
 
export default Footer;
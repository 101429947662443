import React from 'react'
import Header from '../../Components/Header/header'
import Footer from '../../Components/Footer/footer'

function ComingSoon() {
    const containerStyle = {
        backgroundImage: 'url("images/COMING-SOON.jpg")',
        backgroundSize: 'cover', // Adjust as needed
        backgroundRepeat: 'no-repeat', // Adjust as needed
        width: '100%',
        height: '90vh', // Set a desired height
      };
  return (
   <>
   <Header />
   <div style={containerStyle}>
{/* <img src="images/COMING-SOON.jpg" alt="" /> */}
    </div>
   {/* <Footer/> */}
   </>
  )
}

export default ComingSoon
import React from 'react'

const TopDetails=()=>{
return(
    <section id="inner_top_part" class="fee_head">
  <div class="container">
    <div class="row">      
      <div class="col-xs-12 text-center">
        <h2> News & Blog</h2>	           
	 </div>
    </div>
  </div>
</section>
)
}

export default TopDetails
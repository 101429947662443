import React,{useEffect, useRef, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
// import axios from 'axios';
import axios from '../../Pages/Dashboard/httpInterceptor';
import { BASE_URL } from '../../config/constant';

ChartJS.register(ArcElement, Tooltip);

export const data = {
   labels: [],
  datasets: [
    {
      label: '',
      data: [2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 89, 1)',
        'rgba(255, 239, 64, 1)',
        'rgba(255, 129, 234, 1)',
        'rgba(255, 209, 114, 1)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 159, 114, 1)',
        'rgba(255, 159, 234, 1)',
        'rgba(255, 159, 14, 1)',
      ],
      borderWidth: 1,
    },
    
  ],

};

export function PieChart() {
  const [chartData, setChartData] = useState([])
  // const [showChart, setShow] = useState(true)
  const chartReference = useRef();
useEffect(() => {
  getChartData()
}, [])
useEffect(() => {
 
  const bal = chartData.map(e=>e.balance);
  const symbols = chartData.map(e=>e.tokenName);
  console.log('baallll',bal);
  console.log(symbols);
  data.labels = symbols;
  data.datasets[0].data =bal;
  let chartInstance = chartReference.current    
    chartInstance.update();
  return () => {
    
  }
}, [chartData])
const getChartData =()=>{
  const userId = localStorage.getItem('userDetails')
  axios.post(BASE_URL + `userInformation/get/TokenBalance`,{userId}).then(res =>{
    if(res?.data.statusCode === 200){
      const tChartData = res.data.data.filter(e => e.balance >0);
      setChartData(tChartData);
    }
  })
  
}
  return(
    <>
 <Doughnut data={data}  width={150}
  height={150} ref = {chartReference} redraw={true}/>
    </>
  );
}

import React ,{useEffect, useState} from 'react';

const CryptoSection=()=>{
  const [topPairDetails, setTopPairDetails] = useState([
    {
      pairWithSeparator:'BTC/USDT',
      pair: 'BTCUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'ETH/USDT',
      pair: 'ETHUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'XRP/USDT',
      pair: 'XRPUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'XLM/USDT',
      pair: 'XLMUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'TRX/USDT',
      pair: 'TRXUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
  ]);

  const updatePairPrice = (data) =>{
    setTopPairDetails(existing => existing.map(pr => pr.pair == data.s ? 
      {
        ...pr,
        price: (+data.c).toFixed(4),
        percentageChange:data.P,
        priceChange: (+data.p).toFixed(4)
      } : pr))
  }
  useEffect(() => {
    const conn = new WebSocket("wss://stream.binance.com:9443/ws/!ticker@arr");
    conn.onopen = function(evt) {
      // console.log(evt);
        // conn.send(JSON.stringify({ method: "subscribe", topic: "allMiniTickers", symbols: ["$all"] }));
    }
    conn.onmessage = function(evt) {
      try {
        let msgs = JSON.parse(evt.data);
        if (Array.isArray(msgs)) {
          for (let msg of msgs) {
            updatePairPrice(msg);
          }
        } else {
          // updatePairPrice(msgs)
        }
      } catch (e) {
        console.log('Unknown message: ' + evt.data, e);
      }
    }
    return () => {
      conn.close()
    };
  }, []);
    return(
      <section id="market">
      <div className="container">
        <div className="row">
          <div className="col-md-12">    
        <div className="heading">
      <h2>Most Popular <span>Cryptocurrency</span></h2>	
      </div>
      
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Coins Name</th>
                          <th>Last Price</th>
                          <th>24h Change</th>
                          <th>Market Cap</th>
                        </tr>
                      </thead>
                      <tbody>
                    {topPairDetails.map((d, i) => {
                      return (
                        <tr key={i+""+d.pair}>
                          <td>
                            {/* <img src="images/currency_img1.png" alt="img" />  */}
                            <strong> {d.pairWithSeparator} </strong> </td>
                          <td>{d.price}</td>
                          <td ><span class={d.percentageChange<0?"red_clr":"green_clr"}>{d.percentageChange>0?"+":''}{d.percentageChange}</span></td>
                          <td>{d.priceChange}</td>
                        </tr>
                      )
                    }
                    )}
                  
                      </tbody>				 
                    </table>
                  </div>
              
              
                </div>    
        
        </div>
      </div>
    </section>
    )
}

export default CryptoSection
import React  , { useEffect, useState } from "react";
import axios from 'axios';
import { BASE_URL } from "../../config/constant";
import { useHistory } from 'react-router-dom';





const BlogMain = (props) => {
    const [allData , setAllData] = useState([])
    const history = useHistory();




    useEffect(() => {

        axios.get('https://adminapi.ecannacoin.com/getAll/blog_news').then((res)=>{
            console.log(res)
            if(res.data.statusCode == 200){
                setAllData(res.data.data)
            }else{
                setAllData([])
            }
        }).catch((err)=>{
            console.log(err)
        })

      }, [])


      const moveToViewBlog = (e,data)=>{
          localStorage.setItem('blogNews' , JSON.stringify(data));
          // window.history.pushState("/viewBlog");
          history.push('/viewBlog')
      }




  return (
<div>
<section id="blog_banner">
    <div class="container">
      <div class="row">      
        <div class="col-md-12 text-center">
          <h2> News & Blog</h2>	           
     </div>
      </div>
    </div>
  </section>





        <section id="blog_page">
          <div className="container">
            <div className="row" >
              <div class="col-12 blog_nav text-center">

                <ul>
                  {/* <li><a href="#">Announcements</a></li> */}
                  {/* <li class="active"><a href="#">All</a></li> */}
                  {/* <li ><a href="#">Blog</a></li> */}
                  {/* <li><a href="#">Events</a></li> */}
                  {/* <li><a href="#">Bitcoin</a></li> */}
                  {/* <li><a href="#">Blockchain</a></li> */}
                  {/* <li><a href="#">Cryptocurrencies</a></li> */}
                  {/* <li><a href="#">News</a></li> */}
                  {/* <li><a href="#">Opinion</a></li> */}
                </ul>

              </div>
            </div>



    <div className="row">
    <div class="col-lg-8 col-md-7">
        

        {allData.length == 0 && (
            <div> No Data Found </div>
        )}


        {allData.length > 0 && (
            <div class="row">
                {allData.map(row => (
                    // <div>{row.title} </div>
                    <div class="col-lg-6" key={row._id} >
                        <div class="blog_singel">
                            <div class="blog-img">
                                <a ><img src="images/blog_mg.jpg" class="img-fluid" alt="" /></a>
                            </div>
                            <div class="blog-title"  onClick={(e)=>moveToViewBlog(e,row)}  >
                                <span key={row._id}>
                                    {new Intl.DateTimeFormat('en-GB', {
                                        month: 'long',
                                        day: '2-digit',
                                        year: 'numeric',
                                    }).format(new Date(row.date))}
                                </span>
                                <h3><a >{row.title}</a></h3>
                                <p dangerouslySetInnerHTML={{ __html: row.story }}/>
                                {/* <div >
                                <p key={row} dangerouslySetInnerHTML={{__html: row.story}}>  </p>
                                   </div> */}

                            </div>
                        </div>
                    </div>
                ))}

            </div>
        )}



		
	{/* <div class="col-xs-12">
	<ul class="pagination">
  <li class="page-item"><a class="page-link" href="#">Previous</a></li>
  <li class="page-item"><a class="page-link" href="#">1</a></li>
  <li class="page-item active"><a class="page-link" href="#">2</a></li>
  <li class="page-item"><a class="page-link" href="#">3</a></li>
  <li class="page-item"><a class="page-link" href="#">Next</a></li>
	</ul>
	</div> */}
	
		
		</div>





    <div className="col-lg-4 col-md-5">
    <div class="widget-sidebar">
                 <h2 class="title-widget-sidebar">RECENT POST</h2>
                   <div class="content-widget-sidebar">
                    <ul>
                    {allData.map(row => (


                      <li class="recent-post" key={row._id} >
                        <div class="post-img">
                          <img src="images/blog_mg.jpg" class="img-responsive" />
                        </div>
                        <a href="javascript.void(0)"><h5>{row.title}</h5></a>
                        <p><small><i class="fa fa-calendar" data-original-title="" title=""></i> {new Intl.DateTimeFormat('en-GB', {
                          month: 'long',
                          day: '2-digit',
                          year: 'numeric',
                        }).format(new Date(row.date))}</small></p>
                      </li>
                    ))
                    } 
                        <hr/>
                        
                        {/* <li class="recent-post">
                        <div class="post-img">
                         <img src="images/post.jpg" class="img-responsive"/>
                         </div>
                         <a href="#"><h5>Excepteur sint occaecat cupi non proident laborum.</h5></a>
                         <p><small><i class="fa fa-calendar" data-original-title="" title=""></i> 30 Juni 2014</small></p>
                        </li>
                        <hr/>
                        
                        <li class="recent-post">
                        <div class="post-img">
                         <img src="images/post-thumb2.jpg" class="img-responsive"/>
                         </div>
                         <a href="#"><h5>Excepteur sint occaecat cupi non proident laborum.</h5></a>
                         <p><small><i class="fa fa-calendar" data-original-title="" title=""></i> 30 Juni 2014</small></p>
                        </li>
                        <hr/>
                        
                        <li class="recent-post">
                        <div class="post-img">
                         <img src="images/blog1.jpg" class="img-responsive"/>
                         </div>
                         <a href="#"><h5>Excepteur sint occaecat cupi non proident laborum.</h5></a>
                         <p><small><i class="fa fa-calendar" data-original-title="" title=""></i> 30 Juni 2014</small></p>
                        </li> */}
                        
                        
                    </ul>
                   </div>
		</div>




{/* 
    <div class="widget-sidebar">
                 <h2 class="title-widget-sidebar">News</h2>
                   <div class="content-widget-sidebar">
                    <ul>
                        <li class="recent-post">
                        <div class="post-img">
                         <img src="images/post-thumb2.jpg" class="img-responsive"/>
                         </div>
                         <a href="#"><h5>Excepteur sint occaecat cupi non proident laborum.</h5></a>
                         <p><small><i class="fa fa-calendar" data-original-title="" title=""></i> 30 Juni 2014</small></p>
                        </li>
                        <hr/>
                        
                        <li class="recent-post">
                        <div class="post-img">
                         <img src="images/blog1.jpg" class="img-responsive"/>
                         </div>
                         <a href="#"><h5>Excepteur sint occaecat cupi non proident laborum.</h5></a>
                         <p><small><i class="fa fa-calendar" data-original-title="" title=""></i> 30 Juni 2014</small></p>
                        </li>
                        <hr/>
						<li class="recent-post">
                        <div class="post-img">
                         <img src="images/blog_mg.jpg" class="img-responsive"/>
                         </div>
                         <a href="#"><h5>Excepteur sint occaecat cupi non proident laborum.</h5></a>
                         <p><small><i class="fa fa-calendar" data-original-title="" title=""></i> 30 Juni 2014</small></p>
                        </li>
                        
                    </ul>
                   </div>
		</div> */}
		
		<div class="widget-sidebar">
 <h2 class="title-widget-sidebar">NEWSLATTER</h2>
  <p>Fill your email id to get our latest updates.</p>  

<div class="input-group">
      <span class="input-group-addon"><i class="fa fa-envelope-o" aria-hidden="true"></i></span>
      <input id="email" type="text" class="form-control" name="email" placeholder="Email"/>
    </div>
    <button type="button" class="btn btn-warning">Subscribe</button>
             </div>

      </div>








    </div>






          </div>


        </section>
  





  </div>







  );
};

export default BlogMain;

// import Axios from 'axios';
import Axios from '../../Pages/Dashboard/httpInterceptor';
import React, { useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../../config/constant';


export const RepayModal =({id , tokenSymbol ,tokenName, intPerHour , bal })=> {
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState('');
  const [availableAmount , setAvailableAmount] = useState(bal);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const[ borrowAmt , setborrowAmt] = useState(0);
  const[ borrowId , setborrowId] = useState("");


  useEffect(() => {
    let userId = localStorage.getItem('userDetails')
  Axios.post(BASE_URL + `coin/get/borrow`, {
    "userId": userId
  })
    .then(res => {
      if (res.data.statusCode == 200) {
        let filterRes = res.data.data?.filter((a) => a.walletCode == tokenSymbol)
        if(filterRes){
          // setborrowAmt(filterRes[0]?.borrowAmount ? filterRes[0]?.borrowAmount : 0)
          setborrowId(filterRes[0]?._id ? filterRes[0]?._id : "")
          Axios.post(BASE_URL + `coin/getrepayment`, {
            "userId": userId
          }).then((res1)=>{
            setborrowAmt(res1.data.data?.amountLeftToPay)
          })
        }
      }
    })
  }, [])
  
    const handleSubmit = ()=>{
      if(amount > 0){
        if(Number(amount) <= Number(intPerHour)+Number(borrowAmt)){
          let senddata = {
            "id" : borrowId,
            "payAmount": Number(amount),
            "userId" : localStorage.getItem('userDetails')
          } 
          console.log('repay dat ==',senddata )
          // toast.success("under dev")
          Axios.post(BASE_URL+"coin/repayment" , senddata).then((result)=>{
            console.log(result)
            if(result.data.statusCode == 200){
              toast.success(result.data.message);
              handleClose()
              setAmount('')
            }else{
              toast.error(result.data.message)
              handleClose()
              setAmount('')
            }
          }).catch((error)=>{
            toast.error(error)
          })
        }else{
          toast.warning("amount is greater than total dept. amount ")
        }

    }else{
      toast.warning("Enter valid amount")
    }
    }

  return (
    <>
      {/* <ToastContainer /> */}
      <Button variant="primary" onClick={handleShow} size="sm">
        Repay
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Repay</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div style={{display: 'flex', alignItems:'center'}} className="form-control">
                <div>
                <img src={"/coinicon/"+tokenSymbol+".png" } width="30px" height="30px"/>
                </div>
                <div ><h5 style={{marginLeft:10}}>{tokenSymbol}<span className="name"> ({tokenName})</span></h5></div>
            </div>
          
          <div>
           
           <div className='row'>
              <div className='col-md-6 ' style={{marginTop:10 ,marginBottom:10 }}>
                 <h5> Available Balance </h5>
              </div>
              <div className='col-md-6 text-right' style={{marginTop:10, marginBottom:10}}>
                <h5>  {availableAmount} {tokenSymbol} </h5>                
              </div>
              <div className='col-md-6' style={{marginTop:10, marginBottom:10}}>
                <h5> Interest (A)</h5>
              </div>
              <div className='col-md-6 text-right' style={{marginTop:10, marginBottom:10}}>
              <h5>{intPerHour.toFixed(6)} %</h5>                                
              </div>
              <div className='col-md-6' style={{marginTop:10, marginBottom:10}}>
                <h5> Borrowed (B)</h5>
              </div>
              <div className='col-md-6 text-right' style={{marginTop:10, marginBottom:10}}>
              <h5>{borrowAmt} {tokenSymbol} </h5>                                
              </div>
              <div className='col-md-6' style={{marginTop:10, marginBottom:10}}>
                <h5> Total Dept (A+B)</h5>
              </div>
              <div className='col-md-6 text-right' style={{marginTop:10, marginBottom:10}}>
              <h5>{Number(intPerHour.toFixed(6))+Number(borrowAmt.toFixed(6))} {tokenSymbol} </h5>                                
              </div>              
           </div>

           <div className='row'>
                <div className='col-md-12'>
                  <label htmlFor="">Amount <span style={{color:'#edc215'}}>( {availableAmount} {tokenSymbol} Available )</span></label>
                <input placeholder='Amount'  className='form-control' type="number" min={1} value={amount} onChange={(e) =>setAmount(e.target.value) } />
            
                </div>
           </div>

          </div>
        </Modal.Body>
        <div style={{textAlign:'right', padding: 16}}>
        <hr />
          <Button variant="secondary" onClick={()=>handleClose()}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>handleSubmit()}>
            Repay
          </Button>
        </div>
      </Modal>
    </>
  );
}

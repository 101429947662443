import React, { useEffect, useState } from "react";
import "../p2p.scss";
import axiosInstance from "../../Dashboard/httpInterceptor";
import { BASE_URL } from "../../../config/constant";
import Loader from "../../../React-Loader/loader";
import { ToastContainer } from "react-toastify";

function TransferModal({ show, handleClose,coin }) {
  const [loading, setLoading] = useState(false);
  const [transferFrom, setTransferFrom] = useState("Fiat Spot");
  const [transferTo, setTransferTo] = useState("P2P Wallet");
  const [showFromCoinDropdown, setShowFromCoinDropdown] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [balances, setBalances] = useState({});
  const [selectedToken, setSelectedToken] = useState({});
  const [amount, setAmount] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userDetails");
    if (userId && coin) {
      setLoading(true);
      axiosInstance
        .get(BASE_URL + `ercToken/get/all`)
        .then((res) => {
          setTokens(res.data?.data || []);
          setSelectedToken(
            (res.data?.data || []).find((e) => e.tokenSymbol === coin)
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
      getBalances(userId);
    }
  }, [coin]);
  // useEffect(() => {
  //   if (coin) {
  //     setSelectedToken(
  //       (tokens || []).find((e) => e.tokenSymbol === coin)
  //     );
  //     }
  // }, [coin])
  
  const getBalances = (userId) => {
    setLoading(true);
    axiosInstance
      .get(BASE_URL + `get/p2pAndSpotBalances?userId=${userId}`)
      .then((res) => {
        setBalances(res.data?.data || []);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const onTokenSelect = (data) => {
    setLoading(true);
    setSelectedToken(data);
    setShowFromCoinDropdown(false);
    setLoading(false);
  };
  const swap = () => {
    setLoading(true);
    const from = transferFrom;
    const to = transferTo;
    setTransferFrom(to);
    setTransferTo(from);
    setLoading(false);
  };
  const getAvailbleBalance = () => {
    if (transferFrom === "Fiat Spot") {
      return balances?.spotBalances
        ? Number(balances?.spotBalances[selectedToken?.tokenSymbol])
        : 0;
    } else {
      return balances?.p2pBalances
        ? Number(balances?.p2pBalances[selectedToken?.tokenSymbol])
        : 0;
    }
  };

  const onSubmit = () => {
    if (amount <= getAvailbleBalance() && amount>0) {
      setLoading(true);
      const userId = localStorage.getItem("userDetails");
      const payload = {
        userId: userId,
        coinName: selectedToken.tokenSymbol,
        amount: amount,
      };
      if (transferFrom === "Fiat Spot") {
        axiosInstance
          .post(BASE_URL + `transfer/spotToP2P`, payload)
          .then((res) => {
            getBalances(userId);
            setLoading(false);
            setAmount("");
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      } else {
        axiosInstance
          .post(BASE_URL + `transfer/P2PToSpot`, payload)
          .then((res) => {
            getBalances(userId);
            setLoading(false);
            setAmount("");
            handleClose()
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      }
    }
  };
  return (
    <div>
      {loading && <Loader />}
      <ToastContainer/>
      <div
        className={`modal fade in popup_form ${show && "show"}`}
        id="transfer_modal"
        data-backdrop="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content rounded-md">
            <div className="d-flex flex-between px-2 py-2">
              <h5 className="modal-title" id="exampleModalLabel">
                Transfer
              </h5>
              <button
                type="button"
                className="close font-16"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                <span aria-hidden="true">&times;</span>{" "}
              </button>
            </div>
            <div className="modal-body">
              <div
                className="trade_buy_form p-2"
                style={{ maxHeight: "75vh", overflow: "auto" }}
              >
                <div className="font-16 txt-black font-w-500">
                  Internal transfer are free on Ecanna
                </div>
                <form>
                  <div className="bg-off-white p-4 rounded-sm mt-4">
                    <div className="transfer-from d-flex flex-start">
                      <div className="from-to">From</div>
                      <div className="font-w-600">{transferFrom}</div>
                    </div>
                    <div className="d-flex flex-end">
                      <img
                        src="images/sort.png"
                        alt=""
                        height={30}
                        width={30}
                        className="cursor-pointer"
                        onClick={() => swap()}
                      />
                    </div>
                    <div className="transfer-from d-flex flex-start">
                      <div className="from-to">To</div>
                      <div className="font-w-600">{transferTo}</div>
                    </div>
                  </div>
                  <hr />

                  <div>
                    <div>
                      <label htmlFor="coinName-from">Coin</label>
                      <div
                        className="d-flex flex-between bg-off-white p-4 rounded-sm cursor-pointer"
                        onClick={() =>
                          setShowFromCoinDropdown(!showFromCoinDropdown)
                        }
                      >
                        <div className="d-flex flex-start">
                          <div className="mr-2">
                            <img
                              src={
                                "/coinicon/" +
                                selectedToken?.tokenSymbol +
                                ".png"
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          </div>
                          <div className="font-12 font-w-500">
                            {selectedToken?.tokenSymbol}
                            <small className="ml-2">
                              {selectedToken?.tokenName}
                            </small>
                          </div>
                        </div>
                        <div>
                          <i className="fa fa-chevron-down txt-black"></i>
                        </div>
                      </div>
                      {showFromCoinDropdown && (
                        <div className="bg-light-gray rounded-sm mt-1">
                          <ul style={{ maxHeight: 200, overflow: "auto" }}>
                            {(tokens || [])
                              .filter((e) => !e.isFiat)
                              .map((data) => (
                                <li
                                  className="divider ml-4 mr-4 py-2 cursor-pinter"
                                  onClick={() => onTokenSelect(data)}
                                >
                                  <div className="d-flex flex-start ">
                                    <div className="mr-2">
                                      <img
                                        src={
                                          "/coinicon/" +
                                          data.tokenSymbol +
                                          ".png"
                                        }
                                        width="20px"
                                        height="20px"
                                        alt=""
                                      />
                                    </div>
                                    <div className="font-12 font-w-500">
                                      {data.tokenSymbol}
                                      <small className="ml-2">
                                        {data.tokenName}
                                      </small>
                                    </div>
                                  </div>
                                  {/* <div className="divider"></div> */}
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}

                      <div className="from-group mt-4">
                        <div className="d-flex flex-between">
                          <label htmlFor="amount">Amount</label>
                          <div className="font-12">
                            {getAvailbleBalance().toFixed(4)}
                            <small> available</small> / {(+amount).toFixed(4)}{" "}
                            <small>in order</small>
                          </div>
                        </div>
                        <input
                          type="number"
                          placeholder="0.0000"
                          name="amount"
                          id="amount"
                          className="form-control bg-off-white "
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                        {amount > getAvailbleBalance() && (
                          <label className="error-text">Insufficient Fund</label>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-end mt-4">
                      <button type="button" className="btn" onClick={()=> handleClose()}> Cancel</button>
                      <button type="button" disabled={amount <=0 || amount>getAvailbleBalance() || loading}
                        className="bg-yellow-5 btn txt-white font-w-600 px-4"
                        onClick={() => onSubmit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransferModal;

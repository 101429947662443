import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
import Pagination from 'react-js-pagination';

import BigNumber from 'bignumber.js'
// import axios from 'axios';
import axios from './httpInterceptor';
import { BASE_URL } from "../../config/constant";
import moment from 'moment';
import Loader from '../../React-Loader/loader';


class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            withdrawalHistory: [],
            depositHistory: [],
            userData: [],
            loadFeeSubs: false,
            FESSstakeamt: 0,
            historyLoading: true,
            deposithistoryLoading: true,
            userTradeHistory:[],   
            depositActivePageIndex:0,
            withdrawActivePageIndex:0,
            orderActivePageIndex:0,
            INRActivePageIndex:0,
            depositTempCollection:[],
            withdrawTempCollection:[],
            orderTempCollection:[],
            inrdepositHistory:[],
            inrdepositTempCollection:[],
            itemPerPage:10,
            loading : false,
            tokenList:[],
            fromSymbol:'BTC',
            toSymbol:'USDT'
        };
    }

    async componentDidMount() {
        if (!localStorage.getItem('userDetails')) {
            this.props.history.push('/login')
        } else {

            this.setState({ userId: localStorage.getItem('userDetails') })


            let userId = localStorage.getItem('userDetails')
            this.setState({ historyLoading: true , loading : true })
            this.setState({ deposithistoryLoading: true })
            let data = {
                "userId": userId,
            }

            axios.post(BASE_URL + `withdrawal/get/user`, data)
                .then(res => {
                    // console.log(res.data.data);
                    if (res.data.message == "success") {
                        this.setState({
                            withdrawalHistory: res.data.data && res.data.data.slice(0,this.state.itemPerPage),
                            withdrawTempCollection:res.data.data 
                        })
                    }
                    if (res.data.message == "walletDoesNotExist") {
                        // console.log("Wallet not found");
                    }
                    this.setState({ historyLoading: false  , loading : false})
                })

            this.setState({loading : true})
            axios.post(BASE_URL + `depositehistory/get/user`, data)
                .then(res => {
                    // console.log(res.data.data);
                    if (res.data.message == "success") {
                        this.setState({
                            depositHistory: res.data.data && res.data.data.slice(0,this.state.itemPerPage),
                            depositTempCollection:res.data.data
                         })
                    }
                    if (res.data.message == "walletDoesNotExist") {
                        // console.log("Wallet not found");
                    }
                    this.setState({ deposithistoryLoading: false  , loading : false })
                })

                this.getOrderHistory("BTCUSDT")


                this.setState({loading : true})
                axios.post(BASE_URL + `depositeINR/get/all`, data)
                .then(res => {
                    if (res.data.message == "success") {
                        this.setState({
                            inrdepositHistory: res.data.data && res.data.data.slice(0,this.state.itemPerPage),
                            inrdepositTempCollection:res.data.data
                         })
                    }
                    if (res.data.message == "walletDoesNotExist") {
                    }
                    this.setState({ deposithistoryLoading: false , loading : false })
                })
        }



        await axios.get(BASE_URL + `ercToken/get/all`)
            .then(res => {
                if (res.data.message == "success") {
                    this.setState({tokenList:res.data.data})
                }
            })
        await axios.post(BASE_URL + `userInformation/get`, {
            "id": this.state.userId
        })
            .then(res => {
                // console.log(res);
                if (res.data.message == "success") {
                    this.setState({
                        userData: res.data.data
                    })

                }
            })
        await axios.post(BASE_URL + `userBalance/get`, {
            "userId": this.state.userId
        })
            .then(res => {
                // console.log("user id",this.state.userId)
                // console.log(res);
                if (res.data.message == "success") {
                    // if(Number(res.data.data['FESS']) >= Number(this.state.FESSstakeamt)) {
                    //     this.setState({loadFeeSubs:true})
                    // }
                    // if(Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                    //     this.setState({loadFeeSubs:true})
                    // }

                }
            })

        // await axios.post(BASE_URL + `deposite/get/id`,{
        //     tokenSymbol: "TRX",
        //     userId: this.state.userId,
        //     userIDNo: 0
        // })
        // .then(async res => {
        //     // console.log(res);
        //     if (res.data.message == "success") {

        //         // console.log("trx",res.data.data.walletAddress)

        //         await axios.post('https://api.ecannacoin.com:2053/trxadd',{
        //             walletAddress: res.data.data.walletAddress
        //         })
        //         .then()


        //     }
        // })

        // await axios.post('https://coincdn.ECanna.com:2083/xlmadd',{})
        //     .then()
    }
    getOrderHistory=( pair='BTCUSDT')=>{
        let userId = localStorage.getItem('userDetails')
        this.setState({loading : true})
                axios.get(BASE_URL + `order/getall/order-history?userId=${userId}&pair=${pair}`)
                .then(res => {
                    if (res.data.statusCode == 200) {
                        this.setState({ 
                            userTradeHistory: res.data.data && res.data.data.slice(0,this.state.itemPerPage),
                            orderTempCollection:res.data.data
                         })
                    }
                     this.setState({ loading: false })
                }).catch((e)=>{
                    this.setState({ loading: false })
                })
    }
    handleDepositPageChange =(e)=>{
        const start = (this.state.itemPerPage * (e-1)) ;
        const data = this.state.depositTempCollection.slice(start, start + this.state.itemPerPage);
        this.setState({depositHistory:data})
    } 
    handleWithdrawPageChange =(e)=>{
        const start = (this.state.itemPerPage * (e-1)) ;
        const data = this.state.withdrawTempCollection.slice(start, start + this.state.itemPerPage);
        this.setState({withdrawalHistory:data})
    }
    handleOrderPageChange =(e)=>{
        const start = (this.state.itemPerPage * (e-1)) ;
        const data = this.state.orderTempCollection.slice(start, start + this.state.itemPerPage);
        this.setState({userTradeHistory:data})
    }
    handleInrTnxPageChange =(e)=>{
        const start = (this.state.itemPerPage * (e-1)) ;
        const data = this.state.orderTempCollection.slice(start, start + this.state.itemPerPage);
        this.setState({inrdepositHistory:data})
    }
    
    render() {
        document.title = "Transactions";
        document.body.classList.add('faded_bg');

        return (
            <div>
                <Header loadFees={this.state.loadFeeSubs} />
                {this.state.loading ? <Loader /> : <></>}

                <section id="middel_area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 col-sm-3 area_left">
                                <Sidebar loadFees={this.state.loadFeeSubs} />
                            </div>

                            <div className="col-md-10 col-sm-9 area_right">
                                <div className="white_bx clearfix" style={{ minHeight: "500px" }}>
                                    <ul className="nav nav-tabs new_tab" role="tablist">
                                        <li className="active"><a href="#deposit_his" aria-controls="deposit_his" role="tab" data-toggle="tab">Deposit History</a></li>
                                        <li><a href="#withdraw_his" aria-controls="withdraw_his" role="tab" data-toggle="tab">Withdrawal History</a></li>
                                        <li><a href="#order" aria-controls="order" role="tab" data-toggle="tab">Order History</a></li>
                                        <li><a href="#inr-deposit" aria-controls="inr-deposit" role="tab" data-toggle="tab">INR Deposit</a></li>
                                    </ul>
                                    <hr></hr>
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="deposit_his">
                                            {/* <div className="side_txt">
                                            <h6>Deposit History</h6>
                                            </div> */}
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Coin</th>
                                                            <th>Amount</th>
                                                            {/* <th>Fees</th> */}
                                                            <th>Wallet Address / Details</th>
                                                            <th>Date & Time</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>

                                                    {this.state.deposithistoryLoading == true ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="6" height="350px">
                                                                    <div className="loader">Loading...</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        :
                                                        <>
                                                            {this.state.depositHistory !== null ?

                                                                <tbody>
                                                                    {this.state.depositHistory.map((d, xid) => (
                                                                        <tr key={xid}>
                                                                            <td><img src={"/coinicon/" + d.coinName + ".png"} width="20px" height="20px" style={{ marginLeft: "0px", marginRight: "10px" }} /> {d.coinName}</td>
                                                                            <td><b>{new BigNumber(d.amount).toFormat(8, 1)}</b></td>
                                                                            {/* <td>{new BigNumber(d.fees).toFormat(6,1)}</td> */}
                                                                            <td>{d.walletAddress}<div>
                                                                                {d.extratag != "" && d.coinName == "XRP" ?
                                                                                    "Destination Tag : " + d.extratag : <></>
                                                                                }
                                                                                {d.extratag != "" && d.coinName == "XLM" ?
                                                                                    "Memo : " + d.extratag : <></>
                                                                                }

                                                                                {d.coinName == "INR" &&
                                                                                    <>
                                                                                        <b>Bank Name</b> : {d.bankname}<br></br>
                                                                                        <b>Account Number</b> : {d.acno}<br></br>
                                                                                        <b>IFSC</b> : {d.ifsc}<br></br>
                                                                                        <b>Reference ID</b> : {d.refid}<br></br>
                                                                                        <b>Remark</b> : {d.remark}<br></br>
                                                                                    </>
                                                                                }

                                                                            </div></td>
                                                                            <td>{moment(d.date,'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM yyyy HH:MM:SS A')}</td>
                                                                            <td>
                                                                                {d.status == "Pending" && <span className="label label-default">Pending</span>}
                                                                                {d.status == "Processing" && <span className="label label-primary">Processing</span>}
                                                                                {d.status == "Rejected" && <span className="label label-danger">Rejected</span>}

                                                                                {d.status == "Completed" && <><span className="label label-success">Completed</span>
                                                                                    {d.coinName !== "INR" &&
                                                                                        <>
                                                                                            
                                                                                            <a href={
                                                                                                d.coinName == "BTC" ?
                                                                                                    "https://www.blockchain.com/btc/tx/" + d.txid
                                                                                                    :
                                                                                                    d.coinName == "XRP" ?
                                                                                                        "https://bithomp.com/explorer/" + d.txid
                                                                                                        :
                                                                                                        d.coinName == "LTC" ?
                                                                                                            "https://live.blockcypher.com/ltc/tx/" + d.txid
                                                                                                            :
                                                                                                            d.coinName == "DASH" ?
                                                                                                                "https://live.blockcypher.com/dash/tx/" + d.txid
                                                                                                                :
                                                                                                                d.coinName == "BCH" ?
                                                                                                                    "https://explorer.bitcoin.com/bch/tx/" + d.txid
                                                                                                                    :
                                                                                                                    d.coinName == "TRX" ?
                                                                                                                        "https://tronscan.org/#/transaction/" + d.txid
                                                                                                                        :
                                                                                                                        d.coinName == "XLM" ?
                                                                                                                            "https://steexp.com/tx/" + d.txid
                                                                                                                            :
                                                                                                                            d.coinName == "ETC" ?
                                                                                                                                "https://blockscout.com/etc/mainnet/tx/" + d.txid
                                                                                                                                :
                                                                                                                                d.coinName == 'ECNA'?
                                                                                                                                "https://evm.ecannacoin.com/tx/" + d.txid
                                                                                                                                :
                                                                                                                                "https://etherscan.io/tx/" + d.txid

                                                                                            } target="_blank" className="label label-default" style={{ color: "#fff", marginLeft: 5 }}>View Tx</a>
                                                                                        </>
                                                                                    }
                                                                                </>}
                                                                            </td>
                                                                            {/* <td>
                                                                                <Link to={"/cointransaction?type=deposit&coin=" + d.coinName} >
                                                                                   
                                                                                    <button className='btn btn-info'>Details</button>
                                                                                 
                                                                                </Link>
                                                                            </td> */}
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                                :
                                                                <tbody>
                                                                    <tr>
                                                                        <td colspan="5" align="center" className="no_data"><i className="fa fa-file-o"></i> No record found</td>
                                                                    </tr>
                                                                </tbody>
                                                            }
                                                        </>
                                                    }

                                                </table>
                                            </div>
                                            <div style={{ float: 'right' }}>
                                                <Pagination
                                                    activePage={this.state.depositActivePageIndex}
                                                    itemsCountPerPage={this.state.itemPerPage}
                                                    totalItemsCount={this.state.depositTempCollection.length}
                                                    pageRangeDisplayed={5}
                                                    onChange={(e) => this.handleDepositPageChange(e)}
                                                />
                                            </div>
                                        </div>

                                        <div role="tabpanel" className="tab-pane" id="withdraw_his">
                                            {/* <div className="side_txt">
                            <h6>Withdrawal History</h6>
                            </div> */}
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Coin</th>
                                                            <th>Amount</th>
                                                            <th>Fees</th>
                                                            <th>Wallet Address / Details</th>
                                                            <th>Hash/Reciept</th>
                                                            <th>Date & Time</th>
                                                            <th>Status</th>
                                                            <th></th>


                                                        </tr>
                                                    </thead>

                                                    {this.state.historyLoading == true ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="6" height="350px">
                                                                    <div className="loader">Loading...</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        :
                                                        <>
                                                            {this.state.withdrawalHistory !== null ?
                                                                <tbody>
                                                                    {this.state.withdrawalHistory.map((d, xid) => (
                                                                        <tr key={xid}>
                                                                            <td><img src={"/coinicon/" + d.coinName + ".png"} width="20px" height="20px" style={{ marginLeft: "0px", marginRight: "10px" }} /> {d.coinName}</td>
                                                                            <td><b>{new BigNumber(d.amount).toFormat(6, 1)}</b> <small>({new BigNumber(d.inrValue || 0).toFormat(2, 1)} INR)</small> </td>
                                                                            <td>{new BigNumber(d.fees).toFormat(6, 1)}</td>
                                                                            <td>{d.walletAddress}<div>
                                                                                {d.extratag != "" && d.coinName == "XRP" ?
                                                                                    "Destination Tag : " + d.extratag : <></>
                                                                                }
                                                                                {d.extratag != "" && d.coinName == "XLM" ?
                                                                                    "Memo : " + d.extratag : <></>
                                                                                }

                                                                                {d.coinName == "INR" || d.coinName == "ECNA" &&
                                                                                    <>
                                                                                        <b>A/c Holder Name</b> : {d.acHolderName}<br></br>
                                                                                        <b>Bank Name</b> : {d.bankName}<br></br>
                                                                                        <b>Account Type</b> : {d.accountType}<br></br>
                                                                                        <b>Account Number</b> : {d.accountNumber}<br></br>
                                                                                        <b>IFSC</b> : {d.ifsc}<br></br>
                                                                                        <b>Remark</b> : {d.remark}<br></br>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            </td>
                                                                            <td >{d.txhash}</td>
                                                                            <td>{moment(d.date,'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM yyyy HH:MM:SS A')}</td>
                                                                            <td>
                                                                                {d.status == "Pending" && <span className="label label-default">Pending</span>}
                                                                                {d.status == "Processing" && <span className="label label-primary">Processing</span>}
                                                                                {d.status == "Rejected" && <span className="label label-danger">Rejected</span>}
                                                                                {d.status == "Completed" && <><span className="label label-success">Completed</span>
                                                                                    {d.coinName !== "INR" && d.coinName !== "ECNA" &&
                                                                                        <>
                                                                                            <br></br>
                                                                                            <a href={
                                                                                                d.coinName == "BTC" ?
                                                                                                    "https://www.blockchain.com/btc/tx/" + d.txhash
                                                                                                    :
                                                                                                    d.coinName == "XRP" ?
                                                                                                        "https://bithomp.com/explorer/" + d.txhash
                                                                                                        :
                                                                                                        d.coinName == "LTC" ?
                                                                                                            "https://live.blockcypher.com/ltc/tx/" + d.txhash
                                                                                                            :
                                                                                                            d.coinName == "DASH" ?
                                                                                                                "https://live.blockcypher.com/dash/tx/" + d.txhash
                                                                                                                :
                                                                                                                d.coinName == "BCH" ?
                                                                                                                    "https://explorer.bitcoin.com/bch/tx/" + d.txhash
                                                                                                                    :
                                                                                                                    d.coinName == "TRX" ?
                                                                                                                        "https://tronscan.org/#/transaction/" + d.txhash
                                                                                                                        :
                                                                                                                        d.coinName == "XLM" ?
                                                                                                                            "https://steexp.com/tx/" + d.txhash
                                                                                                                            :
                                                                                                                            d.coinName == "ETC" ?
                                                                                                                                "https://blockscout.com/etc/mainnet/tx/" + d.txhash
                                                                                                                                :
                                                                                                                                "https://etherscan.io/tx/" + d.txhash

                                                                                            } target="_blank" className="label label-default" style={{ color: "#fff" }}>View Tx</a></>}
                                                                                </>}
                                                                            </td>
                                                                            <td>
                                                                                <Link to={"/cointransaction?type=withdraw&coin=" + d.coinName} >
                                                                                    {/* <a href='/cointransaction' > */}
                                                                                    <button className='btn btn-info'>Details</button>
                                                                                    {/* </a> */}
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                                :
                                                                <tbody>
                                                                    <tr>
                                                                        <td colspan="5" align="center" className="no_data"><i className="fa fa-file-o"></i> No record found</td>
                                                                    </tr>
                                                                </tbody>
                                                            }
                                                        </>
                                                    }

                                                </table>
                                            </div>
                                            <div style={{ float: 'right' }}>
                                                <Pagination
                                                    activePage={this.state.withdrawActivePageIndex}
                                                    itemsCountPerPage={this.state.itemPerPage}
                                                    totalItemsCount={this.state.withdrawTempCollection.length}
                                                    pageRangeDisplayed={5}
                                                    onChange={(e) => this.handleWithdrawPageChange(e)}
                                                />
                                            </div>
                                        </div>

                                        <div role="tabpanel" className="tab-pane" id="order">
                                            <div className='row'>
                                                 <div className='col-md-4 col-sm-12'>
                                                    <select  onChange={(e)=> {
                                                                this.setState({fromSymbol: e.target.value})
                                                            }}>
                                                                <option  value="" ></option>
                                                    {
                                                        this.state.tokenList.map((token)=>(
                                                            
                                                            <option  value={token.tokenSymbol } >{token.tokenSymbol}</option>
                                                        ))
                                                    }
                                                    </select>    
                                                </div>  
                                                <div className='col-md-4 col-sm-12'>
                                                    <select onChange={(e)=> {
                                                                this.setState({toSymbol: e.target.value})
                                                            }}>
                                                                <option  value="" ></option>
                                                    {
                                                        this.state.tokenList.map((token)=>(
                                                            <option  value={token.tokenSymbol } >{token.tokenSymbol}</option>
                                                        ))
                                                    }
                                                    </select>    
                                                </div> 
                                                <div className='col-md-4 col-sm-12'>
                                                    <button className='btn btn-primary mb-2' onClick={()=> this.getOrderHistory(this.state.fromSymbol+this.state.toSymbol)}>Reload</button>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Coin</th>
                                                            <th>Amount</th>
                                                            <th>Fees</th>
                                                            <th>Total</th>
                                                            <th>Limit</th>
                                                            <th>Order Type</th>
                                                            <th>Date & Time</th>
                                                            <th>Status</th>

                                                        </tr>
                                                    </thead>

                                                    {this.state.userTradeHistory == true ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="6" height="350px">
                                                                    <div className="loader">Loading...</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        :
                                                        <>
                                                            {this.state.userTradeHistory !== null ?
                                                                <tbody>
                                                                    {this.state.userTradeHistory.map((d, xid) => (
                                                                        <tr key={xid}>
                                                                            <td><img src={"/coinicon/" + d.coinName + ".png"} width="20px" height="20px" style={{ marginLeft: "0px", marginRight: "10px" }} /> {d.coinName}{d.pair}</td>
                                                                            <td><b>{new BigNumber(d.amount).toFormat(6, 1)}</b></td>
                                                                            <td>{new BigNumber(d.tredingFee).toFormat(6, 1)}</td>
                                                                            <td>{new BigNumber(d.total).toFormat(6, 1)}</td>
                                                                            <td>
                                                                                <span className={d.orderType ==="Buy" ? "green_clr" : "red_clr"}>{d.orderType}</span>
                                                                                </td>
                                                                            <td>{d.orderMode}</td>
                                                                            <td>{moment(d.timestamp*1000).format('DD MMM yyyy HH:MM:SS A')}</td>
                                                                            <td>
                                                                                {d.status == "Pending" || d.status == "0" && <span className="label label-default">Pending</span>}
                                                                                {d.status == "Cancel" || d.status == "5" || d.status == "2" && <span className="label label-danger">Cancelled</span>}
                                                                                {d.status == "Processing" && <span className="label label-primary">Processing</span>}
                                                                                {d.status == "Rejected" && <span className="label label-danger">Rejected</span>}
                                                                                {d.status == "1" && <><span className="label label-success">Completed</span>
                                                                                    {/* {d.coinName !== "INR" &&
                                                                                        <>
                                                                                            <br></br>
                                                                                            <a href={
                                                                                                d.coinName == "BTC" ?
                                                                                                    "https://www.blockchain.com/btc/tx/" + d.txhash
                                                                                                    :
                                                                                                    d.coinName == "XRP" ?
                                                                                                        "https://bithomp.com/explorer/" + d.txhash
                                                                                                        :
                                                                                                        d.coinName == "LTC" ?
                                                                                                            "https://live.blockcypher.com/ltc/tx/" + d.txhash
                                                                                                            :
                                                                                                            d.coinName == "DASH" ?
                                                                                                                "https://live.blockcypher.com/dash/tx/" + d.txhash
                                                                                                                :
                                                                                                                d.coinName == "BCH" ?
                                                                                                                    "https://explorer.bitcoin.com/bch/tx/" + d.txhash
                                                                                                                    :
                                                                                                                    d.coinName == "TRX" ?
                                                                                                                        "https://tronscan.org/#/transaction/" + d.txhash
                                                                                                                        :
                                                                                                                        d.coinName == "XLM" ?
                                                                                                                            "https://steexp.com/tx/" + d.txhash
                                                                                                                            :
                                                                                                                            d.coinName == "ETC" ?
                                                                                                                                "https://blockscout.com/etc/mainnet/tx/" + d.txhash
                                                                                                                                :
                                                                                                                                "https://etherscan.io/tx/" + d.txhash

                                                                                            } target="_blank" className="label label-default" style={{ color: "#fff" }}>View Tx</a></>} */}
                                                                                </>}
                                                                            </td>
                                                                            {/* <td>
                                                                                <Link to={"/cointransaction?type=withdraw&coin=" + d.coinName} >
                                                                                    <button className='btn btn-info'>Details</button>
                                                                                </Link>
                                                                            </td> */}
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                                :
                                                                <tbody>
                                                                    <tr>
                                                                        <td colspan="5" align="center" className="no_data"><i className="fa fa-file-o"></i> No record found</td>
                                                                    </tr>
                                                                </tbody>
                                                            }
                                                        </>
                                                    }

                                                </table>
                                            </div>
                                            <div style={{ float: 'right' }}>
                                                <Pagination
                                                    activePage={this.state.orderActivePageIndex}
                                                    itemsCountPerPage={this.state.itemPerPage}
                                                    totalItemsCount={this.state.orderTempCollection.length}
                                                    pageRangeDisplayed={5}
                                                    onChange={(e) => this.handleOrderPageChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div role="tabpanel" className="tab-pane" id="inr-deposit">
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                    <tr>
                                                            <th>Coin</th>
                                                            <th>Amount</th>
                                                            {/* <th>Fees</th> */}
                                                            <th> Details</th>
                                                            <th>Date & Time</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>

                                                    {this.state.inrdepositHistory == true ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="6" height="350px">
                                                                    <div className="loader">Loading...</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        :
                                                        <>
                                                            {this.state.inrdepositHistory !== null ?
                                                                <tbody>
                                                                    {this.state.inrdepositHistory.map((d, xid) => (
                                                                       <tr key={xid}>
                                                                       <td><img src={"/coinicon/" + d.coinName + ".png"} width="20px" height="20px" style={{ marginLeft: "0px", marginRight: "10px" }} /> {d.coinName}</td>
                                                                       <td><b>{new BigNumber(d.amount).toFormat(8, 1)}</b></td>
                                                                       {/* <td>{new BigNumber(d.fees).toFormat(6,1)}</td> */}
                                                                       <td><div>
                                                                           {d.coinName == "INR" &&
                                                                               <>
                                                                                   <b>Bank Name</b> : {d.bankname}<br></br>
                                                                                   <b>Account Number</b> : {d.acno}<br></br>
                                                                                   <b>IFSC</b> : {d.ifsc}<br></br>
                                                                                   <b>Reference ID</b> : {d.refid}<br></br>
                                                                                   <b>Remark</b> : {d.remark}<br></br>
                                                                               </>
                                                                           }

                                                                       </div></td>
                                                                       <td>{moment(d.date,'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM yyyy HH:MM:SS A')}</td>
                                                                       <td>
                                                                           {d.status == "Pending" && <span className="label label-default">Pending</span>}
                                                                           {d.status == "Processing" && <span className="label label-primary">Processing</span>}
                                                                           {d.status == "Rejected" && <span className="label label-danger">Rejected</span>}

                                                                           {d.status == "Completed" && <><span className="label label-success">Completed</span>
                                                                              
                                                                           </>}
                                                                       </td>
                                                                       
                                                                   </tr>
                                                                    ))}

                                                                </tbody>
                                                                :
                                                                <tbody>
                                                                    <tr>
                                                                        <td colspan="5" align="center" className="no_data"><i className="fa fa-file-o"></i> No record found</td>
                                                                    </tr>
                                                                </tbody>
                                                            }
                                                        </>
                                                    }

                                                </table>
                                            </div>
                                            <div style={{ float: 'right' }}>
                                                <Pagination
                                                    activePage={this.state.INRActivePageIndex}
                                                    itemsCountPerPage={this.state.itemPerPage}
                                                    totalItemsCount={this.state.inrdepositTempCollection.length}
                                                    pageRangeDisplayed={5}
                                                    onChange={(e) => this.handleInrTnxPageChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default Transactions;
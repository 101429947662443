import React, { useEffect, useState } from 'react'
import { colorArray } from '../p2pContants'
import '../p2p.scss';
import { toast } from 'react-toastify';
import axiosInstance from '../../Dashboard/httpInterceptor';
import { BASE_URL } from '../../../config/constant';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

function Buy({advertiesment, baseAsset, quoteAsset,balances, reloadList, setLoading}) {
  const routeHistory = useHistory();

    const [selected, setSelected] = useState('')
    const [amounts, setAmounts] = useState({amount0: 0, amount1:0})
    const [usersPaymentsMethods, setusersPaymentsMethods] = useState({UPI:null, IMPS:null, BankTransfer:null});

    useEffect(() => {
      const userId = localStorage.getItem("userDetails");
      if(userId)
        getUserPaymentMethods(userId);
    }, [])
    
    const getUserPaymentMethods = (userId) => { 
      axiosInstance
        .get(BASE_URL + `get/userPaymentMethods?userId=${userId || ''}`)
        .then((res) => {
          const data = (res.data?.data);
          setusersPaymentsMethods(data)
        })
        .catch((e) => {
        });
    };
    const amountChangeHandler=(item,amount, side)=>{
      if (side === 'from') {
        setAmounts((amt)=> ({amount0: amount, amount1: amount/ item.price}))
      }else{
        setAmounts((amt)=> ({amount0: amount * item.price , amount1: amount}))
      }
    }
    const submit =(item)=>{
      const usrstr = localStorage.getItem('ECannaUser')
      const user = usrstr ? JSON.parse(usrstr): null
      if (user) {
      if (amounts.amount0>0 && amounts.amount1>0) {
        setLoading(true)
        const payload={
          type:'Buy',
          amount: amounts.amount0,
          price: item.price,
          total: item.price * amounts.amount0,
          assetCoin: item.assetCoin,
          fiatCoin: item.fiatCoin,
          paymentMethods: {UPI:usersPaymentsMethods.UPI, BankTransfer:usersPaymentsMethods.BankTransfer, IMPS: usersPaymentsMethods.IMPS},
          adPostedBy: user.userName,
          userId: user._id,
          emailId: user.emailId,
          matchedWith: item._id
        }
        axiosInstance.post(`${BASE_URL}p2p/matchOrder`,payload).then((res)=>{
          reloadList()
          setLoading(false)
          routeHistory.push('p2p-orders/'+res.data.data)
        }).catch((err)=>{
          setLoading(false)
        })
      }else{
        toast.error('Invailid amount')
      }
    }else{
      toast.error('User session has been expire, login again')
    }
  }
  return (
    <table className="table" style={{minWidth: 1000}}>
          <thead>
            <tr>
              <th>ADVERTISER</th>
              <th>
                PRICE <span className="badges badges-yellow">LOW TO HIGH</span>
              </th>
              <th>AMOUNT</th>
              <th>TOTAL</th>
              <th>PAYMENT</th>
              <th>DATE</th>
              <th>TRADE</th>
            </tr>
          </thead>
          <tbody>
            {advertiesment.filter(e => e.type === 'Sell' && e.fiatCoin === quoteAsset && e.assetCoin === baseAsset).map((data)=>(
            <>
            <tr>
              <td>
                <div className='d-flex flex-start'>
                  <div className="avatar mr-2" style={{background:  colorArray[Math.floor(Math.random() * colorArray.length)]}}>
                  {data.adPostedBy.split(' ')[0].charAt(0)+""+(data.adPostedBy.split(' ').length >1 ?data.adPostedBy.split(' ')[1].charAt(0):'')} 
                  </div>
                  <div className="txt-black font-w-500">
                    <div>{data.adPostedBy}</div>
                    (<small>{data.userId}</small>)
                  </div>
                </div>
              </td>
              <td>
                <div className='font-16 txt-black font-w-600'>
                {(+data.price).toFixed(4)} <small className='font-w-500'>{data.assetCoin}</small>
                </div>
              </td>
              <td>
                <div className='font-16 txt-black font-w-600'>{(+data.amount).toFixed(5)} <small className='font-w-500'></small></div>
              </td>
              <td>
                <div className='font-16 txt-black font-w-600'>{(data.price * data.amount).toFixed(5)} <small className='font-w-500'></small></div>
              </td>
              <td>
                {selected === data._id ? 
                 <div>
                 {data.paymentMethods.UPI &&<div className="pm-bg-1">
                   <div className="d-flex flex-between">
                       <strong className="txt-black">UPI</strong>
                       {/* <div onClick={()=> removePaymentMode('UPI')}><i className="fa fa-close cursor-pointer"></i></div> */}
                       </div>
                           <label className="mt-3"><span className="txt-black font-w-600">Name: </span><small>{data.paymentMethods.UPI.userName}</small></label><br />
                           <label><span className="txt-black font-w-600">UPI: </span><small>{data.paymentMethods.UPI.upiAddress}</small></label>
                           {data.paymentMethods.UPI.qr && <div><img src={data.paymentMethods.UPI.qr} alt="" /></div>}
                 </div>}
                 {data.paymentMethods.IMPS &&<div className="pm-bg-1">
                   <div className="d-flex flex-between">
                       <strong className="txt-black">IMPS</strong>
                       {/* <div onClick={()=> removePaymentMode('IMPS')}><i className="fa fa-close cursor-pointer"></i></div> */}
                       </div>
                           <label className="mt-3"><span className="txt-black font-w-600">Name: </span><small>{data.paymentMethods.IMPS.userName}</small></label><br />
                           <label><span className="txt-black font-w-600">Account No: </span><small>{data.paymentMethods.IMPS.accountNo}</small></label><br />
                           <label><span className="txt-black font-w-600">IFSC Code: </span><small>{data.paymentMethods.IMPS.ifsc}</small></label><br />
                           <label><span className="txt-black font-w-600">Bank Name: </span><small>{data.paymentMethods.IMPS.bankName}</small></label><br />
                           <label><span className="txt-black font-w-600">Account Type: </span><small>{data.paymentMethods.IMPS.accountType}</small></label><br />
                           <label><span className="txt-black font-w-600">Branch: </span><small>{data.paymentMethods.IMPS.branchName}</small></label>
                 </div>}
 
                 {data.paymentMethods.BankTransfer &&<div className="pm-bg-1">
                   <div className="d-flex flex-between">
                       <strong className="txt-black">Bank Transfer (India)</strong>
                       {/* <div onClick={()=> removePaymentMode('BankTransfer')}><i className="fa fa-close cursor-pointer"></i></div> */}
                       </div>
                       <label className="mt-3"><span className="txt-black font-w-600">Name: </span><small>{data.paymentMethods.BankTransfer.userName}</small></label><br />
                           <label><span className="txt-black font-w-600">Account No: </span><small>{data.paymentMethods.BankTransfer.accountNo}</small></label><br />
                           <label><span className="txt-black font-w-600">IFSC Code: </span><small>{data.paymentMethods.BankTransfer.ifsc}</small></label><br />
                           <label><span className="txt-black font-w-600">Bank Name: </span><small>{data.paymentMethods.BankTransfer.bankName}</small></label><br />
                           <label><span className="txt-black font-w-600">Account Type: </span><small>{data.paymentMethods.BankTransfer.accountType}</small></label><br />
                           <label><span className="txt-black font-w-600">Branch: </span><small>{data.paymentMethods.BankTransfer.branchName}</small></label>
                 </div>}
                 </div>
                 :
                 <div className="d-flex">
                 {Object.keys(data.paymentMethods || {}).map((key, i) => (
                    <>
                    {!!data.paymentMethods[key] && <div
                      className={`ml-2 badges ${
                        i === 0
                          ? "badges-yellow"
                          : i === 1
                          ? "badges-green"
                          : "badges-blue"
                      }`}
                    >
                      {" "}
                      { key}
                    </div>}
                </>
                 
                 ))}
               </div>
                }
              </td>
              <td className='font-16 txt-black font-w-600'>{data ? moment(data?.createdOn).format('DD/MM/YYYY, hh:mm a'):''}</td>
              <td>
               { selected === data._id ?  <div>
                    <div className="form-input">
                        <div className='label'>I want to pay</div>
                        <div className='d-flex flex-between mt-3'>
                            <input type="number" placeholder='0.00000' value={amounts.amount0} onChange={(e)=> amountChangeHandler(data, e.target.value, 'from')} />
                            <div className='d-flex flex-end ml-2'>
                            <div className='all'>All</div>
                            <div className='d-flex flex-start ml-2'>
                            <img
                              src={
                                "/coinicon/" +
                                quoteAsset +
                                ".png"
                              }
                              width="14px"
                              height="14px"
                              alt=""
                              className='coin-icon'
                            />
                            <div className='ml-2 coin-name'>{quoteAsset}</div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-input mt-4">
                        <div className='label'>I will receive</div>
                        <div className='d-flex flex-between mt-3'>
                            <input type="number" placeholder='0.00' value={amounts.amount1} onChange={(e)=> amountChangeHandler(data, e.target.value, 'to')}/>
                            <div className='d-flex flex-end ml-2'>
                            <div className='d-flex flex-start ml-2'>
                            <img
                              src={
                                "/coinicon/" +
                                baseAsset +
                                ".png"
                              }
                              width="14px"
                              height="14px"
                              alt=""
                              className='coin-icon'
                            />
                            <div className='ml-2 coin-name'>{baseAsset}</div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-center mt-4'>
                        <button type='button' className='btn ' onClick={()=> setSelected('')}>Cancel</button>
                        <button type='button' className='bg-green-w-5 btn txt-white w-100' onClick={()=> submit(data)}>Buy {baseAsset}</button>
                    </div>
                    
                </div>:
                <button className='bg-green-w-5 btn txt-white' onClick={()=>{setSelected(data._id);setAmounts((amt)=>({amount1: data.amount, amount0: data.amount * data.price}))}}>Buy {baseAsset}</button>
                }
              </td>
            </tr>

            </>))
            }
          </tbody>
        </table>
  )
}

export default Buy
import React, { useEffect, useState } from "react";
import axios from "./httpInterceptor";
import { BASE_URL, IMAGE_START_URL } from "../../config/constant";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../React-Loader/loader";

function AddEditBankDetails() {
  const [passbookLoading, setPassbookLoading] = useState(false);
  const [passbookImg, setPassbookImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [detailsUpdated, setDetailsUpdated]= useState(false);
  const [errors, setErrors] = useState({
    bankname: "Bank name is required",
    acHolderName: "Acoount holder name is required",
    panno:"PAN is required",
    acno:"Account number is required",
    ifsc:"IFSC is required",
    passbook:"Passbook is required",
  });
  const [formData, setFormData] = useState({
    bankname: "",
    acHolderName:"",
    panno: "",
    acno: "",
    ifsc: "",
    passbook: "",
  });

  useEffect(() => {
    setLoading(true)
    let userId = localStorage.getItem("userDetails");
    axios
    .get(BASE_URL + "get/bankdetails/"+ userId)
    .then((res) => {
      setLoading(false)
      setDetailsUpdated(res.data.data?.bankname ? true: false)
      setPassbookImg(res.data.data?.passbook ?(IMAGE_START_URL+"/" +res.data.data?.passbook?.split('kycImages\\')[1]) : "")
        setFormData({
            bankname: res.data.data?.bankname || "",
            acHolderName: res.data.data?.acHolderName || "",
            panno: res.data.data?.panno|| "",
            acno: res.data.data?.acno|| "",
            ifsc: res.data.data?.ifsc|| "",
            passbook: res.data.data?.passbook || "",
        })
        setErrors({
          bankname: !(res.data.data?.bankname) ? "Bank name is required" :"",
          acHolderName: !(res.data.data?.acHolderName) ? "Account holder name is required" :"",
          panno: !(res.data.data?.panno) ? "PAN is required" :"",
          acno: !(res.data.data?.acno) ? "Account number is required":"",
          ifsc: !(res.data.data?.ifsc) ? "IFSC is required":"",
          passbook: !(res.data.data?.passbook) ? "Passbook is required" :"",
        })
    })
    .catch((err) => {
      console.log(err);
      setLoading(false)
      toast.error(err?.message || "faild to get bank details.");
    });
  }, [])
  
  const handleChange = (eve, name) => {
    const data={...formData, [name]: eve?.target?.value}
    setFormData(data);
    const errText = eve?.target?.value?.length ? "" : name + " is required";
    const error ={...errors, [name]: errText}
    setErrors(error);
    console.log(errors, errText);
  };

  // useEffect(() => {
  //   console.log(formData, errors);
    
  // }, [formData])
  
  const handleUploadDoc = (event, name) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const formDataFront = new FormData();
    setPassbookLoading(true);
    formDataFront.append("file", event.target.files[0]);
    let passbook1 = event.target.files[0];
    axios
      .post(BASE_URL + `kyc/upload`, formDataFront, config)
      .then(async (response) => {
        let img1 = await getBase64(passbook1);
        setPassbookImg(img1);
        setFormData((data) => ({ ...data, [name]: response.data.data.path }));
        setPassbookLoading(false);
        setErrors((error) => ({ ...error, [name]: "" }));
      })
      .catch((err) => {
        setPassbookLoading(false);
        setErrors((error) => ({
          ...error,
          [name]: err?.message || "error in passbook upload",
        }));
      });
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleSaveDetails = () => {
    let userId = localStorage.getItem("userDetails");
   let err = "";
   Object.keys(errors).forEach(k =>{
    if (errors[k].length >0) {
      err =errors[k]
      return;
    }
   })
   if (!err) {
    setLoading(true)
    axios
      .post(BASE_URL + "add/bankdetails", {...formData, userId: userId})
      .then((res) => {
        // resetForm();
        toast.success("bank details saved.");
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
        toast.error(err?.message || "faild to save bank details.");
      });
    }else{
      toast.error(err)
    }
  };

  return (
    <>
    {(loading || passbookLoading) && <Loader/>}
      <ToastContainer />
      <div className="clearfix">
        <div className="form-group col-sm-6">
          <label style={{ color: "#aaa", fontSize: "12px" }}>BANK NAME *</label>
          <input
            type="text"
            name="bankname"
            className="form-control"
            placeholder=""
            value={formData.bankname}
            onChange={(e) => handleChange(e, "bankname")}
          />
          {errors.bankname.length > 0 && (
            <span className="formerror">{errors.bankname}</span>
          )}
        </div>
        <div className="form-group col-sm-6">
          <label style={{ color: "#aaa", fontSize: "12px" }}>ACCOUNT HOLDER NAME *</label>
          <input
            type="text"
            name="acHolderName"
            className="form-control"
            placeholder=""
            value={formData.acHolderName}
            onChange={(e) => handleChange(e, "acHolderName")}
          />
          {errors.acHolderName.length > 0 && (
            <span className="formerror">{errors.acHolderName}</span>
          )}
        </div>
        <div className="form-group col-sm-6">
          <label style={{ color: "#aaa", fontSize: "12px" }}>PAN </label>
          <input
            type="text"
            name="panno"
            className="form-control"
            placeholder=""
            value={formData.panno}
            onChange={(e) => handleChange(e, "panno")}
          />
          {errors.panno.length > 0 && (
            <span className="formerror">{errors.panno}</span>
          )}
        </div>

        <div className="form-group col-sm-6">
          <label style={{ color: "#aaa", fontSize: "12px" }}>
            ACCOUNT NUMBER *
          </label>
          <input
            type="text"
            name="acno"
            className="form-control"
            placeholder=""
            value={formData.acno}
            onChange={(e) => handleChange(e, "acno")}
          />
          {errors.acno.length > 0 && (
            <span className="formerror">{errors.acno}</span>
          )}
        </div>

        <div className="form-group col-sm-6">
          <label style={{ color: "#aaa", fontSize: "12px" }}>IFSC *</label>
          <input
            type="text"
            name="ifsc"
            className="form-control"
            placeholder=""
            value={formData.ifsc}
            onChange={(e) => handleChange(e, "ifsc")}
          />
          {errors.ifsc.length > 0 && (
            <span className="formerror">{errors.ifsc}</span>
          )}
        </div>

        {/* <div className="form-group col-sm-6">
        <label style={{ color: "#aaa", fontSize: "12px" }}>IBAN </label>
        <input type="text" name="iban" className="form-control" placeholder="" onChange={(e)=>handleChange(e)} />
        {errors.iban.length > 0 &&
            <span className='formerror'>{errors.iban}</span>}
    </div> */}

        {/* <div className="form-group col-sm-6">
        <label style={{ color: "#aaa", fontSize: "12px" }}>SWIFT CODE </label>
        <input type="text" name="swiftCode" className="form-control" placeholder="" onChange={(e)=>handleChange(e)} />
        {errors.swiftCode.length > 0 &&
            <span className='formerror'>{errors.swiftCode}</span>}
    </div> */}

        <div className="form-group col-sm-6">
          <label style={{ color: "#aaa", fontSize: "12px" }}>
            CANCELLED CHEQUE OR PASSBOOK *
          </label>

          <div>
            {passbookLoading == true && <span>Uploading...</span>}
            {passbookImg && <img src={passbookImg}></img>}
            {/* {formData.passbook && !passbookImg&& <img src={IMAGE_START_URL+"/"+formData.passbook}></img>} */}
          </div>
          <div
            style={{
              border: "1px #ddd dashed",
              overflow: "auto",
              background: "#fff",
              padding: "10px 0px",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <div
              className="col-sm-8"
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                color: "orange",
                fontWeight: "500",
                cursor:"pointer"
              }}
            >
              Upload your Cancelled cheque or passbook photo
            </div>
            <div className="col-sm-4">
              <i
                className="fa fa-cloud-upload"
                aria-hidden="true"
                style={{ color: "#ddd", fontSize: "45px", marginTop: "10px" }}
              ></i>
            </div>
            <input
              type="file"
              id="file"
              name="passbook"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={(e) => handleUploadDoc(e, "passbook")}
              style={{ position: "absolute", height: 64, opacity: 0,cursor:"pointer" }}
            />
          </div>
          <p style={{ fontSize: "12px", color: "#aaa" }}>
            ( Size should be 400kB.)
          </p>
          {errors.passbook.length > 0 && (
            <span className="formerror">{errors.passbook}</span>
          )}
        </div>
      </div>
        <div style={{textAlign:"right"}}>
           <button className="orange_btn" onClick={() => handleSaveDetails()}>
            {detailsUpdated ? "Update" :"Save"}
            </button>
        </div>
    </>
  );
}

export default AddEditBankDetails;

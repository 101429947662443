import React from "react";
import { Bars }  from "react-loader-spinner";
const Loader = () => {
 
    return (

        <div className="loader_parent">
        
{Bars && <Bars
  height="80"
  width="80"
  color="#eba83a"
  ariaLabel="bars-loading"
  wrapperStyle={{}}
  wrapperClass="loader_child"
  visible={true}
  size={10}
/>}
  </div>
 
    );
}
export default Loader;
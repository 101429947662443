import React, { Component } from "react";
import { Button } from "reactstrap";

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../config/constant";
import QRCode from "qrcode.react";
import moment from "moment";
import TextEllipsis from "react-text-ellipsis";
import speakeasy from "@levminer/speakeasy";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  render() {
    document.title = "Contact Us";
    document.body.classList.add("faded_bg");

    return (
      <>
        <Header loadFees={this.state.loadFeeSubs} />
        <section id="inner_top_part" class="fee_head">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 text-center">
                <h2> Contact Us</h2>
              </div>
            </div>
          </div>
        </section>
        <section id="middle_content">
    <div class="container">
	<div class="row">
	<div class="col-md-12">
	<div class="white_bx clearfix">
                <h3>Contact Us</h3>
                <hr></hr>

                <div style={{ textAlign: "justify" }}>
                {/* <p>&nbsp;</p> */}
                  <p>
                  At E-Canna Buy, we believe that everyone must have the ultimate freedom to invest, trade
and earn huge profits along with endless possibilities without many complications. That’s
exactly why E-Canna Buy was formed in June 2021. We provide customers with the
opportunity to increase their savings digitally and make crypto trading accessible in a simple
way. E-Canna Buy is not one but rather a collection of all under one roof. It consists of E
canna digital coins, E-Canna e-commerce store & E-Canna digital exchange. Customers can
buy E-Canna coins and use them to gain profits or trade them to buy branded products and
even more. Through E-Canna digital exchange, we aim to provide a dynamic and secure
environment where customers can conduct instantaneous trading. What we truly aim is to
make customers aware of E-Canna coin’s endless capabilities and use them to achieve
financial growth in the long run. From our inception, the only thing that has kept us moving is
the customers’ trust and support and we will never fail on that.
</p>         
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default ContactUs;

import React from 'react';

const customerServiceSection=()=>{
    return(
      <section id="service">
      <div class="container">
      <div class="row">
       <div class="heading col-12 text-center">
      <h2>Services We <span>Provide</span></h2>	
      </div>
      </div>
        <div class="row">
          <div class="col-md-4 col-sm-6 service_info text-center">
            <div class="white_bg clearfix"> <img src="images/security.png" alt="img" />
              <h3>Best in class security</h3>
              <p>We’ve left no stone unturned to make E-Canna World’s Most Secure Exchange.</p> <br></br> <br></br>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 service_info text-center">
            <div class="white_bg clearfix"> <img src="images/security.png" alt="img" />
              <h3>Super Fast KYC</h3>
              <p>Top of the line identity verification systems process your KYC within a few hours of signing up while ensuring the right KYC protocol.</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 service_info text-center">
            <div class="white_bg clearfix"> <img src="images/security.png" alt="img" />
              <h3>Speed Transactions</h3>
              <p>E-Canna can handle Millions of transactions. Our system infrastructure can scale up in a few seconds to match surging demand.</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 service_info text-center">
            <div class="white_bg clearfix"> <img src="images/security.png" alt="img" />
              <h3>Across 5 Platform</h3>
              <p>E-Canna offers a seamless and powerful trading experience across all platforms - on Web, Android & iOS mobile, Windows, and Mac apps.</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 service_info text-center">
            <div class="white_bg clearfix"> <img src="images/security.png" alt="img" />
              <h3>Simple & Efficient Design</h3>
              <p>Trading on the E-Canna platform is a super fast experience you’ll fall in love with!</p> <br></br> <br></br>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 service_info text-center">
            <div class="white_bg clearfix"> <img src="images/security.png" alt="img" />
              <h3>Built by blockchain believers</h3>
              <p>We’re a team of hardcore traders & passionate blockchain believers. We understand exactly what you need.</p> <br></br>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}

export default customerServiceSection
import React, { useEffect, useMemo, useState } from 'react'
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { Link, useLocation, useParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
// import axios from 'axios';
import axios from '../../Pages/Dashboard/httpInterceptor';
import { BASE_URL } from "../../config/constant";
import Pagination from 'react-js-pagination';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';

export default function FeesDetails() {
    let userId = localStorage.getItem('userDetails')
    
    const [loading, setLoading] = useState(true);
    const [feesList, setfeesList] = useState([])
    const [activePage, setActivePage] = useState(1);
    const [tempArray, setTempArray] = useState([])
  
    useEffect(() => {
        getFeeList()
    }, [])
    const getFeeList = (dr = '') => {

        axios.get(BASE_URL + `ercToken/get/all`)
            .then(res => {
                // console.log(res.data.data);
                if (res.data.message == "success") {
                    setfeesList(res.data.data.slice(0,10))
                    setTempArray(res.data.data);
                }
                if (res.data.message == "walletDoesNotExist") {
                    // console.log("Wallet not found");
                }
                setLoading(false)
            })
    }
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        const s = ((pageNumber-1) * 10);
        const e =  (pageNumber)*10
        const newD = tempArray.slice(s, e)
        setfeesList(newD);
    }

    return (
        <div>
            <Header loadFees={false} />
             <section className="fees_part">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-2 col-sm-3 area_left">
                            <Sidebar loadFees={false} />
                        </div> */}
                        <div className="col-md-12 col-sm-12">
       
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Coin</th>
                                            <th>Transaction Fee</th>
                                            <th>Deposit Fee</th>
                                            <th>Withdraw Fee</th>
                                        </tr>
                                    </thead>

                                    {loading ?
                                        <tbody>
                                            <tr>
                                                <td colSpan="6" height="350px">
                                                    <div className="loader">Loading...</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        :
                                        <>
                                        { feesList ?
                                                <tbody>
                                                    {feesList.map((d, xid) => (
                                                        <tr key={xid}>
                                                            <td>{d.tokenSymbol}</td>
                                                            <td>{d.tfees}</td>
                                                            <td>{d.dfees}</td>
                                                            <td>{d.wfees}</td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                                 :<>
                                               <tbody>
                                                    <tr>
                                                        <td colspan="5" align="center" className="no_data"><i className="fa fa-file-o"></i> No record found</td>
                                                    </tr>
                                                </tbody>:null
                                                </>
                                            }
                                   
                                        </>
                                    }

                                </table>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px', float: 'right' }}>
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={tempArray.length}
                                pageRangeDisplayed={5}
                                onChange={(e) => handlePageChange(e)}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

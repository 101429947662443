import React, { Component } from 'react';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import TopDetails from './TopDetails'
import BlogDetailsMain from './BlogDetailsMain'




class News extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        return ( 
         <>
         <Header />
         <TopDetails />
         <BlogDetailsMain />
         <Footer />
         </>
         );
    }
}
 
export default News;
import React, { Component } from 'react';

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    componentDidMount() {
        localStorage.clear();

        // localStorage.setItem('userDetails','')
        this.props.history.push('/');
    }
    
    render() { 
        return ( 
            <div></div>
         );
    }
}
 
export default Logout;
import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
// import axios from 'axios';
import axios from './httpInterceptor';
import { BASE_URL } from "../../config/constant";
import QRCode from "qrcode.react";
import moment from "moment";
import Pagination from "react-js-pagination";
import speakeasy from "@levminer/speakeasy";
import Loader from '../../React-Loader/loader';
var page = 1;
var id;
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}
class PersonalDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            loginHistory: [],
            loadFeeSubs: false,
            FESSstakeamt: 0,
            oldPassword: '',
            newPassword: '',
            mainError: null,
            userInfo: [],
            bankDetails: {},
            loading: false,
            count: 0,
            userDetails: true,
            enableTwoFA: false,
            disableTwoFA: false,
            activePage: 1,
            newTwoFACode: "",
            twofa: "",
            errors: {
                // oldpassword: '',
                // newpassword: '',
                // cnfpassword: '',
                twofa: '',
            }
        };
    }



    getUserInfo = async () => {
        let userId = localStorage.getItem('userDetails')
        this.setState({loading : true})
        await axios.post(BASE_URL + `userInformation/get`, {
            "id": userId
        })
            .then(res => {
                this.setState({loading : false})
                console.log(res);
                if (res.data.message === "success") {
                    this.setState({
                        userData: res.data.data,
                        newTwoFACode: res.data.data.secret,
                        bankDetails : res.data.data.bankDetail?res.data.data.bankDetail:{}
                    })
                }

                this.setState({ loading: false })

            }).catch((err)=>{
                this.setState({loading : false})
            })
    }

    verifyTwoFA = async (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({ mainError: null })

        if (this.state.twofa === null) {
            errors.twofa = 'Please enter valid 2FA code';
        }
        if (this.state.twofa.length === 0) {
            errors.twofa = 'Please enter valid 2FA code';
        }

        this.setState({ errors });

        if (validateForm(this.state.errors)) {

            var verified = speakeasy.totp.verify({
                secret: this.state.newTwoFACode,
                encoding: 'base32',
                token: this.state.twofa
            });

            if (verified === true) {

                let data
                if (this.state.userData.two_factor === 0) {
                    data = {
                        "id": this.state.userId,
                        "secret": this.state.newTwoFACode,
                        "two_factor": 1,
                    }
                } else {
                    data = {
                        "id": this.state.userId,
                        "secret": "",
                        "two_factor": 0,
                    }
                }


                axios.post(BASE_URL + `userInformation/key/update`, data)
                    .then(res => {

                        this.getUserInfo()

                        this.setState({ twofa: "" })

                        if (this.state.userData.two_factor === 0) {
                            this.setState({ mainError: "success" });
                        } else {
                            this.setState({ mainError: "error" });
                        }

                        this.setState({
                            enableTwoFA: false,
                            disableTwoFA: false,
                            userDetails: true
                        })

                    })



            } else {
                this.setState({ twofa: "" })
                errors.twofa = 'Please enter valid 2FA code';
                this.setState({ errors });
            }

        }
        // console.log("verify",verified)
    }


    enableTwoFA = async () => {
        this.setState({ loading: true })
        var secret = speakeasy.generateSecret({ length: 10 });
        this.setState({ newTwoFACode: secret.base32 })
        this.setState({
            enableTwoFA: true,
            userDetails: false
        })
        this.setState({ loading: false })
    }

    disableTwoFA = async () => {
        this.setState({
            disableTwoFA: true,
            userDetails: false
        })
    }

    async componentDidMount() {
        if (!localStorage.getItem('userDetails')) {
            this.props.history.push('/login')
        } else {
        let userId = localStorage.getItem('userDetails')

            this.setState({ userId: localStorage.getItem('userDetails') })

            this.getUserInfo()
            this.setState({loading : true})
            await axios.post(BASE_URL + `kyc/getstatus`, {
                "id": userId
            })
                .then(res => {
                    this.setState({loading : false})
                    if (res.data.message == "success") {

                        // res.data.data.map(row => {
                            if(res.data.data.kyc == "Approved") {
                                this.setState({
                                    // bankDetails: this.state.bankData,
                                })
                            }
                        // })
                        
                    }
                    // console.log(this.state.bankDetails)
                    // this.setState({ loading: false })
    
                })

            await axios.get(BASE_URL + `ercToken/get/all`)
                .then(res => {
                    // console.log(res);
                    // console.log(res.data.data);
                    if (res.data.message === "success") {

                        res.data.data.map(row => {
                            if (row.tokenSymbol === "FESS") {
                                this.setState({ FESSstakeamt: row.minstake })
                            }
                        })

                    }
                })


            // await axios.post(BASE_URL + `loginhistory/getall`, {
            //     "userId": this.state.userId
            // })
            //     .then(res => {
            //         // console.log("user id",this.state.userId)
            //         // console.log(res);
            //         if (res.data.message === "success") {
            //             this.setState({ loginHistory: res.data.data })

            //             // console.log(res.data.data)

            //         }
            //     })

            await axios.post(BASE_URL + `userBalance/get`, {
                "userId": this.state.userId
            })
                .then(res => {
                    // console.log("user id",this.state.userId)
                    // console.log(res);
                    if (res.data.message === "success") {
                        if (Number(res.data.data['FESS']) >= Number(this.state.FESSstakeamt)) {
                            this.setState({ loadFeeSubs: true })
                        }

                        if (Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                            this.setState({ loadFeeSubs: true })
                        }

                    }
                })
            
                // await axios.post(BASE_URL + `loginhistory/count`, {
                //     "userId": this.state.userId
                // })
                //     .then(res => {
                //         this.setState({count: res.data.data})
                //     })
                
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({ error: null })
        this.setState({ errorSuccess: null })
        switch (name) {
            // case 'oldpassword':
            //     errors.oldpassword =
            //         value.length < 8
            //             ? 'Old Password must be 8 characters long!'
            //             : '';
            //     break;
            // case 'newpassword':
            //     errors.newpassword =
            //         value.length < 8
            //             ? 'New Password must be 8 characters long!'
            //             : '';
            //     break;
            // case 'cnfpassword':
            //     errors.cnfpassword =
            //         value.length < 8
            //             ? 'Confirm Password must be 8 characters long!'
            //             :
            //             this.state.newpassword !== value
            //                 ? 'New Password & Confirm Password should match!'
            //                 : '';
            //     break;
            case 'twofa':
                errors.twofa =
                    value.length < 6
                        ? 'Please enter valid 2FA code'
                        : '';
                break;


            default:
                break;
        }

        this.setState({ errors, [name]: value });
    }


    // handleSubmit = async (event) => {
    //     event.preventDefault();
    //     const { name, value } = event.target;
    //     let errors = this.state.errors;
    //     this.setState({ error: null })
    //     this.setState({ errorSuccess: null })

    //     if (this.state.oldpassword === null) {
    //         errors.oldpassword = 'Old Password must be 8 characters long!';
    //     }
    //     if (this.state.newpassword === null) {
    //         errors.newpassword = 'New Password must be 8 characters long!';
    //     }
    //     if (this.state.cnfpassword === null) {
    //         errors.cnfpassword = 'Confirm Password must be 8 characters long!';
    //     }
    //     if (this.state.newpassword !== this.state.cnfpassword) {
    //         errors.cnfpassword = 'New Password & Confirm Password should match!';
    //     }

    //     this.setState({ errors });

    //     let data = {
    //         "id": id,
    //         "oldPassword": this.state.oldpassword,
    //         "newPassword": this.state.newpassword
    //     }


    //     if (validateForm(this.state.errors)) {
    //         await axios.post(BASE_URL + `changePassword`, data)
    //             .then(res => {
    //                 // console.log(res.data);
    //                 if (res.data.message === "updatedSuccessfully") {
    //                     this.setState({ error: null })
    //                     this.setState({ errorSuccess: 'Password updated successfully' })
    //                     //   alert("Updated!")
    //                 }
    //                 if (res.data.message === "passwordDoesNotMatch") {
    //                     // console.log("Id does not exist!");
    //                     this.setState({ error: 'Old Password does not match' })
    //                     //   alert("Password does not match")
    //                 }
    //             })
    //     }

    // }

    
   async handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        page = pageNumber
        await axios.post(BASE_URL + `loginhistory/getall`, {
            "userId": this.state.userId,
            "pageNumber" : 1
        })
            .then(res => {
                // console.log("user id",this.state.userId)
                // console.log(res);
                if (res.data.message === "success") {
                    this.setState({ loginHistory: res.data.data })

                    // console.log(res.data.data)

                }
            })
    }

    render() {
        const { errors } = this.state;
        document.title = "Personal Details";
        document.body.classList.add('faded_bg');

        return (
            <div>
                <Header loadFees={this.state.loadFeeSubs} />
                {this.state.loading ? <Loader /> : <></>}

                <section id="middel_area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 col-sm-3 area_left">
                                <Sidebar loadFees={this.state.loadFeeSubs} />
                            </div>

                            <div className="col-md-10 col-sm-9 area_right">
                                <div className='row'>
                                    <div className='col-md-6'>
                                    <div className="white_bx clearfix">
                                    <h6>Personal Details</h6>

                                    {this.state.loading === true ?
                                        <div style={{ height: "250px" }}>
                                            <div className="loader">Loading...</div>
                                        </div>

                                        :

                                        <>
                                            {this.state.mainError === "success" &&
                                                <div className="alert alert-success text-center">Two-Factor Authentication (2FA) Activated</div>
                                            }
                                            {this.state.mainError === "error" &&
                                                <div className="alert alert-info text-center">Two-Factor Authentication (2FA) Deactivated</div>
                                            }

                                            {this.state.enableTwoFA === true &&
                                                <>
                                                    <br></br>
                                                    <div className="text-center">
                                                        <h4 style={{ fontWeight: "300" }}>Activate Two-Factor Authentication (2FA)</h4>
                                                        <QRCode value={"otpauth://totp/" + this.state.userData.emailId + "?secret=" + this.state.newTwoFACode + "&issuer= E-Canna"} />
                                                        <br></br>
                                                        <h4 style={{ margin: "10px" }}>{this.state.newTwoFACode}</h4>
                                                        <div className="clearfix"></div>
                                                        <span style={{ color: "red" }}>Please save this authentication code for future use</span>

                                                        <div className="col-md-6 col-md-offset-3">
                                                            <input type="number" style={{ margin: "20px 0px 0px 0px", textAlign: "center" }} className="form-control" name="twofa" onChange={this.handleChange} value={this.state.twofa} placeholder="Enter Authentication Code" noValidate />
                                                            {errors.twofa.length > 0 &&
                                                                <span className='formerror'>{errors.twofa}</span>}
                                                            <div className="clearfix"></div>
                                                            <button className="btn btn-success" style={{ margin: "20px 0px" }} onClick={this.verifyTwoFA} >Verify Code</button>
                                                        </div>
                                                        <div className="col-md-12" style={{textAlign:'justify', marginTop: '20px'}}>
                                            <div className="clearfix"></div>
                                                <h6 style={{borderTop: '1px solid #eee', paddingTop: '20px'}}>2FA code don't work? Try below solution</h6>
                                                <p>It may be because the time isn't correctly synced on your Google Authenticator app.</p> <br/>
                                                <p>To set the correct time:</p>
                                                <p> 1. On your android device, go to the main menu of the Google Authenticator app.</p>
                                                <p> 2. <strong>Tap More &gt; Settings &gt; Time correction for codes &gt; Sync now </strong></p>
                                                <p>On the next screen, the app confirms the time has been synced. You should be able to sign in. The sync will only affect the internal time of your Google Authenticator app, and will not change your device's Date and Time settings.</p>
                                            </div>
                                                        <div className="clearfix"></div>
                                                        {/* <hr></hr> */}
                                                    </div>

                                                </>
                                            }

                                            {this.state.disableTwoFA === true &&
                                                <>
                                                    <br></br>
                                                    <div className="text-center">
                                                        <h4 style={{ fontWeight: "300" }}>Deactivate Two-Factor Authentication (2FA)</h4>

                                                        <div className="col-md-6 col-md-offset-3">
                                                            <input type="number" style={{ margin: "20px 0px 0px 0px", textAlign: "center" }} className="form-control" name="twofa" onChange={this.handleChange} value={this.state.twofa} placeholder="Enter Authentication Code" noValidate />
                                                            {errors.twofa.length > 0 &&
                                                                <span className='formerror'>{errors.twofa}</span>}
                                                            <div className="clearfix"></div>
                                                            <button className="btn btn-success" style={{ margin: "20px 0px" }} onClick={this.verifyTwoFA} >Verify Code</button>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                        <hr></hr>

                                                    </div>


                                                </>
                                            }

                                            {this.state.userDetails === true &&

                                                <div className="row">
                                                    <div className="form-group col-xs-12 full">
                                                        <label style={{ color: "#aaa" }}>Name</label>
                                                        <br></br>
                                                        <span style={{ fontSize: "17px"}}>{this.state.userData.userName}</span>
                                                    </div>
                                                    <div className="form-group col-xs-12 full">
                                                        <label style={{ color: "#aaa" }}>Email Id</label>
                                                        <br></br>
                                                        <span style={{ fontSize: "17px"}}>{this.state.userData.emailId}</span>
                                                    </div>
                                                    {/* <div className="form-group col-xs-6 full">
                                                        <label style={{ color: "#aaa" }}>Phone no.</label>
                                                        <br></br>
                                                        <span style={{ fontSize: "20px", fontWeight: "300" }}>{this.state.userData.phoneNumber}</span>
                                                    </div> */}
                                                    <div className="form-group col-xs-12 full">
                                                        <label style={{ color: "#aaa" }}>Country</label>
                                                        <br></br>
                                                        <span style={{ fontSize: "17px"}}>{this.state.userData.country}</span>
                                                    </div>
                                                    <div className="form-group col-xs-12 full">
                                                        <label style={{ color: "#aaa" }}>Phone Number</label>
                                                        <br></br>
                                                        <span style={{ fontSize: "17px"}}>{this.state.userData.phoneNumber}</span>
                                                    </div>
                                                    <div className="form-group col-xs-12 full">
                                                        <label style={{ color: "#aaa" }}>Two-Factor Authentication (2FA) Status</label>
                                                        <br></br>
                                                        <span style={{ fontSize: "17px"}}>
                                                            {this.state.userData.two_factor === 0 ?
                                                                <>
                                                                    <span>Disabled </span>
                                                                    <button className="btn btn-success" style={{ marginLeft: "20px" }} onClick={this.enableTwoFA}>Enable 2FA</button>
                                                                </>
                                                                :
                                                                <>
                                                                    <span>Enabled </span>
                                                                    <button className="btn btn-danger" style={{ marginLeft: "20px" }} onClick={this.disableTwoFA}>Disable 2FA</button>
                                                                </>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>

                                            }

                                            {/* <div className="clearfix change_pass" style={{ marginTop: "40px" }}>
                                                <h6>Change Password</h6>
                                                <form onSubmit={this.handleSubmit} noValidate>
                                                    <div className="row">
                                                        <div className="form-group col-sm-4">
                                                            <label style={{ color: "#aaa" }}>Old Password</label>
                                                            <input type="password" name="oldpassword" placeholder="" onChange={this.handleChange} />
                                                            {errors.oldpassword.length > 0 &&
                                                                <span className='formerror'>{errors.oldpassword}</span>}
                                                            {this.state.error !== null &&
                                                                <span className='formerror'>{this.state.error}</span>}
                                                            {this.state.errorSuccess !== null &&
                                                                <span className='formerror' style={{ color: "green" }}>{this.state.errorSuccess}</span>}
                                                        </div>
                                                        <div className="form-group col-sm-4">
                                                            <label style={{ color: "#aaa" }}>New Password</label>
                                                            <input type="password" name="newpassword" placeholder="" onChange={this.handleChange} />
                                                            {errors.newpassword.length > 0 &&
                                                                <span className='formerror'>{errors.newpassword}</span>}
                                                        </div>
                                                        <div className="form-group col-sm-4">
                                                            <label style={{ color: "#aaa" }}>Confirm Passowrd</label>
                                                            <input type="password" name="cnfpassword" placeholder="" onChange={this.handleChange} />
                                                            {errors.cnfpassword.length > 0 &&
                                                                <span className='formerror'>{errors.cnfpassword}</span>}
                                                        </div>
                                                        <div className="col-md-12 text-right">
                                                            <button type="submit" className="blue_btn">Change Password</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div> */}
                                        </>
                                    }
                                </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="white_bx clearfix">
                                                <h6>Bank Details</h6>
                                              
                                                    <div className="row">
                                                    {this.state.userData.kyc == 1 &&
                                                    <>
                                                    <div className="form-group col-sm-12">
                                                            <label style={{ color: "#aaa" }}>A/C Holder Name</label>
                                                            <input type="text" name="bankname" placeholder="" className="form-control" value={this.state.bankDetails.acHolderName}readOnly />
                                                        </div> 
                                                        <div className="form-group col-sm-12">
                                                            <label style={{ color: "#aaa" }}>Bank Name</label>
                                                            <input type="text" name="bankname" placeholder="" className="form-control" value={this.state.bankDetails.bankName}readOnly />
                                                        </div> 

                                                        
                                                        <div className="form-group col-sm-12">
                                                            <label style={{ color: "#aaa" }}>Account Number</label>
                                                            <input type="text" name="acno" placeholder="" className="form-control" value={this.state.bankDetails.acno} readOnly />
                                                        </div>

                                                        <div className="form-group col-sm-12">
                                                            <label style={{ color: "#aaa" }}>IFSC Code</label>
                                                            <input type="text" name="ifsc" placeholder="" className="form-control" value={this.state.bankDetails.ifsc} readOnly />
                                                        </div>
                                                        <div className="form-group col-sm-12">
                                                        <p>To update bank details and phone number, please contact admin.</p>
                                                        </div>
                                                     </>
                                                    }

                                                        {this.state.userData.kyc !== 1 &&
                                                        <div style={{padding:"50px 0px"}}>
                                                        <center><a href="/kyc" className="orange_btn">Verify KYC</a></center>
                                                        </div>
                                                        }
                                                        

                                                    </div>
                                           
                                            </div>
                                    </div>
                                </div>
                                

                                
                                {/* <div className="white_bx clearfix">
                                    <div className="side_txt">
                                        <h6>Login History</h6>
                                    </div>
                                    <div className="table-responsive recent_login">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Login Device</th>
                                                    <th>IP Address</th>
                                                    <th>Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.loginHistory.map((row, xid) => (
                                                    <tr key={xid}>
                                                        <td>{row.browser}</td>
                                                        <td>{row.ipaddress}</td>
                                                        <td>{moment(new Date(row.timestamp * 1000)).format('MM/DD/YYYY hh:MM A')}</td>
                                                    </tr>
                                                )).reverse()}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ marginTop: '20px', float: 'right' }}>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={this.state.count}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                </div> */}
                            </div>


                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default PersonalDetails;
import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
// import axios from 'axios';
import axios from './httpInterceptor';
import { BASE_URL } from "../../config/constant";
import Notifications, {notify} from 'react-notify-toast';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import BigNumber from 'bignumber.js'
import { PieChart } from '../../Components/pieChart/pieChart';
import { TransferModal } from '../../Components/margin-dashboard/TransferBalance';
import { BorrowModal }  from '../../Components/borrow/borrow';
import { RepayModal }  from '../../Components/repay/repay';
import Loader from '../../React-Loader/loader';
var id;
var tableFields = [];
var fessAmt = 0;
var fessUsdValue = 0;
class CanclOrderMargin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            cancelOrderrows : [],
            loading : false
        };
    }

    async componentDidMount() {
        if(!localStorage.getItem('userDetails')) {
          this.props.history.push('/login')
        } else {
            this.setState({ userId: localStorage.getItem('userDetails') })

        }
               
    }

    async componentWillMount() {
        id = localStorage.getItem('userDetails');
        console.log("User id: ", id);
        
        this.getOpenOrders()
    }



    getOpenOrders = async() => {
        this.setState({loading : true})
        id = localStorage.getItem('userDetails');
        await axios.post(BASE_URL + `marginOrder/get/allorders`, {
            "status": 5,
            "userId": id,
        })
        .then(async (res) => {
            this.setState({ cancelOrderrows: res.data.data , loading : false })
            console.log(this.state)
        })
    }

    render() {
     document.title = "Cancel Order Margin";
        document.body.classList.add('faded_bg');
     return (
            <div>
                <Notifications />
                <Header  loadFees={this.state.loadFeeSubs}/>
                {this.state.loading ? <Loader /> : <></>}
                <section id="middel_area" className='mDashboard'>
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-md-2 col-sm-3 area_left">

                                <Sidebar  loadFees={this.state.loadFeeSubs}/>
                            </div>

                            <div className="col-md-10 col-sm-9 area_right">
                                <div className="clearfix assets_part">
                                    <div className="dashboard_top_info row">
                                        
                                    </div>
                                </div>

                                

                                <div className="white_bx clearfix">
                                    <div className="side_txt">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                            <h6>Cancel Order / Margin 
                                           {/* <Link to={'/borrowHistory'} className='btn btn-primary btn-sm mt_mb_0'>
                                                   Borrow History
                                                   </Link>  */}
                                           </h6>
                                            </div>
                                        </div>
                                            
                                    </div>
                                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                          <th>Coin</th>                            
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Fees</th>
                            <th>Total</th>
                            <th>Limit</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>

                        {this.state.cancelOrderrows.map((row, xid) => (
                                <tr key={xid}>
                                  <td className={row.orderType == "Buy" ? "green_clr" : "red_clr"}>
                                    {row.coinName + row.pair}
                                    </td>
                                  <td className={row.orderType == "Buy" ? "green_clr" : "red_clr"}>{Number(row.price).toFixed(8)} {row.pair}</td>
                                  <td>{row.amount} {row.coinName}</td>
                                  <td>{row.tredingFee} {row.coinName}</td>
                                  <td>{row.total} {row.pair}</td>
                                  <td className={row.orderType == "Buy" ? "green_clr" : "red_clr"}>{row.orderType}</td>
                                  <td>{row.dateTime}</td>
                                </tr>
                              )).reverse()}
                        </tbody>
                      </table>
                      {this.state.cancelOrderrows.length ==0 &&
                            <div className='text-danger text-center'>No Data Found</div>
                        }
                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default CanclOrderMargin;
import React from 'react';

const introductionSection=()=>{
    return(
      <section id="info_section" class="buy_exchange">
  <div class="container">
    <div class="row flex_box">
	<div class="col-md-5 img_part text-center">
	<img src="images/wallet.png" alt="img" />
	</div>
      <div class="col-md-7">
	  <div class="heading">
        <h2><span>E-Canna</span> Buy Exchange</h2>
      </div>
		 <p>At E-Canna Buy, we believe that everyone must have the ultimate freedom to invest, trade
and earn huge profits along with endless possibilities without many complications. That’s
exactly why E-Canna Buy was formed in June 2021. We provide customers with the
opportunity to increase their savings digitally and make crypto trading accessible in a simple
way. E-Canna Buy is not one but rather a collection of all under one roof. It consists of E
canna digital coins, E-Canna e-commerce store & E-Canna digital exchange.</p>
		<br />
		
      </div>
    </div>
  </div>
</section>
    )
}

export default introductionSection
import React, { useEffect, useState } from "react";
import axiosInstance from "../../Dashboard/httpInterceptor";
import { BASE_URL } from "../../../config/constant";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../React-Loader/loader";
import AddPaymentMethods from "../AddPaymentMethods/AddPaymentMethods";

function BuyAdPost({loading, setLoading,usersPaymentsMethods,getUserPaymentMethods,wazirxTickers,ecnaUsdtrate,balances,tokenSymbol,getBalances}) {
    const [baseAsset, setBaseAsset] = useState("USDT");
    const [loader, setLoader] = useState(false)
    const [showFromCoinDropdown, setShowFromCoinDropdown] = useState(false);
    const [showToCoinDropdown, setShowToCoinDropdown] = useState(false);
    const [selectedFromToken, setSelectedFromToken] = useState({});
    const [selectedToToken, setSelectedToToken] = useState({});
    const [tokens, setTokens] = useState([]);
    const [price, setPrice] = useState(0);
    const [amount, setAmount] = useState(0);
    const [paymentModes, setPaymentModes] = useState([])
    const [selectedPaymentModes, setSelectedPaymentModes] = useState({UPI:null, IMPS:null, BankTransfer:null})
  
    const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
    // const [usersPaymentsMethods, setusersPaymentsMethods] = useState();

    const closePaymentMethodModal = ()=>{
          getPaymentModes()
      setShowPaymentMethodModal(false)
      getUserPaymentMethods()
      }
   useEffect(() => {
    if(wazirxTickers&& ecnaUsdtrate && selectedFromToken && selectedFromToken.tokenSymbol && selectedToToken && selectedToToken.tokenSymbol){
      if(selectedFromToken.tokenSymbol === 'ECNA'){
        setPrice(ecnaUsdtrate.price || '')
      }else {
        const price = wazirxTickers.find(wrx => wrx.symbol === selectedFromToken.tokenSymbol.toLowerCase()+selectedToToken?.tokenSymbol.toLowerCase())
       
        setPrice(price?.lastPrice|| '')
      }
    }
   }, [wazirxTickers,ecnaUsdtrate,selectedFromToken,selectedToToken])
   
    useEffect(() => {
      const userId = localStorage.getItem("userDetails");
      if (userId) {
        setLoading(true);
        axiosInstance
          .get(BASE_URL + `ercToken/get/all`)
          .then((res) => {
            setTokens(res.data?.data || []);
            setSelectedFromToken(
              (res.data?.data || []).find((e) => e.tokenSymbol === (tokenSymbol ? tokenSymbol : "USDT"))
            );
            setSelectedToToken(
              (res.data?.data || []).find((e) => e.tokenSymbol === "INR")
            );
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
          getPaymentModes()
          // getUserPaymentMethods(userId)
      }
    }, []);
    // const getUserPaymentMethods = (userId) => {
    //   setLoading(true);
    //   axiosInstance
    //     .get(BASE_URL + `get/userPaymentMethods?userId=${userId}`)
    //     .then((res) => {
    //       const data = (res.data?.data);
    //       setusersPaymentsMethods(data)
    //       setLoading(false);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       setLoading(false);
    //     });
    // };
    const getPaymentModes=()=>{
      axiosInstance
          .get(BASE_URL + `get/paymentMethods`)
          .then((res) => {
              setPaymentModes(res.data?.data || []);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
    }
    const onFromTokenSelect = (data) => {
      setLoading(true);
      setSelectedFromToken(data);
      setShowFromCoinDropdown(false);
      setLoading(false);
    };
    const onToTokenSelect = (data) => {
      setLoading(true);
      setSelectedToToken(data);
      setShowToCoinDropdown(false);
      setLoading(false);
    };
    const onPaymentModeAdd =(mode)=>{
      if (usersPaymentsMethods && usersPaymentsMethods[mode]) {
        setSelectedPaymentModes({...selectedPaymentModes, [mode]: usersPaymentsMethods[mode]})
      }else{
        setShowPaymentMethodModal(true)
      }
    }
    const removePaymentMode =(mode)=>{
      const modes = {...selectedPaymentModes, [mode]: null};
          setSelectedPaymentModes(modes)
    }

    const adPost =()=>{
      const usrstr = localStorage.getItem('ECannaUser')
      const user = usrstr ? JSON.parse(usrstr): null
      if(validate() && user){
        setLoader(true)
        const payload ={
          assetCoin: selectedFromToken?.tokenSymbol,
          fiatCoin: selectedToToken?.tokenSymbol,
          type:'Buy',
          price: price,
          amount: amount,
          total: price * amount,
          paymentMethods: selectedPaymentModes,
          adPostedBy: user.userName,
          userId: user._id,
          emailId: user.emailId

        }
        axiosInstance.post(`${BASE_URL}p2p/ad/post`, payload).then((res)=>{
          setLoader(false)
          if (res.data.statusCode === 200) {
            toast.success(res.data.message)
            resetValues()
          }else if(res.data.statusCode === 400){
            toast.error(res.data.message)

          }
        }).catch((e)=>{
          console.log(e)
          setLoader(false)
        })
      }
    }
  const validate =()=>{
    if(!selectedFromToken?.tokenSymbol){
      toast.error('Please select Asset')
      return false
    }else if(!selectedToToken?.tokenSymbol){
      toast.error('Please select Fiat coin')
      return false
    }else if(!price){
      toast.error('Please enter price')
      return false
    }else if(!amount){
      toast.error('Please enter amount')
      return false
    }else if(!(amount>0)){
      toast.error('Invalid amount')
      return false
    }
    else if(!validatePaymentMethods()){
      toast.error('Please provide atleast one payment method')
      return false
    }
    return true
  }
  const validatePaymentMethods =()=>{
    let valid = false;
    Object.keys(selectedPaymentModes).forEach((pmKey)=>{
      if (selectedPaymentModes[pmKey]) {
        valid = true
      }
    })
    return valid
  }
  const resetValues =()=>{
    setAmount(0);
    setPrice(0);
    setSelectedPaymentModes({UPI:null, IMPS:null, BankTransfer:null})
    getBalances()
  }
  const getBalance =()=>{
    return balances?.p2pBalances && selectedFromToken?.tokenSymbol ? balances?.p2pBalances[selectedFromToken?.tokenSymbol]: 0.00
  }
  return (
    <>
    <ToastContainer />
    <AddPaymentMethods  show={showPaymentMethodModal}
        handleClose={closePaymentMethodModal}/>
   {loading || loader && <Loader/>}
    <div className="row mt-6">
    <div
      className="col-sm-12 col-md-6"
      style={{ borderRight: "1px solid lightgray" }}
    >
      {/* <button className="btn bg-yellow"> <i className="fa fa-add"></i>Payment</button> */}
      <div className="mt-3">
       <div className="d-flex flex-between">
       <label >Asset</label>
        <div className="txt-green font-12">
         Available Balance: {getBalance()} {selectedFromToken?.tokenSymbol || ''}
        </div>
       </div>
        <div
          className="d-flex flex-between bg-off-white p-4 rounded-sm cursor-pointer"
          onClick={() =>
            setShowFromCoinDropdown(!showFromCoinDropdown)
          }
        >
          <div className="d-flex flex-start">
            <div className="mr-2">
              <img
                src={
                  "/coinicon/" +
                  selectedFromToken?.tokenSymbol +
                  ".png"
                }
                width="20px"
                height="20px"
                alt=""
              />
            </div>
            <div className="font-12 font-w-500">
              {selectedFromToken?.tokenSymbol}
              <small className="ml-2">
                {selectedFromToken?.tokenName}
              </small>
            </div>
          </div>
          <div>
            <i className="fa fa-chevron-down txt-light-gray"></i>
          </div>
        </div>
        {showFromCoinDropdown && (
          <div className="bg-light-gray rounded-sm mt-1">
            <ul style={{ maxHeight: 200, overflow: "auto" }}>
              {(tokens || [])
                .filter((e) => !e.isFiat)
                .map((data) => (
                  <li
                    className="divider ml-4 mr-4 py-2 cursor-pinter"
                    onClick={() => onFromTokenSelect(data)}
                  >
                    <div className="d-flex flex-start ">
                      <div className="mr-2">
                        <img
                          src={
                            "/coinicon/" +
                            data.tokenSymbol +
                            ".png"
                          }
                          width="20px"
                          height="20px"
                          alt=""
                        />
                      </div>
                      <div className="font-12 font-w-500">
                        {data.tokenSymbol}
                        <small className="ml-2">
                          {data.tokenName}
                        </small>
                      </div>
                    </div>
                    {/* <div className="divider"></div> */}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
        <div className="d-flex flex-center mt-3"><i className="fa fa-arrow-down txt-light-gray"></i></div>
      <div className="">
      <label >To Fiat</label>
        <div
          className="d-flex flex-between bg-off-white p-4 rounded-sm cursor-pointer"
          onClick={() =>
            setShowToCoinDropdown(!showToCoinDropdown)
          }
        >
          <div className="d-flex flex-start">
            <div className="mr-2">
              <img
                src={
                  "/coinicon/" +
                  selectedToToken?.tokenSymbol +
                  ".png"
                }
                width="20px"
                height="20px"
                alt=""
              />
            </div>
            <div className="font-12 font-w-500">
              {selectedToToken?.tokenSymbol}
              <small className="ml-2">
                {selectedToToken?.tokenName}
              </small>
            </div>
          </div>
          <div>
            <i className="fa fa-chevron-down txt-light-gray"></i>
          </div>
        </div>
        {showToCoinDropdown && (
          <div className="bg-light-gray rounded-sm mt-1">
            <ul style={{ maxHeight: 200, overflow: "auto" }}>
              {(tokens || [])
                .filter((e) => e.isFiat)
                .map((data) => (
                  <li
                    className="divider ml-4 mr-4 py-2 cursor-pinter"
                    onClick={() => onToTokenSelect(data)}
                  >
                    <div className="d-flex flex-start ">
                      <div className="mr-2">
                        <img
                          src={
                            "/coinicon/" +
                            data.tokenSymbol +
                            ".png"
                          }
                          width="20px"
                          height="20px"
                          alt=""
                        />
                      </div>
                      <div className="font-12 font-w-500">
                        {data.tokenSymbol}
                        <small className="ml-2">
                          {data.tokenName}
                        </small>
                      </div>
                    </div>
                    {/* <div className="divider"></div> */}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
      <div className="mt-3">
      <label htmlFor="price">Price</label>
        <input
          type="number"
          placeholder="0.0000"
          name="price"
          id="price"
          className="form-control bg-off-white "
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
      </div>
      <div className="mt-3">
        <label htmlFor="amount">Amount</label>
        <input
          type="number"
          placeholder="0.0000"
          name="amount"
          id="amount"
          className="form-control bg-off-white "
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <div className="mt-3">
      <label htmlFor="amount">Total</label>
      <input
          type="number"
          placeholder="0.0000"
          name="amount"
          id="amount"
          className="form-control bg-off-white "
          value={price * amount}
          readOnly
        />                
      </div>
    </div>
    <div className="col-sm-12 col-md-6">
      <label> Payment Method</label>
      <div></div>
      <div className="">
        <a
          href="#"
          className="dropdown-toggle btn bg-yellow-5 txt-white font-w-600"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-plus"></i>
          {" Add "}
          <span className="caret"> </span>
        </a>
        <ul className="dropdown-menu">
          {paymentModes.map((modes,i) => (
            <li key={'buy-'+i}>
              <a href="javascript:void" onClick={() => onPaymentModeAdd(modes.paymentMethod)}>
                {modes.displayName}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {selectedPaymentModes.UPI &&<div className="pm-bg">
        <div className="d-flex flex-between">
            <strong className="txt-black">UPI</strong>
            <div onClick={()=> removePaymentMode('UPI')}><i className="fa fa-close cursor-pointer"></i></div>
            </div>
                <label className="mt-3"><span className="txt-black font-w-600">Name: </span>{selectedPaymentModes.UPI.userName}</label><br />
                <label><span className="txt-black font-w-600">UPI: </span>{selectedPaymentModes.UPI.upiAddress}</label>
                {selectedPaymentModes.UPI.qr && <div><img src={selectedPaymentModes.UPI.qr} alt="" /></div>}
      </div>}
      {selectedPaymentModes.IMPS &&<div className="pm-bg">
        <div className="d-flex flex-between">
            <strong className="txt-black">IMPS</strong>
            <div onClick={()=> removePaymentMode('IMPS')}><i className="fa fa-close cursor-pointer"></i></div>
            </div>
                <label className="mt-3"><span className="txt-black font-w-600">Name: </span>{selectedPaymentModes.IMPS.userName}</label><br />
                <label><span className="txt-black font-w-600">Account No: </span>{selectedPaymentModes.IMPS.accountNo}</label><br />
                <label><span className="txt-black font-w-600">IFSC Code: </span>{selectedPaymentModes.IMPS.ifsc}</label><br />
                <label><span className="txt-black font-w-600">Bank Name: </span>{selectedPaymentModes.IMPS.bankName}</label><br />
                <label><span className="txt-black font-w-600">Account Type: </span>{selectedPaymentModes.IMPS.accountType}</label><br />
                <label><span className="txt-black font-w-600">Branch: </span>{selectedPaymentModes.IMPS.branchName}</label>
      </div>}

      {selectedPaymentModes.BankTransfer &&<div className="pm-bg">
        <div className="d-flex flex-between">
            <strong className="txt-black">Bank Transfer (India)</strong>
            <div onClick={()=> removePaymentMode('BankTransfer')}><i className="fa fa-close cursor-pointer"></i></div>
            </div>
                <label className="mt-3"><span className="txt-black font-w-600">Name: </span>{selectedPaymentModes.BankTransfer.userName}</label><br />
                <label><span className="txt-black font-w-600">Account No: </span>{selectedPaymentModes.BankTransfer.accountNo}</label><br />
                <label><span className="txt-black font-w-600">IFSC Code: </span>{selectedPaymentModes.BankTransfer.ifsc}</label><br />
                <label><span className="txt-black font-w-600">Bank Name: </span>{selectedPaymentModes.BankTransfer.bankName}</label><br />
                <label><span className="txt-black font-w-600">Account Type: </span>{selectedPaymentModes.BankTransfer.accountType}</label><br />
                <label><span className="txt-black font-w-600">Branch: </span>{selectedPaymentModes.BankTransfer.branchName}</label>
      </div>}
    </div>
    <div className="col-md-12">
      <hr />

      <div className="d-flex flex-end mt-4">
        <button className="bg-green-w-5 btn txt-white" onClick={()=> adPost()}>
          Post Buy Ad
        </button>
      </div>
    </div>
  </div>
    </>
  )
}

export default BuyAdPost
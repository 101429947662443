import React, {useState, useEffect} from 'react';
import '../p2p.scss';

import {ToastContainer, toast} from 'react-toastify'
import Header from '../../../Components/Header/header';
import Loader from '../../../React-Loader/loader';
import Footer from '../../../Components/Footer/footer';
import { Icon } from '@material-ui/core';
import { colorArray } from '../p2pContants';
import TransferModal from '../transfer/transfer.modal';
import Sell from './Sell';
import Buy from './Buy';
import { SubHeader } from './SubHeader';
import { BASE_URL } from '../../../config/constant';
import axiosInstance from '../../Dashboard/httpInterceptor';
import {useHistory} from 'react-router-dom';

function PToPHome() {
  const routeHistory = useHistory()
    const [loading, setLoading] = useState(false)
    const [activeTrade, setActiveTrade] = useState('Buy');
    const [baseAsset, setBaseAsset] = useState('ECNA')
    const [quoteAsset, setQuoteAsset] = useState('INR')
   const [advertiesment, setAdvertiesment] = useState([])
   const [balances, setBalances] = useState({});
    // const advertiesment=[
    //   {
    //     _id:1,
    //     userName: 'Aditya Roy',
    //     emailId: 'adityar@gmail.com',
    //     amount: 100,
    //     price: 12,
    //     assetCoin: 'INR',
    //     fiatCoin:'ECNA',
    //     type: 'Buy',
    //     paymentMode:['IMPS', 'UPI', 'BANK TRANSFER (INDIA)']
    //   },
    //   {
    //     _id:2,
    //     userName: 'Aditya Roy',
    //     emailId: 'adityar@gmail.com',
    //     amount: 100,
    //     price: 12,
    //     assetCoin: 'INR',
    //     fiatCoin: 'ECNA',
    //     type: 'Buy',
    //     paymentMode:['IMPS', 'UPI', 'BANK TRANSFER (INDIA)'],
    //     decimals: 3
    //   },
    //   {
    //     _id:3,
    //     userName: 'Aditya Roy',
    //     emailId: 'adityar@gmail.com',
    //     amount: 100,
    //     price: 12,
    //     assetCoin: 'ECNA',
    //     fiatCoin: 'INR',
    //     type: 'Sell',
    //     paymentMode:['IMPS', 'UPI', 'BANK TRANSFER (INDIA)'],
    //     decimals: 3
    //   },
    //   {
    //     _id:4,
    //     userName: 'Aditya Roy',
    //     emailId: 'adityar@gmail.com',
    //     amount: 100,
    //     price: 12,
    //     assetCoin: 'ECNA',
    //     fiatCoin: 'INR',
    //     type: 'Sell',
    //     paymentMode:['IMPS', 'UPI', 'BANK TRANSFER (INDIA)'],
    //     decimals: 2
    //   }
    // ]

    
    useEffect(() => {
      const userId = localStorage.getItem("userDetails");
      if(userId){
        getAds(userId)
        getBalances(userId)
      }else{
        routeHistory.push('/login')
        localStorage.clear()
      }
    }, [])
    const getAds = (userId)=>{
      setLoading(true)
      axiosInstance.get(`${BASE_URL}get/pendingAds?userId=${userId}`).then((res)=>{
        setAdvertiesment(res?.data?.data || [])
        console.log(res?.data)
        setLoading(false)
      }).catch(e =>{
        setLoading(false)
      })
    }
     const getBalances = (userId) => {
    setLoading(true);
    axiosInstance
      .get(BASE_URL + `get/p2pAndSpotBalances?userId=${userId}`)
      .then((res) => {
        setBalances(res.data?.data || []);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const reloadList =()=>{
    const userId = localStorage.getItem("userDetails");
    getAds(userId)
  }
  return (
    <div>
      <ToastContainer />
      <Header loadFees={false} />
      {loading ? <Loader /> : <></>}

      <SubHeader  />
      <div className="d-flex flex-between px-4 py-2 bg-white">
        <div className="d-flex flex-start">
          <button
            className={`grp-btn-primary ${baseAsset === "ECNA" && "active"}`}
            onClick={() => setBaseAsset("ECNA")}
          >
            ECNA
          </button>
          <button
            className={`grp-btn-primary ${baseAsset === "BTC" && "active"}`}
            onClick={() => setBaseAsset("BTC")}
          >
            BTC
          </button>
          <button
            className={`grp-btn-primary ${baseAsset === "ETH" && "active"}`}
            onClick={() => setBaseAsset("ETH")}
          >
            ETH
          </button>
          <button
            className={`grp-btn-primary ${baseAsset === "USDT" && "active"}`}
            onClick={() => setBaseAsset("USDT")}
          >
            USDT
          </button>
          <button
            className={`grp-btn-primary ${baseAsset === "BNB" && "active"}`}
            onClick={() => setBaseAsset("BNB")}
          >
            BNB
          </button>
        </div>
        <div className="d-flex flex-center">
          <button
            className={`buy-sell-btn buy ${activeTrade === "Buy" && "active"}`}
            onClick={() => setActiveTrade("Buy")}
          >
            Buy
          </button>
          <button
            className={`buy-sell-btn sell ${
              activeTrade === "Sell" && "active"
            }`}
            onClick={() => setActiveTrade("Sell")}
          >
            Sell
          </button>
        </div>
        <div className="d-flex flex-start">
          <button
            className={`grp-btn-sec ${quoteAsset === "INR" && "active"}`}
            onClick={() => setQuoteAsset("INR")}
          >
            INR
          </button>
          <button
            className={`grp-btn-sec ${quoteAsset === "USD" && "active"}`}
            onClick={() => setQuoteAsset("USD")}
          >
            USD
          </button>
          <button
            className={`grp-btn-sec ${quoteAsset === "EUR" && "active"}`}
            onClick={() => setQuoteAsset("EUR")}
          >
            EUR
          </button>
          <button
            className={`grp-btn-sec ${quoteAsset === "AED" && "active"}`}
            onClick={() => setQuoteAsset("AED")}
          >
            AED
          </button>
          <button
            className={`grp-btn-sec ${quoteAsset === "GBP" && "active"}`}
            onClick={() => setQuoteAsset("GBP")}
          >
            GBP
          </button>
        </div>
      </div>
      <div className="content">
        {activeTrade === "Buy" ? (
          <Buy advertiesment={advertiesment} baseAsset={baseAsset} quoteAsset={quoteAsset} balances={balances} reloadList={reloadList} setLoading={setLoading}/>
        ) : (
          <Sell advertiesment={advertiesment} baseAsset={baseAsset} quoteAsset={quoteAsset} balances={balances} reloadList={reloadList} setLoading={setLoading}/>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PToPHome
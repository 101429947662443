import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
// import { BrowserRouter, Link, Redirect } from "react-router-dom";
// import axios from 'axios';
import axios from './httpInterceptor';
import {BASE_URL} from "../../config/constant";
// import speakeasy from "@levminer/speakeasy";
// import QRCode from "qrcode.react";
import moment from "moment"; 

var id ;
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }
class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
        userData : [],
        userBalance : [],
        loadFeeSubs: false,
        FESSstakeamt: 0,
        oldPassword: '',
        newPassword: '',
        mainError: null,
        userInfo:[],
        subsHistory:[],
        feesPacks: [],
        loading: true,
        userDetails: true,
        enableTwoFA: false,
        disableTwoFA: false,
        newTwoFACode:"",
        twofa:"",
        errors: {
          oldpassword: '',
          newpassword: '',
          cnfpassword: '',
          twofa:'',
        }
    };
  }


  
    getUserInfo = async () => {
        let userId = localStorage.getItem('userDetails')
        await axios.post(BASE_URL + `userInformation/get`,{
            "id": userId
        })
        .then(res => {
            // console.log(res);
            if (res.data.message === "success") {
                this.setState({ 
                    userData: res.data.data,
                    newTwoFACode: res.data.data.secret
                })

            }

            // this.setState({loading:false})
            
        })
    }

    getFeesSubs = async () => {
        this.setState({loading: true})
        let userId = localStorage.getItem('userDetails')
        await axios.get(BASE_URL + `ercToken/get/all`)
            .then(res => {
                // console.log(res);
                // console.log(res.data.data);
                if (res.data.message === "success") {

                    res.data.data.map(row => {
                        if(row.tokenSymbol === "FESS") {
                            this.setState({FESSstakeamt: row.minstake})
                        }
                    })
                    
                }
            })

        await axios.post(BASE_URL + `userBalance/get`, {
                "userId": this.state.userId
            })
            .then(res => {
                // console.log("user id",this.state.userId)
                // console.log(res);
                if (res.data.message === "success") {
                    this.setState({userBalance: res.data.data})
                    if(Number(res.data.data['FESS']) >= Number(this.state.FESSstakeamt)) {
                        this.setState({loadFeeSubs:true})
                    }
                    if(Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                        this.setState({loadFeeSubs:true})
                    }
                    
                }
            })
        
        await axios.get(BASE_URL + `feespack/get/all`)
            .then(res => {
                // console.log(res);
                // console.log(res.data.data);
                if (res.data.message === "success") {

                    this.setState({
                        feesPacks: res.data.data,
                        loading: false
                    })
                    
                }
            })
        
        await axios.post(BASE_URL + `feespack/get/history`, {
                "userId": this.state.userId 
            })
            .then(res => {
                // console.log(res);
                // console.log(res.data.data);
                if (res.data.message === "success") {

                    this.setState({
                        subsHistory: res.data.data
                    })
                    
                }
            })
    }



    async componentDidMount() {
        if(!localStorage.getItem('userDetails')) {
        this.props.history.push('/login')
        } else {
            this.setState({ 
                userId: localStorage.getItem('userDetails'),
                loading: true
            })
        
            this.getUserInfo()
            this.getFeesSubs()
           


        }
    }


    subscribeNow(id) {
        this.setState({mainError:""})
        this.state.feesPacks.map(row => {
            if(row._id === id) {
                if(Number(this.state.userBalance['USDT']) >= row.usdtPrice) {

                    let data = {
                        "userId": this.state.userId,
                        "subsId": row._id,
                        "validTill": row.validTimestap,
                        "amount": row.usdtPrice,
                        "title": row.title,
                        "feePerText": row.feePerText,
                        "validText": row.validText,
                        "feePercent": row.feePercent,
                        "validDays": row.validDays,
                    }
                    axios.post(BASE_URL+`feespack/add`,  data )
                    .then(res => {
                        // console.log(res.data);
                        if(res.data.message === "success") {
                            this.setState({mainError:"success"})
                            this.getUserInfo()
                            this.getFeesSubs()
                        }
                        if(res.data.message === "failure") {
                            this.setState({mainError:"error"})
                        }
                    })

                    // console.log("subscribe",id,row.validTimestap)
                } else {
                    this.setState({mainError:"error"})
                }
                
            }
            
        })
    }
  handleSubmit = async(event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({error: null})
        this.setState({errorSuccess: null})
        
        if(this.state.oldpassword === null) {
            errors.oldpassword = 'Old Password must be 8 characters long!';
        }
        if(this.state.newpassword === null) {
            errors.newpassword = 'New Password must be 8 characters long!';
        }
        if(this.state.cnfpassword === null) {
            errors.cnfpassword = 'Confirm Password must be 8 characters long!';
        }
        if(this.state.newpassword !== this.state.cnfpassword) {
            errors.cnfpassword = 'New Password & Confirm Password should match!';
        }

        this.setState({errors});


     
  }

  render() {
    const {errors} = this.state;
    document.title ="Manage Subscription"; 
    document.body.classList.add('faded_bg'); 

    return (
        <div> 
            <Header loadFees={this.state.loadFeeSubs}/>
            <section id="middel_area">
                <div className="container">
                <div className="row">
                    <div className="col-sm-3 area_left">
                        <Sidebar loadFees={this.state.loadFeeSubs}/>
                    </div>
                    
                    <div className="col-sm-9 area_right">
                        <div className="white_bx clearfix">
                        <h6>Fees Subscription</h6>

                        {this.state.loading === true ?
                        <div style={{height:"250px"}}>
                            <div className="loader">Loading...</div>
                        </div>

                        :
                        
                        <>


                        
                      
                            <div className="row">
                            {this.state.mainError === "success" &&
                            <div className="col-md-12">
                                <div className="alert alert-success text-center">Subscribed Successfully</div> 
                            </div>
                            }
                            {this.state.mainError === "error" &&
                            <div className="col-md-12">
                                <div className="alert alert-danger text-center">Insufficient USDT Balance</div> 
                            </div>
                            }
                            
                            <div className="row form-group" style={{margin:"0px",padding:"15px"}}>
                                <label style={{color:"#aaa"}}>Current Subscription</label>
                                <br></br>

                                
                                {!this.state.userData.feesValidId &&
                                    this.state.feesPacks.map((row,xid) => (
                                        row.title == "General FESS" &&
                                        <div key={xid} className="col-md-12 subscriptionDiv" style={{marginTop:"20px"}}> 
        
                                            <div className="col-md-1">
                                                <i className="fa fa-check-circle-o activeFees" style={{}}></i>
                                            </div>
                                            <div className="col-md-8" style={{borderLeft:"1px #ddd solid"}}>
                                                <span style={{fontSize: "20px",fontWeight: "300"}}>{row.title}</span>
                                                <div className="clearfix"></div>
                                                <div style={{color:"#aaa",margin:"0px"}}>{row.feePerText}</div>
                                                <div style={{color:"#aaa",margin:"0px"}}>{row.validText}</div>
                                            </div>
                                            <div className="col-md-3 text-center">
                                                <div style={{fontSize:"20px",fontWeight:"500",lineHeight:"35px"}}>FREE</div>
                                                <button className="btn btn-default">Subscribed</button>
        
                                            </div>
                                        
        
                                        </div>
                                        ))
                                }
                                { Math.floor(Date.now() / 1000) > Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))  ?
                                <>
                                {/* <div className="col-md-12 subscriptionDiv" style={{}}> 
                                    <div className="col-md-1">
                                        <i className="fa fa-check-circle-o activeFees" style={{}}></i>
                                    </div>
                                    <div className="col-md-8" style={{borderLeft:"1px #ddd solid"}}>
                                  
                                        <span style={{fontSize: "20px",fontWeight: "300"}}>General FESS</span>
                                        <div className="clearfix"></div>
                                        <div style={{color:"#aaa",margin:"0px"}}>Discounted Trading Fees of 0.20%</div>
                                        <div style={{color:"#aaa",margin:"0px"}}>Valid till available FESS Token balance is <b>{this.state.FESSstakeamt} FESS</b></div>
                                    </div>
                                    <div className="col-md-3 text-center">
                                        <div style={{fontSize:"20px",fontWeight:"500",lineHeight:"35px"}}>FREE</div>
                                        <button className="btn btn-default">Subscribed</button>

                                    </div>
                                </div> */}

                                {this.state.feesPacks.map((row,xid) => (
                                row.title == "General FESS" &&
                                <div key={xid} className="col-md-12 subscriptionDiv" style={{marginTop:"20px"}}> 

                                    <div className="col-md-1">
                                        <i className="fa fa-check-circle-o activeFees" style={{}}></i>
                                    </div>
                                    <div className="col-md-8" style={{borderLeft:"1px #ddd solid"}}>
                                        <span style={{fontSize: "20px",fontWeight: "300"}}>{row.title}</span>
                                        <div className="clearfix"></div>
                                        <div style={{color:"#aaa",margin:"0px"}}>{row.feePerText}</div>
                                        <div style={{color:"#aaa",margin:"0px"}}>{row.validText}</div>
                                    </div>
                                    <div className="col-md-3 text-center">
                                        <div style={{fontSize:"20px",fontWeight:"500",lineHeight:"35px"}}>FREE</div>
                                        <button className="btn btn-default">Subscribed</button>

                                    </div>
                                

                                </div>
                                ))}
                                </>
                                :
                                <>
                                {this.state.feesPacks.map(row => (
                                row._id === this.state.userData.feesId &&
                                <div className="col-md-12 subscriptionDiv" style={{marginTop:"20px"}}> 

                                    <div className="col-md-1">
                                        <i className="fa fa-check-circle-o activeFees" style={{}}></i>
                                    </div>
                                    <div className="col-md-8" style={{borderLeft:"1px #ddd solid"}}>
                                        <span style={{fontSize: "20px",fontWeight: "300"}}>{row.title}</span>
                                        <div className="clearfix"></div>
                                        <div style={{color:"#aaa",margin:"0px"}}>{row.feePerText}</div>
                                        <div style={{color:"#aaa",margin:"0px"}}>{row.validText}</div>
                                    </div>
                                    <div className="col-md-3 text-center">
                                        <div style={{fontSize:"20px",fontWeight:"500",lineHeight:"35px"}}>{row.usdtPrice} USDT</div>
                                        <button className="btn btn-default">Subscribed</button>

                                    </div>
                                

                                </div>
                                ))}
                                </>
                                }

                                
                                <div className="clearfix"></div>
                                <br></br>
                                <hr></hr>
                                <br></br>
                                <div className="clearfix"></div>
                                <div className="text-center" style={{marginBottom:"20px"}}>
                                    <img src="/images/fesschain.png"></img>
                                </div>

                                {this.state.loadFeeSubs == true ?
                                <>
                                <div className="col-md-6" style={{padding:"0px"}}>
                                    <label style={{color:"#aaa"}}>Select Subscription</label>
                                </div>
                                <div className="col-md-6 text-right" style={{padding:"0px"}}>
                                    <label style={{color:"#aaa"}}>Available Balance: {Number(this.state.userBalance['USDT']).toFixed(6) >= 0 ? Number(this.state.userBalance['USDT']).toFixed(6) : 0.000000} USDT</label>
                                </div>
                                
                                <br></br>
                                {this.state.feesPacks.map((row,xid) => (
                                row.title !== "General FESS" &&
                                <div key={xid} className="col-md-12 subscriptionDiv" style={{marginTop:"20px"}}> 

                                    <div className="col-md-1">
                                        <i className={Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime)) && row._id === this.state.userData.feesId ? "fa fa-check-circle-o activeFees" : "fa fa-check-circle-o"} style={{}}></i>
                                    </div>
                                    <div className="col-md-8" style={{borderLeft:"1px #ddd solid"}}>
                                        <span style={{fontSize: "20px",fontWeight: "300"}}>{row.title}</span>
                                        <div className="clearfix"></div>
                                        <div style={{color:"#aaa",margin:"0px"}}>{row.feePerText}</div>
                                        <div style={{color:"#aaa",margin:"0px"}}>{row.validText}</div>
                                    </div>
                                    <div className="col-md-3 text-center">
                                        <div style={{fontSize:"20px",fontWeight:"500",lineHeight:"35px"}}>{row.usdtPrice} USDT</div>
                                        {
                                        Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime)) && row._id === this.state.userData.feesId ?
                                        <button className="btn btn-default">Subscribed</button>
                                        :
                                        <button className="btn btn-success" onClick={() => { this.subscribeNow(row._id) }} >Subscribe Now</button>
                                        }

                                    </div>
                                

                                </div>
                                ))}
                                </>
                                :
                                <>
                                <div className="col-md-12 text-center">
                                <br></br><span style={{fontSize: "20px",fontWeight: "300"}}>To activate FESS Army Subscription, your balance should be greater than or equal to <br></br><b>{this.state.FESSstakeamt} FESS</b></span>
                                <br></br>
                                </div>
                                </>
                            }
                                
                            </div>
                            
                            
                            </div>
                        
                        
                    
                        
                        </>
                        }
                        </div>


                        <div className="white_bx clearfix">
                        <div className="side_txt">
                            <h6>Subscription History</h6>
                        </div>
                        <div className="table-responsive recent_login">
                            <table className="table table-hover">
                            <thead>
                                <tr>
                                <th>Subscription Detail</th>
                                <th>Validity</th>
                                <th>Fee Percent</th>
                                <th>Amount</th>
                                <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.subsHistory.map((rowMain,xid) => (
                                    
                                    
                                       
                                        <tr key={xid}>
                                        <td>{rowMain.title}</td>
                                        <td>{rowMain.validText}</td>
                                        <td>{rowMain.feePercent}%</td>
                                        <td>{rowMain.amount} USDT</td>
                                        <td>{moment(new Date(rowMain.timestamp * 1000)).format('MM/DD/YYYY hh:MM A')}</td>
                                        </tr>
                                        
                                        
                                 

                                )).reverse()}
                            </tbody>
                            </table>
                        </div>
                        </div>
                        
                    </div>
                    

                </div>
                </div>
            </section>

            <Footer/>

        </div>
    );
  }
}

export default Setting;
import React, { useEffect, useMemo, useState } from 'react'
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { Link, useLocation, useParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
// import axios from 'axios';
import axios from './httpInterceptor';
import { BASE_URL } from "../../config/constant";
import Pagination from 'react-js-pagination';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';

export default function CoinTransaction() {
    let userId = localStorage.getItem('userDetails')
    
    const [loading, setLoading] = useState(true);
    const [withdrawalHistory, setWithdrawalHistory] = useState()
    const [depositHistory, setDepositHistory] = useState();
    const [tnxType, setTnxType] = useState('');
    const [coinName, setCoinName] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [rangeValue, setRangeValue] = useState([moment(new Date).subtract(1,'month').toDate(), new Date()])
    const { search } = useLocation();
    const qp = new URLSearchParams(search);
    const [tempArray, setTempArray] = useState([])
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    useEffect(() => {
        let tnxType = (search, qp.get('type'));
        setTnxType(tnxType);
        const c = (search, qp.get('coin'))
        setCoinName(c); 
    }, [])
    useEffect(() => {
        if (coinName) {
            if (tnxType == 'withdraw') {
                getWithdrawList();
            } else {
                getDepositList();
            }
        }
    }, [coinName])
    useEffect(() => {
        if (coinName) {
            if (tnxType == 'withdraw') {
                getWithdrawList();
            } else {
                getDepositList();
            }
        }
    }, [activePage])
    const getWithdrawList = (dr = rangeValue) => {
        let data = {
            "userId": userId,
            "coinName": coinName,
            "fromDate": dr[0],
            "toDate": dr[1],
            "pageNumber":activePage
        }
        axios.post(BASE_URL + `withdrawal/get/coinhistorywithdaterange`, data)
            .then(res => {
                // console.log(res.data.data);
                if (res.data.message == "success") {
                    setWithdrawalHistory(res.data.data)
                    setTempArray(res.data.data);
                    setTotalItemsCount(res.data.totalrecords);
                }
                if (res.data.message == "walletDoesNotExist") {
                    // console.log("Wallet not found");
                }
                setLoading(false)
            })
    }
    const getDepositList = (dr= rangeValue) => {
        let data = {
            "userId": userId,
            "coinName": coinName,
            "fromDate": dr[0],
            "toDate": dr[1],
            "pageNumber":activePage
        }
        axios.post(BASE_URL + `depositehistory/get/withdaterange`, data)
            .then(res => {
                // console.log(res.data.data);
                if (res.data.message == "success") {
                    setDepositHistory(res.data.data)
                }
                if (res.data.message == "walletDoesNotExist") {
                    // console.log("Wallet not found");
                }
                setLoading(false)
            })
    }
    const onPick =(e)=>{
        setRangeValue(e)
        let tnxType = (search, qp.get('type'));

        if (tnxType == 'withdraw') {
            getWithdrawList(e);
        } else {
            getDepositList(e);
        }
    }
    const handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
        // setWithdrawalHistory([tempArray[pageNumber - 1]])
    }
    return (
        <div>
            <Header loadFees={false} />
            <section id="middel_area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 col-sm-3 area_left">
                            <Sidebar loadFees={false} />
                        </div>
                        <div className="col-md-10 col-sm-9 area_right">
                            <div className='white_bx clearfix'>
                            <div className='date_range'>
                                <DateRangePicker
                                    onChange={onPick}
                                    value={rangeValue}
                                    className="range"
                                    maxDate={new Date()}
                                />
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Coin</th>
                                            <th>Amount</th>
                                            <th>Fees</th>
                                            <th>Wallet Address / Details</th>
                                            <th>Date & Time</th>
                                            <th>Status</th>

                                        </tr>
                                    </thead>

                                    {loading ?
                                        <tbody>
                                            <tr>
                                                <td colSpan="6" height="350px">
                                                    <div className="loader">Loading...</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        :
                                        <>
                                        {tnxType ==='withdraw' && withdrawalHistory ?
                                                <tbody>
                                                    {withdrawalHistory.map((d, xid) => (
                                                        <tr key={xid}>
                                                            <td><img src={"/coinicon/" + d.coinName + ".png"} width="20px" height="20px" style={{ marginLeft: "0px", marginRight: "10px" }} /> {d.coinName}</td>
                                                            <td><b>{new BigNumber(d.amount).toFormat(6, 1)}</b></td>
                                                            <td>{new BigNumber(d.fees).toFormat(6, 1)}</td>
                                                            <td>{d.walletAddress}<div>
                                                                {d.extratag != "" && d.coinName == "XRP" ?
                                                                    "Destination Tag : " + d.extratag : <></>
                                                                }
                                                                {d.extratag != "" && d.coinName == "XLM" ?
                                                                    "Memo : " + d.extratag : <></>
                                                                }

                                                                {d.coinName == "INR" &&
                                                                    <>
                                                                        <b>Bank Name</b> : {d.bankName}<br></br>
                                                                        <b>Account Type</b> : {d.accountType}<br></br>
                                                                        <b>Account Number</b> : {d.accountNumber}<br></br>
                                                                        <b>IFSC</b> : {d.ifsc}<br></br>
                                                                        <b>Remark</b> : {d.remark}<br></br>
                                                                    </>
                                                                }
                                                            </div></td>
                                                            <td>{d.date}</td>
                                                            <td>
                                                                {d.status == "Pending" && <span className="label label-default">Pending</span>}
                                                                {d.status == "Processing" && <span className="label label-primary">Processing</span>}
                                                                {d.status == "Rejected" && <span className="label label-danger">Rejected</span>}
                                                                {d.status == "Completed" && <><span className="label label-success">Completed</span>
                                                                    {d.coinName !== "INR" &&
                                                                        <>
                                                                            <br></br>
                                                                            <a href={
                                                                                d.coinName == "BTC" ?
                                                                                    "https://www.blockchain.com/btc/tx/" + d.txhash
                                                                                    :
                                                                                    d.coinName == "XRP" ?
                                                                                        "https://bithomp.com/explorer/" + d.txhash
                                                                                        :
                                                                                        d.coinName == "LTC" ?
                                                                                            "https://live.blockcypher.com/ltc/tx/" + d.txhash
                                                                                            :
                                                                                            d.coinName == "DASH" ?
                                                                                                "https://live.blockcypher.com/dash/tx/" + d.txhash
                                                                                                :
                                                                                                d.coinName == "BCH" ?
                                                                                                    "https://explorer.bitcoin.com/bch/tx/" + d.txhash
                                                                                                    :
                                                                                                    d.coinName == "TRX" ?
                                                                                                        "https://tronscan.org/#/transaction/" + d.txhash
                                                                                                        :
                                                                                                        d.coinName == "XLM" ?
                                                                                                            "https://steexp.com/tx/" + d.txhash
                                                                                                            :
                                                                                                            d.coinName == "ETC" ?
                                                                                                                "https://blockscout.com/etc/mainnet/tx/" + d.txhash
                                                                                                                :
                                                                                                                "https://etherscan.io/tx/" + d.txhash

                                                                            } target="_blank" className="label label-default" style={{ color: "#fff" }}>View Tx</a></>}
                                                                </>}
                                                            </td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                                :<>
                                               {tnxType ==='withdraw'? <tbody>
                                                    <tr>
                                                        <td colspan="5" align="center" className="no_data"><i className="fa fa-file-o"></i> No record found</td>
                                                    </tr>
                                                </tbody>:null}
                                                </>
                                            }
                                            <>
                                             { tnxType ==='deposit' &&  depositHistory ?
                                                <tbody>
                                                    {depositHistory.map((d, xid) => (
                                                        <tr key={xid}>
                                                            <td><img src={"/coinicon/" + d.coinName + ".png"} width="20px" height="20px" style={{ marginLeft: "0px", marginRight: "10px" }} /> {d.coinName}</td>
                                                            <td><b>{new BigNumber(d.amount).toFormat(6, 1)}</b></td>
                                                            <td>{new BigNumber(d.fees).toFormat(6, 1)}</td>
                                                            <td>{d.walletAddress}<div>
                                                                {d.extratag != "" && d.coinName == "XRP" ?
                                                                    "Destination Tag : " + d.extratag : <></>
                                                                }
                                                                {d.extratag != "" && d.coinName == "XLM" ?
                                                                    "Memo : " + d.extratag : <></>
                                                                }

                                                                {d.coinName == "INR" &&
                                                                    <>
                                                                        <b>Bank Name</b> : {d.bankName}<br></br>
                                                                        <b>Account Type</b> : {d.accountType}<br></br>
                                                                        <b>Account Number</b> : {d.accountNumber}<br></br>
                                                                        <b>IFSC</b> : {d.ifsc}<br></br>
                                                                        <b>Remark</b> : {d.remark}<br></br>
                                                                    </>
                                                                }
                                                            </div></td>
                                                            <td>{d.date}</td>
                                                            <td>
                                                                {d.status == "Pending" && <span className="label label-default">Pending</span>}
                                                                {d.status == "Processing" && <span className="label label-primary">Processing</span>}
                                                                {d.status == "Rejected" && <span className="label label-danger">Rejected</span>}
                                                                {d.status == "Completed" && <><span className="label label-success">Completed</span>
                                                                    {d.coinName !== "INR" &&
                                                                        <>
                                                                            <br></br>
                                                                            <a href={
                                                                                d.coinName == "BTC" ?
                                                                                    "https://www.blockchain.com/btc/tx/" + d.txid
                                                                                    :
                                                                                    d.coinName == "XRP" ?
                                                                                        "https://bithomp.com/explorer/" + d.txid
                                                                                        :
                                                                                        d.coinName == "LTC" ?
                                                                                            "https://live.blockcypher.com/ltc/tx/" + d.txid
                                                                                            :
                                                                                            d.coinName == "DASH" ?
                                                                                                "https://live.blockcypher.com/dash/tx/" + d.txid
                                                                                                :
                                                                                                d.coinName == "BCH" ?
                                                                                                    "https://explorer.bitcoin.com/bch/tx/" + d.txid
                                                                                                    :
                                                                                                    d.coinName == "TRX" ?
                                                                                                        "https://tronscan.org/#/transaction/" + d.txid
                                                                                                        :
                                                                                                        d.coinName == "XLM" ?
                                                                                                            "https://steexp.com/tx/" + d.txid
                                                                                                            :
                                                                                                            d.coinName == "ETC" ?
                                                                                                                "https://blockscout.com/etc/mainnet/tx/" + d.txid
                                                                                                                :
                                                                                                                d.coinName == 'ECNA'?
                                                                                                                "https://evm.ecannacoin.com/tx/" + d.txid
                                                                                                                :
                                                                                                                "https://etherscan.io/tx/" + d.txid

                                                                            } target="_blank" className="label label-default" style={{ color: "#fff" }}>View Tx</a></>}
                                                                </>}
                                                            </td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                                :
                                                <>
                                                {tnxType ==='deposit' ? <tbody>
                                                    <tr>
                                                        <td colspan="5" align="center" className="no_data"><i className="fa fa-file-o"></i> No record found</td>
                                                    </tr>
                                                </tbody> : null}
                                                </>
                                            }
                                        </>
                                        </>
                                    }

                                </table>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px', float: 'right' }}>
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={(e) => handlePageChange(e)}
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

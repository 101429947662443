import React from 'react'
const HowToUse= ()=>{
return(
    <section id="how_use">
<div class="container">
<div class="row">
<div class="col-xs-12 heading_part">
<h2>How to use</h2>
<p>Check out our guides to getting started with cryptocurrency.</p>
</div>
</div>

<div class="row">
<div class="col-md-4 col-sm-6 col-xs-6 guide_info text-center">
<div class="guide_bx clearfix">
<div class="guide_icon text-center"><img src="images/guide_icon1.png" alt="img"/></div>
<h4>2FA Guide</h4>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
</div>
<div class="col-md-4 col-sm-6 col-xs-6 guide_info text-center">
<div class="guide_bx clearfix">
<div class="guide_icon text-center"><img src="images/guide_icon2.png" alt="img"/></div>
<h4>Withdrawal Address Whitelist</h4>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
</div>
<div class="col-md-4 col-sm-6 col-xs-6 guide_info text-center">
<div class="guide_bx clearfix">
<div class="guide_icon text-center"><img src="images/guide_icon3.png" alt="img"/></div>
<h4>Anti-Phishing Code Guide</h4>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
</div>
<div class="col-md-4 col-sm-6 col-xs-6 guide_info text-center">
<div class="guide_bx clearfix">
<div class="guide_icon text-center"><img src="images/guide_icon4.png" alt="img"/></div>
<h4>How to Deposit on Binance</h4>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
</div>
<div class="col-md-4 col-sm-6 col-xs-6 guide_info text-center">
<div class="guide_bx clearfix">
<div class="guide_icon text-center"><img src="images/guide_icon5.png" alt="img"/></div>
<h4>What is a Market Order?</h4>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
</div>
<div class="col-md-4 col-sm-6 col-xs-6 guide_info text-center">
<div class="guide_bx clearfix">
<div class="guide_icon text-center"><img src="images/guide_icon6.png" alt="img"/></div>
<h4>What is a Limit Order?</h4>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
</div>
</div>


</div>
</section>
)
}
export default HowToUse
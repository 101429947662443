import React from 'react';
import { Link, Redirect } from "react-router-dom";

const downloadAppSection=()=>{
    return(
      <>
        <section id="cta_part">
  	<div className="container">
      <div className='color_bx clearfix'>
    <div className="row flex_box">
      <div className="col-sm-7 stripe_info">
        <h2><span>Sign up now</span> to build your own portfolio for free!</h2>         
      </div>
      <div className='col-sm-5 text-center'>
      <a  className='orange_btn'> <Link to="/register" >  Get Started Now</Link></a>
      </div>
      </div>
    </div>
  </div>
</section>
</>
    )
}

export default downloadAppSection
import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { toast, ToastContainer } from 'react-toastify';
// import axios from 'axios';
import axios from './httpInterceptor';
import { BASE_URL } from "../../config/constant";
// import QRCode from "qrcode.react";
import moment from "moment";
import Pagination from "react-js-pagination";
import speakeasy from "@levminer/speakeasy";
import { RepayModal }  from '../../Components/repay/repay';
import Loader from '../../React-Loader/loader';

var page = 1;
var id;
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}
class BorrowHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            loading : false
        };
    }



    getBorrowHistory = async () => {
        this.setState({loading : true})
        let userId = localStorage.getItem('userDetails')
        await axios.post(BASE_URL + `coin/get/borrow`, {
            "userId": userId
        })
            .then(res => {
                // console.log(res);
                if (res.data.statusCode == 200) {
                    this.setState({
                        userData: res.data.data?res.data.data : [],
                        loading : false
                    })

                }

                // this.setState({ loading: false })

            })
    }

    async componentDidMount() {
        if (!localStorage.getItem('userDetails')) {
            this.props.history.push('/login')
        } else {
            this.setState({ userId: localStorage.getItem('userDetails') })

            this.getBorrowHistory()



        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({ error: null })
        this.setState({ errorSuccess: null })
        switch (name) {
            case 'oldpassword':
                errors.oldpassword =
                    value.length < 8
                        ? 'Old Password must be 8 characters long!'
                        : '';
                break;
            case 'newpassword':
                errors.newpassword =
                    value.length < 8
                        ? 'New Password must be 8 characters long!'
                        : '';
                break;
            case 'cnfpassword':
                errors.cnfpassword =
                    value.length < 8
                        ? 'Confirm Password must be 8 characters long!'
                        :
                        this.state.newpassword !== value
                            ? 'New Password & Confirm Password should match!'
                            : '';
                break;
            case 'twofa':
                errors.twofa =
                    value.length < 6
                        ? 'Please enter valid 2FA code'
                        : '';
                break;


            default:
                break;
        }

        this.setState({ errors, [name]: value });
    }





    async handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        page = pageNumber
        await axios.post(BASE_URL + `loginhistory/getall`, {
            "userId": this.state.userId,
            "pageNumber": 1
        })
            .then(res => {
                // console.log("user id",this.state.userId)
                // console.log(res);
                if (res.data.message === "success") {
                    this.setState({ loginHistory: res.data.data })

                    // console.log(res.data.data)

                }
            })
    }

    render() {
        const { errors } = this.state;
        document.title = "Borrow History";
        document.body.classList.add('faded_bg');
        console.log("User two factor: ", this.state.loginHistory)

        return (
            <div>
                <Header loadFees={this.state.loadFeeSubs} />
                {this.state.loading? <Loader />   : <></>  }
                <ToastContainer /> 
                <section id="middel_area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 col-sm-3 area_left">
                                <Sidebar loadFees={this.state.loadFeeSubs} />
                            </div>

                            <div className="col-md-10 col-sm-9 area_right">

                                <div className="white_bx clearfix">
                                    <div class="side_txt">
                                        <h6>Borrow History</h6>
                                    </div>
                                    <div className="table-responsive recent_login">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>Coin</th>
                                                    <th className='text-center'>Price</th>
                                                    <th className='text-center'>Borrow Amount</th>
                                                    <th className='text-center'>Collateral Amount</th>
                                                    <th className='text-center'>Interest Per Hour</th>
                                                    <th className='text-center'>Status</th>
                                                    <th className='text-center'>Action</th>
                                                    <th className='text-right'>Created Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.userData.map((row, xid) => (
                                                    <tr key={xid}>
                                                        <td className='text-center'>{row.walletCode}</td>
                                                        <td className='text-center'>{row.price}</td>
                                                        <td className='text-center'>{row.borrowAmount}</td>
                                                        <td className='text-center'>{row.collateralAmount}</td>
                                                        <td className='text-center'>{row.interestPerHour}</td>
                                                        <td className='text-center'>
                                                           {row.status == 0 && <a className='btn btn-danger btn-sm'>Pending</a>}
                                                           {row.status == 1 && <a className='btn btn-success btn-sm'>Confirmed</a>}
                                                        </td>
                                                        <td className={row.status == 1 ? 'pointerEventNone text-center' : 'pointerEventvisibleFill text-center'} >
                                                        <RepayModal id={row._id} tokenSymbol={row.walletCode} tokenName={row.walletCode} intPerHour={row.interestPerHour} bal={Number(row.collateralAmount).toFixed(6)}  />

                                                        </td>
                                                        <td className='text-right'>{row.createdAt}</td>
                                                    </tr>
                                                )).reverse()}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ marginTop: '20px', float: 'right' }}>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={this.state.count}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default BorrowHistory;
import React, { useEffect, useState } from "react";
import Loader from "../../../React-Loader/loader";
import axiosInstance from "../../Dashboard/httpInterceptor";
import { BASE_URL } from "../../../config/constant";
import { ToastContainer, toast } from "react-toastify";

function AddPaymentMethods({ show, handleClose, paymentMode }) {
  const [loading, setLoading] = useState(false);
  const [paymentModesMaster, setPaymentModesMaster] = useState([]);
  const [selectedMode, setSelectedMode] = useState(paymentMode || '')
  // const [selectedPaymentModes, setSelectedPaymentModes] = useState({UPI:{upi: '9889321110@ybl', name: 'Raghav Mittal', qr:''}, IMPS:null, BankTransfer:null})
const [upiFormValues, setUpiFormValues] = useState({})
const [IMPSFormValues, setIMPSFormValues] = useState({})
const [bankTransferFromValues, setBankTransferFromValues] = useState({})
const [user, setUser] = useState(null)
  useEffect(() => {
    const usrstr = localStorage.getItem('ECannaUser')
    const userD = usrstr ? JSON.parse(usrstr): null;
    setUser(userD)
    getPaymentModes();
  }, []);
  useEffect(() => {
    if (user && user?._id) {
      getUserPaymentMethods()
    }
  }, [user]);

  const getPaymentModes = () => {
    setLoading(true);
    axiosInstance
      .get(BASE_URL + `get/paymentMethods`)
      .then((res) => {
        setPaymentModesMaster(res.data?.data || []);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const getUserPaymentMethods = () => {
    setLoading(true);
    axiosInstance
      .get(BASE_URL + `get/userPaymentMethods?userId=${user?._id || ''}`)
      .then((res) => {
        const data = (res.data?.data);
        setUpiFormValues(data?.UPI)
        setIMPSFormValues(data?.IMPS)
        setBankTransferFromValues(data?.BankTransfer )
        onPaymentModeAdd(!data?.UPI ? 'UPI': !data?.IMPS ? 'IMPS': 'BankTransfer')
        setLoading(false);
        if(!data?.BankTransfer){
          getUsersBankDetailsFromProfile()
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const getUsersBankDetailsFromProfile = ()=>{
    axiosInstance.post(BASE_URL + `userInformation/get`, {
      "id": user?._id
  }).then((res)=>{
    const d = res?.data?.data?.bankDetail
    const details ={
      accountNo: d?.acno || '',
      ifsc: d?.ifsc || '',
      bankName:d?.bankName || '',
      accountType:d?.accountType || '',
      branchName: d?.branchName || ''
    }
    setBankTransferFromValues(details)
  })
  }
const onPaymentModeAdd= (mode)=>{
    setSelectedMode(mode)
}
 const savePaymentMethods =()=>{
  if(validate() && user){
    const payload={
      userId: user?._id,
      UPI: upiFormValues? {...upiFormValues, userName: user.userName}: upiFormValues,
      IMPS: IMPSFormValues ? {...IMPSFormValues,  userName: user.userName} : IMPSFormValues,
      BankTransfer: bankTransferFromValues ? {...bankTransferFromValues,  userName: user.userName} : bankTransferFromValues
    }
    setLoading(true);
    axiosInstance
      .post(BASE_URL + `p2p/save/userPaymentMethod`, payload)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message)
        handleClose()
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }
 }
const validate =()=>{
  let isValid =false;
  switch (selectedMode) {
    case 'UPI':
      if(!upiFormValues?.upiAddress){
        toast.error('Please enter UPI address')
        isValid = false
      }else{
        isValid = true
      }
      break;

      case 'IMPS':
        if(!IMPSFormValues?.accountNo){
          toast.error('Please enter account No')
          isValid = false
        }else if(!IMPSFormValues?.ifsc){
          toast.error('Please enter IFSC Code')
          isValid = false
        }else if(!IMPSFormValues?.accountType){
          toast.error('Please specify account type')
          isValid = false
        }else if(!IMPSFormValues?.branchName){
          toast.error('Please enter branch name')
          isValid = false
        }else{
          isValid = true
        }
        break;

        case 'BankTransfer':
        if(!bankTransferFromValues?.accountNo){
          toast.error('Please enter account No')
          isValid = false
        }else if(!bankTransferFromValues?.ifsc){
          toast.error('Please enter IFSC Code')
          isValid = false
        }else if(!bankTransferFromValues?.accountType){
          toast.error('Please specify account type')
          isValid = false
        }else if(!bankTransferFromValues?.branchName){
          toast.error('Please enter branch name')
          isValid = false
        }else{
          isValid = true
        }
        break;
  
    default:
      break;
  }
  return isValid;
  }
  return (
    <div>
      {loading && <Loader />}
      <ToastContainer />
      <div
        className={`modal fade in popup_form ${show && "show"}`}
        id="transfer_modal"
        data-backdrop="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content rounded-md">
            <div className="d-flex flex-between px-2 py-2">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Payment Methods
              </h5>
               {/*add payment dropdown  */}
  <div className="d-flex flex-end">
             <div >
                <a
                  href="#"
                  className="dropdown-toggle btn bg-yellow-5 txt-white font-w-600"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-plus"></i>
                  {" Add new payment method"}
                  <span className="caret"> </span>
                </a>
                <ul className="dropdown-menu">
                  {paymentModesMaster.map((modes, i) => (
                    <li key={"buy-" + i} >
                      <a 
                        href="javascript:void"
                        onClick={() => onPaymentModeAdd(modes.paymentMethod)}
                      >
                        {modes.displayName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              </div>
              {/* <button
                type="button"
                className="close font-16"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                <span aria-hidden="true">&times;</span>{" "}
              </button> */}
            </div>
            <div className="modal-body">

              {/* Add Payment method Froms */}

              <div className="pm-bg">
             {selectedMode === 'UPI' ? <form>
                <div className="badges badges-yellow">UPI</div>
                <div style={{ maxHeight: "60vh", overflow: "auto" }}>
                <div className="form-group mt-3">
                    <label htmlFor="upiId">Name</label>
                    <div className="font-w-500" style={{textTransform:'uppercase'}}>{user?.userName}</div>
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="upiId">UPI Address</label>
                    <input type="text" className="form-control"  value={upiFormValues?.upiAddress || ''} onChange={(e)=> setUpiFormValues({...upiFormValues,upiAddress: e.target.value})}/>
                </div>

                </div>
                <div className="mt-4 d-flex flex-end">
                <button  type="button" className='btn ' onClick={()=> handleClose()}>Cancel</button>
                        <button type="button" className='bg-yellow-5 btn txt-white' onClick={()=> savePaymentMethods()}>Save</button>
                </div>
              </form>: 
              selectedMode === 'IMPS' ? <form>
              <div className="badges badges-yellow">IMPS</div>
              <div style={{ maxHeight: "60vh", overflow: "auto" }}>
              <div className="form-group mt-3">
                    <label htmlFor="upiId">Name</label>
                    <div className="font-w-500" style={{textTransform:'uppercase'}}>{user?.userName || ''}</div>
                </div>
              <div className="form-group mt-3">
                  <label htmlFor="upiId">Account No</label>
                  <input type="text" className="form-control"  value={IMPSFormValues?.accountNo || ''} onChange={(e)=> setIMPSFormValues({...IMPSFormValues,accountNo: e.target.value})}/>
              </div>
              <div className="form-group mt-3">
                  <label htmlFor="upiId">IFSC Code</label>
                  <input type="text" className="form-control"  value={IMPSFormValues?.ifsc || ''} onChange={(e)=> setIMPSFormValues({...IMPSFormValues,ifsc: e.target.value})}/>
              </div>
              <div className="form-group mt-3">
                  <label htmlFor="upiId">Bank Name(Optional)</label>
                  <input type="text" className="form-control"  value={IMPSFormValues?.bankName || ''} onChange={(e)=> setIMPSFormValues({...IMPSFormValues,bankName: e.target.value})}/>
              </div>
              <div className="form-group mt-3">
                  <label htmlFor="upiId">Account Type</label>
                  <input type="text" className="form-control"  value={IMPSFormValues?.accountType || ''} onChange={(e)=> setIMPSFormValues({...IMPSFormValues,accountType: e.target.value})}/>
              </div>
              <div className="form-group mt-3">
                  <label htmlFor="upiId">Branch Name</label>
                  <input type="text" className="form-control"  value={IMPSFormValues?.branchName || ''} onChange={(e)=> setIMPSFormValues({...IMPSFormValues,branchName: e.target.value})}/>
              </div>
                </div>

              <div className="mt-4 d-flex flex-end">
              <button type="button" className='btn ' onClick={()=> handleClose()}>Cancel</button>
                      <button  type="button" className='bg-yellow-5 btn txt-white' onClick={()=> savePaymentMethods()}>Save</button>
              </div>
            </form>:
            selectedMode === 'BankTransfer' ? <form>
            <div className="badges badges-yellow">Bank Transfer (India)</div>
            <div style={{ maxHeight: "60vh", overflow: "auto" }}>
            <div className="form-group mt-3">
                  <label htmlFor="upiId">Name</label>
                  <div className="font-w-500" style={{textTransform:'uppercase'}}>{user?.userName || ''}</div>
              </div>
            <div className="form-group mt-3">
                <label htmlFor="upiId">Account No</label>
                <input type="text" className="form-control"  value={bankTransferFromValues?.accountNo || ''} onChange={(e)=> setBankTransferFromValues({...bankTransferFromValues,accountNo: e.target.value})}/>
            </div>
            <div className="form-group mt-3">
                <label htmlFor="upiId">IFSC Code</label>
                <input type="text" className="form-control"  value={bankTransferFromValues?.ifsc || ''} onChange={(e)=> setBankTransferFromValues({...bankTransferFromValues,ifsc: e.target.value})}/>
            </div>
            <div className="form-group mt-3">
                <label htmlFor="upiId">Bank Name(Optional)</label>
                <input type="text" className="form-control"  value={bankTransferFromValues?.bankName || ''} onChange={(e)=> setBankTransferFromValues({...bankTransferFromValues,bankName: e.target.value})}/>
            </div>
            <div className="form-group mt-3">
                <label htmlFor="upiId">Account Type</label>
                <input type="text" className="form-control"  value={bankTransferFromValues?.accountType || ''} onChange={(e)=> setBankTransferFromValues({...bankTransferFromValues,accountType: e.target.value})}/>
            </div>
            <div className="form-group mt-3">
                <label htmlFor="upiId">Branch Name</label>
                <input type="text" className="form-control"  value={bankTransferFromValues?.branchName || ''} onChange={(e)=> setBankTransferFromValues({...bankTransferFromValues,branchName: e.target.value})}/>
            </div>
              </div>

            <div className="mt-4 d-flex flex-end">
            <button  type="button" className='btn ' onClick={()=> handleClose()}>Cancel</button>
                    <button  type="button" className='bg-yellow-5 btn txt-white' onClick={()=> savePaymentMethods()}>Save</button>
            </div>
          </form>:null}
              </div>

            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPaymentMethods;

import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
// import axios from 'axios';
import axios from '../../Pages/Dashboard/httpInterceptor';
import {BASE_URL , IMAGE_START_URL} from "../../config/constant";
import QRCode from "qrcode.react";
import moment from "moment"; 
import TextEllipsis from 'react-text-ellipsis';
import speakeasy from "@levminer/speakeasy";

var imagestartUrl = IMAGE_START_URL
var id ;
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }
class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        data: []
    };
  }





    async componentDidMount() {
        await axios.post(BASE_URL + `announcement/getall`, {
            // "userId": this.state.userId
        })
        .then(res => {
            // console.log("user id",this.state.userId)
            // console.log(res.data);
            if (res.data.message == "success") {
                
                this.setState({
                    data : res.data.data,
                    loading: false,
                })
                
            }
        })
    }




  render() {
    document.title ="Announcements"; 
    document.body.classList.add('faded_bg'); 

    return (
        <div> 
            <Header loadFees={this.state.loadFeeSubs}/>
            <section id="middle_content">
                <div className="container">
                <div className="row">
                    
                    
                    <div className="col-sm-12 area_right">
                        <h3>Announcements</h3>
                        <hr></hr>




                        {this.state.loading == true ?
                        <div style={{height:"250px"}}>
                            <div className="loader">Loading...</div>
                        </div>

                        :
                        
                        <>
                        
                        
{this.state.data.map(row => (
<div class="card col-md-4">
<div class="card col-md-12" style={{border:"1px #ddd solid",padding:"0px",borderRadius:"5px",background:"#fff"}}>
  <img class="card-img-top"  src={imagestartUrl+row.filePath} style={{width:"100%",height:"200px",borderRadius:"5px 5px 0px 0px",borderBottom:"1px #ddd solid"}}/>

  <div class="card-body" style={{padding:"15px",borderRadius:"0px 0px 5px 5px"}}>
    <a href={"/announcement/"+row._id}><h5 class="card-title" style={{marginBottom:"10px"}}>{row.title}</h5></a>
    <p style={{color:"#999",fontSize:"12px",marginBottom:"10px"}}><i class="fa fa-clock-o"></i> {row.date}</p>
    <p class="card-text"></p> 
    <TextEllipsis 
        style={{marginBottom:"10px"}}
        lines={2} 
        tag={'p'} 
        ellipsisChars={'...'} 
        tagClass={'card-text'} 
        debounceTimeoutOnResize={200} 
        useJsOnly={true}>
        {row.body}
    </TextEllipsis>

    <a href={"/announcement/"+row._id} class="btn btn-primary">View More</a>
  </div>
</div>
</div>
))}
                        

                       
                    
                        </>
                        }
                      
                    </div>
                    

                </div>
                </div>
            </section>

            <Footer/>

        </div>
    );
  }
}

export default Setting;